import { autocomplete } from '@algolia/autocomplete-js';
import { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';

export function Autocomplete(props:any) {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children  } : any, root) {
        render(children, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
    // eslint-disable-next-line
  }, []);

  return  <div ref={containerRef} />;
}
