import { Button, Stack, Box } from '@mui/material'
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../../utilities/config';
import swal from 'sweetalert';
import { getVendorListsData, postPreferredVendorList } from '../reducers/vendorListsReducer';

const DialogField : React.FC<any> = ({
    open,
    setOpen,
    dataBuyer,
    name,
}) => {

    const dispatch = useDispatch()

    const store_vendorlists = useSelector((state : RootState) => state.vendor_lists)

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        let body_send = {
            "buyer_id": userCredentials.buyer_id,
            "vendor_id": dataBuyer._id,
            "initial_discount" : null,
            "product" : [],
            "payment_term": null,
            "status" : "Inactive"
        }
        dispatch(postPreferredVendorList(body_send))
    }

    useEffect(() => {
        if(store_vendorlists.post_vendor) {
            dispatch(getVendorListsData())
            swal('Success', 'Success Add Preferred Buyer', 'success')
            handleClose()
        }
        // eslint-disable-next-line
    }, [store_vendorlists.post_vendor]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} maxWidth="lg" fullWidth={true}  >
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>{name}</DialogTitle>
                    <DialogContent>
                        <Stack>
                            { dataBuyer.length !== 0 ? 
                                <Stack>
                                    <Box>Company Name : {dataBuyer.legal_name}</Box>
                                    <Box>Company Code : {dataBuyer.company_code}</Box>
                                    {/* <Box>Address : {dataBuyer.address}</Box> */}
                                </Stack>
                                : null
                            }
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancel</Button>
                        <Button variant="contained" type="submit">
                            { store_vendorlists.loading_post_vendor ? "Loading" : "Request Preferred" } 
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default DialogField
