import { useState, useEffect } from 'react';
import { 
    Box,
    Button,
    Paper,
    Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getOpenPurchaseRequest } from '../purchase_requests/reducers/purchaseRequestReducers';
import DialogOpen from './components/DialogOpen';
import CancelIcon from '@mui/icons-material/Cancel';
import { getTemplatesData } from '../templates/reducers/templatesReducers';
import AlgoliaSearchNew from './components/AlgoliaSearchNew';
import StoreIcon from '@mui/icons-material/Store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WebIcon from '@mui/icons-material/Web';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import swal from 'sweetalert';
import { userCredentials } from '../../../utilities/config';
import axios from 'axios';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import ReactHtmlParser from 'react-html-parser';

const default_image = "https://mpindo.sgp1.digitaloceanspaces.com/vendor/4af15575-6f9c-4ef6-99c3-cf1f25a2193d415069default-image.jpg"

function ProductSearchVendor() {
    const dispatch = useDispatch()
    const store_purchaserequest = useSelector((state : RootState) => state.purchase_request)
    const store_template = useSelector((state : RootState) => state.template)

    const [dataTemplates, setDataTemplates] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});

    const [refetch, setRefetch] = useState(false);

    const [openToko, setOpenToko] = useState(false);

    const handleClickOpen = () => {
      setOpenToko(true);
    };
  
    const handleClose = () => {
      setOpenToko(false);
    };
  

    function getTemplates() {
        setTimeout( async () => {
            let filter = store_template.data
            let array = []
            for(let i of filter) {
                let items : any = []
                for(let k of i.vendors) {
                    for(let h of k.packages){
                        for(let k of h.items) {
                            items.push(k)
                        }
                    }
                }
                array.push({
                    buyerId: i.buyerId,
                    createdAt: i.createdAt,
                    createdBy: i.createdBy,
                    isDeleted: i.isDeleted,
                    name: i.name,
                    total: i.total,
                    updatedAt: i.updatedAt,
                    vendors: i.vendors,
                    items : items,
                    _id :i._id
                })
            }
            setDataTemplates(array)  
        }, 1000);
    }

    useEffect(() => {
        dispatch(getOpenPurchaseRequest())
        dispatch(getTemplatesData())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!store_template.loading) {
            getTemplates()
        }
        // eslint-disable-next-line
    }, [store_template.loading]);



    const addToCart = ( product : any, type : string) => {
        setItemSelected(product)
        if(type === "pr") {
            setOpen(true);
        } else {
            setOpenTemplate(true)
        }
    }

    const getSlugLocalStorage = () => {
        const data = localStorage.getItem('product-store')
        return data ? JSON.parse(data).slug : ""
    }
    
    const getNameLocalStorage = () => {
        const data = localStorage.getItem('product-store')
        return data ? JSON.parse(data).name : ""
    }

    const [loading, setLoading] = useState(false);
    const [detailVendor, setDetailVendor] = useState<any>({});
    const [storeDetail, setStoreDetail] = useState<any>([]);

    useEffect(() => {
        const data = localStorage.getItem('product-store')
        if(data) {
            setLoading(true)
            setTimeout(async() => {
                try {
                    const vendor_detail : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail?vendor_id=${JSON.parse(data).vendor_id}`, {
                      headers : {
                        'Authorization' : `Bearer ${userCredentials.id_token}`
                      }
                    })
                    if(vendor_detail.data.errors === null) {
                        let vendor = vendor_detail.data.data
                        setDetailVendor(vendor)
                        setStoreDetail(vendor.profiles_store)
                        setLoading(false)
                    } else {
                        swal('Error', `${vendor_detail.data.message}`, 'error')
                        setLoading(false)
                    }
                  } catch (error) {
                      swal('Error', `${error}`, 'error')
                      setLoading(false)
                }
            }, 500);
        } else {
            window.location.href = "/dashboard"
        }
    }, []);

    return (
    <div>
        <LoaderBackdrop loading={loading}/>
        { loading ? null :
        <Stack pt={6} pl={1} pr={1}>
            <BreadCrumbs 
                isPage={true}
                link="/dashboard/products"
                second_title="Baju Lebaran"
                slug={getSlugLocalStorage()}
                current="Detail Vendor"
                page='Product Search'
                name={getNameLocalStorage()}
            />

            <Paper sx={{ p : 3, backgroundColor: '#ecf1f7', mt:2 }}>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Stack flexDirection="row" >
                        <Box pr={2}>
                            { storeDetail.length === 0 ? 
                            <img 
                                src={default_image}
                                alt="vendor" width="100px" 
                            /> : 
                            <img 
                                src={storeDetail[0].image_url} 
                                alt="vendor" width="100px" 
                            /> }
                        </Box> 
                        <Box className='vendor-store'>
                            <h2>{detailVendor?.legal_name}</h2>
                            <Stack flexDirection="row" alignItems="center" >
                                <Box><StoreIcon/></Box>
                                <Box>{detailVendor?.category}</Box>
                            </Stack>
                            <Stack flexDirection="row" alignItems="center" >
                                <Box><LocationOnIcon/></Box>
                                <Box>{detailVendor?.city}, {detailVendor?.province}</Box>
                            </Stack>
                            <Stack flexDirection="row" alignItems="center" >
                                <Box pt={1} pr={2}>
                                    <Button variant='contained' size="small" onClick={handleClickOpen}>Info Vendor</Button>
                                </Box>
                                {/* <Box pt={1}>
                                    <Button variant='outlined' size="small">Chat Vendor</Button>
                                </Box> */}
                            </Stack>
                        </Box>
                    </Stack>

                    <Stack flexDirection="column">
                        <Stack flexDirection="row" alignItems="center" >
                            <Box><WebIcon/></Box>
                            { detailVendor.website ? 
                            <Box pl={1}><a href={`https://${detailVendor?.website}`} rel="noreferrer" target="_blank">{detailVendor?.website}</a></Box> :
                            <Box pl={1}>-</Box> }
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" >
                            <Box><InstagramIcon/></Box>
                            <Box pl={1}>{detailVendor?.instagram ? detailVendor?.instagram : "-" }</Box>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" >
                            <Box><FacebookIcon/></Box>
                            <Box pl={1}>{detailVendor?.facebook ? detailVendor?.facebook : "-"}</Box>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" >
                            <Box><TwitterIcon/></Box>
                            <Box pl={1}>{detailVendor?.twitter ? detailVendor?.twitter : "-"}</Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>

            <Stack pt={2} >
                <AlgoliaSearchNew
                    addToCart={addToCart}
                    refetch={refetch}
                    setRefetch={setRefetch}
                    vendor={true}
                    vendor_id={detailVendor?._id}
                    vendor_name={detailVendor?.legal_name}
                />
            </Stack>

            <DialogOpen 
                dataList={store_purchaserequest.open_pr}
                itemSelected={itemSelected} 
                type="pr"
                open={open}
                setOpen={setOpen}
            />
            <DialogOpen 
                dataList={dataTemplates}
                itemSelected={itemSelected}
                type="template"
                open={openTemplate}
                setOpen={setOpenTemplate}
            />
            <Dialog
                open={openToko}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box>Vendor Information</Box>
                        <Box>
                            <CancelIcon
                                onClick={handleClose}
                                sx={{color : "red", cursor: 'pointer'}}
                            />
                        </Box>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    { storeDetail.length ? 
                    <DialogContentText id="alert-dialog-description">
                        { ReactHtmlParser(storeDetail[0].description) }
                    </DialogContentText> 
                    : "-" }
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </Stack> }
    </div>
    )
}

export default ProductSearchVendor;
