import { Box, Paper, Stack, Skeleton } from '@mui/material';

const OrderRejection : React.FC<any> = ({ data }) => {

    return (
        <Box>
           <Paper sx={{ backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`, color: '#fff', p:1 }}>
                { data.loading ?
               <Box>
                    <Skeleton height={70} variant="rectangular" />
               </Box>
               :
               <Box>
                { data.data.order_reject ? 
                <Stack flexDirection="row" justifyContent="space-between" >
                        <Box fontSize={35} fontWeight={400}>{data.data.order_reject.length ? data.data.order_reject[0].count : 0}</Box>
                        <Box fontSize={13} sx={{ textAlign: 'right' }}>
                            <Box>Order Rejection</Box>
                            <Box fontSize={14}>Rp. {data.data.order_reject.length ? data.data.order_reject[0].totalAmount.toLocaleString() : 0} </Box> 
                            <br/>
                        </Box>
                </Stack> : null }
                {/* <Stack flexDirection="row" justifyContent="flex-end">
                        <Box fontSize={13} fontWeight={600} color="red">-7% from a week ago</Box>
                </Stack> */}
               </Box> }
            </Paper>
        </Box>
    )
}

export default OrderRejection
