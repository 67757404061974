import React, { useState, useEffect } from 'react'; 
import { 
    Stack,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Checkbox,
    CircularProgress
} from '@mui/material';
import {  useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../../components/TableData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import moment from 'moment'
import { getDataInvoice, uploadProofPayment } from '../reducers/invoiceReducers';
import swal from 'sweetalert';
import { remove } from 'lodash'
import SearchDataInvoice from './SearchDataInvoice';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { v4 as uuid } from 'uuid';


const TableListInvoice : React.FC<any> = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const store_invoice = useSelector((state : RootState) => state.invoices)

    const [dataImage, setDataImage] = useState<any>("");
    const [loadingImage, setLoadingImage] = useState<any>(false);
    const [selectedRows, setSelectedRows] = useState<any>([]);

    

    const [openPay, setOpenPay] = useState(false);
    const [openTransaction, setOpenTransaction] = useState<any>({
        data : [],
        amount : null, 
        open : false,
        status : ""
    });

    const [loading, setLoading] = useState(true);
    const [dataTable, setDataTable] = useState<any>([]);

    const [selectPayment] = useState("Xendit");
    const [directPayment, setDirectPayment] = useState(null);

    const [copyTrue, setCopyTrue] = useState(false);


    const handleClosePay = () => {
        setOpenPay(false)
        // setSelectedRows([])
    }

    const handleCloseTransaction = () => {
        setOpenTransaction({...openTransaction, open : false})
    }

    // function sumTotalPrice (data : any) {
    //     const totalPriceALl = data.reduce((total : any, data : any) => {
    //       return total + data.grand_total
    //     }, 0)
    //     return totalPriceALl;
    // }

    // function getDocumentReferer (items : any) {
    //     let document_referer = []
    //     for(let element of items) {
    //         document_referer.push(element._id)
    //     }
    //     return document_referer
    // }

    // function getDescriptionInvoice (items : any) {
    //     let description : any = items.map((ele : any) => ele.invoice_number).toString()
    //     return description
    // }

    const onProcessData = (data : any) => {
        let final_array = []
        for(let element of data) {
            final_array.push({...element, checked : false})
        }
        setDataTable(final_array)
        setLoading(false)
    }

    useEffect(() => {
        if(!store_invoice.loading) {
            onProcessData(store_invoice.data)
        }
        // eslint-disable-next-line
    }, [store_invoice.loading]);

    useEffect(() => {
        if(store_invoice.bank_transfer) {
            handleClosePay()
            dispatch(getDataInvoice())
        }
        // eslint-disable-next-line
    }, [store_invoice.bank_transfer]);

    useEffect(() => {
        setLoading(true)
        dispatch(getDataInvoice())
        // eslint-disable-next-line
    }, []);



    function dataCheckItem (data : any) {
        let error = false
        const copy_selected = [...selectedRows]
        if(
            copy_selected[0].vendor._id === data.vendor._id 
            && 
            moment(copy_selected[0].due_date).format('ll') ===  moment(data.due_date).format('ll')
            && copy_selected[0].direct_payment === data.direct_payment ) {
            error = false
        } else {
            error =  true
        }
        return error
    }

    function onChangeSelect (check : any, row: any) {
        const copy_array = [...dataTable]
        const copy_selected = [...selectedRows]


        if(check === true) {
            const newData : any = copy_array.map((obj : any) => {
                if(obj._id === row._id) 
                   return {
                     ...obj,
                     checked: true,
                   }
                return obj
            });
            if(copy_selected.length === 0) {
                setDataTable(newData)
                setSelectedRows([row])
                setDirectPayment(row.direct_payment)
            } else {
                if(!dataCheckItem(row)) {
                    setDataTable(newData)
                    copy_selected.push(row)
                    setSelectedRows(copy_selected)
                } else {
                    swal('Error', 'You cant merge invoice with different due date or different vendor o different payment!', 'error')
                }
            }
        } else {
            const newData = copy_array.map((obj : any) => {
                if(obj._id === row._id)
                   return {
                     ...obj,
                     checked: false,
                   }
                return obj
            });
            setDataTable(newData)

            const items_remove = remove(copy_selected, function(obj : any) {
                return obj._id !== row._id
            });
            setSelectedRows(items_remove)
        }
    }

    const onSelectVirtualAccount = (type : string) => {
        let data_payment = {
            items : selectedRows,
            payment_gateway : selectPayment === "OY" ? "OY Indonesia" : "Xendit",
            type_payment : type
        }
        history.push({ 
            pathname: "/dashboard/finance/invoice",
            search: `?page=payment`, 
            state : {
                data_payment 
            }
        })
    }

    const onSelectDirectPayment = (type : string) => {
        let data_payment = {
            items : selectedRows,
            payment_gateway : selectPayment === "OY" ? "OY Indonesia" : "Xendit",
            type_payment : type,
            direct_payment : directPayment
        }
        history.push({ 
            pathname: "/dashboard/finance/invoice",
            search: `?page=payment`, 
            state : {
                data_payment 
            }
        })
    }

   const onClickProceed = (row : any) => {
    
        let data_invoice : any = {
            invoice_number : row.invoice_number,
            items : row.items,
            reference_document : row.reference_document,
            buyer : row.buyer, 
            vendor : row.vendor,
            down_payment: row.down_payment,
            status : row.lastStatus,
            direct_payment : row.direct_payment,
            detail : row,
            shipping_costs : row.shipping_costs
        }
        history.push({
            pathname: '/dashboard/finance/invoice',
            search: `?page=summary`, 
            state : {
                data_invoice,
                name : 'detail'
            }
        })
    }

    const handleImageChange = (e : any) => {
        const random = uuid();
        setLoadingImage(true)
        setDataImage("")
        setTimeout(() => {
            var S3 = require("aws-sdk/clients/s3");
            const s3bucket = new S3({
                endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT,
                accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
                secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
            });
        
            if (e.target.files && e.target.files[0]) {
            const blob = e.target.files[0]
            const file_name = blob.name.replace(/\s/g, "")
            const params = { Body: blob, 
                            Bucket: process.env.REACT_APP_S3_BUCKET_NAME, 
                            Key: 'buyer/' + random + file_name
                            };
            s3bucket.putObject(params)
            .on('build', (request : any) => {
                request.httpRequest.headers.Host = process.env.REACT_APP_S3_API_URL
                request.httpRequest.headers['Content-Length'] = blob.size;
                request.httpRequest.headers['Content-Type'] = blob.type;
                request.httpRequest.headers['Access-Control-Allow-Origin']= '*';
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
            })
            .send((err : any, data : any) => {
                if (err){  
                    console.log(err, err.stack,);
                } 
                else {      
                    const imageUrl = `${process.env.REACT_APP_S3_CDN_URL}${random}${file_name}`
                    setLoadingImage(false)
                    setDataImage(imageUrl)
                }}
            )} 
        }, 1000);
    }

    const onClickSubmit = () => {
        if(dataImage !== "") {
            const body = {
                "code": openTransaction.data[0].code,
                "image": dataImage,
            }
            dispatch(uploadProofPayment(body))
        }
    }

    useEffect(() => {
        localStorage.removeItem('transaction_id')
    }, []);

    useEffect(() => {
        if(store_invoice.proof_payment) {
            handleCloseTransaction()
            setLoading(true)
            dispatch(getDataInvoice())
        }
        // eslint-disable-next-line
    }, [store_invoice.proof_payment]);
    
    const columns: TableColumn<any>[] = [
        {
            name: '',
            width: '70px',
            cell: (row, index) => (
                <div>
                    {row.lastStatus === "Paid" || row.lastStatus === "Waiting Payment" || row.lastStatus === "Review Payment" ? null :
                    <Checkbox checked={row.checked} onChange={(e) => onChangeSelect(e.target.checked, row)} /> }
                </div>
            )
        },
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'VENDOR',
            selector: row => row.vendor.name,
            width: '200px'
        },
        {
            name: 'INVOICE NUMBER',
            selector: row => row.invoice_number,
            width: '200px'
        },
        {
            name: 'TOTAL',
            width: '200px',
            cell: (row) => (
                <div>Rp. {row.grand_total.toLocaleString()}</div>
            )
        },
        {
            name: 'DUE DATE',
            cell: (row) => (
                <div>{moment(row.due_date).format('ll')}</div>
            )
        },
        {
            name: 'STATUS',
            cell: (row) => (
                <div>{row.lastStatus === undefined ? "Waiting Payment" : row.lastStatus}</div>
            )
        },
        {
            name: 'ACTION',
            width: '300px',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                onClickProceed(row)
                                
                            }}
                        >   
                            Detail Invoice
                        </Button>
                    </Box> 
                    { row.lastStatus !== "Review Payment" && row.lastStatus !== "Waiting Payment" ? null : 
                    <Box>
                        <Button 
                            variant="outlined" 
                            color="error" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                               setOpenTransaction({...openTransaction, open: true, data : row.infoPayment, amount : row.grand_total, status: row.lastStatus })
                            }}
                        >   
                            Transaction
                        </Button>
                    </Box> }
                </Stack>
            ),
        },
    ];

    return (
        <div>
            <Stack direction="row" justifyContent="space-between" pb={3} >
                <Box>
                    <h3>Invoice Lists</h3>
                </Box>
                <Button 
                    disabled={selectedRows.length === 0 ? true : false}
                    variant="contained" color="primary" size="small"
                    onClick={() => {
                        if(directPayment) {
                            onSelectDirectPayment("Direct Payment")                        
                        } else {
                            setOpenPay(true)
                        }
                    }}
                >
                    Pay Invoices
                </Button>
            </Stack>
            <Box pb={2}>
                <SearchDataInvoice 
                    data={dataTable}
                    progressPending={loading}
                    setLoading={setLoading}
                />
            </Box>

            <DataTableBase 
                columns={columns}
                data={dataTable}
                progressPending={loading}
                pagination
                noDataComponent="You dont have any Invoice Yet!"
            />

            <Dialog 
                onClose={handleClosePay}
                aria-labelledby="simple-dialog-title"  
                open={openPay}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContent>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box fontWeight="600">Select Payment Method </Box>
                        {/* { selectPayment === "" ? null :
                        <Box>
                            <Button onClick={() => setSelectPayment("")} color="info">Back</Button>
                        </Box> } */}
                    </Stack>
                    <Stack pt={2} pb={2}>
                        {/* { selectPayment === "" ? 
                        <Box>
                            <Stack className='box-payment-choose' sx={{ backgroundColor: '#4066af' }}>
                                <Box p={2} 
                                    onClick={() => setSelectPayment("OY Indonesia")}
                                >
                                    Pay with OY 
                                </Box> 
                            </Stack>
                            <Stack className='box-payment-choose' sx={{ backgroundColor: '#4373fc'  }}>
                                <Box p={2} onClick={() => setSelectPayment("Xendit")}>
                                    Pay with Xendit
                                </Box>
                            </Stack>
                        </Box>
                        : */}

                        <Box>
                            { selectPayment === "OY" ? 
                            <>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e' }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Virtual Account")} >
                                    Virtual Account
                                </Box>
                            </Stack>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e'  }}>
                                <Box p={2} 
                                    onClick={() => onSelectVirtualAccount("Manual Bank Transfer")}
                                >
                                    Manual Bank Transfer
                                </Box>
                            </Stack>
                            </> 
                            : selectPayment === "Xendit" ? 
                            <>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e' }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Virtual Account")} >
                                    Virtual Account
                                </Box>
                            </Stack>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e'  }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Manual Bank Transfer")}>
                                    Manual Bank Transfer
                                </Box>
                            </Stack>
                            </> : null }
                        </Box>
                        {/* } */}
                    </Stack>
                </DialogContent>
            </Dialog>

            <Dialog 
                onClose={handleCloseTransaction}
                aria-labelledby="simple-dialog-title"  
                open={openTransaction.open}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogContent>
                    { openTransaction.data.length !== 0 ?
                    <Stack pt={2} pb={2} flexDirection="column" justifyContent="center" alignItems="center">
                        <Box fontWeight="500" fontSize={19}>{openTransaction.status}</Box>
                        {openTransaction.status === "Waiting Payment" ?
                        <Box pb={2}>Please make a payment to bank account account below.</Box> : null }
                        <Box mt={1}> Amount : <strong>Rp. { parseInt(openTransaction.amount).toLocaleString() }</strong></Box>
                        <Box mt={1}> Method : <strong>{ openTransaction.data[0].method === "VA" ?  "Virtual Account" : openTransaction.data[0].method }</strong></Box>
                        <Box mt={1}> Bank Name : <strong>{ openTransaction.data[0].bank }</strong></Box>
                        <Stack mt={1} flexDirection="row"  alignItems="center" >
                            <Box> { openTransaction.data[0].method === "VA" ?  "Virtual Account Number" : "Bank Account Number" } : <strong>{ openTransaction.data[0].directions }</strong></Box>
                            <Box pl={2}>
                                <CopyToClipboard 
                                    text={`${openTransaction.data[0].directions}`}
                                    onCopy={() => {
                                        setCopyTrue(true)
                                        setTimeout(() => {
                                            setCopyTrue(false)
                                        }, 2000);
                                    }}
                                > 
                                    <Box
                                    sx={{ 
                                        cursor: 'pointer', 
                                        color: copyTrue ? 'green' : 'black'
                                    }}
                                    >
                                        <FileCopyIcon />
                                    </Box>
                                </CopyToClipboard>
                            </Box>
                        </Stack>
                        { copyTrue ? <Box sx={{color: 'green', pt: 1}}>Virtual Account Success Copied</Box> : null }
                        
                        {  openTransaction.data[0].method === "VA" ? null : 
                        <Box>
                            { openTransaction.data[0].imageUrl === undefined ? 
                            <Stack mt={1} flexDirection="column">
                                <Box pb={1}><strong>Upload Your Proof Payment</strong></Box>
                                <Box>
                                    <Box>
                                        { loadingImage ? "Loading... " : 
                                        <label htmlFor={`file-upload-proof`}>
                                            { dataImage !== "" ? null :
                                            <input  
                                                onChange={handleImageChange}
                                                type="file" 
                                                accept="image/*"
                                                id={`file-upload-proof`}
                                            /> 
                                            }
                                        </label>
                                        }
                                    </Box>
                                    { dataImage === "" ? null :
                                    <Box>
                                        <img alt="icon file" width="300" src={dataImage} />
                                    </Box>
                                    }
                                </Box>
                                <Box mt={2}>
                                    <Button 
                                        variant="contained"
                                        size='small'
                                        disabled={dataImage === "" ? true : false}
                                        onClick={() => onClickSubmit()}
                                    >
                                        { store_invoice.loading_proof_payment ? 
                                            <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                                            "Submit Proof"
                                        }
                                    </Button>
                                </Box>
                            </Stack>
                            :
                            <Stack mt={1} flexDirection="column">
                                <Box pb={1}><strong>Proof Payment :</strong></Box>
                                <Box>
                                    <img alt="icon file" width="300" src={openTransaction.data[0].imageUrl} />
                                </Box>
                            </Stack> }
                        </Box> }

                    </Stack> : null }
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="error"  size="small"  onClick={handleCloseTransaction}>close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TableListInvoice
