// @ts-ignore
import { MainContainer } from '@chatscope/chat-ui-kit-react'
import ChatAction from './components/ChatAction'
import ChatList from './components/ChatList'

function ChatPage() {
  return(
    <div style={{
      height: "500px",
      position: "relative",
      width:'95%',
      margin:'auto'
    }}>
      <MainContainer responsive>
        <ChatList/>
        <ChatAction/>
      </MainContainer>
    </div>
  )
}

export default ChatPage
