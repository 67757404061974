import { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import { UserSuperadminInput } from './teamsTypes';
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { 
    ISelectOption,
} from '../globalTypes'
import { userCredentials } from '../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { disableUserTeams, getAllUsersTeams, postUserTeams, updateUserTeams } from './reducers/teamsReducers';
import Swal from 'sweetalert2'
import crypto from 'crypto-js'; 
import axios from 'axios'
import ButtonLoading from '../../../components/ButtonLoading';
import { sendEmail } from '../../auth/forgot/forgotSlice';
import moment from 'moment';

const validationSchema = yup    
    .object({
        name: yup.string()
        .required("Name is required"),
        email: yup.string() 
        .required("Email is required")
        .email("Email is invalid"),
        password: yup.string() 
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password Must Contain 8 Characters and at least have one Special Case Character, One Lowercase and One Number"
        ),
    })
    .required();
  

const TeamsPage = () => {

    const dispatch = useDispatch()

    const state_teams = useSelector((state : RootState) => state.users_team)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState({
        open: false, 
        name : ""
    });

    const [updateCredential, setUpdateCredential] = useState<any>(userCredentials);
    const [updated, setUpdated] = useState(false);

    const [authID, setAuthID] = useState <any>("");
    const [roleName, setRoleName] = useState <any>("");
    const [emailUser, setEmailUser] = useState <any>({
        email : ""
    });

    const [optionsRoles, setOptionsRoles] = useState<ISelectOption[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    const [errorSelectRoles, setErrorSelectRoles] = useState<boolean>(false);

     /* istanbul ignore next */
    const handleChangeRoles = (value: any) : void => {
        setSelectedRoles(value)
        setErrorSelectRoles(false)
    }

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name});
    };

    const handleClose = () => {
        setOpen({...open, open : false, name : ""});
        setRoleName("")
        setAuthID("")
        setEmailUser({...emailUser, email : ""})
        setSelectedRoles([])
        reset()
    };

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
      } = useForm<UserSuperadminInput>({
        mode: "onBlur",
        resolver: yupResolver(validationSchema)
    });
    

    const onSubmit = (data: UserSuperadminInput): void => {
        if(selectedRoles.length === 0) {
            setErrorSelectRoles(true)
        } else {
            if(open.name === "Create") {
                let postUser = {
                    user_email: data.email,
                    user_password : data.password,
                    user_name : data.name,
                    buyer_id: userCredentials.buyer_id,
                    company_code: userCredentials.company_code,
                    role_id: selectedRoles.value,
                    isFirstTime : false
                }
                dispatch(postUserTeams(postUser))
            } else {
                let updateUser = {
                    auth_id: authID,
                    user_name: data.name,
                    role_id: selectedRoles.value,
                    role_name: selectedRoles.label,
                }
                dispatch(updateUserTeams(updateUser))
            }
        }
    }

    const onClickDisableUser = (row : any) => {
        if(row.status === "ACTIVE") {
            let value = {
               status : "INACTIVE",
               auth_id : row.auth_id
            }
            Swal.fire({
                title: 'Warning!',
                text: "Are you sure want to deactive this user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: 'primary',
                confirmButtonText: 'Yes!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return dispatch(disableUserTeams(value))
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire(
                        'Success!',
                        'User has been deactivated.',
                        'success'
                    )
                }
            })
        } else {
            let value = {
                status : "ACTIVE",
                auth_id : row.auth_id
             }
             Swal.fire({
                title: 'Warning!',
                text: "Are you sure want to activate this user?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return dispatch(disableUserTeams(value))
                },
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire(
                        'Success!',
                        'User has been activated.',
                        'success'
                    )
                }
            })
        }
    }

    useEffect(() => {
        if(state_teams.create) {
            handleClose()
            reset()
            setTimeout(() => {
                dispatch(getAllUsersTeams())
            }, 1000);
        }
        // eslint-disable-next-line
    }, [state_teams.create]);

    useEffect(() => {
        if(state_teams.status) {
            handleClose()
            reset()
            // Swal.fire(
            //     'Success!',
            //     'User has been deactivated.',
            //     'success'
            // )
            setTimeout(() => {
                dispatch(getAllUsersTeams())
            }, 1000);
        }
        // eslint-disable-next-line
    }, [state_teams.status]);

    useEffect(() => {
        if(state_teams.update) {
            handleClose()
            reset()
            if(state_teams.is_owner) {
                setUpdateCredential({...updateCredential, 
                    fullname : state_teams.name_update, 
                })
                setUpdated(true)
            }
        }
        // eslint-disable-next-line
    }, [state_teams.update]);

    useEffect(() => {
        if(updated) {
            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(updateCredential), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
            localStorage.setItem('_?credentialss', saveToLocalStorage)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        // eslint-disable-next-line
    }, [updated]);

    /* istanbul ignore next */
    const onClickUpdate = (row : any) => {
        setValue("name", row.fullname);
        setValue("email", row.email);
        setValue("password", "Password1234!");
        setRoleName(row.role_name)
        setEmailUser({...emailUser, email : row.email})
        setSelectedRoles({ value: row.role_id, label: row.role_name })
        setAuthID(row.auth_id)
        setTimeout(() => {
            handleClickOpen("Edit")
        }, 100);
    }


    const getMasterRoles = async () => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/role?flag=BUYER&default=true&buyer_id=${userCredentials.buyer_id}`)
            if(response.data.errors === null) {
                let roles = response.data.data
                let array_roles = []
                for(let element of roles) {
                    array_roles.push({
                        label : element.name,
                        value : element._id
                    })
                }
                setOptionsRoles(array_roles)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${response.data.message}`,
                  })
            }
            
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${error}`,
              })
        }
    }

    useEffect(() => {
        getMasterRoles()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px', 
            cell: (row, index) => (
            <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            selector: row => row.fullname,
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
        },
        {
            name: 'ROLE',
            selector: row => row.role_name,
        },
       {
            name: 'STATUS',
            selector: row => row.status,
        },
        {
            name: 'LAST LOGIN',
            cell: (row) => (
                <div>
                    { row.last_login_time === undefined ? "-" :
                    <div>
                        {moment(row.last_login_time).fromNow()}
                    </div> }
                </div>
            )
        },
        {
            name: 'ACTION',
            width: '150px',
            cell: (row,i) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" 
                        color={row.status === "ACTIVE" ? "error" : "success"} 
                        size="small"
                        disabled={row.role_name === "Owner" ? true : false}
                        onClick={() => {
                            onClickDisableUser(row)
                        }}
                    >
                        {row.status === "ACTIVE" ? "deactivate" : "activate"}
                    </Button>
                </Stack>
            ),
        },
        {
            name: '',
            width: '150px',
            cell: (row,i) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => onClickUpdate(row)}
                    >
                        Edit
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Users Management"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Users Management</h3>
                </Box>
                <Box>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => handleClickOpen("Create")}
                    >
                        Add New User
                    </Button>
                </Box>
            </Stack>

            {/* Dialog add new user */}
            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="md"
            >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <DialogTitle>{ open.name === "Create" ? "Add New" : "Edit" } User</DialogTitle>
                    <DialogContent style={{minHeight: 550}}>
                        <TextField
                            error={!!errors.name}
                            helperText={errors.name && errors.name.message}
                            {...register('name')}
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            size="small"
                        />
                        <TextField
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                            {...register('email')}
                            margin="normal"
                            fullWidth
                            id="email-user"
                            disabled={open.name === "Edit" ? true : false}
                            label="Email"
                            name="email"
                            size="small"
                        />
                        <TextField
                            error={!!errors.password}
                            helperText={errors.password && errors.password.message}
                            {...register('password')}
                            margin="normal"
                            fullWidth
                            type="password"
                            id="password-user"
                            disabled={open.name === "Edit" ? true : false}
                            label="Password"
                            name="password"
                            size="small"
                        />
                        { open.name !== "Create" ?
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => {
                                dispatch(sendEmail(emailUser))
                            }}
                        >
                            Send Reset Password
                        </Button> : null }
                        <Box pt={2}>
                            <Select
                                placeholder="Select Role"
                                value={selectedRoles}
                                isSearchable={false}
                                options={optionsRoles}
                                onChange={handleChangeRoles}
                                isDisabled={roleName === "Owner" ? true : false}
                            />
                            { 
                            /* istanbul ignore next */
                            errorSelectRoles ? <div className="error-p"><p>Role is required</p></div> : null }
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error" variant="outlined" disabled={state_teams.loading_create || state_teams.loading_update}>Cancel</Button>
                        <Box mr={1} />
                        { open.name === "Create" ?
                        <ButtonLoading 
                            type="submit"
                            name="Submit"
                            loading={state_teams.loading_create}
                        /> :
                        <ButtonLoading 
                            type="submit"
                            name="Save"
                            loading={state_teams.loading_update}
                        /> }
                    </DialogActions>
                </form>
            </Dialog>

            <Box sx={{pt:3}}>
                <DataTableBase 
                    columns={columns}
                    data={state_teams.data}
                    progressPending={state_teams?.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                />
            </Box>

        </Box>
    )
}

export default TeamsPage
