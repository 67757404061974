import { useState, useEffect } from 'react';
import { 
    Stack,
    Grid,
    Box,
    Paper,
    Card,
    Typography,
    Button,
    Divider,
    Avatar
} from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getProductBySlug } from './reducers/productsReducers';
import axios from 'axios'
import swal from 'sweetalert'
import { userCredentials } from '../../../utilities/config';
import { getOpenPurchaseRequest } from '../purchase_requests/reducers/purchaseRequestReducers';
import { getTemplatesData } from '../templates/reducers/templatesReducers';
import DialogOpen from './components/DialogOpen';
import CheckIcon from '@mui/icons-material/Check';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { getAllUsersTeams } from '../teams/reducers/teamsReducers';
import ReactHtmlParser from 'react-html-parser';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import ImageGallery from 'react-image-gallery';
import { setActive } from '../chat/chatSlice';

function DetailProductNew() {

    const location : any = useLocation()
    const dispatch = useDispatch()

    const store_purchaserequest = useSelector((state : RootState) => state.purchase_request)
    const store_template = useSelector((state : RootState) => state.template)
    const state_products = useSelector((state : RootState) => state.detail_product)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [dataTemplates, setDataTemplates] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});

    const convertSlideImages = (img:any) => {
        let images = []
        for(let k of img) {
            images.push({
                original: k,
                thumbnail: k
            })
        }
        return images
    }


    function getTemplates() {
        setTimeout( async () => {
            let filter = store_template.data
            let array = []
            for(let i of filter) {
                let items : any = []
                for(let k of i.vendors) {
                    for(let h of k.packages){
                        for(let k of h.items) {
                            items.push(k)
                        }
                    }
                }
                array.push({
                    buyerId: i.buyerId,
                    createdAt: i.createdAt,
                    createdBy: i.createdBy,
                    isDeleted: i.isDeleted,
                    name: i.name,
                    total: i.total,
                    updatedAt: i.updatedAt,
                    vendors: i.vendors,
                    items : items,
                    _id :i._id
                })
            }
            setDataTemplates(array)  
        }, 1000);
    }

    useEffect(() => {
        dispatch(getOpenPurchaseRequest())
        dispatch(getTemplatesData())
        dispatch(getAllUsersTeams())

        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if(!store_template.loading) {
            getTemplates()
        }
        // eslint-disable-next-line
    }, [store_template.loading]);


    const addToCart = ( product : any, type : string) => {
        setItemSelected(product)
        if(type === "pr") {
            setOpen(true);
        } else {
            setOpenTemplate(true)
        }
    }

    const onClickVendorDetail = () => { 
        const data = {
            slug : location.state.slug,
            name : state_products.product_detail.name,
            vendor_id : state_products.product_detail.vendor_id,
        }
        localStorage.setItem("product-store", JSON.stringify(data))
        window.location.href = "/dashboard/detail/vendor"
    }

    const [loading, setLoading] = useState({
        id : null,
        loading : false,
        type : ""
      }); 

    const addToCartItem =  ( product : any, type : string ) => {
        setLoading({...loading, id: product._id, loading: true, type : type})
        setTimeout( async () => {
          try {
            const vendor_detail : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail?vendor_id=${product.vendor_id}`)
            if(vendor_detail.data.errors === null) {
                let vendor = vendor_detail.data.data
                try {
                  const special_price : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/mybuyer/product`, {
                    params : {
                      vendor_id : product.vendor_id,
                      buyer_id : userCredentials.buyer_id,
                      product_id : product._id,
                    }
                  })
                  if(special_price.data.errors === null) {
                    let quantity = product.minimum_order_quantity < 1 ? 1 : product.minimum_order_quantity
                    let discount_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].discount))
                    let down_payment_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].down_payment))
                    let discount_preferred = (parseInt(special_price.data.data.discount_percentage))
                    let discount_product = ( product.discount * 100)
                    let sum_discount =  discount_product + discount_preferred
                    let tax_percentage = product.pajak.value
                    let discount = product.discount === 0 && discount_preferred === 0 ? (discount_setting / 100) : (sum_discount / 100)
                    let sum_price_discount = discount * product.retail_price
                    let discount_price = product.retail_price - sum_price_discount
                    let sub_total = discount_price * quantity
                    let single_tax_price = (tax_percentage * discount_price)
                    let tax_price = (tax_percentage * discount_price) * quantity
                    let final_price = sub_total + tax_price
    
                    let product_item = {
                      productId : product._id,
                      vendorId: product.vendor_id,
                      name: product.name,
                      vendor : {
                        phone: vendor.phone,
                        email: vendor.email,
                        address: vendor.address,
                        name: product.vendor_name,
                        _id: product.vendor_id
                      },
                      tax : {
                        title : product.pajak.title,
                        value : product.pajak.value,
                      },
                      vendor_name: product.vendor_name,
                      vendor_phone : vendor.phone,
                      sku: product.SKU,
                      slug: product.slug_product,
                      stock: product.stock,
                      brand: product.brand,
                      images_product: product.images_product[0],
                      storage: product.storage,
                      dimension: product.dimension,
                      sub_products: product.sub_products,
                      categories: product.category_id,
                      reported_times: product.reported_times,
                      minimum_order_quantity: product.minimum_order_quantity,
                      retail_price: product.retail_price,
                      discount: discount,
                      discount_product: discount_product,
                      is_special_discount : special_price.data.data.discount_percentage === "0" ? false : true,
                      special_discount : discount_preferred,
                      discount_price: discount_price,
                      quantity : quantity,
                      sub_total : sub_total,
                      single_tax_price: single_tax_price,
                      tax_price : tax_price,
                      tax_percentage: tax_percentage,
                      sum_price_discount: sum_price_discount,
                      final_price : final_price,
                      payment_term: product.payment_term,
                      warehouse_detail: product.warehouse_detail,
                      category_tree : product.category_tree,
                      down_payment_setting: down_payment_setting,
                      note : ""
                    }
                      addToCart(product_item, type)
                      setLoading({...loading, id: product._id, loading: false, type: ""})
                  } else {
                    swal('Error', `${special_price.data.message}`, 'error')
                  }
                } catch (error) {
                  swal('Error', `${error}`, 'error')
                }
            } else {
                swal('Error', `${vendor_detail.data.message}`, 'error')
            }
          } catch (error) {
              swal('Error', `${error}`, 'error')
          }
        }, 1000);
      }
    
    useEffect(() => {
        dispatch(getProductBySlug(location.state.slug))
        // eslint-disable-next-line
    }, [location]);

    
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    const [loadingMessage, setLoadingMessage] = useState(false);

    const onClickCreateChat = async (row : any) => {
        setLoadingMessage(true)

        // const user_vendor : any = await dispatch(getUsersForChat(row.vendor_id))
        dispatch(setActive({
            company_id: state_products.product_detail.vendor_id,
            company_name: state_products.product_detail.vendor_name,
        }))
        
        // if(user_vendor.type === "chat-user/get/fulfilled") {
        //     let data_response = user_vendor.payload.data.data
        //     let user_names = []
        //     for(let k of data_response) {
        //         user_names.push(`${k.fullname} - ${k.company_code}`)
        //     }
        //     for(let l of state_teams.data) {
        //         user_names.push(`${l.fullname} - ${l.company_code}`)
        //     }
        //     let body = {
        //         username:  `${userCredentials.fullname} - ${userCredentials.company_code}`,
        //         secret : userCredentials.buyer_id, 
        //         title : `${row.vendor_name} & ${userCredentials.company_detail.legal_name}`,
        //         usernames : user_names
        //     }
        //     // dispatch(createNewChat(body))
        // } 
    }
    
    return (
        <Stack pl={3} pr={3} >
            { state_products.loading_product_detail ? 
            <LoaderBackdrop loading={state_products.loading_product_detail}/>
            : 
            <>
            <BreadCrumbs 
                isPage={true}
                link="/dashboard/products"
                current={state_products.product_detail.name}
                page='Browse Product'
            />

            <Stack>
                { state_products.product_detail_exist ?
                <Stack pt={3}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Card sx={{mb:3}}>
                                <div style={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                                    <ImageGallery 
                                        items={convertSlideImages(state_products.product_detail.images_product)} 
                                        showPlayButton={false}
                                        showThumbnails={true}
                                        showNav={false}
                                        showBullets
                                        lazyLoad
                                    />
                                </div>
                            </Card>
                            <Divider/>
                            <Stack pt={2} pb={2} flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Stack pt={2} pb={2} flexDirection="row">
                                    <Box pr={1} onClick={onClickVendorDetail}>
                                        <Avatar sx={{ bgcolor: '#28c76f', cursor: 'pointer' }}>{state_products.product_detail.vendor_name.charAt(0)}</Avatar>
                                    </Box>
                                    <Box>
                                        <Box fontSize={18} fontWeight="600" color="black" sx={{ textTransform: 'capitalize', cursor: 'pointer' }} onClick={onClickVendorDetail}>
                                            {state_products.product_detail.vendor_name}
                                        </Box>
                                        <Box fontSize={13} sx={{ pt: '3px' }}>
                                            {state_products.product_detail.warehouse_detail.city}
                                        </Box>
                                    </Box>
                                </Stack>
                                {/* <Stack>
                                    <Box>
                                        { state_teams.loading ? "Loading" : 
                                        <Button 
                                            size="small" color="primary" variant="outlined"
                                            onClick={() => onClickCreateChat(state_products.product_detail)}
                                            disabled={loadingMessage ? true : false}
                                        >
                                            { loadingMessage ? "Loading" : "Chat Vendor" }
                                        </Button> }
                                    </Box>
                                </Stack> */}
                            </Stack>
                            <Divider/>
                        </Grid>
                        <Grid item lg={8} md={6} sm={12} xs={12}>
                            <Paper>
                            <Box p={3}>
                                <Box sx={{ textTransform: 'capitalize' }}>
                                    <Typography variant="h5" component="h2">
                                        {state_products.product_detail.name}
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    { state_products.product_detail.brand === "" ? 
                                    <Typography variant="body2" >
                                        {state_products.product_detail.vendor_name}
                                    </Typography> :
                                    <Typography variant="body2" >
                                        Brand : {state_products.product_detail.brand}
                                    </Typography> }
                                </Box>
                                <Box pt={1}>
                                    <Typography variant="h6" component="h6" fontWeight="600" sx={{ color: '#c70000' }}>
                                    Rp. {state_products.product_detail.discount_price.toLocaleString()}
                                    </Typography>
                                </Box>
                                { state_products.product_detail.discount <= 0 ? null : 
                                <Box pb={1}>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Box> 
                                        <div className="badge-discount">
                                            {Math.floor(state_products.product_detail.discount * 100)}%
                                        </div>
                                        </Box>
                                        <Box >
                                            <Typography variant="body2" fontWeight="400"  sx={{ textDecoration: 'line-through' }}>
                                                Rp. {state_products.product_detail.retail_price.toLocaleString()} 
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box> }

                                { state_products.product_detail.stock !== 0 ? 
                                <Stack flexDirection="row" pt={1}> 
                                    <Box><CheckIcon sx={{ color: 'green' }}/></Box>
                                    <Box pl={1}>
                                        In Stock
                                    </Box>
                                </Stack>
                                :
                                <Stack flexDirection="row"> 
                                    <Box><CancelPresentationIcon sx={{ color: 'red' }}/></Box>
                                    <Box pl={1}>
                                        Out of Stock
                                    </Box>
                                </Stack>
                                }
                                {state_products.product_detail.stock !== 0 ?
                                <Stack flexDirection="row" rowGap={2} pt={2} pb={2} >
                                    <Box>
                                        <Button 
                                            size="small"
                                            variant="contained" 
                                            onClick={() => addToCartItem(state_products.product_detail, 'pr')}
                                            disabled={loading.loading && loading.type === "pr" ? true : false}
                                        >
                                        { loading.loading &&  loading.type === "pr" ? "Loading" : "Add to Purchase Request"}
                                        </Button>
                                    </Box>
                                    <Box pl={1} pr={2}>
                                        <Button 
                                            size="small"
                                            variant="contained" 
                                            color="info"
                                            disabled={loading.loading && loading.type === "template" ? true : false}
                                            onClick={() => addToCartItem(state_products.product_detail, 'template')}
                                        >
                                        { loading.loading &&  loading.type === "template" ? "Loading" : "Add to Template"}
                                        </Button>
                                    </Box>
                                </Stack> : null }

                                
                                <Box sx={{ width: '100%', typography: 'body1', mt: 2 }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Description" value="1" />
                                            <Tab label="ADDITIONAL INFORMATION" value="2" />
                                        </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{ minHeight: 300 }}>
                                            <Box pb={1}>
                                            <Typography variant="body1">
                                                {state_products.product_detail.short_description}
                                            </Typography>
                                            </Box>
                                            <Box>
                                                { ReactHtmlParser(state_products.product_detail.description) }
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ minHeight: 300 }}>
                                            <Stack>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Weight</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.dimension.weight}kg
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={1} pb={1}><Divider/></Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Minimum Order</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.minimum_order_quantity} 
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={1} pb={1}><Divider/></Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Brand</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.brand}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={1} pb={1}><Divider/></Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Vendor</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.vendor_name}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={1} pb={1}><Divider/></Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Ship From</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.warehouse_detail.city}{", "}
                                                            {state_products.product_detail.warehouse_detail.province}{", "}
                                                            {state_products.product_detail.warehouse_detail.country}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={1} pb={1}><Divider/></Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                                            <Box fontWeight={500} >Dimensions</Box>
                                                        </Grid>
                                                        <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                                            {state_products.product_detail.dimension.width} {" x "}
                                                            {state_products.product_detail.dimension.height}  {" x "}
                                                            {state_products.product_detail.dimension.length} cm
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box pt={2} ><Divider/></Box>

                                            </Stack>
                                        </TabPanel>
                                    </TabContext>
                                </Box>

                                <Box sx={{ backgroundColor: '#ddd', padding: '1px 5px', borderRadius: '2px', display: 'flex' }}>
                                    <Box><strong>Categories</strong> : </Box>
                                    <Box pl={1}>
                                    { state_products.product_detail.category_tree.length === 0 ? "-" : 
                                        <Box sx={{ display : 'flex' }}>
                                            { state_products.product_detail.category_tree.map((item:any, i:any) => 
                                                <Box sx={{ display : 'flex' }} key={i}>
                                                <Box>
                                                    {item.name}
                                                </Box> 
                                                { i !== state_products.product_detail.category_tree.length - 1 ? 
                                                    <Box pl={1} pr={1}>{">"}</Box>
                                                : null }
                                                </Box>
                                            )}
                                        </Box>
                                    }
                                    </Box>
                                </Box>
                            </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Stack>
                :
                <Stack mt={2}>
                    <Paper elevation={2}>
                    <Stack direction="row" justifyContent="space-between" p={3} >
                        <Box>
                            <h4>Sorry, Product is not found. Please make sure the product slug is correct.</h4>
                        </Box>
                    </Stack>
                    </Paper>
                </Stack>
                }
            </Stack>
            </>
            }

            <DialogOpen 
                dataList={store_purchaserequest.open_pr}
                itemSelected={itemSelected}
                type="pr"
                open={open}
                setOpen={setOpen}
            />
            <DialogOpen 
                dataList={dataTemplates}
                itemSelected={itemSelected}
                type="template"
                open={openTemplate}
                setOpen={setOpenTemplate}
            />
            
        </Stack>
    )
}

export default DetailProductNew;
