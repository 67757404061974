import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../../utilities/config';
// import { IProductItem } from '../../templateTypes';
import { remove } from 'lodash'
import axios from 'axios';
import { groupBy } from 'lodash'

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false
 
export const getLocalDBTemplate = createAsyncThunk(
    'carts-template/get',
    async (_, { rejectWithValue }) => {
        try {
            const data_carts = await db.collection('db_local_template').get()
            if(data_carts) {
                return data_carts
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const addToLocalDBTemplate = createAsyncThunk(
    'carts-template/add', 
    async (value : any, { rejectWithValue }) => {
        
        const data_carts = await db.collection('db_local_template').get()

        if(data_carts.length === 0) {
            try {
                const add_carts = await db.collection('db_local_template').add(value)
                localStorage.removeItem('id_cart_pr')
                if(add_carts) {
                    return {success : true, message : "Success Added Item to Cart!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } else {
            if(data_carts.find((element : any) => element.productId === value.productId)) {
                let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity + value.quantity
                let total_sub_total =  quantity * value.discount_price
                let tax_price = quantity * value.single_tax_price
                try {
                    const result = await db.collection('db_local_template')
                        .doc({ productId: value.productId })
                        .update({
                            quantity: quantity,
                            sub_total : Math.floor(total_sub_total + tax_price)
                        })
                    if(result) {
                        return {success : true, message : "Success Added Item to Cart!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            } else {
                try {
                    const add_carts = await db.collection('db_local_template').add(value)
                    if(add_carts) {
                        return {success : true, message : "Success Added Item to Cart!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            }
        }
    }
)

export const addQuantityProductTemplate = createAsyncThunk(
    'carts-template/add-quantity', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_template').get()
        if(data_carts.find((element : any) => element.productId === value.productId)) {
            let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity + 1
            let add_sub_total =  quantity * value.discount_price
            let tax_price = quantity * value.single_tax_price
            try {
                const result = await db.collection('db_local_template')
                    .doc({ productId: value.productId })
                    .update({
                        quantity: quantity,
                        sub_total : Math.floor(add_sub_total + tax_price),
                        sub_total_before_tax: Math.floor(add_sub_total),
                    })

                if(result) {
                    return {success : true, message : "Success Increase Item Quantity!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)


export const onChangeQuantityTemplate = createAsyncThunk(
    'carts-template/change-quantity', 
    async (value : any, { rejectWithValue }) => {
        // console.log(value,' valu ini apa ')
        const data_carts = await db.collection('db_local_template').get()
        if(data_carts.find((element : any) => element.productId === value.productId)) {
            let add_sub_total =  value.value_quantity * value.discount_price
            let tax_price = value.value_quantity * value.single_tax_price
            try {
                const result = await db.collection('db_local_template')
                    .doc({ productId: value.productId })
                    .update({
                        quantity: value.value_quantity,
                        sub_total : Math.floor(add_sub_total + tax_price),
                        sub_total_before_tax: Math.floor(add_sub_total),
                    })
                if(result) {
                    return {success : true, message : "Success Change Item Quantity!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removeQuantityProductTemplate = createAsyncThunk(
    'carts-template/remove-quantity', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_template').get()

        if(data_carts.find((element : any) => element.name === value.name)) {
            let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity
            if(quantity <= 1) {
                try {
                    const result = await db.collection('db_local_template')
                        .doc({ productId: value.productId })
                        .delete()
    
                    if(result) {
                        return {success : true, message : "Success Remove Item!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            } else { 
                let change_quantity = data_carts.find((element : any) => element.productId === value.productId).quantity - 1
                let remove_sub_total =  change_quantity * value.discount_price
                let tax_price = change_quantity * value.single_tax_price
                try {
                    const result = await db.collection('db_local_template')
                        .doc({ productId: value.productId })
                        .update({
                            quantity: change_quantity,
                            sub_total : Math.floor(remove_sub_total + tax_price),
                            sub_total_before_tax: Math.floor(remove_sub_total),
                        })
    
                    if(result) {
                        return {success : true, message : "Success Decrease Item Quantity!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }

            }
        }
    }
)

export const removeItemProductTemplate = createAsyncThunk(
    'carts-template/remove-item', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_template').get()

        if(data_carts.find((element : any) => element.name === value.name)) {
            try {
                const result = await db.collection('db_local_template')
                    .doc({ name: value.name })
                    .delete()

                if(result) {
                    return {success : true, message : "Success Remove Item!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removeVendorItemsTemplate = createAsyncThunk(
    'carts-template/remove-allitemvendor', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_template').get() 
        
        if(data_carts.find((element : any) => element.vendor_name === value.vendor_name)) {
            const vendorItems = remove(data_carts, function(obj : any) {
                return obj.vendor_name !== value.vendor_name
            });
            try {
                const result = await db.collection('db_local_template')
                    .set(vendorItems)

                if(result) {
                    return {success : true, message : "Success Remove Items!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const resetCartTemplate = createAsyncThunk(
    'carts-template/reset', 
    async (_, { rejectWithValue }) => {
        try {
            const removeDB = await db.collection('db_local_template').delete()
            if(removeDB) {
                return {success : true, message : "Success Remove All Items!"}
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveTemplate = createAsyncThunk(
    'save-template', 
    async (value : any, { rejectWithValue }) => {
        try {
            const company : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/buyer/detail?buyer_id=${userCredentials.buyer_id}`)
            if(company.data.errors === null) {
                let buyer_detail = company.data.data

                const convert_groupBy : any = Object.entries(groupBy(value.value, (item) => {
                    return JSON.stringify(item.vendor)
                }));

                let final_data : any = convert_groupBy.map(function(key : any) { 
                    let split_array = key[0]
                    return { 
                        vendor : JSON.parse(split_array),
                        packages : {
                            items: key[1],
                        }
                    }; 
                });
        
                let body = {
                    name : value.template_name,
                    buyerId: buyer_detail._id,
                    vendors: final_data,
                    total: value.total,
                    createdBy: userCredentials.fullname,
                }

                let body_put = {
                    name : value.template_name,
                    vendors: final_data,
                    total: value.total,
                    createdBy: userCredentials.fullname,
                }

                // console.log(body_put, 'body_put')
        
                if(!value.update) {
                    try {
                        const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/template`, body)
                        if(response.data.errors === null) {
                            const deletedb = await db.collection('db_local_template').delete()
                            if(deletedb) {
                                return {success : true, message : response.data.message}
                            }
                        } else {
                            return rejectWithValue(response.data.message)
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                } else {
                    try {
                        const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/template/Update/${value.id}`, body_put)
                        if(response.data.errors === null) {
                            const deletedb = await db.collection('db_local_template').delete()
                            if(deletedb) {
                                return {success : true, message : response.data.message}
                            }
                        } else {
                            return rejectWithValue(response.data.message)
                        }
                    } catch (error) {
                        return rejectWithValue(error)
                    }
                }
            } else {
                return rejectWithValue(company.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const updateItemTemplate = createAsyncThunk(
    'update-item-template', 
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/template/addItem`, body)
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error) 
        }
    }
)