import { 
    Stack, Box, Paper, Typography, 
    Button, Dialog,
    DialogContent
} from '@mui/material'
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import TableItemProduct from './components/TableItemProduct';
import ReactToPrint from 'react-to-print';

function InvoiceSummaryPage() {
 
    const location : any = useLocation()
    const history = useHistory()

    const state_invoice = location.state === undefined ? null : location.state.data_invoice
    
    const [openPay, setOpenPay] = useState(false);
    const [selectPayment] = useState("Xendit");


    useEffect(() => {
        if(state_invoice === null) {
            window.location.href = "/dashboard/finance/invoice"
        }   
    }, [state_invoice]);

    function sumTotalPrice (data : any) {
        const totalPriceALl = data.reduce((total : any, data : any) => {
          return total + data.sub_total_received
        }, 0)
        return totalPriceALl;
    }

    const handleClosePay = () => {
        setOpenPay(false)
        // setSelectedRows([])
    }

    const onSelectDirectPayment = (type : string) => {
        let data_payment = {
            items : [state_invoice.detail],
            payment_gateway : selectPayment === "OY" ? "OY Indonesia" : "Xendit",
            type_payment : type,
            direct_payment : state_invoice.direct_payment,
        }
        // console.log(data_payment)
        history.push({ 
            pathname: "/dashboard/finance/invoice",
            search: `?page=payment`, 
            state : {
                data_payment 
            }
        })
    }

    function sumTotalPPNItem (data : any) {
        return data.reduce((total : any, data : any) => {
            return total + data.sub_total_tax
        }, 0)
    }

    function getDueDate(params: any ) {
        let result = new Date();
        result.setDate(result.getDate() + params.payment_term.value);
        return result;
    }

    function sumGrandTotalPrice(data:any) {
        let grand_total = (sumTotalPrice(data) - state_invoice.down_payment) + sumTotalPPNItem(data)
        return grand_total
    }

    const onSelectVirtualAccount = (type : string) => {
        let data_payment = {
            items : [state_invoice.detail],
            payment_gateway : selectPayment === "OY" ? "OY Indonesia" : "Xendit",
            type_payment : type
        }
        history.push({ 
            pathname: "/dashboard/finance/invoice",
            search: `?page=payment`, 
            state : {
                data_payment 
            }
        })
    }

    const componentRef = useRef<any>();
    // console.log(state_invoice);
    
    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={true}
                page="Invoice"
                link="/dashboard/finance/invoice"
                current="Invoice Summary"
            />

            <div >
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Invoice Summary</h3>
                </Box>
                
            </Stack>

            <Stack pt={3} ref={componentRef}>
                <Paper sx={{ border: '2px solid #ccc' }}>
                    <Box p={3}>
                        <Stack direction="row" justifyContent="space-between">
                            <Box pt={2} pb={2} fontWeight="600">Invoice Number : {state_invoice.invoice_number}</Box>
                            <Box pt={2} pb={2} fontWeight="600">Status : <span style={{color: state_invoice.status === 'Paid' ? 'green' : 'black' }}>{state_invoice.status}</span></Box>
                        </Stack> 
                        <Stack pb={2} direction="row" justifyContent="space-between" spacing={3}>
                            <Box sx={{width: '100%', }}>
                                <Box pb={1} fontSize="15px">From :</Box>
                                <Paper sx={{ border: '1px solid #ccc', minHeight: 100 }}>
                                    { !state_invoice.direct_payment ? 
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">PT Manajemen Pemesanan Indonesia</Box>
                                        <Box fontWeight="400" fontSize="12px">+62129339390</Box>
                                        <Box fontWeight="400" fontSize="12px">APL Office Tower, 16th-T9 Floor, Podomoro City. Jl. Letjend. S. Parman Kav. 28, <br/> Tj. Duren Selatan, Grogol Petamburan, Jakarta Barat. 11440</Box>
                                    </Box>
                                    :
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">{state_invoice.vendor.name}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.vendor.phone}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.vendor.address}</Box>
                                    </Box> 
                                    }
                                </Paper>
                            </Box>
                            <Box sx={{width: '100%'}}>
                                <Box pb={1} fontSize="15px">To :</Box>
                                <Paper sx={{ border: '1px solid #ccc', minHeight: 100 }}>
                                    <Box p={1}>
                                        <Box fontWeight="600" fontSize="15px">{state_invoice.buyer.name}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.buyer.phone}</Box>
                                        <Box fontWeight="400" fontSize="12px">{state_invoice.buyer.address}</Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </Stack>

                        {/* <Stack pt={2}>
                            <Paper>
                                <Box p={2}>
                                    <Stack flexDirection="row" alignContent="center" justifyContent="space-between">
                                        <Box pb={2}>
                                            <Typography variant="h6" fontWeight="600">Additional Cost</Typography>
                                            <Typography variant="body2" fontWeight="300">You can add additional cost for this invoice </Typography>
                                        </Box>
                                        <Box>
                                            { !additionalCost ? 
                                            <Button 
                                                variant='contained' color="primary" 
                                                onClick={() => {
                                                    setValueAdditionalCost(default_item)
                                                    setAdditionalCost(true)
                                                }}
                                                size="small"
                                            >Add Additional Cost</Button> :
                                            <Button 
                                                variant='contained' 
                                                size="small"
                                                color="error" 
                                                onClick={() => setAdditionalCost(false)}
                                            >Cancel</Button> }
                                        </Box>
                                    </Stack>
                                    { additionalCost &&
                                    <Stack pt={2} sx={{ width: '100%' }}>
                                        <AdditionalCost items={valueAdditionalCost} setItems={setValueAdditionalCost}/>
                                    </Stack> }
                                </Box>
                            </Paper>
                        </Stack> */}

                        <Stack pt={4}>
                            <TableItemProduct 
                                dataItem={state_invoice.items}
                                arrData = {state_invoice}
                            />
                            <Paper>
                                <Stack direction="row" justifyContent="flex-end" >
                                    <Box p={2}>
                                        <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Sub Total Price</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumTotalPrice(state_invoice.items).toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Shipping Cost</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {state_invoice.shipping_costs.toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13}>Down Payment</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {state_invoice.down_payment.toLocaleString()}</Box>
                                        </Stack>
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13}>Total PPN</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumTotalPPNItem(state_invoice.items).toLocaleString()}</Box>
                                        </Stack>
                                        {/* <Stack direction="row" >
                                            <Box width={250} fontSize={13}>Total Additional Cost</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {sumTotalAdditionalCost(valueAdditionalCost).toLocaleString()}</Box>
                                        </Stack> */}
                                        <Stack direction="row">
                                            <Box width={250} fontSize={13} fontWeight="600">Grand Total</Box>
                                            <Box pl={2} pr={2} fontWeight="600" fontSize={13}>: Rp. {(parseInt(sumGrandTotalPrice(state_invoice.items)) + state_invoice.shipping_costs).toLocaleString()}</Box>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Paper>
                        </Stack>

                        <Stack pt={2}>
                            <Paper>
                                <Box p={2}>
                                    <Typography variant="body2" fontWeight="500">REFERENCE DOCUMENT</Typography>
                                    <Stack direction="row" pt={1} >
                                        <Box width={200} fontSize={13}>Code PO :</Box>
                                        { state_invoice.reference_document.map((val:any, i:any) => (
                                            <Box pl={1} fontWeight="600" fontSize={13} key={i}>({val.code_po})</Box>
                                        )) }
                                    </Stack>
                                    <Stack direction="row" >
                                        <Box width={200} fontSize={13}>Code Package :</Box>
                                        { state_invoice.reference_document.map((val:any, i:any) => (
                                            <Box pl={1} fontWeight="600" fontSize={13} key={i}>({val.code_package})</Box>
                                        )) }
                                    </Stack>
                                    {/* <Stack direction="row" >
                                        <Box width={200}>Payment Term :</Box>
                                        <Box pl={1} fontWeight="600" >{state_invoice.reference_document[0].payment_term.name}</Box>
                                    </Stack> */}
                                </Box>
                            </Paper>
                        </Stack>

                        <Stack pt={2}>
                            <Paper>
                                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <Box p={2}>
                                        <Typography variant="body2" fontWeight="500">DUE DATE : {moment(getDueDate(state_invoice.reference_document[0])).format('ll')}</Typography>
                                    </Box>
                                    <Box p={2}>
                                        <Stack  direction="row" justifyContent="flex-end" spacing={3}>
                                            <ReactToPrint
                                                trigger={() => <Button variant="outlined" color="primary"  size="small" >Print Invoice</Button>}
                                                content={() => componentRef.current}
                                                onBeforePrint={() : any => document.title = `Invoice Number ${state_invoice.invoice_number}`}
                                                onAfterPrint={() => document.title = "Pasar Pemesanan - Buyer Platform"}
                                            />
                                            { state_invoice.status === "Ready to Payment"  &&
                                            <Button 
                                                variant='contained' 
                                                onClick={() => {
                                                    if(state_invoice.direct_payment) {
                                                        onSelectDirectPayment("Direct Payment")                        
                                                    } else {
                                                        setOpenPay(true)
                                                    }
                                                }}
                                            >
                                                Pay Invoice
                                            </Button> }
                                        </Stack> 
                                    </Box>
                                </Stack>
                            </Paper>
                        </Stack>
                    </Box>
                </Paper>
           </Stack>
           </div>

           <Dialog 
                onClose={handleClosePay}
                aria-labelledby="simple-dialog-title"  
                open={openPay}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContent>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box fontWeight="600">Select Payment Method </Box>
                        {/* { selectPayment === "" ? null :
                        <Box>
                            <Button onClick={() => setSelectPayment("")} color="info">Back</Button>
                        </Box> } */}
                    </Stack>
                    <Stack pt={2} pb={2}>
                        {/* { selectPayment === "" ? 
                        <Box>
                            <Stack className='box-payment-choose' sx={{ backgroundColor: '#4066af' }}>
                                <Box p={2} 
                                    onClick={() => setSelectPayment("OY Indonesia")}
                                >
                                    Pay with OY 
                                </Box> 
                            </Stack>
                            <Stack className='box-payment-choose' sx={{ backgroundColor: '#4373fc'  }}>
                                <Box p={2} onClick={() => setSelectPayment("Xendit")}>
                                    Pay with Xendit
                                </Box>
                            </Stack>
                        </Box>
                        : */}

                        <Box>
                            { selectPayment === "OY" ? 
                            <>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e' }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Virtual Account")} >
                                    Virtual Account
                                </Box>
                            </Stack>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e'  }}>
                                <Box p={2} 
                                    onClick={() => onSelectVirtualAccount("Manual Bank Transfer")}
                                >
                                    Manual Bank Transfer
                                </Box>
                            </Stack>
                            </> 
                            : selectPayment === "Xendit" ? 
                            <>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e' }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Virtual Account")} >
                                    Virtual Account
                                </Box>
                            </Stack>
                            <Stack className='box-payment-method' sx={{ border: '2px solid #0e3f8e'  }}>
                                <Box p={2} onClick={() => onSelectVirtualAccount("Manual Bank Transfer")}>
                                    Manual Bank Transfer
                                </Box>
                            </Stack>
                            </> : null }
                        </Box>
                        {/* } */}
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default InvoiceSummaryPage
