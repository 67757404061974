import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';


const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getPurchaseOrdersData = createAsyncThunk(
    'purchase-orders/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                params : {
                    id : userCredentials.buyer_id,
                    skip : "0",
                    limit : "10"
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data.data, count : response.data.data.count,  message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getActivePurchaseOrders = createAsyncThunk(
    'purchase-orders-active/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                params : {
                    id : userCredentials.buyer_id,
                    skip : "0",
                    limit : "1",
                    searchTopic: ['packages.lastStatus'],
                    searchValue: ['New'],
                },
                headers
            })
            
            if(response.data.errors === null) {
                return response.data.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getPurchaseOrdersDPAll = createAsyncThunk(
    'purchase-orders-dp/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                params : {
                    id : userCredentials.buyer_id,
                    skip : "0",
                    limit : "10000"
                },
                headers
            })            
            if(response.data.errors === null) {
                return {data : response.data.data.data,  message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getPurchaseOrdersDataNew = createAsyncThunk(
    'purchase-orders-new/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getPurchaseOrdersDataStatus = createAsyncThunk(
    'purchase-orders-status/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/status/${userCredentials.buyer_id}`, {
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data,  message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseOrdersWaitingDP = createAsyncThunk(
    'purchase-orders-dp/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                params : {
                    id : userCredentials.buyer_id,
                    searchTopic: ['lastStatus'],
                    searchValue: ['Waiting Down Payment'],
                    skip : "0",
                    limit : "10000"
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data.data, count_dp : response.data.data.count, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseOrdersDataDefault = createAsyncThunk(
    'purchase-orders-default/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                params : {
                    id : userCredentials.buyer_id,
                    skip : "0",
                    limit : "10000"
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseOrdersBySearch = createAsyncThunk(
    'purchase-orders-search/get',
    async (search : any, { rejectWithValue }) => {
        try {
            
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseOrdersBySearchLoadMore = createAsyncThunk(
    'purchase-orders-search-loadmore/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);



// get data search by waiting down payment
export const getPurchaseOrdersBySearchWaitingDP = createAsyncThunk(
    'purchase-orders-search-dp/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        skip : search.skip,
                        limit : search.limit
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        skip : search.skip,
                        limit : search.limit
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getPurchaseOrdersDPLoadMore = createAsyncThunk(
    'purchase-orders-dp-loadmore/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-order/new`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue,
                        limit: search.limit,
                        skip: search.skip
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postProofDownPayment = createAsyncThunk(
    'purchase-orders/proof',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/fulfillment/Upload`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postApprovalItem = createAsyncThunk(
    'purchase-orders/approve',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/purchase-order/Accept`, body.data, {headers})
            
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    } 
);

export const postRejectItem = createAsyncThunk(
    'purchase-orders/reject',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/purchase-order/Reject`, body.data, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    } 
);


export const postGoodReceiptNote = createAsyncThunk(
    'purchase-orders/grn',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/grn/${body.reference_doc.packageId}`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        } 
    }
);


export const postSendEmail = createAsyncThunk(
    'purchase-request/sendEmail',
    async (body : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/purchase-request/sendEmail`, body, {headers})
            
            return {data : true, message : response.data.message}
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getAllUsersTeams = createAsyncThunk(
    'get/userteams',
    async (id : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/users`, {
                params : {
                    "vendor_id": id._id
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            if (!err.response) {
              throw err
            }
            return rejectWithValue(err.response)
        }
    }
);


