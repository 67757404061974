export const handleTime = (time:any ) =>{
    let timePost:any = new Date(time)
    let timeNow:any = new Date()
    let distance = timeNow - timePost

    let milisecond= 1000
    let secondInHours= 3600
    let hoursInDay=24
    let minutes= 60
    let seconds= 60

    let distanceDay=Math.floor( distance / (milisecond * secondInHours * hoursInDay))
    let distanceHours= Math.floor(distance/ (milisecond * minutes * seconds))
    let distanceMinutes= Math.floor(distance/(milisecond * seconds))
    let distanceSeconds= Math.floor(distance/ (milisecond))

    function displayDate(){
        if(distanceDay > 7) return (`${new Date(time).toISOString().substring(0, 10)}`)
        if(distanceDay === 1) return (`${distanceDay} day ago`);
        if(distanceDay > 1) return (`${distanceDay} days ago`);
        if(distanceHours === 1)return (`${distanceHours} hour ago`);
        if(distanceHours > 1)return (`${distanceHours} hours ago`);
        if(distanceMinutes === 1)return (`${distanceMinutes} minute ago`);
        if(distanceMinutes > 1)return (`${distanceMinutes} minutes ago`);
        if(distanceSeconds === 0 || 1) return (`${distanceSeconds} second ago`);
        if(distanceSeconds > 1) return (`${distanceSeconds} seconds ago`);
    }

    return displayDate()
}