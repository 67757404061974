import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CreatePurchaseRequests from './create/CreatePurchaseRequests';
import DetailPurchaseRequests from './detail/DetailPurchaseRequests';
import PurchaseRequests from './PurchaseRequests';

function PurchaseRequestPage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'create') {
            return <CreatePurchaseRequests/>
        } else if (active_page === 'detail') {
            return <DetailPurchaseRequests/>
        } else {
            return <PurchaseRequests/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default PurchaseRequestPage
