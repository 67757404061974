import { Stack, Box, Skeleton, Button, TextField } from '@mui/material';
import React, {  useState } from 'react'
import Select from 'react-select'

const SearchData : React.FC<any> = ({ 
    data, 
    progressPending,
    dataSearch,
    setDataSearch,
    status
 }) => {


    const [dateSearch, setDateSearch] = useState(false);

    const [dataSearchTemporary, setDataSearchTemporary] = useState({
        searchTopic : "", 
        searchValue: "",
        rangeTopic : "",
        rangeFrom: "",
        rangeTo: "",
        search : false
    });

    const optionSearch = [
        { label : "Vendor", value : "vendor._id" },
        { label : "Date Range", value : "date" },
        { label : "Order ID", value : "code_po" },
        { label : "Author", value : "createdBy" },
        { label : "Department", value : "department" }
    ]

    const [optionsValue, setoptionsValue] = useState<any>([]);
    const [selectedSearch, setSelectedSearch] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);    

    const proceedOptions = (value : any, data: any) => {
        let data_options : any = []
        if(value === 'vendor._id') {
            for(let ele of data) {
                data_options.push({ value: ele.vendor._id, label: ele.vendor.name })
            }
        } else if(value === "date") {
            setDataSearchTemporary({...dataSearchTemporary, 
                rangeTopic: value
            })
        } else if(value === "total") {
            for(let element of data) {
                data_options.push({ value: element.total, label: `Rp. ${element.total.toLocaleString()}` })
            }
        } else if(value === "department") {
            for(let element of data) {
                data_options.push({ value: element[value] === "generic" ? "N/A" : element[value], label: element[value] === "generic" ? "N/A" : element[value] })
            }
        } else {
            for(let element of data) {
                data_options.push({ value: element[value], label: element[value] })
            }
        }

        const uniqueValuesSet = new Set();
        const filteredArr = data_options.filter((obj:any) => {
            const isPresentInSet = uniqueValuesSet.has(obj.value);
            uniqueValuesSet.add(obj.value);
            return !isPresentInSet;
        });

        let sortBy = filteredArr.sort((a:any,b:any) => {
            if(a.label < b.label) return -1
            return 1
        })
        setoptionsValue(sortBy)
    }

    const handleChangeSearch = (value: any) : void => {
        proceedOptions(value.value, data)
        setSelectedSearch(value)
        setSelectedValue([])
        if(value.value === "date") {
            setDateSearch(true)
            setDataSearchTemporary({...dataSearchTemporary, 
                searchTopic: "",
                rangeTopic: value.value,
                rangeFrom: "",
                rangeTo: "",
                search : false
            })
        } else {
            setDateSearch(false)
            setDataSearchTemporary({...dataSearchTemporary, 
                searchTopic: value.value,
                rangeTopic: "",
                rangeFrom: "",
                rangeTo: "",
                search : false
            })

        }
    }

    const handleChangeValue = (value: any) : void => {
        setDateSearch(false)
        setSelectedValue(value)
        setDataSearchTemporary({...dataSearchTemporary, 
            searchValue: value.value, 
            search : true
        })
    }

    const onClickSearch = () => {
        setDataSearch({...dataSearch, 
            searchTopic: [dataSearchTemporary.searchTopic, 'packages.lastStatus'],
            searchValue: [dataSearchTemporary.searchValue, status],
            rangeTopic: dataSearchTemporary.rangeTopic,
            rangeFrom: dataSearchTemporary.rangeFrom,
            rangeTo: dataSearchTemporary.rangeTo,
            dateSearch : dateSearch
        })
    }

    const onClickReset = () => {
        setSelectedSearch([])
        setSelectedValue([])
        setDataSearchTemporary({...dataSearchTemporary, 
            searchTopic: "",
            searchValue: "",
            rangeFrom: "",
            rangeTo: "",
            rangeTopic: "",
            search : false
        })
        setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: [status],
            rangeTopic: dataSearchTemporary.rangeTopic,
            rangeFrom: dataSearchTemporary.rangeFrom,
            rangeTo: dataSearchTemporary.rangeTo,
            dateSearch : dateSearch
        })
        
    }


    const inActiveButton = () => {
        let active = true
        if(dateSearch) {
            if(dataSearchTemporary.rangeTo !== "" && dataSearchTemporary.rangeFrom !== "") {
                active = false
            }
        } else {
            active = !dataSearchTemporary.search
        }
        return active
    }

    return (
        <Stack>
            { !progressPending ? 
            <Stack flexDirection="row" justifyContent="space-between">
                <Stack flexDirection="row">
                    <Box mr={1} sx={{ minWidth: 250 }}>
                        <Select
                            placeholder="Search By"
                            value={selectedSearch}
                            options={optionSearch}
                            onChange={handleChangeSearch}
                            id={`select-style-searchby`}
                        />
                    </Box>
                    { dataSearchTemporary.rangeTopic === "date" ? 
                    <Stack flexDirection="row">
                        <Box ml={1}>
                            <TextField 
                                type="date"
                                size="small"
                                onChange={(e) => setDataSearchTemporary({...dataSearchTemporary, rangeFrom: e.target.value})}
                                value={dataSearchTemporary.rangeFrom}
                                sx={{ backgroundColor: '#fff' }}
                            />
                        </Box>  
                        <Box pl={1} pt={1}>to</Box>
                        <Box ml={1}>
                            <TextField 
                                type="date"
                                size="small"
                                variant="outlined"
                                value={dataSearchTemporary.rangeTo}
                                onChange={(e) => setDataSearchTemporary({...dataSearchTemporary, rangeTo: e.target.value})}
                                sx={{ backgroundColor: '#fff' }}
                            />
                        </Box>
                    </Stack>  
                    :
                    <Box ml={1} sx={{ minWidth: 250 }}>
                        <Select
                            placeholder="Select Value"
                            value={selectedValue}
                            isSearchable={true}
                            options={optionsValue}
                            onChange={handleChangeValue}
                            id={`select-style-value`}
                        />
                    </Box>
                    }
                    <Box ml={2} mr={2}>
                        <Button 
                            color="primary" variant="contained"
                            disabled={inActiveButton()}
                            onClick={onClickSearch}
                        >Search</Button>
                    </Box> 
                </Stack>  
                <Stack>
                    <Box>
                        <Button 
                            color="secondary" variant="contained"
                            onClick={onClickReset}
                            disabled={selectedSearch.length === 0 ? true : false}
                        >Reset Search</Button>
                    </Box>
                </Stack>  
            </Stack> :
            <Stack flexDirection="row" rowGap={3}>
                <Box mr={1}><Skeleton width={250} height={70}/></Box>
                <Box ml={1}><Skeleton width={250} height={70}/></Box>
                <Box ml={2}><Skeleton width={120} height={70}/></Box>
            </Stack>
            }
        </Stack>
    )
}

export default SearchData
