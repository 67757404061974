import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getGoodReceiptNote, getPurchaseOrdersData, postApprovalItem, postGoodReceiptNote, postProofDownPayment, postRejectItem } from './reducers/goodReceiptNoteReducers';


const initialState: any = {
  data: [], 
  proof : false,
  loading_proof: false,
  loading : false,
  error : null,
  error_proof : null,
  item_pick_approve : false,
  loading_item_pick_approve: false,
  item_pick_reject : false,
  loading_item_pick_reject: false,
  grn : false,
  loading_grn: false,
  data_grn : [],
  loaded_data_grn : false,
  loading_data_grn : false,
};

export const getPurchaseOrders = createSlice({
  name: 'purchase-orders',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get data grn
      .addCase(getGoodReceiptNote.pending, (state) => {
        state.loading_data_grn = true;
      })
      .addCase(getGoodReceiptNote.fulfilled, (state, action:any) => {
        state.loading_data_grn = false;
        state.loaded_data_grn = true
        state.data_grn = action.payload.data;
      })
      .addCase(getGoodReceiptNote.rejected, (state, action : any) => {
        state.loading_data_grn = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      
      // get purchase orders from db
      .addCase(getPurchaseOrdersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPurchaseOrdersData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseOrdersData.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // post proof
      .addCase(postProofDownPayment.pending, (state) => {
        state.loading_proof = true;
      })
      .addCase(postProofDownPayment.fulfilled, (state, action:any) => {
        state.loading_proof = false;
        state.proof = action.payload.data;
      })
      .addCase(postProofDownPayment.rejected, (state, action : any) => {
        state.loading_proof = false;
        state.error_proof = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // post approval  item
      .addCase(postApprovalItem.pending, (state) => {
        state.loading_item_pick_approve = true;
      })
      .addCase(postApprovalItem.fulfilled, (state, action:any) => {
        state.loading_item_pick_approve = false;
        state.item_pick_approve = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .addCase(postApprovalItem.rejected, (state, action : any) => {
        state.loading_item_pick_approve = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post reject  item
      .addCase(postRejectItem.pending, (state) => {
        state.loading_item_pick_reject = true;
      })
      .addCase(postRejectItem.fulfilled, (state, action:any) => {
        state.loading_item_pick_reject = false;
        state.item_pick_reject = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .addCase(postRejectItem.rejected, (state, action : any) => {
        state.loading_item_pick_reject = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // post grn
       .addCase(postGoodReceiptNote.pending, (state) => {
        state.loading_grn = true;
      })
      .addCase(postGoodReceiptNote.fulfilled, (state, action:any) => {
        state.loading_grn = false;
        state.grn = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);

      })
      .addCase(postGoodReceiptNote.rejected, (state, action : any) => {
        state.loading_grn = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export default getPurchaseOrders.reducer;
