import { Button, Stack, TextField, Box } from '@mui/material'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import swal from 'sweetalert'
import DialogField from './DialogField';
import { getSearchVendor } from '../reducers/vendorListsReducer';


const SearchAddNew = () => {

    const dispatch = useDispatch()

    const store_vendorlists = useSelector((state : RootState) => state.vendor_lists)
    
    const [searchValue, setSearchValue] = useState("");
    const [dataBuyer, setDataBuyer] = useState<any>([]);
    const [open, setOpen] = useState(false);

    const onClickSearch = (e : any) => {
        e.preventDefault()
        dispatch(getSearchVendor(searchValue))
    }

    useEffect(() => {
        if(store_vendorlists.loaded_search) {
            if(store_vendorlists.search_vendor !== null) {
                setOpen(true);
                setDataBuyer(store_vendorlists.search_vendor)
            } else {
                swal('Not Found!', "Company Not Found! Please contact your Vendor Admin to get the correct company code.", 'error')
            }
        }
        // eslint-disable-next-line
    }, [store_vendorlists.loaded_search]);


    return (
        <div>
            <form onSubmit={onClickSearch}>
                <Stack direction="row" alignItems="center" alignContent="center" pt={3} >
                    <Box pr={2}>Add New Vendor</Box>
                    <Box>
                        <TextField 
                            label="Find by Company Code"
                            size="small"
                            onChange={(e) => setSearchValue(e.target.value)}
                            required
                        />
                    </Box>
                    <Box pl={2}>
                        <Button
                            variant='outlined'
                            type='submit'
                        >{ store_vendorlists.loading_search ? "Loading" : "Search" } </Button>
                    </Box>
                </Stack>
            </form>
            <DialogField 
                open={open}
                dataBuyer={dataBuyer}
                setOpen={setOpen}
                name="Create New Preferred Buyer"
            />
        </div>
    )
}

export default SearchAddNew
