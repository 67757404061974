import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../utilities/config';
import axios from 'axios';


const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getPurchaseRequestData = createAsyncThunk(
    'purchase-requests/get',
    async (data:any, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-request/paginate/${userCredentials.buyer_id}`, {
                params : {
                    skip : data.page,
                    limit : data.limit
                },
                headers
            })
            if(response.data) { 
                return {data : response.data.data, total: response.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getOpenPurchaseRequest = createAsyncThunk(
    'purchase-requests-open/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-request/Open/${userCredentials.buyer_id}/getByStatus`, {headers})
            if(response.data) { 
                return response.data.data
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getPurchaseRequestBySearch = createAsyncThunk(
    'purchase-requests-search/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-request`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                    }, 
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-request`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removePurchaseRequestData = createAsyncThunk(
    'purchase-requests/remove',
    async (id : string, { rejectWithValue }) => {
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/purchase-request/${id}`, {headers})
            if(response.data.errors === null) { 
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getAllDepartment = createAsyncThunk(
    'deparment/get',
    async (_, { rejectWithValue }) => {
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/department?buyer_id=${userCredentials.buyer_id}`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


