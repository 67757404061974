import { useEffect, useState } from 'react'
import { Stack, Grid, Typography, Box, Paper, Button, Backdrop, CircularProgress, TextField } from '@mui/material'
import { Autocomplete } from './Autocomplete';
import ItemListPurchaseRequest from './ItemListPurchaseRequest';
import { useDispatch, useSelector } from 'react-redux';
import { addToLocalDBCarts } from '../../../purchase_requests/create/reducers/createPurchaseRequestReducers';
import Select from 'react-select'
import { getAllDepartment } from '../../reducers/purchaseRequestReducers';
import { RootState } from '../../../../../app/store';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { getCompanyAddress } from '../../../profile/reducers/profilesReducers';
import { userCredentials } from '../../../../../utilities/config';


const BoxSearch = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const state_location : any = location.state
    const department_value : any = state_location === undefined ? undefined : state_location.department
    const store_profile = useSelector((state : RootState) => state.profile)

    const [itemSelect, setItemSelect] = useState<any>([]);
    const [loadingBackdrop, setLoadingBackdrop] = useState(false);
    const store_purchaserequest = useSelector((state : RootState) => state.purchase_request)

    const [summaryProduct, setSummaryProduct] = useState<any>({
        name : "",
        variance_name : "",
        retail_price : "",
        discount_price : "",
        final_price : "",
        sub_total : "",
        sum_price_discount: "",
        tax_price : "",
        single_tax_price: "",
        tax_percentage: "",
        quantity : "",
        discount : null
    });

    const [departmentOptions, setdepartmentOptions] = useState<any>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>([]);
    const [changeDepartment, setChangeDepartment] = useState(false);

    const handleChangeDepartment = (value:any) => {
        setChangeDepartment(true)
        setSelectedDepartment(value)
    }

    const [optionsAddress, setOptionsAddress] = useState<any[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<any>(); 
    const [loadedAddress, setLoadedAddress] = useState(false);
  
    useEffect(() => {
      if(store_profile.address.length !== 0) {
        let address = []
        for(let element of store_profile.address) {
            address.push({
                label : element.village,
                name : element.city,
                value : element
            })
        }
        setOptionsAddress(address)
        let address_local : any = localStorage.getItem('delivery_area')
        if(address_local === null) {
            localStorage.setItem('delivery_area', JSON.stringify(address.filter((item:any) => item.value.is_default === true)[0]))
            setSelectedAddress(address.filter((item:any) => item.value.is_default === true)[0])
        } else {
            let address = JSON.parse(address_local)
            setSelectedAddress(address)
        }
        setTimeout(() => {
          setLoadedAddress(true)
        }, 1000);
      }
       // eslint-disable-next-line
    }, [store_profile.address]);
  
    const handleChangeAddress = (value: any) : void => {
      setSelectedAddress(value)
      localStorage.setItem('delivery_area', JSON.stringify(value))

      setChangeDepartment(true)
    }
  
    useEffect(() => {
      dispatch(getCompanyAddress())
       // eslint-disable-next-line
    }, []);

    const [optionsVariant, setOptionsVariant] = useState<any[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<any>();

    const onSelectItem = (itemSelected : any) => {
        setItemSelect(itemSelected)
        setSummaryProduct({...summaryProduct, 
            name : itemSelected.name,
            variance_name : "",
            retail_price : itemSelected.retail_price,
            discount_price : itemSelected.discount_price,
            final_price : itemSelected.final_price,
            sub_total: itemSelected.sub_total,
            sum_price_discount: itemSelected.sum_price_discount,
            tax_price: itemSelected.tax_price,
            single_tax_price: itemSelected.single_tax_price,
            tax_percentage: itemSelected.tax_percentage,
            quantity : itemSelected.quantity,
            discount: itemSelected.discount
        })
        setOptionsVariant([])
    }

    const onClickReset = () => {
        setItemSelect([])
        setSelectedVariant([])
        setOptionsVariant([])
        setSummaryProduct({...summaryProduct, 
            name : "",
            variance_name : "",
            retail_price : "",
            discount_price : "",
            final_price : "",
            sub_total: "",
            sum_price_discount: "",
            tax_price: "",
            single_tax_price: "",
            quantity : "",
            tax_percentage: "",
            discount: "",
        })
    }

    const handleChangeVariant = (value: any) : void => {
        setSelectedVariant(value)
        let sum_price_discount = summaryProduct.discount * parseInt(value.value)
        let price_discount = value.value - sum_price_discount
        let single_tax_price = (summaryProduct.tax_percentage * price_discount)
        let tax_price = single_tax_price * summaryProduct.quantity

        setSummaryProduct({...summaryProduct, 
            variance_name : value.label,
            retail_price : value.value,
            discount_price : price_discount,
            sum_price_discount: sum_price_discount, 
            sub_total : price_discount * summaryProduct.quantity,
            single_tax_price: single_tax_price,
            tax_price : tax_price,
        })
    }

    const onClickAdd = () => {
        let add_quantity = parseFloat(summaryProduct.quantity) + 1
        if(add_quantity > itemSelect.stock) {
            swal('Error', `Product stock left ${itemSelect.stock}!`, 'error')
        } else {
            let add_sub_total = parseInt(summaryProduct.discount_price) * add_quantity
            setSummaryProduct({...summaryProduct, 
                sub_total : add_sub_total,
                tax_price: summaryProduct.single_tax_price * add_quantity,
                quantity : add_quantity
            })
        }
    }

    const onClickRemove = () => {
        let remove_quantity = parseFloat(summaryProduct.quantity) - 1
        let remove_sub_total = parseInt(summaryProduct.discount_price) * remove_quantity
        setSummaryProduct({...summaryProduct, 
            sub_total : remove_sub_total,
            tax_price: summaryProduct.single_tax_price * remove_quantity,
            quantity : remove_quantity
        })
    }


    const onChangeQuantity = (e : any ) => {
        const newValue = e.target.value;
        const decimalRegex = /^\d*\.?\d*$/;
        if (decimalRegex.test(newValue)) {
            let add_sub_total = parseInt(summaryProduct.discount_price) * newValue
            setSummaryProduct({...summaryProduct, 
                sub_total : add_sub_total,
                tax_price: summaryProduct.single_tax_price * newValue,
                quantity : newValue
            })
            setTimeout(() => {
                if (parseInt(newValue) > itemSelect.stock) { 
                    setSummaryProduct({...summaryProduct, 
                        sub_total : parseInt(summaryProduct.discount_price) * itemSelect.stock,
                        tax_price: summaryProduct.single_tax_price * itemSelect.stock,
                        quantity :itemSelect.stock
                    })
                    swal('Error', `Product stock left ${itemSelect.stock}!`, 'error')
                } else if(parseInt(newValue) < parseInt(itemSelect.minimum_order_quantity) ) {
                    swal('error', `Minimum order quantity is ${parseInt(itemSelect.minimum_order_quantity)}`, 'error')
                    setSummaryProduct({...summaryProduct, 
                        sub_total : parseInt(summaryProduct.discount_price) * parseInt(itemSelect.minimum_order_quantity),
                        tax_price: summaryProduct.single_tax_price * parseInt(itemSelect.minimum_order_quantity),
                        quantity :parseInt(itemSelect.minimum_order_quantity)
                    })
                }  
            }, 1000);
        }
    }

    const onClickToList = () => {
        if(itemSelect.stock !== 0) {
            let product_item = {
                name: itemSelect.name,
                productId : itemSelect.productId,
                sku: `${itemSelect.sku}`,
                brand: itemSelect.brand,
                images_product: itemSelect.images_product,
                minimum_order_quantity: parseInt(itemSelect.minimum_order_quantity),
                dimension: itemSelect.dimension,
                slug: itemSelect.slug,
                stock: itemSelect.stock,
                storage: itemSelect.storage,
                vendor : itemSelect.vendor,
                vendorId: itemSelect.vendorId,
                vendor_name: itemSelect.vendor_name,
                // vendor_address : itemSelect.vendor_address,
                // vendor_phone : itemSelect.vendor_phone,
                // warehouse_detail: itemSelect.warehouse_detail,
                payment_term: itemSelect.payment_term,
                // reported_times: itemSelect.reported_times,
                tax : itemSelect.tax,
                statuses : [],
                warehouse : [],
                // category_tree: itemSelect.categories,
                retail_price: summaryProduct.retail_price,
                discount: summaryProduct.discount,
                discount_price: summaryProduct.discount_price,
                sub_total_before_tax: summaryProduct.sub_total,
                sub_total: Math.floor((summaryProduct.sub_total + summaryProduct.tax_price)),
                quantity : parseFloat(summaryProduct.quantity),
                single_tax_price: summaryProduct.single_tax_price,
                down_payment_setting: itemSelect.down_payment_setting,
                note : ""
            }
            dispatch(addToLocalDBCarts(product_item))
            setItemSelect([])
        } else {
            swal('Error', 'Product stock is empty, please contact vendor.', 'error')
        }
    }

    useEffect(() => {
        dispatch(getAllDepartment())
         // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_purchaserequest.loaded_department) {
            let userId = userCredentials.auth_id
            let department = []
            for(let k of store_purchaserequest.department) {
                for(let i of k.users) {
                    if(i.userId === userId) {
                        department.push({ value: k.name, label: k.name })
                    }
                }
            }
            setdepartmentOptions(department)
        }
         // eslint-disable-next-line
    }, [store_purchaserequest.loaded_department]);

    useEffect(() => {
        if(department_value !== undefined) {
            if(department_value !== "generic") {
                setSelectedDepartment({value: department_value, label: department_value})
            }
        }
        // eslint-disable-next-line
      }, [department_value]);
 
    if(!loadedAddress) {
    return (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    )
    } else {
        return (
            <Stack>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>            
                <Stack pb={2}>
                    <Grid container spacing={3}>
                        <Grid item lg={3} md={3} sm={3} xs={6} >
                            <Typography color="textPrimary" variant="body1">Choose Department (optional) </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={6}>
                        <Box sx={{ minWidth: 300 }}>
                            <Select
                                placeholder="Select Department"
                                value={selectedDepartment}
                                isSearchable={true}
                                options={departmentOptions}
                                onChange={handleChangeDepartment}
                                id="select-style-department"
                            />
                        </Box> 
                        </Grid>
                    </Grid>
                </Stack>
                <Stack pb={2}>
                    <Grid container spacing={3}>
                        <Grid item lg={3} md={3} sm={3} xs={6} >
                            <Typography color="textPrimary" variant="body1">Delivery Area  </Typography>
                            <span style={{fontSize : '12px'}}>Base on your address</span>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={6}>
                        <Box sx={{ minWidth: 300 }}>
                            <Select
                                placeholder="Select Area"
                                value={selectedAddress}
                                isSearchable={true}
                                options={optionsAddress}
                                onChange={handleChangeAddress}
                                id="select-style-delivery"
                                // isDisabled={state_location === undefined ? false : true} 
                            />
                        </Box> 
                        </Grid>
                    </Grid>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={3} xs={6} >
                        <Typography color="textPrimary" variant="body1">Search Product </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={6}>
                        <Autocomplete
                            placeholder="Search by Vendor, Product Name or SKU"
                            openOnFocus 
                            onSelectItem={onSelectItem}
                            itemSelected={itemSelect}
                            setLoadingBackdrop={setLoadingBackdrop}
                            selectedAddress={selectedAddress}
                        />
                    </Grid>
                </Grid>

                <Stack>
                { itemSelect.length !== 0 ? 
                    <Box pt={4} pb={2}>
                        <Paper sx={{border: `1px solid ${process.env.REACT_APP_THEMED_COLOR}`}}>
                            <Box p={3}>
                                <div className='item-onselect'>
                                    <Stack flexDirection="row" justifyContent="space-between">
                                        <h3>{summaryProduct.name}{summaryProduct.variance_name === "" ? null : ` - ${summaryProduct.variance_name}`}</h3>
                                        <Box><Button variant="outlined" size="small" color="error" onClick={onClickReset}>Cancel</Button></Box>
                                    </Stack>

                                    <Stack pt={1}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={3} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Retail Price : </Box>
                                                    <Box fontWeight={600} fontSize="13px">
                                                        Rp. {summaryProduct.retail_price.toLocaleString()}
                                                    </Box> 
                                                </Stack>
                                            </Grid>
                                            <Grid item lg={4} md={3} sm={6} xs={6}>
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Vendor : </Box>
                                                    <Box fontWeight={600} fontSize="13px">{itemSelect.vendor_name}</Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Discount :</Box>
                                                    <Box fontWeight={600} fontSize="13px">
                                                        { itemSelect.discount_product === 0 ? '0%' : 
                                                        `${Math.floor(itemSelect.discount_product)}%` 
                                                        } 
                                                        { itemSelect.is_special_discount ? 
                                                        ` + ${Math.floor(itemSelect.special_discount)}% Special Discount ` : null }
                                                    </Box>
                                                </Stack> 
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Sku : </Box>
                                                    <Box fontWeight={600} fontSize="13px">{itemSelect.sku.substring(0,30)}..</Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Price Discount : </Box>
                                                    { itemSelect.discount === 0 ? 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. 0</Box> : 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. {summaryProduct.sum_price_discount.toLocaleString()}</Box> }
                                                </Stack> 
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                <Box fontSize="13px">Payment Terms : </Box>
                                                    <Box fontWeight={600} fontSize="13px" pl={1}> {itemSelect.payment_term.name}</Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Price After Discount : </Box>
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. {summaryProduct.discount_price.toLocaleString()}</Box> 
                                                </Stack> 
                                            </Grid>
                                        
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                <Box fontSize="13px">Tax : </Box>
                                                    <Box fontWeight={600} pl={1} fontSize="13px">{itemSelect.tax.value * 100}% (Rp. {summaryProduct.single_tax_price.toLocaleString()})</Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Sub Total Price : </Box>
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. {summaryProduct.sub_total.toLocaleString()}</Box> 
                                                </Stack> 
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={6} xs={6} >
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Total Tax Price: </Box>
                                                    { itemSelect.tax.value === 0 ? 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">0</Box> : 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. {summaryProduct.tax_price.toLocaleString()}</Box> }
                                                </Stack> 
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </div>
                                <Stack pt={1}  flexDirection="row" justifyContent="space-between" alignItems="center" >
                                    <Stack pt={1} flexDirection="row" rowGap={2} alignItems="center">
                                        <Box pr={3}>
                                            <Stack flexDirection="row" sx={{ border: '1px solid #000', width: 'fit-content', p : 1, borderRadius: 1 }}>
                                                <Box fontWeight={600} pr={1} fontSize="14px">Final Price + Tax :</Box>
                                                <Box fontWeight={600} fontSize="15px" color="red">Rp. {(summaryProduct.sub_total + summaryProduct.tax_price).toLocaleString()}</Box>
                                            </Stack>
                                        </Box>
                                        { optionsVariant.length === 0 ? null :
                                        <Box pr={2} sx={{minWidth: 250}}>
                                            <Select
                                                placeholder="Select Variant"
                                                value={selectedVariant}
                                                isSearchable={false}
                                                options={optionsVariant}
                                                onChange={handleChangeVariant}
                                            />
                                        </Box>  }
                                        <Box pr={2}>
                                            <Button 
                                                size="small"
                                                variant="contained" 
                                                onClick={onClickRemove}
                                                disabled={ summaryProduct.quantity <= 1 || summaryProduct.quantity <= parseInt(itemSelect.minimum_order_quantity)  ? true : false }
                                            >-</Button>
                                        </Box>
                                        <Box fontSize="20px" sx={{paddingTop: '2px'}}>
                                            <TextField 
                                                value={summaryProduct.quantity}
                                                onChange={onChangeQuantity} 
                                                size="small"
                                                sx={{ width: 100 }} 
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </Box>
                                        <Box pl={2} >
                                            <Button 
                                                size="small"
                                                variant="contained" 
                                                color='secondary'
                                                onClick={onClickAdd}
                                            >+</Button>
                                        </Box>

                                        <Box pl={4}>
                                            <Button 
                                                variant="contained" color="primary" size="small" 
                                                onClick={onClickToList}
                                            > Add to PR</Button>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Box pt={1} fontSize="12px">Mininum Order : {itemSelect.minimum_order_quantity}</Box> 
                                <Box pt={1} fontSize="12px">In Stock : {itemSelect.stock}</Box> 
                            </Box>
                        </Paper>
                    </Box>
                    : null }
                </Stack>

                <Stack>
                    <ItemListPurchaseRequest 
                        department={selectedDepartment.value === undefined ? "generic" : selectedDepartment.value} 
                        changeDepartment={changeDepartment}
                        setChangeDepartment={setChangeDepartment}
                        selectedAddress={selectedAddress}
                    />
                </Stack>
            </Stack>
        ) 
    }
}

export default BoxSearch
