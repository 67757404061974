import { Button, Grid, Stack } from '@mui/material'
import { Box } from '@mui/system'
import MenuPopOver from '../../../components/MenuPopOver'
import {userCredentials} from './../../../utilities/config'
import CardStatistic from './components/CardStatistic'
import { Link, useHistory } from 'react-router-dom'
import { checkValidation } from '../../../utilities/userFeaturesValidation'
import UnverifiedWelcome from './components/UnverifiedWelcome'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { useEffect, useState } from 'react'
import { getPurchaseRequestData } from '../purchase_requests/reducers/purchaseRequestReducers'
import { getActivePurchaseOrders } from '../purchase_orders/reducers/purchaseOrdersReducers'
import { getDataInvoice } from '../finance/invoice/reducers/invoiceReducers'
import cargo from '../../../assets/img/dashboard/cargo.png'
import cart from '../../../assets/img/dashboard/cart.png'
import order from '../../../assets/img/dashboard/order.png'
import payable from '../../../assets/img/dashboard/payable.png'

function HomeDashboard() {
    const history = useHistory()

    const dispatch = useDispatch()
    const { loading : loading_pr, total_data } = useSelector((state : RootState) => state.purchase_request)
    const { count_active, loading_active } = useSelector((state : RootState) => state.purchase_orders)
    const { loading, loaded, data } = useSelector((state : RootState) => state.invoices)

    const [totalPayable, setTotalPayable] = useState<any>(null);
    const [totalPurchases, setTotalPurchases] = useState<any>(null);

    const username = userCredentials === null ? "" : userCredentials.fullname
    const company = userCredentials === null ? "" : userCredentials.buyer_name

    // console.log(userCredentials, 'user credentisl')
    useEffect(() => {
        const data = {
            limit : 10,
            page : 0
        }
        dispatch(getPurchaseRequestData(data))
        dispatch(getActivePurchaseOrders())
        dispatch(getDataInvoice())
        // eslint-disable-next-line
    }, []);

    function sumTotalPayable (data : any) {
        return data.reduce((total : any, data : any) => {
          return total + data.grand_total
        }, 0)
    }

    const getStatusTotalPayable = (data:any) => {
        let filter = data.filter((key : any) => key.lastStatus !== "Paid")
        return sumTotalPayable(filter)
    }

    const getStatusTotalPurchases = (data:any) => {
        let filter = data.filter((key : any) => key.lastStatus === "Paid")
        return sumTotalPayable(filter)
    }

    useEffect(() => {
        if(loaded) {
            setTotalPayable(getStatusTotalPayable(data))
            setTotalPurchases(getStatusTotalPurchases(data))
        }
        // eslint-disable-next-line
    }, [loading]);

    return (
        <Box>
            { userCredentials.company_status === "UNVERIFIED" ? 
            <UnverifiedWelcome/>
            :
            <Box sx={{pl:3, pr:3, pt:2}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box>
                        <h3>Welcome back, {username}</h3>
                        <Box pt={0.5} fontWeight="400">{company}</Box>
                    </Box>
                    { checkValidation('61d5d017ca14956261f09665') ?
                    <Box sx={{display: 'flex'}}>
                        <Button variant="contained" color="primary"   size="small" 
                            onClick={() => {
                                history.push({
                                    pathname: "/dashboard/purchase-requests",
                                    search: `?page=create`, 
                                })
                            }}
                        >
                            Create New PR
                        </Button>
                        <Box sx={{mr:2}} />
                        <MenuPopOver
                            name="Create New PR From Template"
                            color="primary"
                        />
                    </Box> : null }
                </Stack>
                <Stack sx={{pt:3}} >
                    <Grid container spacing={3}>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <Link to="/dashboard/finance/invoice">
                            <CardStatistic 
                                label="Account Payable"
                                value={totalPayable}
                                loading={loading}
                                currency={true}
                                background="#fff"
                                src={payable}
                            />
                            </Link>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <Link to="/dashboard/finance/invoice">
                            <CardStatistic 
                                label="Total Purchases"
                                value={totalPurchases}
                                loading={loading}
                                currency={true}
                                background="#fff"
                                src={order}
                            />
                            </Link>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <Link to="/dashboard/purchase-requests">
                            <CardStatistic 
                                label="Total PR"
                                value={total_data}
                                loading={loading_pr}
                                currency={false}
                                background="#fff"
                                src={cart}
                            />
                            </Link>
                        </Grid>
                        <Grid item xl={3} lg={3} sm={6} xs={12}>
                            <Link to="/dashboard/purchase-orders">
                            <CardStatistic 
                                label="Active PO"
                                value={count_active}
                                loading={loading_active}
                                currency={false}
                                background="#fff"
                                src={cargo}
                            />
                            </Link>
                        </Grid>
                    </Grid>
                </Stack>
            </Box> }
        </Box>
    )
}

export default HomeDashboard
