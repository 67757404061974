
const waiting_verified : any = [
    {
      all_child_navigations_parents: [],
      all_features_parents: [],
      child_navigations: [],
      features: [],
      module_detail : {
        flag: "BUYER",
        has_child: false,
        iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/e2a33eae-4126-4afd-9cea-85b30eada25ahome(1).svg",
        is_parent: true,
        name: "Waiting Verifiy",
        path: "/dashboard",
        sort_number: "1"
      }
    }
]

const step_register : any = [
    {
      all_child_navigations_parents: [],
      all_features_parents: [],
      child_navigations: [],
      features: [],
      module_detail : {
        flag: "BUYER",
        has_child: false,
        iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/e2a33eae-4126-4afd-9cea-85b30eada25ahome(1).svg",
        is_parent: true,
        name: "Completing Register",
        path: "/register/step",
        sort_number: "1"
      }
    }
]

const admin_menu : any = [
  {
    all_child_navigations_parents: [],
    all_features_parents: [],
    child_navigations: [],
    features: [],
    module_detail : {
      flag: "BUYER",
      has_child: false,
      iconImage: "https://mpindo.sgp1.cdn.digitaloceanspaces.com/admin/e2a33eae-4126-4afd-9cea-85b30eada25ahome(1).svg",
      is_parent: true,
      name: "Select Company",
      path: "/login/select-company",
      sort_number: "1"
    }
  }
]

export const getAllModulesPath = (first_time : boolean, status : string, role : any ) => {
    let all_modules = []

    if(first_time) {
        for(let module of step_register) {
          if(module.child_navigations.length !== 0) {
            for(let child of module.child_navigations) {
              all_modules.push(child.path)
            }
          }
          all_modules.push(module.module_detail.path)
        }
    } else {
        if(status === "UNVERIFIED") {
            for(let module of waiting_verified) {
                if(module.child_navigations.length !== 0) {
                  for(let child of module.child_navigations) {
                    all_modules.push(child.path)
                  }
                }
                all_modules.push(module.module_detail.path)
            }
          } else {
            for(let module of role.modules) {
                if(module.child_navigations.length !== 0) {
                  for(let child of module.child_navigations) {
                    all_modules.push(child.path)
                  }
                }
                all_modules.push(module.module_detail.path)
                all_modules.push("/dashboard/detail/vendor")
            }
          }
    }
    return all_modules
}


export const getAllModulesMenu = (first_time : boolean, status : string, role : any ) => {
    let all_modules = []

    if(first_time) {
        all_modules = step_register
    } else {
        if(status === "UNVERIFIED") {
            all_modules = waiting_verified
        } else {
            all_modules = role.modules
        }
    }
    return all_modules
}


export const getAllModulesPathSuperAdmin = () => {
  let all_modules = []
  for(let module of admin_menu) {
    if(module.child_navigations.length !== 0) {
      for(let child of module.child_navigations) {
        all_modules.push(child.path)
      }
    }
    all_modules.push(module.module_detail.path)
  }
  return all_modules
}


export const getAllModulesSuperAdmin = () => {
  return admin_menu
}