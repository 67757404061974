import { Box, Stack, TextField, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableColumn } from 'react-data-table-component';
import { RootState } from '../../../app/store'
import DataTableBase from '../../../components/TableData'
import {userCredentials} from '../../../utilities/config'
import { getAllBuyerCompany, getAllBuyerCompanyBySearch, loginAsBuyerCompany } from './reducers/selectCompanyReducers'

function SelectCompany() {

    const dispatch = useDispatch()
    const store_buyer = useSelector((state : RootState) => state.all_buyer)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [indexUser, setIndexUser] = useState<any>("");

    const [searchCompany, setSearchCompany] = useState("");

    const onClickSearch = () => {
        if(searchCompany !== "") {
            let query = {
                buyer_name : searchCompany
            }
            dispatch(getAllBuyerCompanyBySearch(query))
        }
    }
    useEffect(() => {
        dispatch(getAllBuyerCompany())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_buyer.login_as_buyer) {
            window.location.href = "/dashboard"
        }
        // eslint-disable-next-line
    }, [store_buyer.login_as_buyer]);


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'COMPANY NAME',
            selector: row => row.buyer_name,
        },
        {
            name: 'Owner Name',
            selector: row => row.fullname,
        },
        {
            name: 'Owner Email',
            selector: row => row.email,
        },
        {
            name: 'ACTION',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    <Box >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            fullWidth
                            onClick={() => {
                                setIndexUser(i)
                                dispatch(loginAsBuyerCompany(row.auth_id))
                            }}
                            disabled={store_buyer.loading_as_buyer ? true : false}
                        >   
                            {store_buyer.loading_as_buyer && i === indexUser ? "Loading.." : "Login"}
                        </Button>
                    </Box> 
                </Stack>
            ),
        },
    ];
    return (
        <div>
           <div className="section-top">
                <h3>Welcome Super Admin - {userCredentials.fullname}</h3>
                <p>Please select company</p>
           </div>

            <Stack pt={3} pl={3} pr={3} direction="row">
               <Box sx={{width: 500, mr: 1}}>
               <TextField 
                    label="Search Company"
                    size="small"
                    fullWidth
                    value={searchCompany}
                    onChange={(e) => setSearchCompany(e.target.value)}
                />
               </Box>
               <Box>
                    <Button 
                        variant='contained'
                        disabled={searchCompany === "" ? true : false}
                        onClick={onClickSearch}
                    >
                       {store_buyer.loading ? "Loading.." : "Search"}
                   </Button>
               </Box>
               <Box ml={1}>
                    <Button 
                        variant='outlined'
                        onClick={() => {
                            setSearchCompany("")
                            dispatch(getAllBuyerCompany())
                        }}
                    >
                       Clear
                   </Button>
               </Box>
            </Stack>

            <Stack p={3}>
                <DataTableBase 
                    columns={columns}
                    data={store_buyer.data}
                    progressPending={store_buyer.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                    paginationPerPage={limit}
                    noDataComponent="Company Not Found"
                />
            </Stack>
        </div>
    )
}

export default SelectCompany
