import { useState, useEffect } from 'react'; 
import { 
    Button, Stack, CircularProgress ,
    Dialog, Box,
    DialogActions,
    DialogContent,
    DialogTitle} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import DataTableBase from '../../../../components/TableData';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux'; 
import { getPurchaseRequestData, removePurchaseRequestData } from '../reducers/purchaseRequestReducers';
import { RootState } from '../../../../app/store';
import moment from 'moment'
import axios from 'axios'
import swal from 'sweetalert' 
import Swal from 'sweetalert2'
import { checkValidation } from '../../../../utilities/userFeaturesValidation';
import SearchData from './SearchData';
import { postSubmitPurchaseRequest } from '../create/reducers/createPurchaseRequestReducers';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false





const DataTablePurchaseRequest = ({ filterOpen } : any) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const store_purchaserequest = useSelector((state : RootState) => state.purchase_request)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const [totalPage, setTotalPage] = useState(0);
    
    const [loading, setLoading] = useState(store_purchaserequest.loading);
    
    const [loadingUpdate, setLoadingUpdate] = useState({
        index : null,
        loading : false
    });
    const [dataPurchaseRequests, setDataPurchaseRequests] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const [valueStatus, setValueStatus] = useState<any>([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangePerPage = (page:any) => {
        setPage(page-1)
    }

    function getPurchaseRequestOrders() {
        setTimeout( async () => {
            let filter = store_purchaserequest.data.filter((element : any) => element.lastStatus === "Open" )
            setDataPurchaseRequests(filterOpen ? filter : store_purchaserequest.data)  
            setTotalPage(store_purchaserequest.total_data)
            setLoading(false)
        }, 1000);
    }

    useEffect(() => {
        setLoading(true)
        const data = {
            limit,
            page,
        }
        dispatch(getPurchaseRequestData(data))
        // eslint-disable-next-line
    }, [limit, page]);

    useEffect(() => {
        if(store_purchaserequest.loaded) {
            getPurchaseRequestOrders()
        }
        // eslint-disable-next-line
    }, [store_purchaserequest.loaded]);

    function onClickViewDetail (value : any) {
        history.push({
            pathname: "/dashboard/purchase-requests",
            search: `?page=detail`, 
            state : {
                data : value 
            }
        })
    }

    const onClickUpdate = async (params:any, i : any) => {
        setLoadingUpdate({...loadingUpdate, index : i, loading: true })
        try {
            const items : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/purchase-request/Items/${params._id}`)
            if(items.data.errors === null) {
                let vendor = items.data.data
                let result = await db.collection('db_local_CART').set(vendor)
                if(result) {
                    history.push({
                        pathname: "/dashboard/purchase-requests",
                        search: `?page=create`, 
                        state : {
                            name : "Update",
                            department : params.department,
                            code_pr : params.code_pr
                        }
                    })
                }
                let id_cart_pr = {
                    saved: true,
                    change: false,
                    id : params._id
                }
                localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
            } else {
                swal('Error', `${items.data.message}`, 'error')
            }
          } catch (error) {
              swal('Error', `${error}`, 'error')
          }
    }

    const onClickRemove = (row : any, i : any) => {
        Swal.fire({
            title: 'Warning!',
            text: "Are you sure want to delete this PR?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: 'primary',
            confirmButtonText: 'Yes!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(removePurchaseRequestData(row._id))
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {
                    limit,
                    page,
                }
                dispatch(getPurchaseRequestData(data))
            }
        })
    }

// my task
    const onClickSubmitPR = (row : any) => {
        Swal.fire({
            title: 'Warning!',
            text: "Are you sure want to Submit this PR?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(postSubmitPurchaseRequest(row._id))
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                setTimeout(() => {
                    setLoading(true)
                    const data = {
                        limit,
                        page,
                    }
                    dispatch(getPurchaseRequestData(data))
                }, 1000);
            }
        })
    }


    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '50px',
            cell: (row, index) => (
            <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
            </p>
            )
        }, 
        {
            name: 'CODE',
            width: '110px',
            cell: (row, i) => (
                <div>
                    { row.lastStatus === 'Open' ? 
                    <>
                    { checkValidation('61d59fd8f1fca909b978f7bb') ?
                    <Box 
                        onClick={() => onClickUpdate(row, i)}
                        sx={{cursor : 'pointer', color: 'blue'}}
                        >{ row.code_pr }
                    </Box> : 
                    <Box>{row.code_pr}</Box>
                    }
                    </> 
                    :
                   <Box 
                        onClick={() => onClickViewDetail(row)}
                        sx={{cursor : 'pointer', color: 'blue'}}
                    >{ row.code_pr }</Box> }
                </div>
            ),

        },
        {
            name: 'DATE',
            cell: (row) => (
                <div>{moment(row.createdAt).format('l')}</div>
            )
        },
        // {
        //     name: 'AUTHOR',
        //     selector: row => row.createdBy,
        // },
        {
            name: 'DEPARTMENT',
            cell: (row) => (
                <div>{row.department === 'generic' ? "N/A" : row.department}</div>
            )
        },
        {
            name: 'VENDOR',
            cell: (row) => (
                <div>
                    {row.vendors.length === 0 ? "" :
                    <div>{ row.vendors.length > 1 ? "Multi Vendor" : row.vendors[0].vendor.name }</div>
                    }
                </div>
            ),
        },
        {
            name: 'TOTAL PRICE',
            width: '120px',
            cell: (row) => (
                <div>Rp. {row.total.toLocaleString()}</div>
            )
        },
        {
            name: 'STATUS',
            width: '100px',
            cell: (row) => (
                <Chip label={row.lastStatus} size="small" />
            ),
        },
        {
            name: 'HISTORY',
            width: '100px',
            cell: (row) => ( 
                <div>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        size="small" 
                        onClick={() => {
                            setValueStatus(row.statuses)
                            handleClickOpen()
                        }}
                        fullWidth
                    >
                    View
                    </Button>
                </div>
            ),
        },
        {
            name: 'ACTION',
            width: '310px',
            cell: (row, i) => (
                <Stack direction="row" spacing={3}>
                    { row.lastStatus === 'Open' ? 
                    <>
                    <Box>
                     { checkValidation('61d59fd8f1fca909b978f7bb') ?
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            onClick={() => onClickUpdate(row, i) }
                            fullWidth
                        >
                        { loadingUpdate.index === i && loadingUpdate.loading ? 
                            <div className="loading-button"> 
                                <p>Loading</p>  
                                <CircularProgress size={20} color="inherit" />
                            </div> : "Edit"
                        }
                        </Button> : null }
                    </Box> 
                    
                    <Box>
                    { checkValidation('61d5d017ca14956261f09665') ?
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="small" 
                            onClick={() => onClickSubmitPR(row)}
                            fullWidth
                        >
                        Submit
                        </Button> : null }
                    </Box>
                    </>:
                    <Box onClick={() => onClickViewDetail(row) }>
                        <Button 
                            variant="contained" 
                            color="info" 
                            size="small" 
                            fullWidth
                        >
                            View Detail
                        </Button>
                    </Box> }
                   
                    { checkValidation('61d59fddf1fca909b978f7bd') ?
                    <>
                    { row.lastStatus === "Open"   ? 
                    <Box>
                        <Button 
                            variant="outlined" 
                            color="error" 
                            size="small" 
                            onClick={() => onClickRemove(row, i) }
                            fullWidth
                        >
                            Delete
                        </Button>
                    </Box> : null }
                    </> : null }

                </Stack>
            ),
        },
    ];

    const columnsStatus: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '60px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'DATE',
            width: '200px',
            cell: (row) => (
                <div>{moment(row.timestamp).format('lll')}</div>
            )
        },
        {
            name: 'STATUS',
            selector: row => row.name,
            width: '120px'
        },
        {
            name: 'NAME',
            selector: row => row.user,
            width: '120px'
        },
        
        {
            name: 'NOTE',
            selector: row => row.note,
            width: '240px'
        },
        
    ];

    return (
        <div>

            <Box pb={2}>
                <SearchData
                    data={dataPurchaseRequests}
                    progressPending={loading}
                    setLoading={setLoading}
                />
            </Box>
            <DataTableBase 
                columns={columns}
                data={dataPurchaseRequests}
                progressPending={loading}
                pagination
                paginationServer
                onChangePage={(value) => onChangePerPage(value)}
                onChangeRowsPerPage={(value) => setLimit(value)}
                paginationPerPage={limit}
                paginationTotalRows={totalPage}
                // paginationDefaultPage={page}
                noDataComponent="Purchase Request Not Found!"
            />
            {/* DIALOG OPEN HISTORY */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {"History Purchase Requests"}
                </DialogTitle>
                <DialogContent>
                    { valueStatus.length === 0 ? null :
                    <DataTableBase 
                        columns={columnsStatus}
                        data={valueStatus}
                    /> }
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DataTablePurchaseRequest
