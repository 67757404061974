import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import {  Box, Stack, Avatar } from '@mui/material';
import { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import algoliasearch from 'algoliasearch/lite';
import swal from 'sweetalert';
import axios from 'axios'
import { userCredentials } from '../../../../../utilities/config';

const appId = `${process.env.REACT_APP_API_ALGOLIA_KEY}`
const apiKey = `${process.env.REACT_APP_API_ALGOLIA_SECRET}`
const searchClient = algoliasearch(appId, apiKey);

export function AutocompleteTemplate(props : any) {
  // console.log(props.selectedAddress, 'addreses')
  const containerRef = useRef(null);

  const onChooseProduct = async (product : any) => {
    props.setLoadingBackdrop(true)
      try {
        const vendor_detail : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail?vendor_id=${product.vendor_id}`)
        if(vendor_detail.data.errors === null) {
            let vendor = vendor_detail.data.data
            try {
              const special_price : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/mybuyer/product`, {
                params : {
                  vendor_id : product.vendor_id,
                  buyer_id : userCredentials.buyer_id,
                  product_id : product._id,
                }
              })
              if(special_price.data.errors === null) {
                  let quantity = product.minimum_order_quantity < 1 ? 1 : product.minimum_order_quantity
                  let discount_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].discount))
                  let down_payment_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].down_payment))
                  let discount_preferred = (parseInt(special_price.data.data.discount_percentage))
                  let discount_product = ( product.discount * 100)
                  let sum_discount =  discount_product + discount_preferred
                  let tax_percentage = product.pajak.value
                  let discount = product.discount === 0 && discount_preferred === 0 ? (discount_setting / 100) : (sum_discount / 100)
                  let sum_price_discount = discount * product.retail_price
                  let discount_price = product.retail_price - sum_price_discount
                  let sub_total = discount_price * quantity
                  let single_tax_price = (tax_percentage * discount_price)
                  let tax_price = (tax_percentage * discount_price) * quantity
                  let final_price = sub_total + tax_price

                  let product_item = {
                    productId : product._id,
                    vendorId: product.vendor_id,
                    name: product.name,
                    vendor : {
                      email: vendor.email,
                      phone: vendor.phone,
                      address: `${vendor.street}, ${vendor.village}, ${vendor.subdistrict}, ${vendor.city}, ${vendor.province}, ${vendor.country}, ${vendor.postcode}`,
                      name: product.vendor_name,
                      _id: product.vendor_id
                    },
                    tax : {
                      title : product.pajak.title,
                      value : product.pajak.value,
                    },
                    vendor_name: product.vendor_name,
                    // vendor_phone : vendor.phone,
                    sku: product.SKU,
                    slug: product.slug_product,
                    stock: product.stock,
                    brand: product.brand,
                    images_product: product.images_product[0],
                    storage: product.storage,
                    // dimension: product.dimension,
                    sub_products: product.sub_products,
                    categories: product.category_id,
                    // reported_times: product.reported_times,
                    minimum_order_quantity: product.minimum_order_quantity,
                    retail_price: product.retail_price,
                    discount: discount,
                    discount_product: discount_product,
                    is_special_discount : special_price.data.data.discount_percentage === "0" ? false : true,
                    special_discount : discount_preferred,
                    discount_price: discount_price,
                    discount_setting: discount_setting,
                    quantity : quantity,
                    sub_total : sub_total,
                    single_tax_price: Math.floor(single_tax_price),
                    tax_price : tax_price,
                    tax_percentage: tax_percentage,
                    sum_price_discount: sum_price_discount,
                    final_price : final_price,
                    payment_term: product.payment_term,
                    // warehouse_detail: product.warehouse_detail,
                    // category_tree : product.category_tree,
                    down_payment_setting: down_payment_setting,
                    note : ""
                  }
                  props.setLoadingBackdrop(false)
                  return product_item
              } else {
                props.setLoadingBackdrop(false)
                swal('Error', `${special_price.data.message}`, 'error')
                return null
              }
            } catch (error) {
              props.setLoadingBackdrop(false)
              swal('Error', `${error}`, 'error')
              return null
            }
        } else {
            props.setLoadingBackdrop(false)
            swal('Error', `${vendor_detail.data.message}`, 'error')
            return null
        }
      } catch (error) {
          props.setLoadingBackdrop(false)
          swal('Error', `${error}`, 'error')
          return null
      }
  }

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children } : any, root) {
        render(children, root);
      },
      initialState : {
        query : props.itemSelected.name ? props.itemSelected.name : '',
        // facetFilters : `warehouse_detail.coverage.nama: '${ props.selectedAddress.name || '' }' AND NOT images_product : 'https://mpindo.sgp1.digitaloceanspaces.com/636cb538141026613e75882b/7ffbad75-8f2c-47fd-88ca-9d0a2ce986df.jpg' AND NOT images_product : 'https://mpindo.sgp1.digitaloceanspaces.com/63919619141026613e75a2c6/98767b6b-cc96-44f3-af03-2c1cb356bd32.jpg' `, 
      },
      getSources: ({ setQuery } : any) => [
        // {
        //     sourceId : 'actions',
        //     templates : {
        //         header() {
        //             return <Box fontWeight={600} mt={3} mb={1}>Last Search</Box>
        //         },
        //         item ({ item } : any) {
        //             return (
        //                 <Box mb={1} mt={1} className='product-list-algolia'>
        //                     <p>{item.name} - sdkufdsh</p>
        //                     <h6>{item.vendor_name}</h6>
        //                 </Box>
        //             )
        //         }
        //     },
        //     getItemInputValue: ({ item }) => item.name,
        //     getItems ({ query } : any ) {
        //         return [
        //             {
        //                 name : 'Product Cantik',
        //                 vendor_name : 'Luxofood',
        //             }
        //         ]
        //     },
        //     onSelect(params : any) {
        //         props.onSelectItem(params.item)
        //     }
        // },
        {
            sourceId: 'products',
            templates : {
                header() {
                    return <Box fontWeight={600} mb={1}>All Products</Box>
                },
                item({ item, components } : any) {
                    return (
                    <Stack flexDirection="row" alignContent="center" >
                      <Box p={1}>
                          <Avatar
                              alt={item.name}
                              src={item.images_product[0]}
                              sx={{ width: 40, height: 40 }}
                              variant="square"
                          />
                      </Box>
                      <Box mb={1} mt={1} className='product-list-algolia'>
                          <p>
                              <span style={{ fontWeight: 'bold' }} ><components.Highlight attribute="name" hit={item} /></span> {" - "}
                              <span><components.Highlight attribute="vendor_name" hit={item} /></span> 
                          </p>
                          <Stack flexDirection="row">
                              <Box><h5>Price : Rp. {item.retail_price === null ? "0" : item.retail_price.toLocaleString()}</h5></Box>
                              <Box pl={2}><h6>Sku : {item.SKU}</h6></Box>
                          </Stack>
                      </Box>
                    </Stack>
                    )
                }
            },
            getItems ({ query }) {
                return getAlgoliaResults({
                    searchClient,
                    queries : [
                        {
                            indexName: 'products',
                            params: {
                              "filters" : `warehouse_detail.coverage: '${ props.selectedAddress.name || '' }' `,
                              "hitsPerPage": 10,
                            },
                            query,
                        } 
                    ]
                })
            },
            onSelect (params : any) {
              setTimeout(async() => {
                const final_item = await onChooseProduct(params.item)
                if(final_item) {
                  props.onSelectItem(final_item)
                }
              }, 100);
            } 
        }
    ],
      ...props,
    });

    return () => {
      search.destroy();
    };
    // eslint-disable-next-line
  }, [props]);

  return <div ref={containerRef} />;
}
