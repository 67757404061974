import { Stack, Paper, Grid, Button, Box } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import {userCredentials} from './../../../utilities/config'
import { useDispatch } from 'react-redux' 
import {sendEmail} from '../../auth/forgot/forgotSlice'

const username : string = userCredentials === null ? "" : userCredentials.fullname
const email : string = userCredentials === null ? "" : userCredentials.email

function AccountPage() {
    const dispatch = useDispatch()

    const dataEmail = {
        email : email
    }

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Account Information"
            />
           <Box sx={{pt:2}}>
                <h3>Account Information</h3>
           </Box>

           <Box sx={{pt:2, width: '100%'}}>
                <Paper elevation={3}> 
                    <Box p={3}>
                    <Stack mt={1}>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Full Name</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{username}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Email</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{email}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Password</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">********</Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant='outlined'
                                        size="small"
                                        onClick={() => {
                                            dispatch(sendEmail(dataEmail))
                                        }}
                                    >Send Reset Password</Button>
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Stack>
                    </Box>
                </Paper>
           </Box>
        </Box>
    )
}

export default AccountPage
