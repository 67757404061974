import { useEffect, useState } from 'react';
import { Stack, Badge, CardMedia, Button } from '@mui/material';
import { Box } from '@mui/system'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Drawer from '@mui/material/Drawer';
import imgemptycart from '../../../../assets/img/icon/empty_cart.svg'
import { remove } from 'lodash'
import CheckIcon from '@mui/icons-material/Check';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import swal from 'sweetalert';
import { RootState } from '../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setRefectComparation } from '../productsSlice';

const ProductComparation : React.FC<any> = () => {
  const store_products = useSelector((state : RootState) => state.detail_product)
  const dispatch = useDispatch()

  const [stateComparation, setStateComparation] = useState(false);
 
  const [dataComparation, setDataComparation] = useState<any>([]);

  const toggleDrawerComparation = (value : any) => {
    setStateComparation(value);
  }

  const onClickRemove= (value : any) => { 
    let copy_item = [...dataComparation]
    swal({
      title: "Confirmation",
      text: `Are you sure want to remove Item ${value.name}?`,
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        const items_remove = remove(copy_item, function(obj : any) {
            return obj._id !== value._id
        });
        localStorage.setItem('data_comparation', JSON.stringify(items_remove))
        swal('Removed!', 'Product has been removed.','success')
        dispatch(setRefectComparation(!store_products.comparation))
      }
    });
  }

  const onClickRemoveAll = () => { 
    swal({
      title: "Confirmation",
      text: `Are you sure want to remove All Item?`,
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        localStorage.removeItem('data_comparation')
        swal('Removed!', 'Product has been removed.','success')
        dispatch(setRefectComparation(!store_products.comparation))
      }
    });
  }


  useEffect(() => {
    let data_comparation = localStorage.getItem('data_comparation')
    let array_comparation : any = data_comparation === null ? [] : JSON.parse(data_comparation)
    setDataComparation(array_comparation)
  }, [store_products.comparation]);

  return (
      <div className="menu-cart-button">
        <Box pr={3}>
          <Badge 
              color={dataComparation.length === 0 ? "primary" : "error"}
              badgeContent={dataComparation.length === 0 ? '0' : dataComparation.length}  
              sx={{pr:2}}
            >
            {/* <button onClick={() => toggleDrawerComparation(true)} >
              <Box sx={{display: 'flex'}}>
                <div className="cart-menu">
                    <div className="cart-text">
                      <h4>VIEW COMPARISON </h4>
                    <ArrowDropDownIcon style={{color: "#fff", marginLeft: '10px'}}/>
                  </div>
                </div>
              </Box>
            </button> */}
            <Box onClick={() => toggleDrawerComparation(true)} sx={{display: 'flex', cursor: 'pointer'}}>
                <Box fontWeight={600} fontSize={15}>VIEW COMPARISON</Box>
            </Box>
          </Badge>
        </Box>

        {/* product comparation */}
        <Drawer
            anchor="bottom"
            open={stateComparation}
            onClose={() => toggleDrawerComparation(false)}
        >
          <Stack direction="row" justifyContent="flex-end" sx={{mr:6}}>
            <Box onClick={() => toggleDrawerComparation(false)}>
              <CancelPresentationIcon 
                style={{ 
                  marginTop: 10,
                  color: 'red', 
                  cursor: 'pointer',
                  fontSize: 40 
                }}/>
            </Box>
          </Stack>
          
          <Stack pl={4} pr={4} pb={4} sx={{ minHeight: '80vh' }}>
            <Stack direction="column" justifyContent="space-between" pt={3} pb={4} justifyItems="center">
              { dataComparation.length === 0 ? 
              <Stack sx={{ p: 2 }}>
                <div className="box-menu-cart" >
                    <div className="content-cart-empty">
                        <div>
                          <img alt="img empty cart" src={imgemptycart} />
                          <h3>Products Comparison is empty!</h3>
                        </div>
                    </div>
                </div>
              </Stack> : 
              <Stack>
                <Stack flexDirection="row" sx={{ padding: '10px' }}>
                  <Box fontSize={20} fontWeight={500}>Compare {dataComparation.length} Product</Box>
                  <Box ml={3}>
                    <Button onClick={onClickRemoveAll} color="error" size="small" variant="outlined">Remove All</Button>
                  </Box>
                </Stack>
                <Box pt={2}>
                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                    <Box sx={{ width: 220 }} ></Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Stack 
                            sx={{ 
                              width: 300, 
                            }} 
                            key={idx} 
                            flexDirection="row"
                            justifyContent="center"
                            alignContent="center"
                            pb={2}
                          >
                            <Box 
                              sx={{ 
                                backgroundColor: '#ddd',
                                padding: '10px',
                              }} 
                            >
                              <Stack flexDirection="row" justifyContent="flex-end">
                                <Box 
                                  sx={{ cursor:'pointer' }}
                                  onClick={() => onClickRemove(value)}
                                >
                                  <CancelPresentationIcon/>
                                </Box>
                              </Stack>
                              <CardMedia
                                  style={{
                                    height: 150,
                                    width: 150,
                                    padding: '5px'
                                  }}
                                  image={value.images_product[0]}
                                  title="product"
                              />
                            </Box>
                          </Stack>
                          
                        ))}
                  </Stack>
                  <Stack flexDirection="column" sx={{ backgroundColor: '#ccc', padding: '10px' }}>
                    <Box fontWeight={500} fontSize={20} >Product Detail</Box>
                  </Stack>
                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                      <Box sx={{ width: 200 }} >Name</Box>
                      { dataComparation.map((value:any, idx:any) => (
                        <Box 
                          sx={{ 
                            width: 300, 
                            textAlign: 'center',
                            marginLeft: '5px',
                            marginRight: '5px' 
                          }} 
                          key={idx} 
                        >{value.name}</Box>
                      ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ backgroundColor: '#ddd', padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Retail Price</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px',
                              textDecoration: 'line-through'
                            }} 
                            key={idx} 
                          >Rp. {value.retail_price.toLocaleString()}</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Discount</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.discount * 100}%</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ backgroundColor: '#ddd', padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Price after Discount</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px', 
                              color: '#c70000'
                            }} 
                            key={idx} 
                          >Rp. {value.discount_price.toLocaleString()}</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                      <Box sx={{ width: 200 }} >Stock</Box>
                      { dataComparation.map((value:any, idx:any) => (
                        <Stack 
                          sx={{ 
                            width: 300, 
                            marginLeft: '5px',
                            marginRight: '5px' 
                          }} 
                          key={idx} 
                          flexDirection="row"
                          justifyContent="center"
                        >
                          { value.stock !== 0 ?
                          <Stack flexDirection="row" pt={1}> 
                              <Box><CheckIcon sx={{ color: 'green' }}/></Box>
                              <Box pl={1}>
                                  In Stock
                              </Box>
                          </Stack>
                          :
                          <Stack flexDirection="row"> 
                              <Box><CancelPresentationIcon sx={{ color: 'red' }}/></Box>
                              <Box pl={1}>
                                  Out of Stock
                              </Box>
                          </Stack> }
                        </Stack>
                      ))}
                  </Stack>

                  <Stack flexDirection="row" sx={{ backgroundColor: '#ccc', padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Minimum Order</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.minimum_order_quantity}</Box>
                        ))}
                  </Stack>

                  <Stack flexDirection="row" sx={{  padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Description</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.description}</Box>
                        ))}
                  </Stack>

                  <Stack mt={2} flexDirection="column" sx={{ backgroundColor: '#ccc', padding: '10px' }}>
                    <Box fontWeight={500} fontSize={20} >Additional Information</Box>
                  </Stack>

                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Weight</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.dimension.weight}kg</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ backgroundColor: '#ddd', padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Dimension</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.dimension.width} {" x "}
                          {value.dimension.height}  {" x "}
                          {value.dimension.length} cm</Box>
                        ))}
                  </Stack>

                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Brand</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.brand}</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ backgroundColor: '#ddd', padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Vendor</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.vendor_name}</Box>
                        ))}
                  </Stack>
                  <Stack flexDirection="row" sx={{ padding: '10px' }}>
                    <Box sx={{ width: 200 }} >Ship From</Box>
                        { dataComparation.map((value:any, idx:any) => (
                          <Box 
                            sx={{ 
                              width: 300, 
                              textAlign: 'center',
                              marginLeft: '5px',
                              marginRight: '5px' 
                            }} 
                            key={idx} 
                          >{value.warehouse_detail.city}{", "}
                          {value.warehouse_detail.province}{", "}
                          {value.warehouse_detail.country}</Box>
                        ))}
                  </Stack>
                </Box>
              </Stack>
              }
                
            </Stack>
          </Stack> 
        </Drawer>
    </div>
  );
}


export default ProductComparation;