import { Box } from '@mui/system'
import { Stack } from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { useLocation } from 'react-router-dom';
import BoxSearch from './components/BoxSearch';

function CreatePurchaseRequests() {
    const location = useLocation()
    const state_location : any = location.state
    const name : any = state_location === undefined ? undefined : state_location.name
    const code_pr : any = state_location === undefined ? undefined : state_location.code_pr

    // console.log(userCredentials, 'usersss')

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                current={name === undefined ? "Create Purchase Request" : "Update Purchase Request" }
                isPage={true}
                page="Purchase Request"
                link="/dashboard/purchase-requests"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>{name === undefined ? "Create New Purchase Request" : `Update Purchase Request (Code : ${code_pr})` } </h3>
                </Box>
            </Stack>
            <Stack pt={3}>
                <BoxSearch />
            </Stack>
        </Box>
    )
}

export default CreatePurchaseRequests;
