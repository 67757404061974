import { useEffect, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Badge, Popover, Stack, Divider, Chip } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { userCredentials } from '../utilities/config';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {socket} from '../utilities/socket'
import { getDataInvoiceByCode } from '../features_app/dashboard/finance/invoice/reducers/invoiceReducers';
import swal from 'sweetalert';
import { initMessage, receiveMessage, updateMessage } from '../features_app/dashboard/chat/chatSlice';
import { ChatMessage } from '../features_app/dashboard/chat/interface';

function NotificationBell() {

    const history = useHistory()
    const dispatch = useDispatch()

    const [message, setMessage] = useState<any>([]);
    const [historyNotif, setHistoryNotf] = useState<any>([]);
    const [totalNewMessage, setTotalNewMessage] = useState([]);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    };

    const notifUnRead = (row:any) => {
      return row.filter((data:any) => !data.read_by || !data.read_by[userCredentials.auth_id])
    }

    // console.log(historyNotif,'historyNotif')
    useEffect(() => {
      socket.on('connect', async () => {
        const data = {
          user_id : userCredentials.auth_id,
          user_name : userCredentials.fullname,
          company_id : userCredentials.buyer_id,
          company_name : userCredentials.buyer_name,
          channel : 'BUYER',
          created_at : userCredentials.created_at,
        }
        socket.emit('login', data)
        socket.on('history', ({notif, chats} : any) => {
          if(notif){ 
            setTotalNewMessage(notifUnRead(notif.data))
            setHistoryNotf(notif.data) 
          }
          if(chats) {
            dispatch(initMessage(chats))
          }
        })
        socket.on('notif', (notif : any) => {
          setMessage(notif)
        })
        socket.on('msg_from', (chat: ChatMessage) => {
          dispatch(receiveMessage(chat))
        })
        socket.on('update_msg', (upd: ChatMessage[]) => {
          dispatch(updateMessage(upd))
        })
      })
      return () => { socket.disconnect(); };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if(message.message !== undefined) {
        let array_message = [...historyNotif]
        array_message.unshift(message)
        setHistoryNotf(array_message)
        setTotalNewMessage(notifUnRead(array_message))
      }
      // eslint-disable-next-line
    }, [message]);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const replaceNotifRead = () => {
      socket.on('notif-update', (notif : any) => {
        const notif_array = [...historyNotif]
        const target = notif_array.find((obj:any) => obj._id === notif._id);
        const source = {...notif};
        Object.assign(target, source);
        const filter : any = notif_array.filter((data:any) => !data.read_by)
        setTotalNewMessage(filter)
        setHistoryNotf(notif_array)
      })
    }

    const onClickReadMessage = (row: any) => {
      socket.emit('read_notif', [`${row._id}`])
      replaceNotifRead()
      handleClose()

      switch(row.category) {
        case "Orders": {
          switch(row.status) {
            case "Waiting Down Payment" : {
              history.push({
                  pathname: "/dashboard/purchase-orders",
                  search: `?status=Waiting DP`, 
                  state : {
                    payload : {...row.payload, open : true}
                  }
              })
              break;
            }
            case "Active" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=New`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Pick" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Pick`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Ready to Shipped" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Ready to Shipped`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Shipped" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Shipped`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Delivered" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Delivered`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Paid" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Paid`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Cancel" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Cancel`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
            case "Completed" : {
              history.push({
                pathname: "/dashboard/purchase-orders",
                search: `?status=Paid`, 
                state : {
                  payload : {...row.payload, open : true},
                  open : true
                }
              })
              break;
            }
          }
          break;
        }
        case "Invoice": {
          setOpenBackdrop(true);
          setTimeout(async() => {
            try {
              const res : any = await dispatch(getDataInvoiceByCode(row.payload.invoiceNumber))
              if(res.payload.data.length) {
                handleCloseBackdrop()
                const invoice = res.payload.data[0]
                let data_invoice : any = {
                  detail : invoice,
                  invoice_number : invoice.invoice_number,
                  items : invoice.items,
                  reference_document : invoice.reference_document,
                  buyer : invoice.buyer,
                  vendor : invoice.vendor,
                  down_payment: invoice.down_payment,
                  status : invoice.lastStatus,
                  direct_payment : invoice.direct_payment,
                  infoPayment : invoice.infoPayment && invoice.lastStatus !== "Paid" ? invoice.infoPayment : [],
                  from : 'notification'
                }
                history.push({
                  pathname: '/dashboard/finance/invoice',
                  search: `?page=summary`, 
                  state : {
                      data_invoice,
                      name : 'detail',
                  }
                })
              } else {
                swal("Error!", `Invoice not found!`, 'error')
                handleCloseBackdrop()
              }
            } catch (error) {
              swal("Error!", `${error}`, 'error')
              handleCloseBackdrop()
            }
          }, 500);
          break;
        }
        default: {
          break;
        }
      }

      // switch(row.category) {
      //   case "Orders": {
      //     if(row.read_by && row.read_by[userCredentials.auth_id]) {
      //       window.location.href = "/dashboard/purchase-orders" 
      //     } else {
      //       dispatch(readNotification(row._id))
      //       setTimeout(() => {
      //         window.location.href = "/dashboard/purchase-orders" 
      //       }, 100);
      //     }
      //     break;
      //   }
      //   case "Invoice": {
      //     const invoice = row.message.replace("Incoming Invoice", "")
      //     setAnchorEl(null);
      //     setOpenBackdrop(true);
      //     setTimeout(async() => {
      //       try {
      //         const res : any = await dispatch(getDataInvoiceByCode(invoice))
      //         if(res.payload.data.length) {
      //           handleCloseBackdrop()
      //           const invoice = res.payload.data[0]
      //           let data_invoice : any = {
      //             invoice_number : invoice.invoice_number,
      //             items : invoice.items,
      //             reference_document : invoice.reference_document,
      //             buyer : invoice.buyer,
      //             vendor : invoice.vendor,
      //             down_payment: invoice.down_payment,
      //             status : invoice.lastStatus,
      //             direct_payment : invoice.direct_payment
      //           }
      //           if(row.read_by && row.read_by[userCredentials.auth_id]) {
      //             history.push({
      //               pathname: '/dashboard/finance/invoice',
      //               search: `?page=summary`, 
      //               state : {
      //                   data_invoice,
      //                   name : 'detail'
      //               }
      //             })
      //           } else {
      //             dispatch(readNotification(row._id))
      //             setTimeout(() => {
      //               history.push({
      //                 pathname: '/dashboard/finance/invoice',
      //                 search: `?page=summary`, 
      //                 state : {
      //                     data_invoice,
      //                     name : 'detail'
      //                 }
      //               })
      //             }, 100);
      //           }  
      //         } else {
      //           swal("Error!", `Invoice not found!`, 'error')
      //           handleCloseBackdrop()
      //         }
      //       } catch (error) {
      //         swal("Error!", `${error}`, 'error')
      //         handleCloseBackdrop()
      //       }
      //     }, 500);
      //     break;
      //   }
      //   case "Report": {
      //     if(row.read_by && row.read_by[userCredentials.auth_id]) {
      //       if(row.link_url !== "") {
      //         window.location.href = `${row.link_url}`
      //       }
      //     } else {
      //       dispatch(readNotification(row._id))
      //       setTimeout(() => {
      //         if(row.link_url !== "") {
      //           window.location.href = `${row.link_url}`
      //         }
      //       }, 500);
      //     }
      //     break;
      //   }
      //   case "Broadcast": {
      //     if(row.read_by && row.read_by[userCredentials.auth_id]) {
      //     } else {
      //       dispatch(readNotification(row._id))
      //       setTimeout(() => {
      //         window.location.reload()
      //       }, 500);
      //     }
      //     break;
      //   }
      //   default: {
      //     if(row.read_by && row.read_by[userCredentials.auth_id]) {
      //     } else {
      //       dispatch(readNotification(row._id))
      //       setTimeout(() => {
      //         window.location.reload()
      //       }, 500);
      //     }
      //     break;
      //   }
      // }
    };

    
    const onClickAllNotification = () => {
      handleClose()
      history.push({ 
          pathname: "/dashboard",
          search: `?page=notification`
      })
    }

  return (
    <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
          onClick={handleCloseBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack flexDirection="row">
          <Box mr={2} sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <Badge 
              badgeContent={totalNewMessage.length === 0 ? "0" : totalNewMessage.length} 
              color={totalNewMessage.length === 0 ? "primary" : "error"}
            >
              <NotificationsIcon />
            </Badge>
          </Box>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ 
            mt : 3 
          }}
        >
          <Stack sx={{ width: 450, fontSize: '13px' }}>
            { historyNotif.length === 0 ? 
              <Box p={3}>
                You dont have any new notification yet.
              </Box>
            : 
            <Box>
                <Stack 
                  flexDirection="column"
                  sx={{ 
                    maxHeight: 500,
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                  }}
                >
                  { historyNotif.slice(0,15).map((ele : any, i :any) => (
                    <Box key={i} onClick={() => onClickReadMessage(ele)}>
                      <Box 
                        p={2} 
                        sx={{ 
                          backgroundColor: ele.read_by && ele.read_by[userCredentials.auth_id] ? '#fff' : '#c8e6c9', 
                          cursor: 'pointer', 
                          textTransform: 'capitalize' 
                        }}
                      >
                        <Box>{ele.message}</Box>
                          <Stack pt={1} direction="row" alignItems="center">
                              <Box pr={1}>
                                <Chip label={ele.category ? ele.category : "Broadcast"} variant='outlined' size="small" />
                              </Box>
                              <Box fontWeight={600} fontSize={12}>
                                {moment(ele.createdAt).fromNow()}
                              </Box>
                          </Stack>
                        </Box>
                      <Box>
                        <Divider sx={{ border: '1px solid #ccc'}} />
                      </Box>
                    </Box>
                  )) }
                </Stack>
                <Stack 
                  flexDirection="row" justifyContent="center" 
                  sx={{backgroundColor: '#e8e6f5', cursor: 'pointer'}}
                  onClick={onClickAllNotification}
                >
                  <Box p={2}  >
                    <Box color="#000" fontWeight={500}>View All Notifications</Box>
                  </Box>
                </Stack>
            </Box>
            }
          </Stack>
          
        </Popover>
    </div>
  )
}

export default NotificationBell