import { createAsyncThunk } from '@reduxjs/toolkit';
import crypto from 'crypto-js'; 
import Axios from 'axios'
import {
    InputState
} from '../loginTypes'
import { getAllModulesPathSuperAdmin, getAllModulesSuperAdmin } from '../../../../utilities/setModulesPath';
import jwt_decode from "jwt-decode";


export const loginAction = createAsyncThunk(
    'auth/login',
    async (value : InputState , { rejectWithValue }) => {
        const body = { 
            'grant_type': 'password',
            'username': value.email,
            'password': value.password,
            'client_id': `${process.env.REACT_APP_CLIENT_AUTH_ADMIN}`,
            'connection': `${process.env.REACT_APP_CONNECTION_AUTH}`,
            'scope': 'openid',
        }
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_AUTH_ADMIN}/oauth/token`, body)
            if(response.data) {
                const decoded : any = jwt_decode(response.data.id_token)               
                const decode_token = decoded['https://admin.mpindo.id/profile']
                try {
                    if(decode_token.flag === "BUYER") {
                        let data = {
                            access_token : response.data.access_token,
                            id_token : response.data.id_token,  
                            expires_in : response.data.expires_in,
                            email : decode_token.email,
                            owner_email : decode_token.email,
                            fullname : decode_token.name, 
                            role : decode_token.role,  
                            avatar : decoded.picture,
                            first_time : false,
                            auth_id : decode_token.auth_id,
                            login: true,
                            login_start_date : new Date(),
                            modules_path : getAllModulesPathSuperAdmin(),
                            modules_menu : getAllModulesSuperAdmin(),
                        }
                        const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                        localStorage.setItem('_?credentialss', saveToLocalStorage)
                        return data
                    } else {
                        return rejectWithValue("You are not Buyer Super Admin!")
                    }
                } catch (err : any) {
                    return rejectWithValue(err.message)
                }
            } else {
                return rejectWithValue(response.data.error_description)
            }
          } catch (err : any) {
            return rejectWithValue(err.response.data.error_description)
        }
    }
  );
