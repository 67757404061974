import { useState, useEffect } from 'react';
import { Paper, Button, Stack, CircularProgress } from '@mui/material';
import imgemptycart from '../../../../../assets/img/icon/empty_cart.svg'
import { Box } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../app/store';
import { getLocalDBCarts, resetCart, savePurchaseRequest, submitPurchaseRequest } from '../reducers/createPurchaseRequestReducers';
import TableItemCarts from '../../../../../components/TableItemCarts';
import SnackBarAlert from '../../../../../components/SnackBarAlert';
import { checkValidation } from '../../../../../utilities/userFeaturesValidation';
// import Select from 'react-select'
// import { getCompanyAddress } from '../../../profile/reducers/profilesReducers';
import Swal from 'sweetalert2';
import { useIdleTimer } from 'react-idle-timer'
// import moment from 'moment';


const ItemListPurchaseRequest : React.FC<any> = ({
  department,
  changeDepartment,
  setChangeDepartment,
  selectedAddress
}) => {
  const dispatch = useDispatch()
  
  // const store_profile = useSelector((state : RootState) => state.profile)

  const store_carts = useSelector((store : RootState) => store.carts) 

  const [initialSaved, setInitialSaved] = useState<any>({});

  const [openSnackBar, setopenSnackBar] = useState(false);
  const [dataCartProps, setDataCartProps] = useState<any[]>([]);
  // const [loadedAddress, setLoadedAddress] = useState(false);

  // const [optionsAddress, setOptionsAddress] = useState<any[]>([]);
  // // const [selectedAddress, setSelectedAddress] = useState<any>(); 

  // const [deliveryAddress, setDeliveryAddress] = useState<any>({});

  // const [changeAddress, setChangeAddress] = useState(false);

  // const handleChangeAddress = (value: any) : void => {
  //   // setSelectedAddress(value)
  //   // setDeliveryAddress(value.value)
  // }

  const setInitialIDCart = () => {
    let id_cart_pr = {
      saved: false,
      change: false,
      id : null
    }
    let local_IDcart = localStorage.getItem('id_cart_pr')
    if(local_IDcart === null) {
      setInitialSaved(id_cart_pr)
      localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
    } else {
      setInitialSaved(JSON.parse(local_IDcart))
    }
  }

  const updateInitialIDCart = () => {
    let id_cart_pr = {
      saved: false,
      change: true,
      id : initialSaved.id
    }
    localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
    setInitialIDCart()
  }

  useEffect(() => {
    dispatch(getLocalDBCarts())
    setInitialIDCart()
    // dispatch(getCompanyAddress())
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if(store_profile.address.length !== 0) {
  //     const default_address = store_profile.address.filter((element : any) => element.is_default === true)
  //     setDeliveryAddress(default_address[0])
  //     let address = []
  //     for(let element of store_profile.address) {
  //         address.push({
  //             label : element.name,
  //             value : element
  //         })
  //     }
  //     setOptionsAddress(address)
  //     setLoadedAddress(true)
  //   }
  //    // eslint-disable-next-line
  // }, [store_profile.address]);

  useEffect(() => {
    if(store_carts.success_save) {
      setInitialIDCart()
      setChangeDepartment(false)
    }
     // eslint-disable-next-line
  }, [store_carts.success_save]);

  useEffect(() => {
    if(!store_carts.loading) {
      setDataCartProps(store_carts.carts)
      setopenSnackBar(false)
    }
    // eslint-disable-next-line
  }, [store_carts.loading]);

  useEffect(() => {
    if(store_carts.success_add) {
      // setopenSnackBar(true)
      updateInitialIDCart()
      dispatch(getLocalDBCarts())
    }
    // eslint-disable-next-line
  }, [store_carts.success_add]);

  useEffect(() => {
    if(changeDepartment) {
      updateInitialIDCart()
    }
     // eslint-disable-next-line
  }, [changeDepartment]);

  useEffect(() => {
    if(store_carts.success_reset) {
      // setopenSnackBar(true)
      dispatch(getLocalDBCarts())
      setChangeDepartment(false)
    }
    // eslint-disable-next-line
  }, [store_carts.success_reset]);

  useEffect(() => {
    if(store_carts.submit) {
      dispatch(getLocalDBCarts())
      setChangeDepartment(false)
      setTimeout(() => {
        window.location.href = "/dashboard/purchase-requests"
      }, 1000);
    }
    // eslint-disable-next-line
  }, [store_carts.submit]);

  function sumTotalPrice (data : any) {
    const totalPriceALl = data.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceALl;
  }

  function sumTotalQuantity (data : any) {
    const itemQuantity = data.reduce((total : any, data : any) => {
      return total + data.quantity
    }, 0)
    return parseFloat(itemQuantity);
  }

  const onClickResetAll = () => {
    Swal.fire({
      title : "Confirmation",
      text: 'Are you sure want to clear all the items?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'primary',
      confirmButtonText: 'Yes, Clear All',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return dispatch(resetCart())
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Cleared!',
          'All items has been removed.',
          'success'
        )
      }
    })
  }

  const onClicksavePurchaseRequest = () => {
    dispatch(savePurchaseRequest({
      value : dataCartProps,
      total : sumTotalPrice(dataCartProps),
      type : initialSaved.change,
      address : selectedAddress.value,
      department : department
    }))
  }

  let local_id_cart : any = localStorage.getItem('id_cart_pr')
  let id_cart = JSON.parse(local_id_cart)


  const handleOnIdle = (event : any) => { 
    if(dataCartProps.length !== 0) {
      if(id_cart.id === null || id_cart.id === undefined ) {
        onClicksavePurchaseRequest()
      } else {
        dispatch(savePurchaseRequest({
          value : dataCartProps,
          total : sumTotalPrice(dataCartProps),
          type : "autosave",
          address : selectedAddress.value,
          department : department,
          id_pr : id_cart.id
        }))
      }
    }
    getRemainingTime()
  }

  const { getRemainingTime } = useIdleTimer({
      timeout: 5000,
      onIdle: handleOnIdle,
      debounce: 500
  })


  return (
      <div>
          
          { dataCartProps.length !== 0 ?
          <Stack mt={3}>
            <div>
                <Stack flexDirection="row" alignItems="center">
                  <Box pb={3}>
                    <h4>Total Items Quantity ({sumTotalQuantity(dataCartProps).toFixed(1)})</h4>
                  </Box>
                  <Box pb={2} pl={2}>
                    <Button 
                        variant="outlined" 
                        color="error" 
                        size="small" 
                        onClick={onClickResetAll}
                    > 
                        Clear All Items
                    </Button>
                  </Box>
                </Stack>
                
                <div className="content-cart">
                    <TableItemCarts 
                      dataCartProps={dataCartProps}
                    />
                </div>
            </div>
            <Box pt={2} >
              <Paper elevation={4}>
                <Stack p={2}>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <Box fontSize="17px" fontWeight="600">Delivery Address : </Box>
                    {/* { !changeAddress ? 
                    <Box>
                      <Button 
                          variant="contained" 
                          color="secondary" 
                          size="small" 
                          onClick={() => setChangeAddress(true)}
                      >
                          Change Address
                      </Button>
                    </Box>
                    :
                    <Box sx={{ minWidth: 300 }}>
                      <Select
                          placeholder="Select Address" 
                          value={selectedAddress}
                          isSearchable={true}
                          options={optionsAddress}
                          onChange={handleChangeAddress}
                      />
                    </Box> 
                    } */}
                  </Stack>
                  <Box pt={1}>
                    <Box fontWeight="500">{selectedAddress.value.name}</Box>
                    <Box fontWeight="400">{selectedAddress.value.phone}</Box>
                    <Box>
                        {selectedAddress.value.street}, {selectedAddress.value.village}, {selectedAddress.value.subdistrict}, 
                        {selectedAddress.value.city}, {selectedAddress.value.province}, <br/>
                        {selectedAddress.value.postcode}, {selectedAddress.value.country}
                    </Box>
                  </Box>
                </Stack>
              </Paper>
            </Box>

            <Box pt={2} mb={4}>
              <Paper elevation={4}>
                <div className="total-cart">
                  <h3>Total Rp. {sumTotalPrice(dataCartProps).toLocaleString()}</h3>
                  { checkValidation('61d5d017ca14956261f09665') ?
                  <div className="right-button">
                    <Button 
                      color="primary" variant="contained" sx={{ mr:2 }}
                      onClick={() => onClicksavePurchaseRequest()}
                      disabled={initialSaved.saved ? true : false}
                    >
                      { store_carts.loading_save ? 
                        <div className="loading-button"> 
                          <p>Loading</p>  
                          <CircularProgress size={20} />
                        </div> : "Save"
                      }
                    </Button>
                    <Button 
                      color="success" 
                      variant="contained" 
                      disabled={initialSaved.saved  ? false : true}
                      onClick={() => dispatch(submitPurchaseRequest())}
                    >
                      { store_carts.loading_submit ? 
                        <div className="loading-button"> 
                          <p>Loading</p>  
                          <CircularProgress size={20} />
                        </div> : "Submit" 
                      }
                    </Button>
                  </div> : null }
                </div>
              </Paper>
            </Box>
          </Stack> :
          <Stack sx={{ p: 4 }}>
            <div className="box-menu-cart" >
                <div className="content-cart-empty">
                    <div> 
                      <img alt="img empty cart" src={imgemptycart} />
                      <h3>Your PR Item List is empty!</h3>
                    </div>
                </div>
            </div>
          </Stack> }
        <SnackBarAlert message={store_carts?.message_snackbar} initialState={openSnackBar} />
    </div>
  );
}


export default ItemListPurchaseRequest;