import { Stack, Box, Paper,
    Button, Alert,
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress, 
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../../app/store';
import UploadProof from '../../../../purchase_orders/components/UploadProof';
import { checkStatusPayment, uploadProofPayment } from '../../reducers/invoiceReducers';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';


function WaitingPayment() {

    const history = useHistory()
    const dispatch = useDispatch()

    const store_invoice = useSelector((state : RootState) => state.invoices)

    const [loaded, setLoaded] = useState(false);
    const [dataTransaction, setDataTransaction] = useState<any>({});
    const [unpaid, setUnpaid] = useState(false);

    const [copyTrue, setCopyTrue] = useState(false);

    // console.log(dataTransaction,'data transaction')

    useEffect(() => {
        const transaction_id : any = localStorage.getItem('transaction_id') ? localStorage.getItem('transaction_id') : null
        if(transaction_id === null || transaction_id === 'undefined') {
            window.location.href = "/dashboard/finance/invoice"
        } else {
            let data_transaction : any = JSON.parse(transaction_id) 
            setDataTransaction({...dataTransaction, 
                va_number: data_transaction.va_number === undefined ? data_transaction.account_number : data_transaction.va_number, 
                amount: data_transaction.amount === undefined ? data_transaction.expected_amount : data_transaction.amount, 
                bank_name : data_transaction.bank_name,
                id : data_transaction.id,
                code: data_transaction.code,
                payment_method : data_transaction.payment_method
            })
            setLoaded(true)
        }
        // eslint-disable-next-line
    }, []);

    const onClickCheckStatus = () => {
        setUnpaid(false)
        dispatch(checkStatusPayment(dataTransaction.id))
    } 



    const [open, setOpen] = useState(false);
    const [dataImage, setDataImage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDataImage("")
        setLoading(false)
    };

    const onClickSubmit = () => {
        if(dataImage !== "") {
            const body = {
                "code": dataTransaction.code,
                "image": dataImage,
            }
            dispatch(uploadProofPayment(body))
        }
    }


    useEffect(() => {
        if(store_invoice.check) {
            if(store_invoice.check_payment.length === 0) {
                history.push({
                    pathname: '/dashboard/finance/invoice',
                }); 
            } else {
                if(store_invoice.check_payment[0].lastStatus === "Waiting Payment") {
                    setUnpaid(true)
                } else {
                    history.push({
                        pathname: '/dashboard/finance/invoice',
                        search: `?page=payment-confirmed`, 
                        state : {
                            data : store_invoice.check_payment[0]
                        } 
                    }); 
                }
            }
        }
        // eslint-disable-next-line
    }, [store_invoice.check]);

    useEffect(() => {
        if(store_invoice.proof_payment) {
            handleClose()
            setTimeout(() => {
                window.location.href = "/dashboard/finance/invoice"
            }, 1500);
        }
        
    }, [store_invoice.proof_payment]);

    return (
        <Box>
            <Stack pt={3} >
                <Box>
                    <h3>Waiting Payment</h3>
                </Box>
            </Stack>

            <Stack pt={3} >
                <Paper sx={{ border: '1px solid #ddd', textAlign: 'center' }}>
                    <Stack p={3} flexDirection="row" justifyContent="center">
                        <Paper sx={{ textAlign: 'center', width: '40%', border: '2px solid #fb8500' }}>
                            <Box p={3}>
                                <Box fontWeight="500" fontSize="30px" color="#fb8500">Waiting Your Payment</Box>
                                { dataTransaction.payment_method === "Manual Bank Transfer" ?
                                <Box fontWeight="400" fontSize="15px">Please make your payment and upload your proof of transaction.</Box> :
                                <Box fontWeight="400" fontSize="15px">You have 12 hours to pay before this transaction is expired.</Box> }

                                <Stack pt={2}>
                                    <Box fontSize="15px">Transaction ID</Box>
                                    <Box fontSize="20px" fontWeight="500">{loaded && dataTransaction.id}</Box>
                                </Stack>
                                <Stack pt={2}>
                                    <Box fontSize="15px">Payment</Box>
                                    <Box fontSize="20px" fontWeight="500">{loaded && dataTransaction.bank_name} {dataTransaction.payment_method === "va" ? "Virtual Account" : dataTransaction.payment_method}</Box>
                                </Stack>


                                <Stack pt={2}>
                                    { dataTransaction.payment_method === "Manual Bank Transfer" ? 
                                        <Box fontSize="15px">Bank Account Number</Box> :
                                        <Box fontSize="15px">Virtual Account Number</Box> 
                                    }
                                    <Stack mt={1} flexDirection="row" justifyContent="center"  alignItems="center" >
                                        <Box fontSize="20px" fontWeight="500">{loaded && dataTransaction.va_number }</Box>
                                        <Box pl={1}>
                                            <CopyToClipboard 
                                                text={`${loaded && dataTransaction.va_number}`}
                                                onCopy={() => {
                                                    setCopyTrue(true)
                                                    setTimeout(() => {
                                                        setCopyTrue(false)
                                                    }, 2000);
                                                }}
                                            >
                                                <Box
                                                sx={{ 
                                                    cursor: 'pointer', 
                                                    color: copyTrue ? 'green' : 'black'
                                                }}
                                                >
                                                    <FileCopyIcon />
                                                </Box>
                                            </CopyToClipboard>
                                        </Box>
                                    </Stack>
                                    { copyTrue ? <Box sx={{color: 'green', pt: 1}}>Account Number Copied</Box> : null }
                                </Stack>

                                <Stack pt={2}>
                                    <Box fontSize="15px">Total Payment</Box>
                                    <Box fontSize="20px" fontWeight="500">Rp. {loaded && dataTransaction.amount.toLocaleString()}</Box>
                                </Stack>

                                { unpaid ? 
                                <Stack pt={3}>
                                    <Alert severity="error">Not paid yet!, Please make sure you already paid or try again in a moment.</Alert>
                                </Stack> : null }

                                { dataTransaction.payment_method  !== "Manual Bank Transfer" ?  
                                <Box pt={3}> 
                                    <Button 
                                        variant="outlined" color="info"
                                        size="small" 
                                        disabled={store_invoice.loading_check_payment ? true : false}
                                        onClick={onClickCheckStatus}
                                    >
                                        {store_invoice.loading_check_payment ? "Checking payment.." : "Check Payment"}
                                    </Button>
                                </Box>
                                :
                                <Box pt={3}> 
                                    <Button 
                                        variant="outlined" color="primary"
                                        size="small" 
                                        // disabled={store_invoice.loading_check_payment ? true : false}
                                        onClick={handleClickOpen}
                                    >
                                       Upload Proof
                                    </Button>
                                </Box> }
                            </Box>
                        </Paper>
                    </Stack>
                </Paper>
            </Stack>

            {/* DIALOG UPLOAD PRROF */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm" 
            >
                <DialogTitle id="alert-dialog-title">
                    {"Upload Proof Payment"}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <UploadProof 
                            setDataImage={setDataImage}
                            dataImage={dataImage}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="error"  size="small" >Cancel</Button>
                    <Button disabled={dataImage === "" ? true : false} onClick={() => onClickSubmit()} variant="contained" color="primary"  size="small" >
                        { store_invoice.loading_proof_payment ? 
                            <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                            "Submit"
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default WaitingPayment
