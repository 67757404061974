import { Stack, Box } from '@mui/material'
import BreadCrumbs from '../../../../components/BreadCrumbs'
import TableListInvoice from './components/TableListInvoice'


function InvoicesPage() {
    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Invoice"
            />
            <Stack pt={3}>
                <TableListInvoice/>
            </Stack>
        </Box>
    )
}

export default InvoicesPage
