import React from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box, 
    Stack
} from '@mui/material';
import { useHistory } from 'react-router-dom';

const TableItemDetail : React.FC<any> = ({ data }) => {

  // console.log(data, 'data template')
  const history = useHistory()
  function sumTotalPrice (data : any) {
    const totalPriceALl = data.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceALl;
  }

  const styles = {
    fontSize : 12
  }

  return (
    <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>No</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Name</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Retail Price</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Discount</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Price After Discount</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Tax</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Total Tax</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Qty</TableCell>
                    <TableCell style={{fontWeight: 700, fontSize: 13}}>Sub Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { data.map((row :any, i : any) => (
                <TableRow key={i} >
                  <TableCell  sx={styles} component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    <Box 
                      sx={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        history.push({
                          pathname: "/dashboard/products",
                          search: `?page=detail-product`, 
                          state : {
                            slug : row.slug
                          }
                        })
                      }}
                    >
                      {row.name}
                    </Box>
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                  RP. {row.retail_price && row.retail_price.toLocaleString() || "N/A"}
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    {row.discount * 100}%
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    RP. {row.discount_price.toLocaleString()}
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    {row.tax.title} 
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                   Rp.{(row.quantity * row.single_tax_price).toLocaleString()}
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    {row.quantity}
                  </TableCell>
                  <TableCell  sx={styles} component="th" scope="row">
                    RP. {row.sub_total ? row.sub_total.toLocaleString() : ""}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
          
          <Paper>
            <Stack flexDirection="row" justifyContent="space-between">
              <Box p={2}>Total</Box> 
              <Box p={2} pr={4} fontWeight="bold">Rp. {sumTotalPrice(data).toLocaleString()}</Box> 
            </Stack>
          </Paper>
        </TableContainer>
    </Box>
  );
}

export default TableItemDetail