import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getAllBuyerCompany, getAllBuyerCompanyBySearch, loginAsBuyerCompany } from './reducers/selectCompanyReducers';

const initialState: any = {
  data : [],
  loading: false,
  login_as_buyer : false,
  loading_as_buyer: false
};

export const getAllBuyerLists = createSlice({
  name: 'selec-company',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get buyer list
      .addCase(getAllBuyerCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyerCompany.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;

      })
      .addCase(getAllBuyerCompany.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      // get buyer list by search
      .addCase(getAllBuyerCompanyBySearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBuyerCompanyBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;

      })
      .addCase(getAllBuyerCompanyBySearch.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // login as buyer
      .addCase(loginAsBuyerCompany.pending, (state) => {
        state.loading_as_buyer = true;
      })
      .addCase(loginAsBuyerCompany.fulfilled, (state, action:any) => {
        state.loading_as_buyer = false;
        state.login_as_buyer = action.payload.data;

      })
      .addCase(loginAsBuyerCompany.rejected, (state, action : any) => {
        state.loading_as_buyer = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});


export default getAllBuyerLists.reducer;
