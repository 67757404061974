import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userCredentials } from '../../../../utilities/config';
import { readMessage, setActive } from '../chatSlice'
import { handleTime } from '../handleTime';
import {
    Sidebar,
    Search,
    ConversationList,
    Conversation,
    // Avatar,
 } from '@chatscope/chat-ui-kit-react';
// import profile from '../../../../assets/img/icon/profile.png'
import { ChatMessage, listChat } from '../interface';
import { Box } from '@mui/material';


const ChatList = () => {
    const dispatch = useDispatch()
    const {chats, chat_list} = useSelector((state:any) => state.chat);

    const [search,setSearch] = useState('');

    function getUnread(co_id: string) {
        return chats.filter((c: ChatMessage) =>
            (c.from_company_id === co_id || c.to_company_id === co_id)
            && c.from_company_id !== userCredentials.buyer_id
            && !c.read_by
        );
    }

    function chat_prop(e: listChat) {
        const unread = getUnread(e.company_id);
        return {
            onClick() {
                dispatch(setActive({
                    company_id : e.company_id,
                    company_name : e.company_name,
                }));
                if(unread?.length) {
                    dispatch(readMessage(unread?.map((c: ChatMessage) => c._id)));
                }
            },
            name : e.company_name,
            lastSenderName: e.last?.from_user_name,
            info: e.last?.text,
            lastActivityTime: handleTime(e.last?.createdAt),
            unreadCnt: unread.length
        }
    }

    const handleSearch = (l:listChat) =>
        search.length === 0
        || (l.company_name && l.company_name?.toLowerCase().indexOf(search.toLowerCase()) > -1)
    
    return(
        <Sidebar position="left" scrollable={false}>
            {chat_list.length > 0
                ? <Search placeholder="Search..." onChange={setSearch}/>
                : <Box sx={{textAlign:'center', pt:3}}>no conversation yet</Box>
            }
            <ConversationList>
                {chat_list.filter(handleSearch).map((l: listChat, idx: number) =>
                    <Conversation key={idx} {...chat_prop(l)}>
                        {/* <Avatar src={profile} name="Emily" status='dnd'/>              */}
                    </Conversation>
                )}
            </ConversationList>
        </Sidebar>
    )
}

export default ChatList