import React from 'react'
import { useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { getProfileCompany } from './reducers/profilesReducers'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CompanyInfo from './components/CompanyInfo'
import CompanyAddress from './components/CompanyAddress'
// import BankAccount from './components/BankAccount'
import CompanyLegalDoc from './components/CompanyLegalDoc'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

function ProfilePage() {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getProfileCompany())
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Profile Page"
            />
            <Box sx={{pt:2, pb:2}}>
                <h3>Profile Company</h3>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Company Info" {...a11yProps(0)} />
                        <Tab label="Legal Documents" {...a11yProps(1)} />
                        <Tab label="Company Address" {...a11yProps(2)} />
                        {/* <Tab label="Bank Account" {...a11yProps(3)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                   <CompanyInfo
                        data={store_profile}
                   />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CompanyLegalDoc
                        data={store_profile}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CompanyAddress/>
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                    <BankAccount/>
                </TabPanel> */}
            </Box>
        </Box>
    )
}

export default ProfilePage
