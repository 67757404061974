import React from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box, 
    Stack
} from '@mui/material';
import { useHistory } from 'react-router-dom';


const TableItem : React.FC<any> = ({ data }) => {

  const history = useHistory()

  function sumTotalPrice (data : any) {
    const totalPriceALl = data.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceALl;
  }

  const stylesCell = {
    fontSize : 11,
    fontWeight: 700
  }

  return (
    <Box >
    { data.map((element : any, index : any) => (
      <Box key={index} mt={2} mb={2}>
      <Box>
          <Paper square>
            <Stack flexDirection="row" justifyContent="space-between" sx={{ p: 2 }}> 
              <Box>
                <h5>Code Package: {element.code_package}</h5>
                <h5>Payment Terms : {element.payment_terms}</h5>
              </Box>
            </Stack>
          </Paper>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table"  > 
              <TableHead>
                <TableRow >
                    <TableCell style={stylesCell}> NO</TableCell>
                    <TableCell style={stylesCell}>NAME</TableCell>
                    <TableCell style={stylesCell}>RETAIL PRICE</TableCell>
                    <TableCell style={stylesCell}>DISCOUNT</TableCell>
                    <TableCell style={stylesCell}>DISCOUNT PRICE</TableCell>
                    <TableCell style={stylesCell}>TAX</TableCell>
                    <TableCell style={stylesCell}>QTY</TableCell>
                    <TableCell style={stylesCell}>SUB TOTAL + TAX</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
              { element.items.map((row :any, i : any) => (
                <TableRow key={i} style={stylesCell}>
                  <TableCell component="th" scope="row">
                  {i + 1}
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    <Box 
                      sx={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        history.push({
                          pathname: "/dashboard/products",
                          search: `?page=detail-product`, 
                          state : {
                            slug : row.slug
                          }
                        })
                      }}
                    >
                      {row.name}
                    </Box>
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    RP. {row.retail_price.toLocaleString()}
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    {Math.floor(row.discount * 100)}%
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    RP. {row.discount_price.toLocaleString()}
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    {row.tax.title}
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    {row.quantity}
                  </TableCell>
                  <TableCell style={stylesCell} component="th" scope="row">
                    RP. {row.sub_total ? row.sub_total.toLocaleString() : ""}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
          
          <Paper>
            <Stack flexDirection="row" justifyContent="space-between">
              <Box p={2}>Sub Total Price</Box> 
              <Box p={2} pr={4} fontWeight="bold">Rp. {Math.floor(sumTotalPrice(element.items)).toLocaleString()}</Box> 
            </Stack>
          </Paper>
        </TableContainer>
    </Box>
    )) }
    </Box>
  );
}

export default TableItem