import React, { useEffect, useState } from 'react';
import { 
    Stack,
    Dialog,
    DialogContent,
    Button,
    Box,
    Paper,
    Divider,
    Grid,
    TextField
} from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToLocalDBCarts, updateItemPurchaseRequest } from '../../purchase_requests/create/reducers/createPurchaseRequestReducers';
import { RootState } from '../../../../app/store';
import { addToLocalDBTemplate, updateItemTemplate } from '../../templates/create/reducers/createNewTemplateReducers';
import { getTemplatesData } from '../../templates/reducers/templatesReducers';
import CancelIcon from '@mui/icons-material/Cancel';
import swal from 'sweetalert';
import { getOpenPurchaseRequest } from '../../purchase_requests/reducers/purchaseRequestReducers';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//     <Tooltip {...props} classes={{ popper: className }} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       backgroundColor: '#3f51b5',
//       color: 'rgba(0, 0, 0, 0.87)',
//       maxWidth: 350,
//       fontSize: theme.typography.pxToRem(12),
//       border: '1px solid #3f51b5',
//     }, 
// }));

const DialogOpen : React.FC<any> = ({ 
    dataList,
    type,
    open,
    setOpen,
    itemSelected
}) => { 

    const history = useHistory()
    const dispatch = useDispatch()
    const store_purchaserequest = useSelector((state : RootState) => state.carts)
    const store_template = useSelector((state : RootState) => state.carts_template)
    const [index, setIndex] = useState("");

    const [summaryProduct, setSummaryProduct] = useState<any>({
        name : "",
        variance_name : "",
        retail_price : "",
        discount_price : "",
        final_price : "",
        sub_total : "",
        sum_price_discount: "",
        tax_price : "",
        single_tax_price: "",
        tax_percentage: "",
        quantity : null,
        discount : null
    });


    const onClickAdd = () => {
        let add_quantity = summaryProduct.quantity + 1
        if(add_quantity > itemSelected.stock) {
            swal('Error', `Product stock left ${itemSelected.stock}!`, 'error')
        } else {
        let add_sub_total = parseInt(summaryProduct.discount_price) * add_quantity
            setSummaryProduct({...summaryProduct, 
                sub_total : add_sub_total,
                tax_price: summaryProduct.single_tax_price * add_quantity,
                quantity : add_quantity
            }) 
        }
    }

    const onClickRemove = () => {
        let remove_quantity = summaryProduct.quantity - 1
        let remove_sub_total = parseInt(summaryProduct.discount_price) * remove_quantity
        setSummaryProduct({...summaryProduct, 
            sub_total : remove_sub_total,
            tax_price: summaryProduct.single_tax_price * remove_quantity,
            quantity : remove_quantity
        })
    }

    const onChangeQuantity = (e : any ) => {
        const newValue = e.target.value || 0
        const decimalRegex = /^\d*\.?\d*$/;
        if (decimalRegex.test(newValue)) {
            let add_sub_total = parseInt(summaryProduct.discount_price) * parseFloat(newValue)
            setSummaryProduct({...summaryProduct, 
                sub_total : add_sub_total,
                tax_price: summaryProduct.single_tax_price * parseFloat(newValue),
                quantity : parseFloat(newValue)
            })
            setTimeout(() => {
                if (parseFloat(newValue) > itemSelected.stock) { 
                    setSummaryProduct({...summaryProduct, 
                        sub_total : parseInt(summaryProduct.discount_price) * itemSelected.stock,
                        tax_price: summaryProduct.single_tax_price * itemSelected.stock,
                        quantity :itemSelected.stock
                    })
                    swal('Error', `Product stock left ${itemSelected.stock}!`, 'error')
                } else if(parseFloat(newValue) < itemSelected.minimum_order_quantity) {
                    swal('error', `Minimum order quantity is ${itemSelected.minimum_order_quantity}`, 'error')
                    setSummaryProduct({...summaryProduct, 
                        sub_total : parseInt(summaryProduct.discount_price) * itemSelected.minimum_order_quantity,
                        tax_price: summaryProduct.single_tax_price * itemSelected.minimum_order_quantity,
                        quantity : parseInt(itemSelected.minimum_order_quantity)
                    })
                }  
            }, 1000);
        }
    }


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(itemSelected.name !== undefined) {
            setSummaryProduct({...summaryProduct, 
                name : itemSelected.name,
                variance_name : "",
                retail_price : itemSelected.retail_price,
                discount_price : itemSelected.discount_price,
                final_price : itemSelected.final_price,
                sub_total: itemSelected.sub_total,
                sum_price_discount: itemSelected.sum_price_discount,
                tax_price: itemSelected.tax_price,
                single_tax_price: itemSelected.single_tax_price,
                tax_percentage: itemSelected.tax_percentage,
                quantity : itemSelected.quantity,
                discount: itemSelected.discount
            })
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        if(store_purchaserequest.update_item) {
            dispatch(getOpenPurchaseRequest())
            handleClose()
            setIndex("")
        }
        // eslint-disable-next-line
    }, [store_purchaserequest.update_item]);

    useEffect(() => {
        if(store_template.update_item) {
            dispatch(getTemplatesData())
            handleClose()
            setIndex("")
        }
        // eslint-disable-next-line
    }, [store_template.update_item]);

    const onClickCreateNewPR = async (type : any) => {
        if(itemSelected.stock !== 0) {
            let product_item = {
                productId: itemSelected.productId,
                vendorId: itemSelected.vendorId,
                name: itemSelected.name,
                vendor: itemSelected.vendor,
                vendor_name : itemSelected.vendor_name, 
                vendor_address : itemSelected.vendor_address,
                vendor_phone : itemSelected.vendor_phone,
                minimum_order_quantity: parseInt(itemSelected.minimum_order_quantity),
                category_tree: itemSelected.category_tree,
                sku: itemSelected.SKU,
                slug: itemSelected.slug,
                brand: itemSelected.brand,
                images_product: itemSelected.images_product[0],
                reported_times: itemSelected.reported_times,
                storage: itemSelected.storage,
                dimension: itemSelected.dimension,
                tax : itemSelected.tax,
                categories: itemSelected.categories,
                warehouse: itemSelected.warehouse,
                payment_term: itemSelected.payment_term,
                retail_price: summaryProduct.retail_price,
                discount: summaryProduct.discount,
                discount_product: parseInt(itemSelected.discount_product),
                discount_price: summaryProduct.discount_price,
                sub_total_before_tax: summaryProduct.sub_total,
                sub_total: Math.floor((summaryProduct.sub_total + summaryProduct.tax_price)),
                quantity : summaryProduct.quantity,
                single_tax_price: summaryProduct.single_tax_price,
                down_payment_setting: itemSelected.down_payment_setting,
                note : ""
            }
            if(type === "pr") {
                const removeDBCart = await db.collection('db_local_CART').delete()
                if(removeDBCart) {
                    dispatch(addToLocalDBCarts(product_item))
                    history.push({
                        pathname: "/dashboard/purchase-requests",
                        search: `?page=create`, 
                    })
                    setTimeout(() => {
                        let data = { 
                            id: null,
                            change: false,
                            saved: false
                        }
                        localStorage.setItem('id_cart_pr', JSON.stringify(data))
                    }, 1000);
                }
            } else {
                const removeDBTemplate = await db.collection('db_local_template').delete()
                if(removeDBTemplate) {
                    dispatch(addToLocalDBTemplate(product_item))
                    history.push({
                        pathname: "/dashboard/templates",
                        search: `?page=create`,
                    })
                }
            }
        } else {
            swal('Error', 'Product stock is empty, please contact vendor.', 'error')
        }
    }

    const onClickUpdate = (item : any, type : string, data : any) => {
        if(type === 'pr') {
            let coverage = [...item.warehouse_detail.coverage]
            let city = data.address.city
            if(coverage.includes(city)){
                if(item.stock !== 0) {
                    const data_item : any = {
                        productId: item.productId,
                        vendorId: item.vendorId,
                        name: item.name,
                        vendor: item.vendor,
                        vendor_name : item.vendor_name,
                        vendor_address : item.vendor_address,
                        vendor_phone : item.vendor_phone,
                        minimum_order_quantity: item.minimum_order_quantity,
                        category_tree: item.category_tree,
                        sku: item.SKU,
                        slug: item.slug,
                        brand: item.brand,
                        images_product: item.images_product[0],
                        reported_times: item.reported_times,
                        storage: item.storage,
                        dimension: item.dimension,
                        tax : item.tax,
                        categories: item.categories,
                        warehouse: item.warehouse,
                        payment_term: item.payment_term,
                        retail_price: summaryProduct.retail_price,
                        discount: summaryProduct.discount,
                        discount_product: summaryProduct.discount_product,
                        discount_price: summaryProduct.discount_price,
                        sub_total_before_tax: summaryProduct.sub_total,
                        sub_total: Math.floor((summaryProduct.sub_total + summaryProduct.tax_price)),
                        quantity : summaryProduct.quantity,
                        single_tax_price: summaryProduct.single_tax_price,
                        down_payment_setting: item.down_payment_setting,
                        note : ""
                    }
                    if(type === "pr") {
                        let body_send = {
                            "id": data._id,
                            "vendor": item.vendor,
                            "item": data_item,
                            "status": "Open"
                        }
                        dispatch(updateItemPurchaseRequest(body_send))
                    } else {
                        let body_send = {
                            "id": data._id,
                            "vendor": item.vendor,
                            "item": data_item,
                            "status": "Open",
                            "name" : data.name
                        }
                        dispatch(updateItemTemplate(body_send))
                    }   
                } else {
                    swal('Error', 'Product stock is empty, please contact vendor.', 'error')
                }
            } else {
                swal('error', 'This product cannot added to this PR, because vendor coverage area for this product does not match with your address!', 'error')
            }
        } else {
            if(item.stock !== 0) {
                const data_item : any = {
                    productId: item.productId,
                    vendorId: item.vendorId,
                    name: item.name,
                    vendor: item.vendor,
                    vendor_name : item.vendor_name,
                    vendor_address : item.vendor_address,
                    vendor_phone : item.vendor_phone,
                    minimum_order_quantity: item.minimum_order_quantity,
                    category_tree: item.category_tree,
                    sku: item.SKU,
                    slug: item.slug,
                    brand: item.brand,
                    images_product: item.images_product[0],
                    reported_times: item.reported_times,
                    storage: item.storage,
                    dimension: item.dimension,
                    tax : item.tax,
                    categories: item.categories,
                    warehouse: item.warehouse,
                    payment_term: item.payment_term,
                    retail_price: summaryProduct.retail_price,
                    discount: summaryProduct.discount,
                    discount_product: summaryProduct.discount_product,
                    discount_price: summaryProduct.discount_price,
                    sub_total_before_tax: summaryProduct.sub_total,
                    sub_total: Math.floor((summaryProduct.sub_total + summaryProduct.tax_price)),
                    quantity : summaryProduct.quantity,
                    single_tax_price: summaryProduct.single_tax_price,
                    down_payment_setting: item.down_payment_setting,
                    note : ""
                }
                if(type === "pr") {
                    let body_send = {
                        "id": data._id,
                        "vendor": item.vendor,
                        "item": data_item,
                        "status": "Open"
                    }
                    dispatch(updateItemPurchaseRequest(body_send))
                } else {
                    let body_send = {
                        "id": data._id,
                        "vendor": item.vendor,
                        "item": data_item,
                        "status": "Open",
                        "name" : data.name
                    }
                    dispatch(updateItemTemplate(body_send))
                }   
            } else {
                swal('Error', 'Product stock is empty, please contact vendor.', 'error')
            }
        }
    }


    return (
        <div>
            <Dialog 
                onClose={handleClose} 
                aria-labelledby="simple-dialog-title"  
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                <Stack p={1} flexDirection="row" justifyContent="flex-end">
                    <Box>
                        <CancelIcon
                            onClick={handleClose}
                            sx={{color : "red", cursor: 'pointer'}}
                        />
                    </Box>
                </Stack>
                <DialogContent>
                    { itemSelected.name === undefined ? null : 
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <Stack flexDirection="row" justifyContent="space-between">
                                        <Box fontWeight="500">Summary </Box>
                                        <Box color="red" fontSize="12px" fontWeight="600">
                                            { itemSelected.discount_product === 0 ? "Discount 0%" : 
                                            `Discount : ${Math.floor(itemSelected.discount_product)}%` 
                                            } 
                                            { itemSelected.is_special_discount ? 
                                            ` + Special Discount : ${Math.floor(itemSelected.special_discount)}%` : null }
                                        </Box>
                                    </Stack>
                                    <Divider/>
                                    <Stack pb={2} pt={1}>
                                        <Box fontSize="15px">{summaryProduct.name} {summaryProduct.variance_name === "" ? null : ` - ${summaryProduct.variance_name}`}</Box>
                                        <Grid container rowSpacing={2} columnSpacing={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Retail Price : </Box>
                                                    <Box fontWeight={600} fontSize="13px"> 
                                                        Rp. {summaryProduct.retail_price.toLocaleString()}
                                                    </Box> 
                                                </Stack>
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Discount: </Box>
                                                    <Box fontWeight={600} fontSize="13px"> 
                                                        Rp. {summaryProduct.sum_price_discount.toLocaleString()}
                                                    </Box> 
                                                </Stack>
                                                <Stack flexDirection="row">
                                                    <Box pr={1} fontSize="13px">Price Afer Discount: </Box>
                                                    <Box fontWeight={600} fontSize="13px"> 
                                                        Rp. {summaryProduct.discount_price.toLocaleString()}
                                                    </Box> 
                                                </Stack>
                                                <Stack pb={1} flexDirection="row" alignItems="center">
                                                    <Box pt={1} fontSize="13px" fontWeight="600">Sub Total:</Box>
                                                    <Box pl={1} pt={1} color="red">Rp.{summaryProduct.sub_total.toLocaleString()}</Box>
                                                </Stack>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Tax : </Box>
                                                    <Box fontWeight={600} pl={1} fontSize="13px">{itemSelected.tax.value * 100}% (Rp. {summaryProduct.single_tax_price.toLocaleString()})</Box>
                                                </Stack>
                                                <Stack flexDirection="row">
                                                    <Box fontSize="13px">Total Tax Price: </Box>
                                                    { itemSelected.tax.value === 0 ? 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">0</Box> : 
                                                    <Box pl={1} fontWeight={600} fontSize="13px">Rp. {summaryProduct.tax_price.toLocaleString()}</Box> }
                                                </Stack> 
                                            </Grid>
                                        </Grid>
                                        
                                        
                                        <Stack flexDirection="row" sx={{ border: '1px solid #000', width: 'fit-content', p : 1, borderRadius: 1 }}>
                                            <Box fontWeight={600} pr={1} fontSize="14px">Final Price + Tax :</Box>
                                            <Box fontWeight={600} fontSize="15px" color="red">Rp. {(summaryProduct.sub_total + summaryProduct.tax_price).toLocaleString()}</Box>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <Box fontWeight="500"> Qty </Box>
                                    <Divider/>
                                    <Stack pb={2} pt={1}>
                                        <Box>
                                            <Stack flexDirection="row" rowGap={2} alignItems="center" >
                                                <Box pr={1}>
                                                    <Button  
                                                        size="small"
                                                        variant="contained" 
                                                        color="error"
                                                        onClick={onClickRemove}
                                                        disabled={ summaryProduct.quantity <= 1 || summaryProduct.quantity <= itemSelected.minimum_order_quantity  ? true : false }
                                                    >-</Button>
                                                </Box>
                                                <Box >
                                                    <TextField 
                                                        value={summaryProduct.quantity}
                                                        onChange={onChangeQuantity}
                                                        size="small"
                                                        sx={{ width: 100 }} 
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{step: "0.01"}}
                                                    />

                                                </Box>
                                                <Box pl={1}>
                                                <Button 
                                                    size="small"
                                                    variant="contained" 
                                                    onClick={onClickAdd}
                                                >+</Button>
                                                </Box>
                                            </Stack>
                                            <Box pt={1} fontSize="12px">Minimum Order : {itemSelected.minimum_order_quantity}</Box>
                                            <Box pt={1} fontSize="12px">Stock : {itemSelected.stock}</Box>
                                        </Box>
                                        
                                    </Stack>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid> }

                    <Stack mt={2} flexDirection="column" justifyContent="center">
                        <Stack flexDirection="row" justifyContent="center">
                            { dataList.length === 0 ? 
                            <Box pt={2} pb={2}>
                                There is no {type === "pr" ? "Purchase Request" : "Template"} Open
                            </Box> :
                            <ul className="list-pr">
                                { dataList && dataList.map((val : any, i : any) => (
                                <li key={i}>
                                    {/* <HtmlTooltip
                                        title={

                                        <div className='list-item-tooltip'>
                                            <ul>
                                                { val.items.map((name : any, i : any) => (
                                                    <li key={i} className='item-list-all'>
                                                        <div>
                                                            <span>{i + 1}.</span>
                                                        </div>
                                                        <div>
                                                            <p>{name.name}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        }
                                        placement="right"
                                        arrow
                                    > */}
                                        <div 
                                            className="divhover" 
                                            onClick={() => {
                                                setIndex(i)
                                                onClickUpdate(itemSelected, type, val)
                                            }}
                                        >
                                            { type === "pr" ? 
                                            <div>
                                                { store_purchaserequest.loading_update_item && index === i ? 
                                                "Loading.." : 
                                                <p>Add to {type === "pr" ? "PR" : "Template"} <span style={{ color: 'red' }} > {val.code_pr}</span></p>
                                                }
                                            </div> :
                                            <div>
                                                { store_template.loading_update_item && index === i ? 
                                                    "Loading.." : 
                                                    <p> Add to {type === "pr" ? "PR" : "Template"} <span style={{ color: 'red' }} >{val.name} </span></p>
                                                }
                                            </div>
                                            }
                                        </div>
                                    {/* </HtmlTooltip> */}
                                </li>
                                ))}
                            </ul>
                            }
                        </Stack>
                        <Stack flexDirection="row" justifyContent="center">
                            <Box fontWeight={600}>Or </Box>
                        </Stack>
                        <Stack pt={2}>
                            <Button 
                                variant="contained" color="primary" size="medium"
                                onClick={() => onClickCreateNewPR(type)}
                            >
                                Create new { type === "pr" ? "PR" : "Template" }
                            </Button>
                        </Stack>
                    </Stack> 
                </DialogContent> 
            </Dialog>
        </div>
    )
}

export default DialogOpen
