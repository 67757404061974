import React, { useState, useEffect } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tabs,
    Tab,
    Badge,
    Stack,
    Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableItem from './TableItem';
import moment from 'moment'
import { userCredentials } from '../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrdersBySearchLoadMore } from '../reducers/purchaseOrdersReducers';
import { RootState } from '../../../../app/store';
import swal from 'sweetalert'
import { createNewChat, getUsersForChat } from '../../chat/reducers/chatReducers';
import { getAllUsersTeams } from '../../teams/reducers/teamsReducers';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../../components/LoaderProgress';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  

const CardOrder : React.FC<any> = ({ data,  status, array_tabs, dataSearch, activeStatus, stateLocation }) => {
    const dispatch = useDispatch()

    const store_chat = useSelector((state : RootState) => state.chat)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [dataOrder, setDataOrder] = useState<any>([]);
    const [hasMore,setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    
    const [value, setValue] = useState(0);
    const [defaultValue, setDefaultValue] = useState(0);
    const [expanded, setExpanded] = useState<any>(false);
    const [idxItem, setIdxItem] = useState();
    const [loadingMessage, setLoadingMessage] = useState(false);
    
    const handleChangeAccordion =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        if(isExpanded) {
            setValue(value);
        } else {
            setValue(defaultValue)
        }
    };

    const handleChange = (event: any, value : any) => {
        setValue(value);
    };


    function getPackageByStatus(data:any, status: string) {
        let data_package = data.filter((key : any) => key.lastStatus === status)
        return data_package
    }


    useEffect(() => {
        if(stateLocation.open) {
            setExpanded("0");
        }
    }, [stateLocation]);




    function BadgeLabel ({name, total, index} : any) {
        return (
            <Badge 
              color={total === 0 ? "warning" : "error"}
              badgeContent={total === 0 ? null : total}  
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ cursor : 'pointer' }}
            >
              <Chip size="small" label={name} color="primary" variant={index === defaultValue ? 'filled' : 'outlined'} />
            </Badge>
          )
    }

    function BadgeLabelItem ({name, total, index, idx} : any) {
        return (
          <Badge 
            color={total === 0 ? "warning" : "error"}
            badgeContent={total === 0 ? null : total}  
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ cursor : 'pointer' }}
          >
            <Chip size="small" label={name} color="primary" variant={index === value ? 'filled' : 'outlined'} />
          </Badge>
        )
    }

    

    const onClickCreateChat = async (row : any, i : any) => {
        setLoadingMessage(true)
        setIdxItem(i)

        const user_vendor : any = await dispatch(getUsersForChat(row.vendor._id))

        if(user_vendor.type === "chat-user/get/fulfilled") {
            let data_response = user_vendor.payload.data.data
            let user_names = []
            for(let k of data_response) {
                user_names.push(`${k.fullname} - ${k.company_code}`)
            }
            for(let l of state_teams.data) {
                user_names.push(`${l.fullname} - ${l.company_code}`)
            }
            let body = {
                username:  `${userCredentials.fullname} - ${userCredentials.company_code}`,
                secret : userCredentials.buyer_id, 
                title : `${row.vendor.name} & ${userCredentials.company_detail.legal_name}`,
                usernames : user_names
            }
            dispatch(createNewChat(body))
        } 
    }

    const fetchMoreData = async () => {
        const body = {
            searchTopic : dataSearch.searchTopic,
            searchValue: dataSearch.searchValue,
            rangeTopic : dataSearch.rangeTopic,
            rangeFrom: dataSearch.rangeFrom,
            rangeTo: dataSearch.rangeTo,
            limit: dataSearch.limit,
            skip: page + 1,
            search : dataSearch.search,
        }
        const newdata : any = await dispatch(getPurchaseOrdersBySearchLoadMore(body))
        if(newdata.type === "purchase-orders-search-loadmore/get/fulfilled"){
            setPage(page + 1)
            let array = [...dataOrder, ...newdata.payload.data.data]
            if(newdata.payload.data.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataOrder(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        if(store_chat.chat) {
            setLoadingMessage(false)
            window.location.href = '/dashboard/message'
        }
    }, [store_chat.chat]);

    useEffect(() => {
      if(activeStatus === "New" ) {
        setValue(0)
        setDefaultValue(0)
      } else if(activeStatus === "Pick" ) {
        setValue(1)
        setDefaultValue(1)
      } else if(activeStatus === "Ready to Ship" ) {
        setDefaultValue(2)
        setValue(2)
      } else if(activeStatus === "Shipped" ) {
        setDefaultValue(3)
        setValue(3)
      } else if(activeStatus === "Delivered" ) {
        setDefaultValue(4)
        setValue(4)
      } else if(activeStatus === "Paid" ) {
        setDefaultValue(5)
        setValue(5)
      } else if(activeStatus === "Cancel" ) {
        setDefaultValue(6)
        setValue(6)
      } 
      
    }, [activeStatus]);

    useEffect(() => {
        setDataOrder(data)
        if(data.length < dataSearch.limit) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);


    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };    
    
    let handleCalculateShippingCost = (num:any) =>{
        if(num.packages[0]?.shipping_cost === undefined) return 0
        if(num.packages.length > 1){
            const initialValue = 0;
            let multipleShippingCost:number = num.packages?.reduce((total:any,currentValue:any) =>{
                return currentValue.shipping_cost + total;
            },initialValue)
            return multipleShippingCost
        }else{
            let [sinleShippingCost] = num.packages.map((e:any) => e.shipping_cost)
            return sinleShippingCost
        }
    }
    
    return (
    <Stack>
        <div>
            <InfiniteScroll
                dataLength={dataOrder.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<LoaderProgress/>}
                scrollableTarget="scrollableDiv"
                endMessage={
                    <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={5} mb={5}>
                        <Box>
                            <b>No More Order...</b>
                        </Box>
                        <Box pt={1}>
                            <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                <p>Back to Top</p>
                            </Button>
                        </Box> 
                    </Stack>
                }
            >
            { dataOrder?.map((val : any, i : any) => (
                <Box mb={4} mt={4} key={i} >
                    <Paper elevation={2} sx={{ borderLeft: '3px solid #43a047', borderRight: '3px solid #43a047', borderBottom: '3px solid #43a047' }}>
                        <Paper sx={{ borderTop: '3px solid #43a047' }}>
                            <Grid container spacing={2} justifyContent="space-between" sx={{pt:1, pb:1}}>
                                <Grid item>
                                    <Box fontWeight="bold" pl={2} fontSize={14}>Order ID : {val.code_po}</Box>
                                    <Box fontWeight="normal" pl={2} fontSize={13}> Date : {moment(val.date).format('ll')}</Box>
                                </Grid>
                            </Grid>
                           
                        </Paper>
                        <Box p={2}>
                            <Grid container spacing={3} >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                    <Box pr={1} fontSize={13}>{val.vendor.name} </Box>
                                        {/* { state_teams.loading ? "Loading" : 
                                        <Button 
                                            size="small" color="primary" variant="outlined"
                                            onClick={() => onClickCreateChat(val, i)}
                                            disabled={loadingMessage ? true : false}
                                        >
                                            { loadingMessage && idxItem === i ? "Loading" : "Contact Vendor" }
                                        </Button> } */}
                                    </Box>  
                                    <Box pt={1} fontWeight="normal" fontSize={14}>
                                        <Box style={{width: '80%'}} fontSize={12}>{val.vendor.phone}</Box>
                                        <Box style={{width: '80%'}}>{val.vendor.address}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Department : {val.department === 'generic' ? "N/A" : val.department}
                                    </Box>
                                        <Box fontWeight="bold" pt={1} fontSize={13}> 
                                            { activeStatus === 'Waiting DP' || activeStatus === 'New'?
                                            `Total Price : Rp. ${ val.grand_total.toLocaleString()}`
                                            :
                                            `Total Price : Rp. ${ (parseInt(val.grand_total) + handleCalculateShippingCost(val)).toLocaleString()}`
                                            }
                                        </Box>
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Down Payment : Rp. {val.down_payment === null ? 0 : parseInt(val.down_payment).toLocaleString()}
                                    </Box>
                                    { activeStatus === 'New'?
                                    null
                                    :
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Total Shipping Cost : Rp.
                                        { handleCalculateShippingCost(val).toLocaleString()}
                                    </Box>
                                    }
                                </Grid>
                            </Grid>
                            
                        </Box>

                        <Box pt={2} >
                            <Paper elevation={3}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}  expanded={expanded === `${i}`} onChange={handleChangeAccordion(`${i}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id={`panel1a-header-${val._id}`}
                                        sx={{ borderTop: '3px solid #43a047', borderBottom: `${expanded === `${i}` ? '3px solid #43a047' : ''}` }}
                                    >
                                        
                                        <Stack flexDirection="row" alignItems="center">
                                            <Box fontWeight="bold" fontSize={14} color="#000">
                                                Click here to {expanded === `${i}` ? "close" : "view"} All Packages ({val.packages?.length})
                                            </Box>
                                            <Box pl={1} pr={2}>
                                                <ExpandMoreIcon sx={{ color: '#000', marginTop: '4px' }}/>
                                            </Box>
                                            { parseInt(expanded) ===  parseInt(i) && expanded !== false ? null : 
                                            <Box>
                                                <Stack flexDirection="row" >
                                                    { array_tabs.map((item:any, idx:any) => (
                                                        <Box mr={4} key={idx}> 
                                                            <BadgeLabel name={item.label} total={getPackageByStatus(val.packages, item.value).length} idx={i}  index={idx}/> 
                                                        </Box>
                                                    )) }
                                                </Stack>
                                            </Box> }
                                        </Stack> 
                                    </AccordionSummary>
                                    <AccordionDetails>  
                                        <Box>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                                                { status === "Waiting Payment" ? null : 
                                                <Tabs TabIndicatorProps={{style: {background:'white' }}} value={value} onChange={handleChange} name="1" aria-label="basic tabs example" color="error">
                                                    { array_tabs.map((item:any, idx:any) => (
                                                        <Tab key={idx} label={<BadgeLabelItem name={item.label} total={getPackageByStatus(val.packages, item.value).length} index={idx} />} {...a11yProps(idx)} />
                                                    ))}
                                                </Tabs> }
                                            </Box>
                                            <Paper style={{ backgroundColor: '#ddd' }}>
                                                {/* task */}
                                                { array_tabs.map((item:any, idx:any) => (
                                                <TabPanel value={value}  index={idx} key={idx}>
                                                    <TableItem 
                                                        data={getPackageByStatus(val.packages, item.value)}  
                                                        status={item.label}
                                                        department={val.department}
                                                        dataOrders={val}
                                                        vendor_name={val.vendor.name}
                                                        vendor_id={val.vendor}
                                                        state_teams={state_teams.data}
                                                    /> 
                                                </TabPanel>
                                                ))}
                                            </Paper>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Box>

                    </Paper>
                </Box>
                ))}
            </InfiniteScroll>
        </div>

    </Stack>
    )
}

export default CardOrder
