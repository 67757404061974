import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userCredentials } from '../../../utilities/config';
import { socket } from '../../../utilities/socket';
import { ChatMessage, listChat } from './interface';

function getRemotCo(c: ChatMessage) {
  let company_id: string | undefined;
  let company_name: string | undefined;
  if(userCredentials.buyer_id === c.from_company_id) {
    company_id = c.to_company_id;
    company_name = c.to_company_name;
  } else {
    company_id = c.from_company_id;
    company_name = c.from_company_name;
  }
  return { company_id, company_name };
}
const initialState: any = {
  isEstablishingConnection: false,
  isConnected: false,
  chats: [],
  chat_list: [],
  active_chat: null,
  loading: false,
};

export const chatReducers = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setState: ((state, {payload}: PayloadAction<Boolean>) => {
      state.isConnected = payload;
    }),
    initMessage: ((state, {payload}: PayloadAction<ChatMessage[]>) => {
      const list: any = [];
      payload.forEach(c => {
        const remote_co = getRemotCo(c);
        const exist = list.find((x: listChat) => x.company_id === remote_co.company_id);
        if(exist) exist.last = c;
        else list.unshift({ ...remote_co, last: c })
      });
      state.chat_list = list;
      state.chats = payload;
    }),
    receiveMessage: ((state, { payload }: PayloadAction<ChatMessage>) => {
      const remote_co = getRemotCo(payload);
      const idx_co = state.chat_list.findIndex((x: listChat) => x.company_id === remote_co.company_id);
      const list = {...remote_co, last: payload};
      if(idx_co > -1) state.chat_list.splice(idx_co, 1, list);
      else state.chat_list.unshift(list);
      state.chats.push(payload);
    }),
    sendMessage: ((state, { payload }: PayloadAction<ChatMessage>) => {
      socket.emit('msg_to', payload);
    }),
    setActive: ((state, { payload }: PayloadAction<any>) => {
      state.active_chat = payload;
    }),
    updateMessage: ((state, {payload}:PayloadAction<ChatMessage[]>) => {
      payload.forEach(u => {
        const idx_chat = state.chats.findIndex((c: ChatMessage) => c._id === u._id);
        state.chats.splice(idx_chat, 1, u);
      })
    }),
    readMessage : ((state, {payload}: PayloadAction<any>) => {
      socket.emit('read_msg', payload);
    })
  },
});

export const { 
  setState,
  initMessage,
  receiveMessage,
  sendMessage,
  setActive,
  updateMessage,
  readMessage
} = chatReducers.actions;
export default chatReducers.reducer;
