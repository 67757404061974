import { Stack, Box, Button, Paper, Grid, Checkbox } from '@mui/material'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../../../../app/store';
import { userCredentials } from '../../../../../../utilities/config';
import { getBankVendor, getDataAllBank, postCreatePaymentWithOY, postCreatePaymentWithXendit } from '../../reducers/invoiceReducers';

function PaymentCheckout() {

    const dispatch = useDispatch()
    const location : any = useLocation()
    const history = useHistory()

    const state_payment = location.state === undefined ? null : location.state.data_payment
    const store_invoice = useSelector((state : RootState) => state.invoices)

    const [selectBank, setSelectBank] = useState("");
    const [selectBankCode, setSelectBankCode] = useState("");
    const [selectBankCodeName, setSelectBankCodeName] = useState(""); 
    const [selectBankAccount, setSelectBankAccount] = useState("");

    function sumTotalPrice (data : any) {
        const totalPriceALl = data.reduce((total : any, data : any) => {
          return total + data.grand_total
        }, 0)
        return totalPriceALl;
    }

    function getDocumentReferer (items : any) {
        let document_referer = []
        for(let element of items) {
            document_referer.push(element._id)
        }
        return document_referer
    }

    const onClickConfirm = () => {
        if(state_payment.payment_gateway === "OY Indonesia") {
            let data_send = {
                "partner_user_id": `${userCredentials.buyer_id}`,
                "amount": sumTotalPrice(state_payment.items),
                "bank_code": selectBankCode,
                "bank_name": selectBank,
                "username_display": userCredentials.fullname,
                "email": userCredentials.email,
                "method": state_payment.type_payment === "Virtual Account" ? "VA" : state_payment.type_payment,
                "payment_gateway": state_payment.direct_payment ? state_payment.type_payment : state_payment.payment_gateway,
                "document_referer": getDocumentReferer(state_payment.items),
                "bank_name_code" : selectBankCodeName,
                "account_number" : selectBankAccount,
                "direct_payment" : state_payment.direct_payment
            }
            // console.log(data_send, 'data_send')
            dispatch(postCreatePaymentWithOY(data_send))
        } else {
            let data_send = {
                "partner_user_id": `${userCredentials.buyer_id}`,
                "amount": sumTotalPrice(state_payment.items),
                "bank_code": selectBank.toLocaleUpperCase(),
                "bank_name": selectBank.toLocaleUpperCase(),    
                "username_display": userCredentials.fullname,
                "email": userCredentials.email,
                "method": state_payment.type_payment === "Virtual Account" ? "VA" : state_payment.type_payment,
                "payment_gateway": state_payment.direct_payment ? state_payment.type_payment : state_payment.payment_gateway,
                "document_referer": getDocumentReferer(state_payment.items),
                "bank_name_code" : selectBankCodeName,
                "account_number" : selectBankAccount,
                "direct_payment" : state_payment.direct_payment
            }
            // console.log(data_send, 'data_send')
            dispatch(postCreatePaymentWithXendit(data_send))
        }
    }

    useEffect(() => {
        if(state_payment === null) { 
            window.location.href = "/dashboard/finance/invoice"
        } else {
            let data_value = {
                payment_gateway : state_payment.payment_gateway,
                payment_type : state_payment.type_payment,
            }
            dispatch(getDataAllBank(data_value)) 
            if(state_payment.direct_payment) {
                dispatch(getBankVendor(state_payment.items[0].vendor._id))
            }
        }
        // eslint-disable-next-line
    }, [state_payment]);

    useEffect(() => {
        if(store_invoice.create_va) {
            history.push({
                pathname: '/dashboard/finance/invoice',
                search: `?page=waiting-payment`, 
            }); 
        }
        // eslint-disable-next-line
    }, [store_invoice.create_va]);

    useEffect(() => {
        if(store_invoice.bank_vendor.length) {
            setSelectBankCode(store_invoice.bank_vendor[0].code)
            setSelectBank(store_invoice.bank_vendor[0].bank)
            setSelectBankCodeName(store_invoice.bank_vendor[0].name_code)
            setSelectBankAccount(store_invoice.bank_vendor[0].account_number)
        }
        // eslint-disable-next-line
    }, [store_invoice.bank_vendor]);

    return (
        <Box>
            <Stack pt={3} >
            { state_payment.direct_payment ? 
                <Box> 
                    <h3>Payment Checkout to Vendor {state_payment.items[0].vendor.name}</h3>
                </Box> 
                :
                <Box> 
                    <h3>Payment Checkout with {state_payment.type_payment}</h3>
                </Box>
            }
            </Stack>

            
            <Stack pt={3} direction="row" justifyContent="space-between" spacing={3}>
                { state_payment.direct_payment ? null :
                <Paper sx={{ border: '1px solid #1b4559', width: '70%' }}>
                    <Box p={3}>
                        <Box fontWeight="500" fontSize="20px" >Transfer fund to the following bank accounts.</Box>
                        { store_invoice.loading_bank ? 
                        <Box pt={2}>
                            Loading ...
                        </Box>
                        :
                        <Stack pt={3}>
                            { store_invoice.bank.length === 0 ? "Bank not found" : 
                            <Grid container spacing={2}>
                                {store_invoice.bank.map((val:any, index:any) => (
                                <Grid item xl={4} lg={6} md={12} sm={12} xs={12} key={index}>
                                    <Paper sx={{ border: '1px solid #afc0c9', height: 80 }}>
                                        <Stack direction="row" p={2} alignItems="center" justifyContent="space-between" >
                                            <Stack direction="row" alignItems="center" justifyContent="space-between" >
                                                <Box>
                                                    <Checkbox 
                                                        checked={selectBank === val.name ? true : false} 
                                                        onChange={() => {
                                                            setSelectBankCode(val.code)
                                                            setSelectBank(val.name)
                                                            setSelectBankCodeName(val.name_code)
                                                            setSelectBankAccount(val.account_number)
                                                        }}
                                                    />
                                                </Box>
                                                <Box fontSize={12}>
                                                    {val.name}
                                                </Box>
                                            </Stack>
                                            <Box
                                                component="img"
                                                src={val.imageUrl}
                                                sx={{
                                                    width: '60px',
                                                    pl: 2
                                                }}
                                            />
                                        </Stack>
                                    </Paper>
                                </Grid>
                                ))}
                            </Grid> 
                            }
                        </Stack>
                        }
                    </Box>
                </Paper> }

                { state_payment === null ? null : 
                <Paper sx={{ backgroundColor: '#1b4559', width: `${state_payment.direct_payment ? '50%' : '30%'}`, color: '#fff' }}>
                    <Box p={3}>
                        <Stack>
                            <Box fontSize="25px" fontWeight="500">Summary</Box>
                            <Box fontSize="15px" fontWeight="300">Please make sure the amount !</Box>
                        </Stack>
                        <Stack pt={4} pb={2}>
                            <Box fontSize="15px" fontWeight="300">Payment : </Box>
                            { selectBank === "" ?
                                <Box fontSize="20px" fontWeight="400" color="#e8505b">
                                   { store_invoice.loading_bank_vendor ? "Please Wait ..." : "Please select a bank!" } 
                                </Box>
                                :
                                <Box fontSize="20px" fontWeight="600" color={`${process.env.REACT_APP_THEMED_COLOR}`}>
                                    {selectBank} {state_payment.type_payment}
                                </Box>
                            }
                        </Stack>
                        <Stack pt={2}>
                            <Box fontSize="15px" fontWeight="300">Invoices Number</Box>
                            {state_payment.items.map((data:any, index:any) => (
                                <Stack direction="row" justifyContent="space-between" key={index}>
                                    <Box fontSize="18px" fontWeight="500">{data.invoice_number}</Box>
                                    <Box fontSize="18px" fontWeight="600">Rp. {data.grand_total.toLocaleString()}</Box>
                                </Stack>
                            )) }
                        </Stack>
                        <Stack pt={3} direction="row" justifyContent="space-between">
                            <Box fontSize="20px" fontWeight="500">Total Payment</Box>
                            <Box fontSize="20px" fontWeight="500">Rp. {sumTotalPrice(state_payment.items).toLocaleString()}</Box>
                        </Stack>
                        <Stack pt={3}>
                            <Button 
                                variant="contained" 
                                size="small" 
                                color="primary"
                                disabled={selectBank === "" || store_invoice.loading_create_va ? true : false}
                                onClick={onClickConfirm}
                            >
                                { store_invoice.loading_create_va ? "Loading.." : "PAY INVOICE" }
                            </Button>
                        </Stack>
                    </Box>
                </Paper> }
            </Stack>
        </Box>
    )
}

export default PaymentCheckout
