import { Box } from '@mui/system'
import { Button, Stack } from '@mui/material';
import BreadCrumbs from '../../../components/BreadCrumbs'
import MenuPopOver from '../../../components/MenuPopOver';
import DataTablePurchaseRequest from './components/DataTablePurchaseRequest';
import { useHistory } from 'react-router-dom';
import { checkValidation } from '../../../utilities/userFeaturesValidation';
// import { userCredentials } from '../../../utilities/config';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false

const PurchaseRequests = () => {
    const history = useHistory()

    const onClickCreateNewPR = async () => {
        const removeDB = await db.collection('db_local_CART').delete()
        if(removeDB) {
            localStorage.removeItem('id_cart_pr')
            history.push({
                pathname: "/dashboard/purchase-requests",
                search: `?page=create`, 
            })
        }
    }

    return (
        <Stack pt={2} pl={3} pr={3}>
            <BreadCrumbs 
                isPage={false}
                current="Purchase Requests Page"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Purchase Requests</h3>
                </Box>
                { checkValidation('61d5d017ca14956261f09665') ? 
                <Box sx={{display: 'flex'}}>
                    <MenuPopOver
                        name="Create New PR From Template"
                        color="primary"
                    />
                    <Box mr={2} />
                    <Button  
                        variant="contained" color="primary"
                        size='small'
                        onClick={() => onClickCreateNewPR()}
                    >
                        Create New PR
                    </Button>
                </Box> : null }
            </Stack>

            <Stack pt={3}>
                <DataTablePurchaseRequest/>
            </Stack>
        </Stack>
    )
}

export default PurchaseRequests;
