import React, { useEffect, useState } from 'react'
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid, 
    Box, 
    Button,
    TextField
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { groupBy } from 'lodash'
import { useDispatch } from 'react-redux';
import { 
    addQuantityProduct, 
    removeVendorItems, 
    removeItemProduct, 
    removeQuantityProduct, 
    onChangeQuantityProduct
} from '../features_app/dashboard/purchase_requests/create/reducers/createPurchaseRequestReducers';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const TableItemCarts : React.FC<any> = ({ dataCartProps }) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [groupCart, setGroupCart] = useState<any>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const proceedCart = () => {
          let copy = [...dataCartProps]
          const convert_array = Object.entries(groupBy(copy, 'vendor_name'));
          let final_data : any = convert_array.map(function(key) { 
            return { 
              vendor_name: key[0], 
              items: key[1] 
            }; 
          });
          setGroupCart(final_data)
          setLoaded(true)
        }
        proceedCart()
    }, [dataCartProps]);

    function sumSubPrice (data:any) {
        const totalPriceVendor = data.reduce((total : any, data : any) => {
          return total + data.sub_total
        }, 0)
        return Math.floor(totalPriceVendor);
    }

    function sumDownPayment (data:any) {
        let down_payment = data[0].down_payment_setting / 100
        const totalPriceVendor = data.reduce((total : any, data : any) => {
          return total + data.sub_total
        }, 0)
        let final_down_payment = totalPriceVendor * down_payment
        return Math.floor(final_down_payment);
    }


    const onClickRemoveVendor = (data: any) => {
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure want to clear all items by Vendor ${data.vendor_name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: 'primary',
            confirmButtonText: 'Yes, Clear All',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(removeVendorItems({
                    vendor_name : data.vendor_name
                }))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              'Cleared!',
              'All items has been removed.',
              'success'
            )
          }
        })
    }

    const onClickRemoveItem = (value: any) => {
        Swal.fire({
            title : "Confirmation",
            text: `Are you sure want to remove item ${value.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: 'primary',
            confirmButtonText: 'Yes, Remove It',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(removeItemProduct({
                    name : value.name,
                }))
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) { 
            Swal.fire(
              'Removed!',
              'Item has been removed.',
              'success'
            ) 
          }
        })
    }


    const onChangeQuantity = (e : any, value : any ) => {
        const newValue = e.target.value || 0
        const decimalRegex = /^\d*\.?\d*$/;
        if (decimalRegex.test(newValue)) {
            dispatch(onChangeQuantityProduct({
                productId : value.productId,
                discount_price : value.discount_price, 
                retail_price : value.retail_price,
                single_tax_price: value.single_tax_price,
                value_quantity : parseFloat(newValue),
                minimum_order : parseInt(value.minimum_order_quantity) 
            }))

            setTimeout(() => {
                if(parseFloat(newValue) < parseInt(value.minimum_order_quantity) ) {
                    swal('error', `Minimum order quantity is ${parseInt(value.minimum_order_quantity)}`, 'error')
                    dispatch(onChangeQuantityProduct({
                        productId : value.productId,
                        discount_price : value.discount_price,
                        retail_price : value.retail_price,
                        single_tax_price: value.single_tax_price,
                        value_quantity : parseInt(value.minimum_order_quantity)
                    })) 
                } else if (parseFloat(newValue) >  value.stock) { 
                    swal('Error', `Product stock left ${value.stock}!`, 'error')
                    dispatch(onChangeQuantityProduct({
                        productId : value.productId,
                        discount_price : value.discount_price,
                        retail_price : value.retail_price,
                        single_tax_price: value.single_tax_price,
                        value_quantity : value.stock
                    })) 
                }
            }, 1000);
        }
    }

    const stylesCell = {
        fontSize : 12
    }

    return (
    <div>
        { loaded && groupCart.map((data : any, index : any) => (
            <Box mb={4} key={index}>
            <TableContainer>
                <Paper square variant="outlined">
                    <div className="vendor-title" >
                        <h4>{data.vendor_name}</h4>
                        <Box mr={2}>
                            <Button 
                                variant="outlined" 
                                color="error" 
                                size="small" 
                                onClick={() => onClickRemoveVendor(data)}
                            >
                                Remove Vendor Items
                            </Button>
                        </Box>
                    </div>
                </Paper>
                <Paper square variant="outlined">
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow >
                            <TableCell  width="40px" sx={stylesCell}>No</TableCell>
                            <TableCell  width="200px" sx={stylesCell}>Name</TableCell>
                            <TableCell sx={stylesCell} >Vendor</TableCell>
                            <TableCell sx={stylesCell} >Retail Price</TableCell>
                            <TableCell sx={stylesCell} >Disc%</TableCell>
                            <TableCell sx={stylesCell} >Final Price</TableCell>
                            <TableCell sx={stylesCell} >Ppn</TableCell>
                            <TableCell sx={stylesCell} width="200px">Quantity</TableCell>
                            <TableCell sx={stylesCell} >Sub Total + Ppn</TableCell>
                            <TableCell sx={stylesCell} >Action</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.items.map((value : any, index : any) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row"  sx={stylesCell} >{index + 1}</TableCell>
                                <TableCell component="th" scope="row"  sx={stylesCell}>
                                    <Box 
                                        sx={{ cursor: 'pointer', color: 'blue' }}
                                        onClick={() => {
                                            history.push({
                                            pathname: "/dashboard/products",
                                            search: `?page=detail-product`, 
                                            state : {
                                                slug : value.slug
                                            }
                                            })
                                        }}
                                        >
                                        {value.name}
                                    </Box>
                                </TableCell>
                                <TableCell component="th" scope="row"  sx={stylesCell}>{value.vendor_name}</TableCell>
                                <TableCell  sx={stylesCell}>RP. {parseInt(value.retail_price).toLocaleString()}</TableCell>
                                <TableCell  sx={stylesCell}>{Math.floor(value.discount * 100)}%</TableCell>
                                <TableCell  sx={stylesCell} >RP. {Math.floor(value.discount_price).toLocaleString()}</TableCell>
                                <TableCell  sx={stylesCell}>{value.tax.title}</TableCell>
                                <TableCell  sx={stylesCell}> 
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box 
                                                pr={1} 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    if(value.minimum_order_quantity === value.quantity) {
                                                        swal("Error", `Minimum order quantity is ${value.minimum_order_quantity}!`, "error")
                                                    } else {
                                                        dispatch(removeQuantityProduct({
                                                            name : value.name,
                                                            productId : value.productId,
                                                            discount_price : value.discount_price,
                                                            retail_price : value.retail_price,
                                                            single_tax_price: value.single_tax_price
                                                        }))
                                                    }
                                                }} 
                                            >
                                                <RemoveCircleOutlineIcon color="error"/>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box pr={1} pl={1}>
                                                <TextField 
                                                    value={value.quantity}
                                                    onChange={(e) => onChangeQuantity(e, value)}
                                                    size="small"
                                                    sx={{ width: 65, fontSize: 12 }}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{step: "0.01"}}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box 
                                                pr={1} 
                                                style={{cursor: 'pointer'}}
                                                onClick={() => { 
                                                    if (value.quantity >= value.stock) {
                                                        swal("Error", `Product stock left ${value.stock}!`, "error")
                                                    } else  {
                                                        dispatch(addQuantityProduct({
                                                            name : value.name,
                                                            productId : value.productId,
                                                            discount_price : value.discount_price,
                                                            retail_price : value.retail_price,
                                                            single_tax_price: value.single_tax_price
                                                        }))}
                                                    }
                                                }
                                            >
                                                <AddCircleIcon color="success"/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell  sx={stylesCell}>RP. {Math.floor(value.sub_total).toLocaleString()}</TableCell>
                                <TableCell  sx={stylesCell}>
                                    <Grid container>
                                        <Grid item>
                                        <Box 
                                            pr={1} 
                                            style={{cursor: 'pointer'}} 
                                            onClick={() => onClickRemoveItem(value)}
                                        >
                                            <DeleteForeverIcon color="error"/>
                                        </Box>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Paper>
                <Paper square variant="outlined">
                    <Box p={2} mr={6}>
                        <div className="sub-total-cart">
                            <Box>Down Payment ({data.items[0].down_payment_setting}%)</Box>
                            <h4>Rp. {sumDownPayment(data.items).toLocaleString()}</h4>
                        </div>
                        <div className="sub-total-cart">
                            <h4>Total Price / Vendor</h4>
                            <h4>Rp. {sumSubPrice(data.items).toLocaleString()}</h4>
                        </div>
                    </Box>
                </Paper>
            </TableContainer>
        </Box>
        ))}
    </div>
    )
}

export default TableItemCarts
