import React, { useState, useRef, useEffect } from 'react'
import { Box, TextField, IconButton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search';
import { ChatMessage } from '../interface';
import { sendMessage } from '../chatSlice';
import { 
    ChatContainer, 
    MessageList, 
    Message, 
    MessageInput,
    Avatar,
    ConversationHeader,
    TypingIndicator,
 } from '@chatscope/chat-ui-kit-react';
 import profile from '../../../../assets/img/icon/profile.png'
 import { handleTime } from '../handleTime';
 import start_conversation from '../../../../assets/img/icon/start-conversation.jpg'




const ChatAction = () =>{
    const dispatch = useDispatch()
    const {active_chat, chats} = useSelector((state:any) => state.chat);

    let messageEnd = useRef<null | HTMLDivElement>()

    
    const [messageInputValue, setMessageInputValue] = useState("");
    const [search, setSearch] = useState('');
    const [fileUpload,setFileUpload] = useState<any>()

    const autoScrollBottom = () =>{
        messageEnd.current?.scrollIntoView({ behavior : 'smooth'})
    }

    const typing = (e:any) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            sendMsg();
            console.log('enter');
        }        
    }
    const sendMsg = () => {
        const data = {
            to_company_id: active_chat?.company_id,
            to_company_name: active_chat?.company_name,
            text: messageInputValue,
        };
        if(messageInputValue.length) {
            dispatch(sendMessage(data))
            setMessageInputValue('')
        }
    }
    
    useEffect(() =>{
        autoScrollBottom()
    },[])

    const handleAttach = () =>{
        document.getElementById('open_file')?.click()                
    }

    return (active_chat &&
        <ChatContainer>
            <ConversationHeader>
                <ConversationHeader.Back />
                <Avatar src={profile} name="Zoe" status='dnd'/>
                <ConversationHeader.Content userName={active_chat?.company_name} info={active_chat?.last_activity} />
                <ConversationHeader.Actions> 
                    <Box 
                        display='flex' 
                        flexDirection='row' 
                        justifyContent='space-between' 
                        alignItems='center' 
                        minWidth='30%'
                        maxWidth={400}
                        maxHeight={37} 
                        border='0.5px solid #67b7f7'
                        borderRadius='8px'
                        mr={2}
                        >
                        <TextField 
                            fullWidth
                            onChange={(e)=> setSearch(e.target.value)}
                            sx={{ 
                                border:'none', 
                                '& .MuiInputBase-input' : { 
                                    padding : '4px', 
                                    maxWidth:'100%',
                                    fontSize:'14px',
                                    maxHeight:'10px',
                                    paddingLeft:'10px'
                                },
                                '& .MuiOutlinedInput-notchedOutline' : {
                                    border:'none'
                                }
                            }} 
                            placeholder='Search Message...' size='small'/>
                        <IconButton >
                            <SearchIcon fontSize='medium' color='primary'/>
                        </IconButton>
                    </Box>
                    {/* <EllipsisButton orientation="vertical" /> */}
                </ConversationHeader.Actions> 
            </ConversationHeader>     
            <MessageList autoScrollToBottomOnMount={true} scrollBehavior='auto' typingIndicator={
                active_chat.typing?.length > 0 && <TypingIndicator content={active_chat.typing.join(', ')}/>
            }>
                {chats.filter((x:ChatMessage) =>
                    (x.from_company_id === active_chat.company_id || x.to_company_id === active_chat.company_id)
                    && (!search.length || x.text.indexOf(search) > -1)
                ).map((e:ChatMessage, idx:number) =>
                <Message style={{ maxWidth:'400px' }} 
                    key={idx}
                    model={{
                        message: e.text,
                        sentTime: handleTime(e.createdAt),
                        sender: e.from_company_name,
                        direction:  e.to_company_id === active_chat.company_id? 'outgoing' : 'incoming',
                        position: "single"
                    }}
                    // avatarSpacer={e.to_company_id === sender? true : false}                          
                >
                    <Message.Header sender={e.from_user_name} />
                    <Message.Footer sentTime={handleTime(e.createdAt)} />
                </Message>)
                }
            </MessageList>
            <MessageInput onAttachClick={handleAttach}  placeholder="Type message here" value={messageInputValue} onChange={val => setMessageInputValue(val)} onKeyUp={typing}/>
            {/* <input onChange={(e)=>setFileUpload(e.target?.files)} type='file' id='open_file' style={{ display:'none'}}/> */}
        </ChatContainer> 
    ) || (
        <Box sx={{ width:'25%', margin:'auto'}}>
            <Box sx={{ margin:'auto', width:'100%'}} component='img' src={start_conversation} alt='chatting'/>
            <Box sx={{ display:'flex', justifyContent:'center', textAlign:'center'}} fontWeight={600} fontSize={20}>Start your conversation now!</Box>
        </Box>
    )
}

export default ChatAction
