import { createAsyncThunk } from '@reduxjs/toolkit';
import { userCredentials } from '../../../../../utilities/config';
import { remove } from 'lodash'
import axios from 'axios';
import { groupBy } from 'lodash'

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false

export const getLocalDBCarts = createAsyncThunk(
    'carts/get',
    async (_, { rejectWithValue }) => {
        try {
            const data_carts = await db.collection('db_local_CART').get()
            if(data_carts) {
                return data_carts
            }
          } catch (err : any) {
            if (!err.data_carts) {
              throw err
            }
            return rejectWithValue(err.data_carts)
        }
    }
);


export const addToLocalDBCarts = createAsyncThunk(
    'carts/add', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get()

        if(data_carts.length === 0) {
            try {
                const add_carts = await db.collection('db_local_CART').add(value)
                localStorage.removeItem('id_cart_pr')
                if(add_carts) {
                    return {success : true, message : "Success Added Item to Cart!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } else {
            if(data_carts.find((element : any) => element.productId === value.productId)) {
                let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity + value.quantity
                let total_sub_total =  quantity * value.discount_price
                let tax_price = quantity * value.single_tax_price
                try {
                    const result = await db.collection('db_local_CART')
                        .doc({ productId: value.productId })
                        .update({
                            quantity: quantity,
                            sub_total : Math.floor(total_sub_total + tax_price)
                        })
                    if(result) {
                        return {success : true, message : "Success Added Item to Cart!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            } else {
                try {
                    const add_carts = await db.collection('db_local_CART').add(value)
                    if(add_carts) {
                        return {success : true, message : "Success Added Item to Cart!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            }
        }
    }
)

export const addQuantityProduct = createAsyncThunk(
    'carts/add-quantity', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get()
        if(data_carts.find((element : any) => element.productId === value.productId)) {
            let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity + 1
            let add_sub_total =  quantity * value.discount_price
            let tax_price = quantity * value.single_tax_price

            try {
                const result = await db.collection('db_local_CART')
                    .doc({ productId: value.productId })
                    .update({
                        quantity: quantity,
                        sub_total : Math.floor(add_sub_total + tax_price),
                        sub_total_before_tax: Math.floor(add_sub_total),
                    })

                if(result) {
                    return {success : true, message : "Success Increase Item Quantity!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const onChangeQuantityProduct = createAsyncThunk(
    'carts/change-quantity', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get()
        if(data_carts.find((element : any) => element.productId === value.productId)) {
            let add_sub_total =  value.value_quantity * value.discount_price
            let tax_price = value.value_quantity * value.single_tax_price
            try {
                const result = await db.collection('db_local_CART')
                    .doc({ productId: value.productId })
                    .update({
                        quantity: value.value_quantity,
                        sub_total : Math.floor(add_sub_total + tax_price),
                        sub_total_before_tax: Math.floor(add_sub_total),
                    })
                if(result) {
                    return {success : true, message : "Success Change Item Quantity!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removeQuantityProduct = createAsyncThunk(
    'carts/remove-quantity', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get()

        if(data_carts.find((element : any) => element.productId === value.productId)) {
            let quantity = data_carts.find((element : any) => element.productId === value.productId).quantity
            if(quantity <= 1) {
                try {
                    const result = await db.collection('db_local_CART')
                        .doc({ productId: value.productId })
                        .delete()
    
                    if(result) {
                        return {success : true, message : "Success Remove Item!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }
            } else { 
                let change_quantity = data_carts.find((element : any) => element.productId === value.productId).quantity - 1
                let remove_sub_total =  change_quantity * value.discount_price
                let tax_price = change_quantity * value.single_tax_price
                try {
                    const result = await db.collection('db_local_CART')
                        .doc({ productId: value.productId })
                        .update({
                            quantity: change_quantity,
                            sub_total : Math.floor(remove_sub_total + tax_price),
                            sub_total_before_tax: Math.floor(remove_sub_total),
                        })
    
                    if(result) {
                        return {success : true, message : "Success Decrease Item Quantity!"}
                    }
                } catch (error) {
                    return rejectWithValue(error)
                }

            }
        }
    }
)

export const removeItemProduct = createAsyncThunk(
    'carts/remove-item', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get()

        if(data_carts.find((element : any) => element.name === value.name)) {
            try {
                const result = await db.collection('db_local_CART')
                    .doc({ name: value.name })
                    .delete()

                if(result) {
                    return {success : true, message : "Success Remove Item!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const removeVendorItems = createAsyncThunk(
    'carts/remove-allitemvendor', 
    async (value : any, { rejectWithValue }) => {
        const data_carts = await db.collection('db_local_CART').get() 
        
        if(data_carts.find((element : any) => element.vendor_name === value.vendor_name)) {
            const vendorItems = remove(data_carts, function(obj : any) {
                return obj.vendor_name !== value.vendor_name
            });
            try {
                const result = await db.collection('db_local_CART')
                    .set(vendorItems)

                if(result) {
                    return {success : true, message : "Success Remove Items!"}
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        }
    }
)

export const resetCart = createAsyncThunk(
    'carts/reset', 
    async (_, { rejectWithValue }) => {
        try {
            const removeDB = await db.collection('db_local_CART').delete()
            if(removeDB) {
                localStorage.removeItem('id_cart_pr')
                return {success : true, message : "Success Remove All Items!"}
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const savePurchaseRequest = createAsyncThunk(
    'save-pr', 
    async (value : any, { rejectWithValue }) => {
        try {
            const convert_groupBy : any = Object.entries(groupBy(value.value, (item) => {
                return JSON.stringify(item.vendorId)
            }));

            function sumDownPayment (data:any) {
                let down_payment = data[0].down_payment_setting / 100
                const totalPriceVendor = data.reduce((total : any, data : any) => {
                  return total + data.sub_total
                }, 0)
                let final_down_payment = totalPriceVendor * down_payment
                return Math.floor(final_down_payment);
            }

            let final_data : any = convert_groupBy.map(function(key : any) { 
                let vendor = key[1][0].vendor
                return { 
                    down_payment : sumDownPayment(key[1]),
                    vendor : vendor,
                    packages : {
                        items: key[1],
                    }
                }; 
            });

            let body = {
                code: userCredentials.costume_code === undefined || userCredentials.costume_code === "" ? userCredentials.company_code : userCredentials.costume_code,
                department : value.department,
                buyer: {
                    "_id": userCredentials.buyer_id,
                    "name": userCredentials.buyer_name, 
                    "address":  `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province}, 
                    ${value.address.postcode}`,
                    "phone": value.address.phone,
                    "email" : userCredentials.owner_email
                },
                address : {
                    "name" : value.address.name,
                    "addregitss":  `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province}, 
                    ${value.address.postcode}`,
                    "zip_code": value.address.postcode,
                    "city": `${value.address.city}`,
                    "phone": value.address.phone
                },
                vendors: final_data,
                total: Math.floor(value.total),
                createdBy: userCredentials.fullname,
            }

            let local_id_cart : any = localStorage.getItem('id_cart_pr')
            let id_cart = JSON.parse(local_id_cart)
            
            try {
                if(value.type === true && id_cart.id !== null && id_cart.id !== undefined) {        
                    let body_put = {
                        id: id_cart.id,
                        department : value.department,
                        code: userCredentials.costume_code === undefined || userCredentials.costume_code === "" ? userCredentials.company_code : userCredentials.costume_code,
                        buyer: {
                            "_id": userCredentials.buyer_id,
                            "name": userCredentials.buyer_name,
                            "address":  `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province},  ${value.address.postcode}`,
                            "phone": value.address.phone,
                            "email" : userCredentials.owner_email
                        },
                        address : {
                            "name" : value.address.name,
                            "address": `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province}, ${value.address.postcode}`,
                            "zip_code": value.address.postcode,
                            "city": `${value.address.city}`,
                            "phone": value.address.phone
                        },
                        vendors: final_data,
                        total: Math.floor(value.total),
                        createdBy: userCredentials.fullname,
                    }
                    
                    const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase-request`, body_put)
                    if(response.data.errors === null) {
                        let id_cart_pr = {
                            saved: true,
                            change: false,
                            id : response.data.data._id
                        }
                        localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
                        return {success : true, message : response.data.message, save: 'notauto'}
                    } else {
                        return rejectWithValue(response.data.message)
                    }
                } else if (value.type === "autosave" && id_cart.id !== null && id_cart.id !== undefined) {  
                    let body_put = {
                        id: value.id_pr,
                        department : value.department,
                        code: userCredentials.costume_code === undefined || userCredentials.costume_code === "" ? userCredentials.company_code : userCredentials.costume_code,
                        buyer: {
                            "_id": userCredentials.buyer_id,
                            "name": userCredentials.buyer_name,
                            "address":  `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province},  ${value.address.postcode}`,
                            "phone": value.address.phone,
                            "email" : userCredentials.owner_email
                        },
                        address : {
                            "name" : value.address.name,
                            "address": `${value.address.street}, ${value.address.village}, ${value.address.subdistrict}, ${value.address.city}, ${value.address.province}, ${value.address.postcode}`,
                            "zip_code": value.address.postcode,
                            "city": `${value.address.city}`,
                            "phone": value.address.phone
                        },
                        vendors: final_data,
                        total: Math.floor(value.total),
                        createdBy: userCredentials.fullname,
                    }
                    const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase-request`, body_put)

                    if(response.data.errors === null) {
                        let id_cart_pr = {
                            saved: true,
                            change: false,
                            id : response.data.data._id
                        }
                        localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
                        return { succes : true, messages : response.data.message, save: 'auto'}
                    } else {
                        return rejectWithValue(response.data.message)
                    }
                } else {
                    let headers : any = {
                        headers: { 
                            Authorization : `Bearer ${userCredentials.access_token}`
                        }
                    }
                    const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/purchase-request`, body, headers,)

                    if(response.data.errors === null) {
                        let id_cart_pr = {
                            saved: true,
                            change: false,
                            id : response.data.data._id
                        }
                        localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
                        return {success : true, message : response.data.message, save: 'notauto'}
                    } else {
                        return rejectWithValue(response.data.message)
                    }
                }
            } catch (error) {
                return rejectWithValue(error)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const submitPurchaseRequest = createAsyncThunk(
    'submit-pr', 
    async (_, { rejectWithValue }) => { 
        let local_id_cart : any = localStorage.getItem('id_cart_pr')
        let id_cart = JSON.parse(local_id_cart)
        try {
            let body = {
                lastStatus : "Open",
                user : userCredentials.fullname 
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase-request/submit/${id_cart.id}`, body)
            if(response.data.errors === null) {
                await db.collection('db_local_CART').delete()
                localStorage.removeItem('id_cart_pr')
                return {submit : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


export const postSubmitPurchaseRequest = createAsyncThunk(
    'submit-pr/post', 
    async (id : string, { rejectWithValue }) => { 
        try {
            let body = {
                lastStatus : "Open",
                user : userCredentials.fullname 
            }
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase-request/submit/${id}`, body)
            if(response.data.errors === null) {
                await db.collection('db_local_CART').delete()
                localStorage.removeItem('id_cart_pr')
                return {submit : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateItemPurchaseRequest = createAsyncThunk(
    'update-item-pr', 
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await axios.put(`${process.env.REACT_APP_API_SERVER}/purchase-request/addItem`, body)
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error) 
        }
    }
)

