import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
// import {
//   IStatePurchaseRequest
// } from './purchaseRequestTypes'
import { getAllDepartment, getOpenPurchaseRequest, getPurchaseRequestBySearch, getPurchaseRequestData, removePurchaseRequestData } from './reducers/purchaseRequestReducers';


const initialState: any = {
  data: [], 
  total_data : 0,
  loading : true,
  loaded : false,
  error : null,
  remove: false,
  loading_remove: false,
  department: [],
  loading_department: false,
  loaded_department: false,
  open_pr : [],
  loaded_pr : false,
  loading_open_pr : false,
};

export const getPurchaseRequest = createSlice({
  name: 'purchase-request',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get purchase request
      .addCase(getPurchaseRequestData.pending, (state) => {
        state.loading = true;
        state.remove = false;
        state.loaded = false;
      })
      .addCase(getPurchaseRequestData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.loaded = true;
        state.data = action.payload.data;
        state.total_data = action.payload.total.count
      })
      .addCase(getPurchaseRequestData.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // get purchase request
      .addCase(getOpenPurchaseRequest.pending, (state) => {
        state.loading_open_pr = true;
        state.loaded_pr = false;
      })
      .addCase(getOpenPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_open_pr = false;
        state.loaded_pr = true;
        state.open_pr = action.payload;
      })
      .addCase(getOpenPurchaseRequest.rejected, (state, action : any) => {
        state.loading_open_pr = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // get department
      .addCase(getAllDepartment.pending, (state) => {
        state.loading_department = true;
        state.loaded_department = false
      })
      .addCase(getAllDepartment.fulfilled, (state, action:any) => {
        state.loading_department = false;
        state.loaded_department = true
        state.department = action.payload.data;
      })
      .addCase(getAllDepartment.rejected, (state, action : any) => {
        state.loading_department = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // get purchase request
      .addCase(getPurchaseRequestBySearch.pending, (state) => {
        state.loading = true;
        state.remove = false;
        state.loaded = false;
      })
      .addCase(getPurchaseRequestBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.loaded = true;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseRequestBySearch.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // remove purchase request
      .addCase(removePurchaseRequestData.pending, (state) => {
        state.loading_remove = true;
      })
      .addCase(removePurchaseRequestData.fulfilled, (state, action:any) => {
        state.loading_remove = false;
        state.remove = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(removePurchaseRequestData.rejected, (state, action : any) => {
        state.loading_remove = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
      
  },
});

export default getPurchaseRequest.reducer;
