import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProductBySlug = createAsyncThunk(
    'product-slug/fetch', 
    async (slug: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/product/by/${slug}`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})


export const getCategoryByLevel = createAsyncThunk(
    'product-category/fetch', 
    async (level: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/category/tree`)
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})