import { createSlice } from '@reduxjs/toolkit';
import {
  RegisterState
} from './registerTypes'
import {registerAction} from './reducers/registerReducers'

const initialState: RegisterState = {
  register: false, 
  loading : false,
  error : null,
  success : null
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clearErrorMessageReg: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerAction.fulfilled, (state, action:any) => {
        state.loading = false;
        state.register = true;
        state.success = "Registration Successful! We've sent link to your email. Please verify your email to continue...";

      })
      .addCase(registerAction.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
      })
  },
});

export const { clearErrorMessageReg } = registerSlice.actions

export default registerSlice.reducer;
