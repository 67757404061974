import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getSearchVendor, getVendorListsData, postPreferredVendorList, removeVendorLists } from './reducers/vendorListsReducer';

const initialState: any = {
  data: [], 
  search_vendor : [],
  loading : true,
  loading_search : false,
  loaded_search : false,
  post_vendor : false,
  loading_post_vendor : false,
  remove_vendor : false,
  loading_remove_vendor : false,
};

export const getPreferredVendor = createSlice({
  name: 'vendor-lists',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // search vendor from db
      .addCase(getSearchVendor.pending, (state) => {
        state.loading_search = true;
        state.loaded_search = false;
      })
      .addCase(getSearchVendor.fulfilled, (state, action:any) => {
        state.loading_search = false;
        state.search_vendor = action.payload.data;
        state.loaded_search = true;
      })
      .addCase(getSearchVendor.rejected, (state, action : any) => {
        state.loading_search = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get vendor lists from db
      .addCase(getVendorListsData.pending, (state) => {
        state.loading = true;
        state.post_vendor = false;
        state.remove_vendor = false;
        state.search_vendor = []
      })
      .addCase(getVendorListsData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getVendorListsData.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
     // post vendor list
      .addCase(postPreferredVendorList.pending, (state) => {
        state.loading_post_vendor = true;
      })
      .addCase(postPreferredVendorList.fulfilled, (state, action:any) => {
        state.loading_post_vendor = false;
        state.post_vendor = action.payload.data;
      })
      .addCase(postPreferredVendorList.rejected, (state, action : any) => {
        state.loading_post_vendor = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // reove vendor list
      .addCase(removeVendorLists.pending, (state) => {
        state.loading_remove_vendor = true;
      })
      .addCase(removeVendorLists.fulfilled, (state, action:any) => {
        state.loading_remove_vendor = false;
        state.remove_vendor = action.payload.data;
      })
      .addCase(removeVendorLists.rejected, (state, action : any) => {
        state.loading_remove_vendor = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export default getPreferredVendor.reducer;
