import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box, 
    Stack,
    Button,
    TextField,
    CircularProgress
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { postGoodReceiptNote } from '../reducers/goodReceiptNoteReducers';
import { userCredentials } from '../../../../utilities/config';
import { useHistory } from 'react-router-dom';

const styleTableRow = () => {
  return {fontSize: '12px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const TableItemGRN : React.FC<any> = ({ 
  element,
  setDataGRN,
  dataGRN,
  dataOrders
}) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)

  const onChangeReceived = (row : any, qty : any, items : any) => {
    let copy_array = [...items.items]
    const newData = copy_array.map((obj : any) => {
      if(obj._id === row._id)
          return {
            ...obj,
            received: parseFloat(qty),
            sub_total_received : (parseFloat(qty) * row.discount_price)
          }
      return obj
    });
    let newItems = {
      items: newData,
      lastStatus: items.lastStatus,
      payment_terms: items.payment_terms,
      statuses: items.statuses,
      total: items.total,
      updated: items.updated,
      _id: items._id
    }
    setDataGRN({...dataGRN, data : newItems})
  
  }

  const onChangeNoteText = (row : any, note : any, items : any) => {
    let copy_array = [...items.items]
      const newData = copy_array.map((obj : any) => {
        if(obj._id === row._id)
           return {
             ...obj,
             note: note,
           }
        return obj
      });
      let newItems = {
        items: newData,
        lastStatus: items.lastStatus,
        payment_terms: items.payment_terms,
        statuses: items.statuses,
        total: items.total,
        updated: items.updated,
        _id: items._id
      }
      setDataGRN({...dataGRN, data : newItems})
  }

  const onSubmitGRN = (e : any) => {
    e.preventDefault()
    let data_grn = {
      "received": {
        "code": "DN-Code",
        "date": new Date(),
        "name": userCredentials.fullname
      },
      "vendor": dataOrders.vendor,
      "items": element.items,
      "reference_doc": dataOrders.reference_doc
    }
    console.log(data_grn, 'data grn')
    // dispatch(postGoodReceiptNote(data_grn))
  }

  function sumSubPrice (data:any) {
    const totalPriceVendor = data.items.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceVendor;
  }

  return (
    <>
    { !dataGRN.grn ? null :
      <Box>
          <Box mb={2}>
            <form onSubmit={onSubmitGRN}>
            <TableContainer sx={{ backgroundColor: '#fff', border: '1px solid #ddd' }}>
              <Table aria-label="simple table"  > 
                  <TableHead>
                    <TableRow >
                        <TableCell width="20px" sx={styleTableRow()}>NO</TableCell>
                        <TableCell width="350px" sx={styleTableRow()}>NAME</TableCell>
                        <TableCell width="150px" sx={styleTableRow()}>RETAIL PRICE</TableCell>
                        <TableCell width="100px" sx={styleTableRow()}>DISC %</TableCell>
                        <TableCell width="180px" sx={styleTableRow()}>PRICE AFTER DISCOUNT</TableCell>
                        <TableCell width="80px" sx={styleTableRow()}>PPN</TableCell>
                        <TableCell width="80px" sx={styleTableRow()}>QTY</TableCell>
                        <TableCell width="180px" sx={styleTableRow()}>SUB TOTAL + PPN</TableCell>
                        <TableCell width="120px" sx={styleTableRow()}>GRN QTY</TableCell> 
                        <TableCell width="250px" sx={styleTableRow()}>NOTE</TableCell> 
                    </TableRow>
                  </TableHead> 
                  <TableBody >
                  { element.items.map((row :any, i : any) => (
                    <TableRow key={i} >
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {i + 1}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        <Box 
                          sx={{ cursor: 'pointer', color: 'blue' }}
                          onClick={() => {
                            history.push({
                              pathname: "/dashboard/products",
                              search: `?page=detail-product`, 
                              state : {
                                slug : row.slug
                              }
                            })
                          }}
                        >
                          {row.name}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.retail_price.toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                      {row.discount * 100}%
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.discount_price.toLocaleString()}
                      </TableCell>
                      <TableCell>
                          <Box >
                            { row.tax ? row.tax.title : null }
                          </Box> 
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        {row.quantity}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        RP. {row.sub_total.toLocaleString()}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        <TextField
                          label="QTY"
                          value={row.received}
                          onChange={(e : any) => {
                            onChangeReceived(row, e.target.value, element)
                          }}
                          type="number"
                          size="small"
                          inputProps={{ min: 0}}
                          required
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" sx={styleTableColumn()}>
                        <TextField
                          label="Note"
                          onChange={(e) => onChangeNoteText(row, e.target.value, element)}
                          type="text"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <Paper>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" p={2} >
                  <Stack flexDirection="row">
                    <Box fontSize="13px">
                      Sub Total + PPN :
                    </Box>
                    <Box>
                      <Box pl={2}><h5>Rp. {sumSubPrice(element).toLocaleString()}</h5></Box>
                    </Box>
                  </Stack>
                  <Box>
                    <Button 
                      variant="contained" 
                      color="secondary" 
                      type="submit"
                      size='small'
                    >
                      { store_purchaseorders.loading_grn ? 
                          <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                          "Submit INBOUND"
                      }
                    </Button>
                  </Box>
                </Stack>
                
              </Paper>
            </Box>
            </form>
        </Box>
      </Box> }
    </>
    )
}

export default TableItemGRN