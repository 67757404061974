import { useEffect, useState } from 'react';
import {
    Paper,
    Box,
    TextField,
    Button,
    CircularProgress, 
    Alert
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { loginAction } from './reducers/loginReducers';
import { UserFormSubmit } from './loginTypes'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import logo from '../../../assets/img/logo-color.png'

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
  })
  .required();


const LoginSuperAdmin = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state : RootState) => state.login )

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserFormSubmit>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: UserFormSubmit): void => {
    dispatch(loginAction(data))
  }


  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /* istanbul ignore next */
  useEffect(() => {
    if(auth.login) {
      setTimeout(() => {
        window.location.href = '/login/select-company'
      }, 1000);
    } 
  }, [auth.login]);
  
  return (
    <div className="container-box">
        <Paper sx={{ margin: 5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '4rem 2rem',
            }}
          >
            <Box>
                <a href='/'>
                    <img src={logo} alt="logo" width="150px" />
                </a>
            </Box>

            <Box className='name-login' color="primary" sx={{ textAlign: 'center', paddingBottom: 5 }}>
              <h1>Log in</h1> 
              <p>Sign in to super admin account.</p>
            </Box>

            <Box sx={{ mt: 1, maxWidth : 450 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                {...register('email', { required: true })}
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <TextField
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                {...register('password', { required: true })}
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              { auth.error === null ? null :
              <Box pt={1}>
                <Alert severity="error">{auth.error}</Alert>
              </Box> }

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="medium"
                color="primary"
              >
                Sign In 
                
                { /* istanbul ignore next */
                  auth.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
              </Button>
            </form>
            </Box>
          </Box>
        </Paper>
      </div>
    )
}

export default LoginSuperAdmin;
