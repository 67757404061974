import { Box, Skeleton } from '@mui/material';

const TotalSales : React.FC<any> = ({ data }) => {

    const stylesHeaderToday : any = {
        border: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 2,
        fontWeight : 500,
        backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`
    }
    const stylesHeaderMonth : any = {
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        borderBottom: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`
    }
    const stylesHeaderYear : any = {
        borderRight: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderBottom: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
        backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`
    }
    const stylesColumn : any  = {
        border: '1px solid #000', 
        textAlign: 'center',
        height : '40px',
        pt: 1,
        fontWeight : 500,
    }

    return (
        <Box pt={2}>
            { data.loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
                <Box>
                    <Box sx={stylesHeaderToday} >Total Purchase Today</Box>
                    { data.data.purchase_today ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.purchase_today.length ? data.data.purchase_today[0].totalAmount.toLocaleString() : 0}</Box> : null }
                    <Box sx={stylesHeaderMonth} >Total Purchase This Month</Box>
                    { data.data.purchase_month ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.purchase_month.length ? data.data.purchase_month[0].totalAmount.toLocaleString() : 0}</Box> : null }
                    <Box sx={stylesHeaderYear} >Total Purchase This Year</Box>
                    { data.data.purchase_year ? 
                    <Box sx={stylesColumn} fontSize={15} fontWeight={600}>Rp. {data.data.purchase_year.length ? data.data.purchase_year[0].totalAmount.toLocaleString() : 0}</Box> : null }
                </Box>
            }
        </Box>
    )
}

export default TotalSales
