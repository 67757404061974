import { Stack, Box, Button, Paper, TextField, Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import AddressForm from '../../../../components/AddressForm';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getCompanyCategoryBuyer, postCompanyDetail } from '../../step_register/reducers/stepRegisterReducers';
import { userCredentials } from '../../../../utilities/config';
// import { getProfileCompany } from '../reducers/profilesReducers';
import crypto from 'crypto-js'; 

const CompanyInfo : React.FC<any> = ({ data }) => {

    const dispatch = useDispatch()
    const state_stepregister = useSelector((state : RootState) => state.step_register) 

    const [valueUpdateInfo, setValueUpdateInfo] = useState<any>({
        business_name : "",
        phone : "",
        whatsapp: "",
        name : "",
        website: "",
        instagram: "",
        facebook: "",
        twitter : "",
        costume_code : ""
    });

    const [valueAddress, setValueAddress] = useState<any>({
        country : "Indonesia",
        country_id : "1",
        error_country : false,
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        zipcode_id : "",
        error_zipcode : false,
        street : "",
        id: null,
        is_default : false
    });

    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    // state for category company
    const [optionsCategoryCompany, setOptionsCategoryCompany] = useState<any[]>([]);
    const [selectedCategoryCompany, setSelectedCategoryCompany] = useState<any>([]);
    const [errorCategoryCompany, setErrorCategoryCompany] = useState<boolean>(false);

    const [updateCredential, setUpdateCredential] = useState<any>(userCredentials);
    const [updated, setUpdated] = useState(false);

     /* istanbul ignore next */
     const handleChangeCategoryCompany = (value: any) : void => {
        setErrorCategoryCompany(false)
       setSelectedCategoryCompany(value)
    }

    const onChangeValue = (e:any) => {
        setValueUpdateInfo({...valueUpdateInfo, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueUpdateInfo({...valueUpdateInfo, 
            business_name : "",
            costume_code : "",
            phone : "",
            whatsapp: "",
            name : "",
            website: "",
            instagram: "",
            facebook: "",
            twitter : ""
        })
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })
    }

    // console.log(userCredentials, 'userCredentials')
    const onClickSubmit = (e : any) => {
        e.preventDefault()
        let update_detail  = {
            _id: userCredentials.buyer_id,
            category: selectedCategoryCompany.value === undefined ? "" : selectedCategoryCompany.value,
            name : valueUpdateInfo.business_name,
            costume_code : valueUpdateInfo.costume_code.replace(/\s/g, ''),
        }
        let update_contact  = {
            _id: userCredentials.buyer_id,
            country: valueAddress.country,
            country_id: valueAddress.country_id,
            province: valueAddress.province,
            province_id: valueAddress.province_id,
            city: valueAddress.city,
            city_id: valueAddress.city_id,
            subdistrict: valueAddress.subdistrict,
            subdistrict_id: valueAddress.subdistrict_id,
            village: valueAddress.village,
            village_id: valueAddress.village_id,
            postcode: valueAddress.zipcode,
            postcode_id: valueAddress.zipcode_id,
            street: valueAddress.street,
            phone : valueUpdateInfo.phone,
            whatsapp : valueUpdateInfo.whatsapp,
            technical_contact : valueUpdateInfo.name,
        }
        let update_socmed  = {
            _id: userCredentials.buyer_id,
            website : valueUpdateInfo.website,
            instagram : valueUpdateInfo.instagram,
            facebook : valueUpdateInfo.facebook,
            twitter : valueUpdateInfo.twitter
        }
        if(open.name === "Detail Informasi") {
            dispatch(postCompanyDetail(update_detail))
        } else if (open.name === "Contact & Address") {
            dispatch(postCompanyDetail(update_contact))
        } else {
            dispatch(postCompanyDetail(update_socmed))
        }
    }

    // useEffect(() => {
    //     if(state_stepregister.company_detail) {
    //         handleClose()
    //         dispatch(getProfileCompany())
    //     }
    //     // eslint-disable-next-line
    // }, [state_stepregister.company_detail])

    useEffect(() => {
        dispatch(getCompanyCategoryBuyer())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(state_stepregister.loaded_company_category) {
            let category = state_stepregister.company_category
            let array_category = []
            for(let element of category) {
                array_category.push({
                    label : element.category,
                    value : element.category
                })
            }
            setOptionsCategoryCompany(array_category)
        }
        // eslint-disable-next-line
    }, [state_stepregister.loaded_company_category]);


    useEffect(() => {
        if(state_stepregister.company_detail) {
            setUpdateCredential({...updateCredential, 
                costume_code : valueUpdateInfo.costume_code,
            })
            setUpdated(true)
        }
        // eslint-disable-next-line
    }, [state_stepregister.company_detail])

    useEffect(() => {
        if(updated) {
            const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(updateCredential), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
            localStorage.setItem('_?credentialss', saveToLocalStorage)
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        // eslint-disable-next-line
    }, [updated]);

    return (
        <Stack>
        { data.loading ? "Fetching data.." :
            <Box>
                { data.data === null ? "Company Info not found" : 
                
                <Stack mt={1}>
                    <Stack flexDirection="row" alignItems="center">
                        <Box fontWeight="700" fontSize={22}>Detail Information</Box>
                        <Box pl={2}>
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={() => { 
                                    setValueUpdateInfo({...valueUpdateInfo, 
                                        business_name : data.data.name, 
                                        costume_code : data.data.costume_code ? data.data.costume_code : ""  })
                                    setSelectedCategoryCompany({ value : data.data.category, label : data.data.category })
                                    handleOpen('Detail Informasi')
                                }}
                            >Edit</Button>
                        </Box>
                    </Stack>
                    <Stack mt={1}>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Legal Name</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.legal_name}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Business Name</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.name === "" ? "-" : data.data.name}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Company Code</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.company_code}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Custom Code</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.costume_code ? data.data.costume_code : "N/A"}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Category</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.category === "" ? "-" : data.data.category}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Type</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.type === "" ? "-" : data.data.type}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Status</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.verification_status}</Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack pt={2} flexDirection="row" alignItems="center">
                        <Box fontWeight="700" fontSize={22}>Contact</Box>
                        <Box pl={2}>
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={() => { 
                                    setValueAddress({...valueAddress, 
                                        country : data.data.country,
                                        country_id : data.data.country_id,
                                        error_country : false,
                                        province : data.data.province,
                                        province_id : data.data.province_id,
                                        error_province : false,
                                        city : data.data.city,
                                        city_id : data.data.city_id,
                                        error_city : false,
                                        subdistrict : data.data.subdistrict,
                                        subdistrict_id : data.data.subdistrict_id,
                                        error_subdistrict : false,
                                        village : data.data.village,
                                        village_id : data.data.village_id,
                                        error_village : false,
                                        zipcode : data.data.postcode,
                                        zipcode_id : data.data.postcode_id,
                                        error_zipcode : false,
                                        street : data.data.street,
                                    })
                                    setValueUpdateInfo({...valueUpdateInfo, 
                                        phone : data.data.phone,
                                        whatsapp: data.data.whatsapp,
                                        name : data.data.technical_contact,
                                    })
                                    handleOpen('Contact & Address')
                                }}
                            >Edit</Button>
                        </Box>
                    </Stack>
                    <Stack mt={1}>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Contact Name</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.technical_contact === "" ? "-" : data.data.technical_contact}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Main Phone Number</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.phone}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Mobile Phone Number</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.whatsapp}</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Address</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.street}, {data.data.village}, {data.data.subdistrict}, {data.data.city}, {data.data.postcode}, {data.data.country}</Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack pt={2} flexDirection="row" alignItems="center">
                        <Box fontWeight="700" fontSize={22}>Social Media</Box>
                        <Box pl={2}>
                            <Button
                                size='small'
                                variant='outlined'
                                onClick={() => {
                                    setValueUpdateInfo({
                                        ...valueUpdateInfo, 
                                        website : data.data.website,
                                        instagram : data.data.instagram,
                                        facebook : data.data.facebook,
                                        twitter : data.data.twitter,
                                    })
                                    handleOpen('Social Media')
                                }}
                            >Edit</Button>
                        </Box>
                    </Stack>
                    <Stack mt={1}>
                        <Grid container rowSpacing={1}>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Website</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.website === "" ? "-" : data.data.website }</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Instagram</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.instagram === "" ? "-" : data.data.instagram }</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Facebook</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.facebook === "" ? "-" : data.data.facebook }</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={2} xl={2}>
                                <Box>Twitter</Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={10} xl={10}>
                                <Box fontWeight="600">{data.data.twitter === "" ? "-" : data.data.twitter }</Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    
                </Stack> }
                
            </Box>
        }

            <Dialog open={open.open} maxWidth="md" fullWidth={true}  >
                { open.name === "Detail Informasi" ? 
                
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>Update Detail Information</DialogTitle>
                    <Box sx={{ minHeight: 400 }}>
                        <Paper sx={{ border: '1px solid #ccc', m: 2 }}>
                            <Stack p={3}>
                                <Grid container >
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Business Name</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                label="Name"
                                                type="text"
                                                value={valueUpdateInfo.business_name}
                                                onChange={onChangeValue}
                                                name="business_name"
                                                size="small"
                                                // required
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Company Category</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box pt={1} pb={1}>
                                        <Select
                                            placeholder="Select Category Company"
                                            value={selectedCategoryCompany}
                                            isSearchable={true}
                                            options={state_stepregister.loaded_company_category && optionsCategoryCompany}
                                            onChange={handleChangeCategoryCompany}
                                            id="select-style-cat-update"
                                        />
                                        </Box>
                                        { 
                                        errorCategoryCompany ? <div className="error-p"><p>Category is required</p></div> : null }
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={1}><h4>Custom Code</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <Box>
                                            <TextField
                                                margin="dense"
                                                fullWidth
                                                label="Custom Code"
                                                type="text"
                                                value={valueUpdateInfo.costume_code}
                                                onChange={onChangeValue}
                                                name="costume_code"
                                                size="small"
                                                // required
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Paper>
                        <Box pl={2} sx={{ color: 'red', cursor: 'pointer' }}>
                            <a href="mailto: marketing@mpindo.id">If you want to modify other fields please contact us.</a>
                        </Box>
                    </Box>


                    <Box m={2}>
                        <DialogActions>
                            <Button onClick={handleClose} color="error" variant="outlined">Cancel</Button>
                            <Button variant="contained" type="submit">
                                { state_stepregister.loading_comp_detail ? "Loading" : "Save" } 
                            </Button>
                        </DialogActions>
                    </Box>
                </form> 
               : 
                open.name === "Contact & Address" ? 
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>Edit {open.name}</DialogTitle>
                        <DialogContent>
                            <Paper sx={{ border: '1px solid #ccc' }}>
                            <Stack p={3}>
                                <Grid container >
                                <Grid item xl={3} lg={3} xs={12}>
                                    <Box pt={1}><h4>Contact Name</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={9} xs={12}>
                                    <Box>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            label="Name"
                                            type="text"
                                            value={valueUpdateInfo.name}
                                            onChange={onChangeValue}
                                            name="name"
                                            size="small"
                                            // required
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xl={3} lg={3} xs={12}>
                                    <Box pt={1}><h4>Main Phone Number</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={9} xs={12}>
                                    <Box>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={valueUpdateInfo.whatsapp}
                                            onChange={(e) => setValueUpdateInfo({...valueUpdateInfo, whatsapp : e})}
                                            defaultCountry="ID"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xl={3} lg={3} xs={12}>
                                    <Box pt={1}><h4>Mobile Phone Number</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={9} xs={12}>
                                    <Stack>
                                        <Box>
                                            <PhoneInput
                                                placeholder="Enter hotline number"
                                                value={valueUpdateInfo.phone}
                                                onChange={(e) => setValueUpdateInfo({...valueUpdateInfo, phone : e})}
                                                defaultCountry="ID"
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                                </Grid>
                                <AddressForm 
                                    setValueAddress={setValueAddress}
                                    valueAddres={valueAddress}
                                />
                            </Stack>
                            </Paper>
                        </DialogContent>
                    <Box m={2}>
                        <DialogActions>
                            <Button onClick={handleClose} color="error" variant="outlined">Cancel</Button>
                            <Button variant="contained" type="submit">
                            { state_stepregister.loading_comp_detail ? "Loading" : "Save" } 
                            </Button>
                        </DialogActions>
                    </Box>
                </form> : 
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>Edit {open.name}</DialogTitle>
                        <DialogContent>
                            <Paper sx={{ border: '1px solid #ccc' }}>
                            <Stack p={3}>
                            <Grid container>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Website</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueUpdateInfo.website}
                                            margin="dense"
                                            fullWidth
                                            name="website"
                                            placeholder="www.websitecompany.com"
                                            type="website"
                                            id="website"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Instagram</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}> 
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueUpdateInfo.instagram}
                                            margin="dense"
                                            fullWidth
                                            name="instagram"
                                            placeholder="www.instagram.com/accountinstagram"
                                            type="instagram"
                                            id="instagram"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Facebook</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueUpdateInfo.facebook}
                                            margin="dense"
                                            fullWidth
                                            name="facebook"
                                            placeholder="www.facebook.com/accountfacebook"
                                            type="facebook"
                                            id="facebook"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xl={3} lg={4} xs={12}>
                                        <Box pt={2}><h4>Twitter</h4></Box>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={12}>
                                        <TextField
                                            onChange={onChangeValue}
                                            value={valueUpdateInfo.twitter}
                                            margin="dense"
                                            fullWidth
                                            name="twitter"
                                            placeholder="www.twitter.com/accounttwitter"
                                            type="twitter"
                                            id="twitter"
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            </Paper>
                        </DialogContent>

                    <Box m={2}>
                        <DialogActions>
                            <Button onClick={handleClose} color="error" variant="outlined">Cancel</Button>
                            <Button variant="contained" type="submit">
                            { state_stepregister.loading_comp_detail ? "Loading" : "Save" } 
                            </Button>
                        </DialogActions>
                    </Box>
                </form> 
                }
            </Dialog>
        </Stack>
    )
}

export default CompanyInfo
