import React from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Box, 
    Stack
} from '@mui/material';
import { useHistory } from 'react-router-dom';


const styleTableRow = () => {
  return {fontSize: '12px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const TableItem : React.FC<any> = ({ data }) => {
  const history = useHistory()

  function backgroundColorStyle (row : any) {
    if(row.quantity === row.received) {
      return "#1bc5bd"
    } else {
      return "#ffc866"
    }
  }

  return (
    <>
    <Box>
      { data.length === 0  ? `Data not found!` :
      <Stack>
        <Box>
          <TableContainer sx={{ backgroundColor: '#fff', border: '1px solid #ddd' }}>
                <Table aria-label="simple table"  > 
                    <TableHead >
                      <TableRow >
                          <TableCell width="20px"  sx={styleTableRow()}>NO</TableCell>
                          <TableCell width="250px" sx={styleTableRow()}>NAME</TableCell>
                          <TableCell width="150px" sx={styleTableRow()}>RETAIL PRICE</TableCell>
                          <TableCell width="100px" sx={styleTableRow()}>DISC</TableCell>
                          <TableCell width="200px" sx={styleTableRow()}>PRICE AFTER DISCOUNT </TableCell>
                          <TableCell width="70" sx={styleTableRow()}>PPN</TableCell>
                          <TableCell width="70" sx={styleTableRow()}>QTY</TableCell>
                          <TableCell width="150px" sx={styleTableRow()}>SUB TOTAL + PPN</TableCell>
                          <TableCell width="80px" sx={styleTableRow()}>RECEIVED</TableCell>
                          <TableCell width="180px" sx={styleTableRow()}>RECEIVED SUB TOTAL + PPN</TableCell>
                          
                      </TableRow>
                    </TableHead> 
                    { data.map((row :any, indexItem : any) => (
                    <TableBody key={indexItem} style={{ backgroundColor: `${backgroundColorStyle(row)}`}}>
                      <TableRow >
                        <TableCell sx={styleTableColumn()}>
                          {indexItem + 1}
                        </TableCell>
                        <TableCell sx={styleTableColumn()}> 
                          <Box 
                            sx={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => {
                              history.push({
                                pathname: "/dashboard/products",
                                search: `?page=detail-product`, 
                                state : {
                                  slug : row.slug
                                }
                              })
                            }}
                          >
                            {row.name}
                          </Box>
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          RP. {row.retail_price.toLocaleString()}
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          {row.discount * 100}%
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          RP. {row.discount_price.toLocaleString()}
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                            <Box >
                              { row.tax ? row.tax.title : null }
                            </Box> 
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          {row.quantity}
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          RP. {row.sub_total.toLocaleString()}
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                          <Box sx={{ color: row.quantity !== row.received ? 'red' : '' }}>{row.received}</Box>
                        </TableCell>
                        <TableCell sx={styleTableColumn()}>
                        <Box sx={{ color: row.quantity !== row.received ? 'red' : '' }}>RP. {row.sub_total_received.toLocaleString()}</Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    ))}
                </Table>
              </TableContainer>
        </Box>
      </Stack>
      }
    </Box>
    </>
    )
}

export default TableItem