import { useState } from 'react';
import { Stack, Button  } from '@mui/material';
import { Box } from '@mui/system'
import { useHistory } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Drawer from '@mui/material/Drawer';
import MenuPopOver from '../../../../components/MenuPopOver';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import DataTablePurchaseRequest from '../../purchase_requests/components/DataTablePurchaseRequest';

const LBase = require("localbase");
const db: any = new LBase.default("db");
db.config.debug = false


const PurchaseRequestOpen : React.FC<any> = () => {
  const [state, setState] = useState(false);
  const history = useHistory()

  const onClickCreateNewPR = async () => {
      const removeDB = await db.collection('db_local_CART').delete()
      if(removeDB) {
          history.push({
              pathname: "/dashboard/create/purchase-requests"
          })
      }
  }

  const toggleDrawer = (value : any) => {
    setState(value);
  }

  return (
      <div >
          <Box onClick={() => toggleDrawer(true)} sx={{display: 'flex', cursor: 'pointer'}}>
            <Stack flexDirection="row" pr={3} alignItems="center">  
                  <Box fontWeight={600} fontSize={15}>VIEW OPEN PR</Box>
                  <ArrowDropDownIcon style={{color: "#fff"}}/>
            </Stack>
          </Box>

        <Drawer
            anchor="bottom"
            open={state}
            onClose={() => toggleDrawer(false)}
            style={{ width: 600 }}
        >
          <Stack direction="row" justifyContent="flex-end" sx={{mr:6}}>
            <Box onClick={() => toggleDrawer(false)}>
              <ExpandCircleDownIcon 
                style={{ 
                  marginTop: 10,
                  color: '#000', 
                  cursor: 'pointer',
                  fontSize: 30 
                }}/>
            </Box>
          </Stack>
          
          <Stack pl={4} pr={4} pb={4} sx={{ minHeight: 550 }}>
            <Stack direction="row" justifyContent="space-between" pt={3} pb={4} >
                <Box>
                    <h3>Open Purchase Requests</h3>
                </Box>
                <Box sx={{display: 'flex'}}>
                    <MenuPopOver
                        name="Create New PR From Template"
                        color="primary"
                    />
                    <Box mr={2} />
                    <Button 
                        variant="contained" color="primary"
                        onClick={() => onClickCreateNewPR()}
                        size="small"
                    >
                        Create New PR
                    </Button>
                </Box>
            </Stack>
            <Stack>
              <DataTablePurchaseRequest filterOpen={true}/>
            </Stack>
          </Stack> 
        </Drawer>

    </div>
  );
}


export default PurchaseRequestOpen;