import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const getSearchVendor = createAsyncThunk(
    'search-vendor/get',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail`, {
                params : {
                    company_code : value
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const getVendorListsData = createAsyncThunk(
    'vendor-lists/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/myvendor`, {
                params : {
                    buyer_id : userCredentials.buyer_id
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const postPreferredVendorList = createAsyncThunk(
    'vendor-lists/post',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/mybuyer`, value , {headers})
            if(response.data.errors === null) {
                return {data : true, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);


export const removeVendorLists = createAsyncThunk(
    'vendor-lists/remove',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.delete(`${process.env.REACT_APP_API_SERVER}/myvendor`, {
                params : {
                    vendor_id : value.vendor_id,
                    buyer_id : value.buyer_id,
                },
                headers
            })
            if(response.data.errors === null) {
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);