import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Forgot from '../features_app/auth/forgot/Forgot';
import Login from '../features_app/auth/login/Login'
import Register from '../features_app/auth/register/Register';
import AccountPage from '../features_app/dashboard/account/AccountPage';
import VendorLists from '../features_app/dashboard/vendor_lists/VendorLists';
import CustomRole from '../features_app/dashboard/custom_role/CustomRole';
import FinancePage from '../features_app/dashboard/finance/FinancePage';
import PurchaseOrders from '../features_app/dashboard/purchase_orders/PurchaseOrders';
import ProfilePage from '../features_app/dashboard/profile/ProfilePage';
import StepRegister from '../features_app/dashboard/step_register/StepRegister';
import TeamsPage from '../features_app/dashboard/teams/TeamsPage';
import Error404 from '../features_app/error404/Error404';
import DashboardLayout from '../utilities/DashboardLayout';
import CreatePurchaseRequests from '../features_app/dashboard/purchase_requests/create/CreatePurchaseRequests';
import CreateNewTemplate from '../features_app/dashboard/templates/create/CreateNewTemplate';
import DetailPurchaseRequests from '../features_app/dashboard/purchase_requests/detail/DetailPurchaseRequests';
import DetailTemplates from '../features_app/dashboard/templates/detail/DetailTemplates';
import SettingPage from '../features_app/dashboard/setting/SettingPage';
import PaymentPage from '../features_app/dashboard/finance/invoice/payment/PaymentPage';
import PurchaseRequestPage from '../features_app/dashboard/purchase_requests/PurchaseRequestPage';
import TemplatesPage from '../features_app/dashboard/templates/TemplatesPage';
import MainProductPage from '../features_app/dashboard/productsearch/MainProductPage';
import MainInvoicePage from '../features_app/dashboard/finance/invoice/MainInvoicetPage';
import LoginSuperAdmin from '../features_app/auth/login_superadmin/Login';
import SelectCompany from '../features_app/dashboard/select_company/SelectCompany';
import GoodReceiptNote from '../features_app/dashboard/good_receipt_note/GoodReceiptNote';
import Department from '../features_app/dashboard/department/Department';
import ChatPage from '../features_app/dashboard/chat/ChatPage';
import MainHomePage from '../features_app/dashboard/home/MainIHomePage';
import ChartsPage from '../features_app/dashboard/reports/charts/ChartsPage';
import ChartsDownload from '../features_app/dashboard/reports/download/ChartsDownload';
import InvitationPage from '../features_app/dashboard/invitation/InvitationPage';
import ProductSearchVendor from '../features_app/dashboard/productsearch/ProductSearchVendor';

function AppRoute() {  
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/superadmin" component={LoginSuperAdmin} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={Forgot} />
          <DashboardLayout exact path="/login/select-company" component={SelectCompany} />
          <DashboardLayout exact path="/register/step" component={StepRegister} />
          <DashboardLayout exact path="/dashboard" component={MainHomePage} />
          <DashboardLayout exact path="/dashboard/purchase-orders" component={PurchaseOrders} />
          <DashboardLayout exact path="/dashboard/teams" component={TeamsPage} />
          <DashboardLayout exact path="/dashboard/purchase-requests" component={PurchaseRequestPage} />
          <DashboardLayout exact path="/dashboard/good-receipt-note" component={GoodReceiptNote} />
          <DashboardLayout exact path="/dashboard/create/purchase-requests" component={CreatePurchaseRequests} />
          <DashboardLayout exact path="/dashboard/detail/purchase-requests" component={DetailPurchaseRequests} />
          <DashboardLayout exact path="/dashboard/profile" component={ProfilePage} />
          <DashboardLayout exact path="/dashboard/custom-role" component={CustomRole} />
          <DashboardLayout exact path="/dashboard/department" component={Department} />
          <DashboardLayout exact path="/dashboard/account" component={AccountPage} />
          <DashboardLayout exact path="/dashboard/templates" component={TemplatesPage} />
          <DashboardLayout exact path="/dashboard/create/template" component={CreateNewTemplate} />
          <DashboardLayout exact path="/dashboard/detail/template" component={DetailTemplates} />
          <DashboardLayout exact path="/dashboard/finance/invoice" component={MainInvoicePage} />
          <DashboardLayout exact path="/dashboard/finance/payment" component={PaymentPage} />
          <DashboardLayout exact path="/dashboard/finance" component={FinancePage} />
          <DashboardLayout exact path="/dashboard/products" component={MainProductPage} />
          <DashboardLayout exact path="/dashboard/detail/vendor" component={ProductSearchVendor} />
          <DashboardLayout exact path="/dashboard/message" component={ChatPage} />
          <DashboardLayout exact path="/dashboard/setting" component={SettingPage} />
          <DashboardLayout exact path="/dashboard/invitation" component={InvitationPage} />
          <DashboardLayout exact path="/dashboard/vendor-list" component={VendorLists} />
          <DashboardLayout exact path="/dashboard/report-charts" component={ChartsPage} />
          <DashboardLayout exact path="/dashboard/report-download" component={ChartsDownload} />
          <Route exact path="*" component={Error404} />

        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AppRoute;
