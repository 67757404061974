import React, { useState, useEffect } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableItem from './TableItem';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { getGoodReceiptNote } from '../reducers/goodReceiptNoteReducers';
import { RootState } from '../../../../app/store';
import TableItemGRN from './TableItemGRN';
  

const CardOrder : React.FC<any> = () => {
    const dispatch = useDispatch()

    const store_grn = useSelector((state : RootState) => state.grn)

    const [dataGRN, setDataGRN] = useState<any>([]);
    const [loadedGRN, setLoadedGRN] = useState(false);

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChangeAccordion =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };


    const [dataUpdate, setDataUpdate] = useState<any>({
        grn : false,
        id : null,
        data : []
      });
    
      const onClickCreateGRN = (element : any) => {
        let itemsNew = []
        for(let k of element.items) {
          itemsNew.push({...k, received : k.quantity, sub_total_received : k.sub_total})
        }
        let newItems = {
          items: itemsNew,
          lastStatus: "",
          payment_terms: "",
          statuses: element.statuses,
          total: 0,
          updated: "",
          _id: element._id
        }
        setDataUpdate({...dataUpdate, grn: true, data : newItems,  id : element._id })
      }
    
    useEffect(() => {
        dispatch(getGoodReceiptNote())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_grn.loaded_data_grn) {
            setDataGRN(store_grn.data_grn)
            setLoadedGRN(true)
        }
        // eslint-disable-next-line
    }, [store_grn.loaded_data_grn]);

    function sumTotalPriceQty (data : any) {
        const totalPriceALl = data.reduce((total : any, data : any) => {
          return total + data.sub_total
        }, 0)
        return totalPriceALl;
    }
    function sumTotalPriceReceived (data : any) {
        const totalPriceALl = data.reduce((total : any, data : any) => {
          return total + data.sub_total_received
        }, 0)
        return totalPriceALl;
    }
    
    function checkStatusItem(params:any) {
        let check = false
        for(let ele of params) {
            if(ele.quantity === ele.received) {
                check = false
            } else {
                check = true
            }
        }
        return check
    }

    return (
    <Stack>
        {loadedGRN ? 
        <div>
            { dataGRN.length === 0 ? "Data not found" :
                <Box>
                    { dataGRN.map((val : any, i : any) => (
                        <Box mb={4} mt={4} key={i} >
                            <Paper elevation={3} sx={{ backgroundColor: '#fff'}}>
                                <Paper sx={{ backgroundColor: '#fff'}}>
                                    <Grid container spacing={2} justifyContent="space-between">
                                        <Grid item>
                                            <Box fontWeight="bold" pl={2} fontSize={14}> GRN Code : {val.received.code}</Box>
                                            <Box fontWeight="normal" pl={2} fontSize={13}> Date : {moment(val.reference_doc.date_order).format('ll')} - Base on Order ID : {val.reference_doc.code_po}</Box>
                                        </Grid>
                                        <Grid item>
                                            <Box pr={2}>
                                                <Stack direction="row" spacing={1}>
                                                    { val.lastStatus === "Good Receive Note Submit" ?
                                                    <Chip label="Waiting Vendor Confirmation" color="info" />
                                                    : val.lastStatus === "Ready to Invoice" ?
                                                    <Chip label="Approved by Vendor" color="primary" />
                                                    : val.lastStatus === "Invoicing" ?
                                                    <Chip label="Invoice Created" color="success" />
                                                    :
                                                    <Chip label="Need Revision GRN" color="error" /> }
                                                </Stack>
                                            </Box>
                                        </Grid> 
                                    </Grid>
                                
                                </Paper>
                                <Box p={2}>
                                    <Grid container spacing={3} >
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                                <Box pr={1} fontSize={13}>{val.vendor.name} </Box>
                                                <Button size="small" color="primary" variant="outlined">
                                                    Message
                                                </Button>
                                            </Box> 
                                            <Box pt={1} fontWeight="normal" fontSize={14}>
                                                <Box style={{width: '80%'}} fontSize={12}>{val.vendor.phone}</Box>
                                                <Box style={{width: '80%'}}>{val.vendor.address}</Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                            <Box fontWeight="bold" pt={1} fontSize={13}> 
                                                Total Price + PPN : Rp. {(sumTotalPriceQty(val.items)).toLocaleString()}
                                            </Box>
                                            { checkStatusItem(val.items) ? 
                                                <Box fontWeight="bold" pt={1} fontSize={13} color="red"> 
                                                    Total Price (GRN) + PPN : Rp. {(sumTotalPriceReceived(val.items)).toLocaleString()}
                                                </Box> 
                                            : null }
                                        </Grid>
                                    </Grid>
                                    
                                </Box>

                                <Box pt={2} >
                                    <Paper elevation={3} sx={{ backgroundColor: '#fff'}}>
                                        <Accordion sx={{ backgroundColor: '#fff'}}  expanded={expanded === `${i}`} onChange={handleChangeAccordion(`${i}`)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id={`panel1a-header-${val._id}`}
                                            >
                                                <Box fontWeight="bold" fontSize={14} >
                                                    Detail All Item ({val.items?.length})
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box p={1}>
                                                    <Box>
                                                        <Paper >
                                                            <Stack flexDirection="row" justifyContent="space-between" sx={{ p: 2 }}>
                                                            <Box>
                                                                <h5>Code Package: {val.reference_doc.code_package}</h5>
                                                                <h5>Payment Terms : {val.reference_doc.payment_term.name}</h5>
                                                            </Box>
                                                            { val.lastStatus === "Rejected" ?
                                                            <Box>
                                                            { dataUpdate.grn ?
                                                                <Button 
                                                                    variant="outlined" color="error" size="small"
                                                                    onClick={() => setDataUpdate({...dataUpdate, grn : false, data : [], id : null})}
                                                                >
                                                                    Cancel
                                                                </Button> 
                                                                :
                                                                <Button 
                                                                    variant="contained" 
                                                                    color="primary" 
                                                                    size="small"
                                                                    onClick={() => {
                                                                        onClickCreateGRN(val)
                                                                    }}
                                                                >
                                                                    CORRECTION GRN
                                                                </Button>
                                                            }
                                                            </Box> : null }
                                                            </Stack>
                                                        </Paper>
                                                    </Box>
                                                    { dataUpdate.grn ?
                                                    <TableItemGRN 
                                                        element={dataUpdate.data}
                                                        setDataGRN={setDataUpdate}
                                                        dataGRN={dataUpdate}
                                                        dataOrders={val}
                                                    /> :
                                                    <TableItem 
                                                        data={val.items} 
                                                        dataOrders={val}
                                                        status="hello"
                                                    /> 
                                                    }
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                </Box>

                            </Paper>
                        </Box>
                    ))}
                </Box>
            }
            
        </div> : "Loading..." }

    </Stack>
    )
}

export default CardOrder
