import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import {  getAllDepartment, postAddDepartment, updateDepartment } from './reducers/departmentReducers';

const initialState: any = {
  data: [], 
  loading : false,
  department_post : false,
  loading_department_post : false,
  department_update : false,
  loading_department_update : false,
};

export const stepRegisterVendor = createSlice({
  name: 'teams',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all department
      .addCase(getAllDepartment.pending, (state) => {
        state.loading = true;
        state.department_post = false;
        state.department_update = false;
      })
      .addCase(getAllDepartment.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllDepartment.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post department 
      .addCase(postAddDepartment.pending, (state) => {
        state.loading_department_post = true;
      })
      .addCase(postAddDepartment.fulfilled, (state, action:any) => {
        state.loading_department_post = false;
        state.department_post = action.payload.data;
      })
      .addCase(postAddDepartment.rejected, (state, action : any) => {
        state.loading_department_post = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // update department
      .addCase(updateDepartment.pending, (state) => {
        state.loading_department_update = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action:any) => {
        state.loading_department_update = false;
        state.department_update = action.payload.data;
      })
      .addCase(updateDepartment.rejected, (state, action : any) => {
        state.loading_department_update = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});


export default stepRegisterVendor.reducer;
