import {
    Box,
    TextField,
    Button, 
    Grid, 
    Link,
    Paper,
    CircularProgress
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { sendEmail } from './forgotSlice';
import { UserFormSubmit } from './forgotTypes'
import logo from '../../../assets/img/logo-color.png'

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required")
      .email("Email is invalid")
  })
  .required();


const Forgot = () => {

  const dispatch = useDispatch()
  const forgot = useSelector((state : RootState) => state.forgot)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserFormSubmit>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  

  const onSubmit = (data: UserFormSubmit): void => {
    dispatch(sendEmail(data))
  }

    return (
      <div className="container-box">
      <Paper sx={{ margin: 5 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '4rem 2rem',
          }}
        >
          <Box>
              <a href='/'>
                  <img src={logo} alt="logo" width="150px" />
              </a>
          </Box>

          <Box className='name-login' color="primary" sx={{ textAlign: 'center', paddingBottom: 5 }}>
            <h1>Forgot password</h1> 
            <p>Tell us your email so we can send you a reset link.</p>
          </Box>

          <Box sx={{ mt: 1, maxWidth : 450 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ width: 450 }}>
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                    {...register('email', { required: true })}
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  data-testid="submit-forgot"
                >
                  Submit
                  {
                    /* istanbul ignore next */
                    forgot.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> 
                  } 
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/">
                      Back to Sign In
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Paper>
      </div>
    )
}

export default Forgot;
