import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getDataInvoice = createAsyncThunk(
    'invoice/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Buyer?id=${userCredentials.buyer_id}`, {headers})

            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getDataInvoiceByCode = createAsyncThunk(
    'invoice/getbycode',
    async (code:string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Buyer`, {
                params : {
                    id : userCredentials.buyer_id,
                    searchTopic: 'invoice_number',
                    searchValue: `${code}`
                },
                headers
            })
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getInvoicesBySearch = createAsyncThunk(
    'invoice-search/get',
    async (search : any, { rejectWithValue }) => {
        try {
            if(search.dateSearch) {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Buyer`, {
                    params : {
                        id : userCredentials.buyer_id,
                        rangeTopic: search.rangeTopic,
                        rangeFrom: `${search.rangeFrom}T00:00:00`,
                        rangeTo: `${search.rangeTo}T23:59:59`,
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/invoice/Buyer`, {
                    params : {
                        id : userCredentials.buyer_id,
                        searchTopic: search.searchTopic,
                        searchValue: search.searchValue
                    },
                    headers
                })
                if(response.data.errors === null) { 
                    return {data : response.data.data, message : response.data.message}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
          } catch (err : any) {
            return rejectWithValue(err)
        }
    }
);

export const getDataAllBank = createAsyncThunk(
    'bank/get',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank`, {
                params: {
                    category : value.payment_type,
                    payment : value.payment_gateway
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const postCreatePaymentWithOY = createAsyncThunk(
    'vitual-account-oy/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            if(body.method === "VA") {
                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/oy/va`, body, {headers})
                if(response.data.status.message === "Success") {
                    return {data : true, response : response.data,  message : response.data.status.message}
                } else {
                    return rejectWithValue(response.data.status.message)
                }
            } else {
                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/manual-bank-transfer`, body, {headers})
                if(response.data.status.message === "Success") {
                    return {data : true, response : response.data,  message : response.data.status.message}
                } else {
                    return rejectWithValue(response.data.status.message)
                }
            }
            
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const postPayBankTransfer = createAsyncThunk(
    'bank-transfer-oy/post', 
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/oy/bank-transfer`, body, {headers})
            if(response.data.status) {
                window.open(response.data.url, '_blank');
                return {data : true,  message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const postCreatePaymentWithXendit = createAsyncThunk(
    'vitual-account-xendit/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            if(body.method === "VA") {
                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/xendit/va`, body, {headers})
                if(response.data.id) {
                    return {data : true, response : response.data,  message : "Success"}
                } else {
                    return rejectWithValue(response.data.message)
                }
            } else {
                const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/manual-bank-transfer`, body, {headers})
                if(response.data.id) {
                    return {data : true, response : response.data,  message : "Success"}
                } else {
                    return rejectWithValue(response.data.message)
                }
            }
            
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const checkStatusPayment = createAsyncThunk(
    'payment-status/get',
    async (id: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/transaction/Code/${id}`, {headers})
            if(response.data.errors === null) {
                return {response : response.data.data,  message : "Success"}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const uploadProofPayment = createAsyncThunk(
    'payment-proof/post',
    async (body: any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.post(`${process.env.REACT_APP_API_SERVER}/transaction/bukti-transfer`, body, {headers})
            if(response.data.errors === null) {
                return {data : true, response : response.data.data,  message : "Success"}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);


export const getBankVendor = createAsyncThunk(
    'bank-vendor/get',
    async (vendor_id: string, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/bank/vendor?vendor_id=${vendor_id}`, {headers})
            if(response.data.errors === null) {
                return {data : response.data.data,  message : "Success"}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

