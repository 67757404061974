import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    TextField,
    Button, 
    Grid, 
    Link, 
    CircularProgress,
    Alert 
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../app/store';
import { registerAction } from './reducers/registerReducers';
import { UserFormSubmit } from './registerTypes'
import { clearErrorMessageReg } from './registerSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import logo from '../../../assets/img/logo-color.png'

const validationSchema = yup
  .object({
    email: yup.string()
      .required("Email is required") 
      .email("Email is invalid"),
    password: yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password Must Contain 8 Characters and at least have one Special Case Character, One Lowercase and One Number"
      ),
    fullname: yup.string()
      .required("Full Name is required"),
    company_name: yup.string()
      .required("Company Name is required")
  })
  .required();


const Register = () => {
  const dispatch = useDispatch()
  const register_store = useSelector((state : RootState) => state.register )

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UserFormSubmit>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (data: UserFormSubmit): void => {
    dispatch(registerAction(data))
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /* istanbul ignore next */
  useEffect(() => {
    if(register_store.register) {
      setTimeout(() => {
          reset()
          window.location.href = "/"
        }, 5000);
    }
    // eslint-disable-next-line
  }, [register_store.register, reset]);

  
  useEffect(() => {
    if(register_store.error !== null) {
      setTimeout(() => {
        dispatch(clearErrorMessageReg())
      }, 3000);
    }
    // eslint-disable-next-line
  }, [register_store.error]);

  useEffect(() => {
    if(register_store.success !== null) {
      reset()
    }
    // eslint-disable-next-line
  }, [register_store.success]);

  return (
    <div className="container-box">
        <Paper sx={{ margin: 5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '4rem 2rem',
            }}
          >
            <Box>
                <a href='/'>
                    <img src={logo} alt="logo" width="150px" />
                </a>
            </Box>

            <Box className='name-login' color="primary" sx={{ textAlign: 'center', paddingBottom: 5 }}>
              <h1>Log in</h1> 
              <p>Sign in to your buyer account.</p>
            </Box>

            <Box sx={{ mt: 1, maxWidth : 450 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                error={!!errors.fullname}
                helperText={errors.fullname && errors.fullname.message}
                {...register('fullname', { required: true })}
                margin="normal"
                fullWidth
                id="fullname"
                label="Full Name"
                name="fullname"
                autoComplete="fullname"
              />
              <TextField
                error={!!errors.company_name}
                helperText={errors.company_name && errors.company_name.message}
                {...register('company_name', { required: true })}
                margin="normal"
                fullWidth
                id="company_name"
                label="Legal Company Name"
                name="company_name"
                autoComplete="company_name"
              />
              <TextField
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                {...register('email', { required: true })}
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <TextField
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                {...register('password', { required: true })}
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              { register_store.error === null ? null :
              <Box pt={1}>
                <Alert severity="error">
                  { register_store.error === "Invalid sign up" ? 
                    "User already registered, please use another email!" :
                    register_store.error
                  }
                </Alert>
              </Box> }

              { register_store.success === null ? null :
              <Box pt={1}>
                <Alert severity="success">
                  { register_store.success }
                </Alert>
              </Box> }

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="medium"
              >
                Sign Up
                
                { /* istanbul ignore next */
                  register_store.loading && <CircularProgress  size={30} color="inherit" style={{marginLeft: 10}} /> }
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item>
                  {"Already have an account? "}
                  <Link href="/">
                    Signin
                  </Link>
                </Grid>
              </Grid>
            </form>
            </Box>
          </Box>
        </Paper>
      </div>
    )
}

export default Register;
