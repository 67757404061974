import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getActivePurchaseOrders, getPurchaseOrdersBySearch, getPurchaseOrdersBySearchWaitingDP, getPurchaseOrdersData, getPurchaseOrdersDataDefault, getPurchaseOrdersDataNew, getPurchaseOrdersDataStatus, getPurchaseOrdersWaitingDP, postApprovalItem, postGoodReceiptNote, postProofDownPayment, postRejectItem, postSendEmail, getAllUsersTeams } from './reducers/purchaseOrdersReducers';


const initialState: any = {
  data: [], 
  count : 0,
  count_active : 0,
  loading_active : false,
  data_default : [],
  data_dp : [],
  count_dp : 0,
  loading_data_dp : false,
  loading_default : false,
  proof : false,
  loading_proof: false,
  loading : false,
  error : null,
  error_proof : null,
  item_pick_approve : false,
  loading_item_pick_approve: false,
  item_pick_reject : false,
  loading_item_pick_reject: false,
  grn : false,
  loading_grn: false,
  orders_new : [],
  loading_orders_new : false,
  data_status : [],
  loading_data_status : false,
  loading_data_dp_all : false,
  data_dp_all : [],
  email_value : [],
  loading_email : true,
  error_email : null,
  email_value_vendor: [],
  loading_email_vendor: [],
};

export const getPurchaseOrders = createSlice({
  name: 'purchase-orders',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get purchase orders from db
      .addCase(getPurchaseOrdersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPurchaseOrdersData.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseOrdersData.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      //  get purchase orders new
      .addCase(getPurchaseOrdersDataNew.pending, (state) => {
        state.loading_orders_new = true;
      })
      .addCase(getPurchaseOrdersDataNew.fulfilled, (state, action:any) => {
        state.loading_orders_new = false;
        state.orders_new = action.payload.data;
      })
      .addCase(getPurchaseOrdersDataNew.rejected, (state, action : any) => {
        state.loading_orders_new = false;
        swal("Error", `${action.payload}`, 'error')
      })


      //  get purchase orders new
      .addCase(getActivePurchaseOrders.pending, (state) => {
        state.loading_active = true;
      })
      .addCase(getActivePurchaseOrders.fulfilled, (state, action:any) => {
        state.loading_active = false;
        state.count_active = action.payload.count;
      })
      .addCase(getActivePurchaseOrders.rejected, (state, action : any) => {
        state.loading_active = false;
        swal("Error", `${action.payload}`, 'error')
      })

      //  get purchase orders status
      .addCase(getPurchaseOrdersDataStatus.pending, (state) => {
        state.loading_data_status = true;
      })
      .addCase(getPurchaseOrdersDataStatus.fulfilled, (state, action:any) => {
        state.loading_data_status = false;
        state.data_status = action.payload.data;
      })
      .addCase(getPurchaseOrdersDataStatus.rejected, (state, action : any) => {
        state.loading_data_status = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get purchase orders from db
      .addCase(getPurchaseOrdersWaitingDP.pending, (state) => {
        state.loading_data_dp = true;
      })
      .addCase(getPurchaseOrdersWaitingDP.fulfilled, (state, action:any) => {
        state.loading_data_dp = false;
        state.data_dp = action.payload.data;
        state.count_dp = action.payload.count_dp;
      })
      .addCase(getPurchaseOrdersWaitingDP.rejected, (state, action : any) => {
        state.loading_data_dp = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      //  get purchase orders search by down payment
      .addCase(getPurchaseOrdersBySearchWaitingDP.pending, (state) => {
        state.loading_data_dp_all = true;
      })
      .addCase(getPurchaseOrdersBySearchWaitingDP.fulfilled, (state, action:any) => {
        state.loading_data_dp_all = false;
        state.data_dp_all = action.payload.data;
      })
      .addCase(getPurchaseOrdersBySearchWaitingDP.rejected, (state, action : any) => {
        state.loading_data_dp_all = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get purchase orders from db
      .addCase(getPurchaseOrdersDataDefault.pending, (state) => {
        state.loading_default = true;
      })
      .addCase(getPurchaseOrdersDataDefault.fulfilled, (state, action:any) => {
        state.loading_default = false;
        state.data_default = action.payload.data;
      })
      .addCase(getPurchaseOrdersDataDefault.rejected, (state, action : any) => {
        state.loading_default = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // get purchase orders from db
      .addCase(getPurchaseOrdersBySearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPurchaseOrdersBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getPurchaseOrdersBySearch.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // post proof
      .addCase(postProofDownPayment.pending, (state) => {
        state.loading_proof = true;
      })
      .addCase(postProofDownPayment.fulfilled, (state, action:any) => {
        state.loading_proof = false;
        state.proof = action.payload.data;
      })
      .addCase(postProofDownPayment.rejected, (state, action : any) => {
        state.loading_proof = false;
        state.error_proof = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // post approval  item
      .addCase(postApprovalItem.pending, (state) => {
        state.loading_item_pick_approve = true;
      })
      .addCase(postApprovalItem.fulfilled, (state, action:any) => {
        state.loading_item_pick_approve = false;
        state.item_pick_approve = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .addCase(postApprovalItem.rejected, (state, action : any) => {
        state.loading_item_pick_approve = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post reject  item
      .addCase(postRejectItem.pending, (state) => {
        state.loading_item_pick_reject = true;
      })
      .addCase(postRejectItem.fulfilled, (state, action:any) => {
        state.loading_item_pick_reject = false;
        state.item_pick_reject = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .addCase(postRejectItem.rejected, (state, action : any) => {
        state.loading_item_pick_reject = false;
        swal("Error", `${action.payload}`, 'error')
      })
       // post grn
       .addCase(postGoodReceiptNote.pending, (state) => {
        state.loading_grn = true;
      })
      .addCase(postGoodReceiptNote.fulfilled, (state, action:any) => {
        state.loading_grn = false;
        state.grn = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 1000);

      })
      .addCase(postGoodReceiptNote.rejected, (state, action : any) => {
        state.loading_grn = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // post Email
      .addCase(postSendEmail.pending, (state) => {
        state.loading_email = true;
      })
      .addCase(postSendEmail.fulfilled, (state, action:any) => {
        state.loading_email = false;
        state.email_value = action.payload.data
        swal("success", `${action.payload.message}`, 'success')
      })
      .addCase(postSendEmail.rejected, (state, action:any) => {
        state.loading_email = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // 
      .addCase(getAllUsersTeams.pending, (state) => {
        state.loading_email_vendor = true;
      })
      .addCase(getAllUsersTeams.fulfilled, (state, action:any) => {
        state.loading_email_vendor = false;
        state.email_value_vendor = action.payload.data
      })
      .addCase(getAllUsersTeams.rejected, (state, action:any) => {
        state.loading_email_vendor = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
  },
});

export default getPurchaseOrders.reducer;
