import { useState, useEffect } from 'react';
import { Paper, Button, Stack, CircularProgress } from '@mui/material';
// import imgemptycart from '../../../../../assets/img/icon/empty_cart.svg'
import { Box } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../app/store';
import { 
  getLocalDBTemplate, 
  // resetCartTemplate, 
  saveTemplate,
} from '../reducers/createNewTemplateReducers';
import SnackBarAlert from './../../../../../components/SnackBarAlert';
import TableItemTemplate from './TableItemTemplate';
import { useLocation } from 'react-router-dom';
import { checkValidation } from '../../../../../utilities/userFeaturesValidation';
import swal from 'sweetalert';

const ItemListTemplate : React.FC<any> = ({
  templateName, setTemplateName
}) => {
  const dispatch = useDispatch()
  
  const store_carts = useSelector((store : RootState) => store.carts_template) 
  const location = useLocation()
  const data_location : any = location.state

  const [initialSaved, setInitialSaved] = useState<any>({});

  // console.log(initialSaved, 'initial saved')
  // const [templateName, setTemplateName] = useState("");
  const [openSnackBar, setopenSnackBar] = useState(false);
  const [dataCartProps, setDataCartProps] = useState<any[]>([]);
 
  const setInitialIDCart = () => {
    let id_cart_pr = {
      saved: false,
      change: false,
      id : null
    }
    let local_IDcart = localStorage.getItem('id_cart_pr')
    if(local_IDcart === null) {
      setInitialSaved(id_cart_pr)
      localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
    } else {
      setInitialSaved(JSON.parse(local_IDcart))
    }
  }

  const updateInitialIDCart = () => {
    let id_cart_pr = {
      saved: false,
      change: true,
      id : initialSaved.id
    }
    localStorage.setItem('id_cart_pr', JSON.stringify(id_cart_pr))
    setInitialIDCart()
  }

  useEffect(() => {
    dispatch(getLocalDBTemplate())
    setInitialIDCart()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(store_carts.success_save) {
      setTimeout(() => {
        window.location.href = "/dashboard/templates"
      }, 1500);
    }
  }, [store_carts.success_save]);

  useEffect(() => {
    if(!store_carts.loading) {
      setDataCartProps(store_carts.carts)
      setopenSnackBar(false)
    }
    // eslint-disable-next-line
  }, [store_carts.loading]);

  useEffect(() => {
    if(store_carts.success_add) {
      // setopenSnackBar(true)
      updateInitialIDCart()
      dispatch(getLocalDBTemplate())
    }
    // eslint-disable-next-line
  }, [store_carts.success_add]);

  useEffect(() => {
    if(store_carts.success_reset) {
      setopenSnackBar(true)
      dispatch(getLocalDBTemplate())
    }
    // eslint-disable-next-line
  }, [store_carts.success_reset]);

  useEffect(() => {
    if(store_carts.submit) {
      dispatch(getLocalDBTemplate())
    }
    // eslint-disable-next-line
  }, [store_carts.submit]);

  useEffect(() => {
    if(data_location !== undefined) {
      setTemplateName(data_location.data.name)
    }
    // eslint-disable-next-line
  }, [data_location]);

  function sumTotalPrice (data : any) {
    const totalPriceALl = data.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceALl;
  }

  function sumTotalQuantity (data : any) {
    const itemQuantity = data.reduce((total : any, data : any) => {
      return total + data.quantity
    }, 0)
    return parseFloat(itemQuantity);
  }

  function onClickSaveTemplate() {
    if(templateName === "") {
      swal('Error', "Template Name is Required!", "error")
    } else {
      dispatch(saveTemplate({ 
        value : dataCartProps,
        total : sumTotalPrice(dataCartProps),
        type : initialSaved.change,
        template_name : templateName,
        update : data_location !== undefined ? true : false,
        id : data_location !== undefined ? data_location.data._id : null,
      }))
    }
  }

  return (
      <div>
          { dataCartProps.length !== 0 ?
          <Stack mt={4}>
              <Stack flexDirection="row" alignItems="center">
                <Box pb={3}>
                  <h4>Total Items Quantity ({sumTotalQuantity(dataCartProps).toFixed(1)})</h4>
                </Box>
                {/* <Box pb={2} pl={2}>
                  <Button 
                      variant="outlined" 
                      color="error" 
                      size="small" 
                      onClick={() => dispatch(resetCartTemplate())}
                  >
                      Reset Template
                  </Button>
                </Box> */}
              </Stack>
              
              <div className="content-cart">
                  <TableItemTemplate 
                    dataCartProps={dataCartProps}
                  />
              </div>
          <Box pt={2} >
            <Paper elevation={4}>
              <div className="total-cart">
                <h4>Total Rp. {sumTotalPrice(dataCartProps).toLocaleString()}</h4>
                { checkValidation('61d6a2589c048bd8552bd904') ? 
                <div className="right-button">
                  <Button 
                    color="primary" 
                    size='small'
                    variant="contained" sx={{ mr:2, ml : 2 }}
                    onClick={onClickSaveTemplate}
                    disabled={initialSaved.saved ? true : false}
                  >
                    { store_carts.loading_save ? 
                      <div className="loading-button"> 
                        <p>Loading</p>  
                        <CircularProgress size={20} color="inherit" />
                      </div> : "Save Template"
                    }
                  </Button>
                </div> : null }
              </div>
            </Paper>
          </Box>
        </Stack> : null }
        <SnackBarAlert message={store_carts?.message_snackbar} initialState={openSnackBar} />
    </div>
  );
}


export default ItemListTemplate;