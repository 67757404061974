import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';
 
import { 
    getAllBroadcastMessage,  
    postEmailBlast
} from './reducers/invitationReducers'

const initialState: any = {
  data: [],
  loading : false,
  loading_create : false,
  create : false,
};

export const broadcastSlice = createSlice({
  name: 'broadcast-message',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllBroadcastMessage.pending.type] : (state) => {
        state.loading = true
        state.create = false
    },
    [getAllBroadcastMessage.fulfilled.type] : (state, action) => {
        state.loading = false
        state.data = action.payload.data
    },
    [getAllBroadcastMessage.rejected.type] : (state, action) => {
        state.loading = false
        swal('Error', `${action.payload}`, 'error')
    },
    [postEmailBlast.pending.type] : (state) => {
        state.loading_create = true
    },
    [postEmailBlast.fulfilled.type] : (state, action) => {
        state.loading_create = false
        state.create = true
        swal('Success', `${action.payload.message}`, 'success')
    },
    [postEmailBlast.rejected.type] : (state, action) => {
        state.loading_create = false
        swal('Error', `${action.payload.message}`, 'error')
    },
  }
});

export default broadcastSlice.reducer;