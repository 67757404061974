import { useState, useEffect } from 'react';
import { 
    Stack,
} from '@mui/material';
// import BreadCrumbs from '../../../components/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getOpenPurchaseRequest } from '../purchase_requests/reducers/purchaseRequestReducers';
import DialogOpen from './components/DialogOpen';
import { getTemplatesData } from '../templates/reducers/templatesReducers';
import AlgoliaSearchNew from './components/AlgoliaSearchNew';

function ProductSearchNew() {
    const dispatch = useDispatch()
    const store_purchaserequest = useSelector((state : RootState) => state.purchase_request)
    const store_template = useSelector((state : RootState) => state.template)

    const [dataTemplates, setDataTemplates] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});

    const [refetch, setRefetch] = useState(false);


    function getTemplates() {
        setTimeout( async () => {
            let filter = store_template.data
            let array = []
            for(let i of filter) {
                let items : any = []
                for(let k of i.vendors) {
                    for(let h of k.packages){
                        for(let k of h.items) {
                            items.push(k)
                        }
                    }
                }
                array.push({
                    buyerId: i.buyerId,
                    createdAt: i.createdAt,
                    createdBy: i.createdBy,
                    isDeleted: i.isDeleted,
                    name: i.name,
                    total: i.total,
                    updatedAt: i.updatedAt,
                    vendors: i.vendors,
                    items : items,
                    _id :i._id
                })
            }
            setDataTemplates(array)  
        }, 1000);
    }

    useEffect(() => {
        dispatch(getOpenPurchaseRequest())
        dispatch(getTemplatesData())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!store_template.loading) {
            getTemplates()
        }
        // eslint-disable-next-line
    }, [store_template.loading]);



    const addToCart = ( product : any, type : string) => {
        setItemSelected(product)
        if(type === "pr") {
            setOpen(true);
        } else {
            setOpenTemplate(true)
        }
    }


    return (
        <Stack pt={6} pl={1} pr={1} >
            <Stack pt={2} >
                <AlgoliaSearchNew
                    addToCart={addToCart}
                    refetch={refetch}
                    setRefetch={setRefetch}
                    vendor={false}
                />
            </Stack>

            <DialogOpen 
                dataList={store_purchaserequest.open_pr}
                itemSelected={itemSelected} 
                type="pr"
                open={open}
                setOpen={setOpen}
            />
            <DialogOpen 
                dataList={dataTemplates}
                itemSelected={itemSelected}
                type="template"
                open={openTemplate}
                setOpen={setOpenTemplate}
            />
        </Stack>
    )
}

export default ProductSearchNew;
