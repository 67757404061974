import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CreateNewTemplate from './create/CreateNewTemplate';
import DetailTemplates from './detail/DetailTemplates';
import Templates from './Templates';

function TemplatesPage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'create') {
            return <CreateNewTemplate/>
        } else if (active_page === 'detail') {
            return <DetailTemplates/>
        } else {
            return <Templates/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default TemplatesPage
