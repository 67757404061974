import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios'
import { userCredentials } from '../../../../utilities/config';

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }

export const fetchRolesCustom = createAsyncThunk(
    'Roles-Custom/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/role?flag=BUYER&buyer_id=${userCredentials.buyer_id}&default=true`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const fetchParentModules = createAsyncThunk(
    'parentmodules/fetch', 
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/module/parents?flag=BUYER`, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const postRolesCustom = createAsyncThunk(
    'Roles-Custom/post', 
    async (body : any, { rejectWithValue }) => {
        try {
            const response : any = await Axios.post(`${process.env.REACT_APP_API_SERVER}/role`, body, {headers})
            if(response.data.errors === null) { 
                return {data : response.data.data, message : response.data.message}
            } else {
                return rejectWithValue(response.data.message)
            }
        } catch (error) {
            return rejectWithValue(error)
        }
})

export const updateRolesCustom = createAsyncThunk(
  'Roles-Custom/update', 
  async (value : any, { rejectWithValue }) => {
      try {
        const response : any = await Axios.put(`${process.env.REACT_APP_API_SERVER}/role/${value._id}`, value.body, {headers})
        if(response.data.errors === null) { 
            return {data : response.data.data.data, message : response.data.message}
        } else {
            return rejectWithValue(response.data.message)
        }
      } catch (error) {
          return rejectWithValue(error)
      }
})

export const removeRolesCustom = createAsyncThunk(
  'Roles-Custom/delete', 
  async (value : any, { rejectWithValue }) => {
      try {
        const response : any = await Axios.delete(`${process.env.REACT_APP_API_SERVER}/role/${value._id}`, {headers})
        if(response.data.errors === null) { 
            return {data : response.data.data.data, message : response.data.message}
        } else {
            return rejectWithValue(response.data.message)
        }
      } catch (error) {
          return rejectWithValue(error)
      }
})


