import { createSlice } from '@reduxjs/toolkit';
import { getCategoryByLevel, getProductBySlug } from './reducers/productsReducers';
import swal from 'sweetalert'
// import { getAllUsersTeams } from './reducers/productsReducers';

const initialState: any = {
  product_detail: {},
  product_detail_exist : false,
  loading_product_detail : true,
  category : [],
  loading_category : false, 
  filter_categories : [],
  filter_breadcrumbs: [],
  comparation : false
};

export const getProductsReducer = createSlice({
  name: 'products',
  initialState, 
  reducers: {
    filterCategories: (state, action: any) => {
      state.filter_categories = action.payload
    },
    filterBreadcrumbs: (state, action: any) => {
      state.filter_breadcrumbs = action.payload
    },
    setRefectComparation: (state, action : any) => {
      state.comparation = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // get product by slug
      .addCase(getProductBySlug.pending, (state) => {
        state.loading_product_detail = true;
        state.product_detail_exist = false
        state.success_update_variant = false
        state.success_post_variant = false
      })
      .addCase(getProductBySlug.fulfilled, (state, action:any) => { 
        state.loading_product_detail = false;
        state.product_detail = action.payload.data;
        state.product_detail_exist = true
      })
      .addCase(getProductBySlug.rejected, (state, action : any) => {
        state.loading_product_detail = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // get caegory
      .addCase(getCategoryByLevel.pending, (state:any) => {
        state.loading_category = true;
      })
      .addCase(getCategoryByLevel.fulfilled, (state:any, action:any) => { 
        state.loading_category = false;
        state.category = action.payload.data;
      })
      .addCase(getCategoryByLevel.rejected, (state:any, action : any) => {
        state.loading_category = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export const { filterCategories, filterBreadcrumbs, setRefectComparation } = getProductsReducer.actions

export default getProductsReducer.reducer;
