import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { userCredentials } from '../../../../utilities/config';
import { getAllModulesMenu, getAllModulesPath } from '../../../../utilities/setModulesPath';
import crypto from 'crypto-js'; 

const headers = { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.id_token}` }

export const getAllBuyerCompany = createAsyncThunk(
    'buyer-list/get',
    async (_, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user-buyer?isOwner=true`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const getAllBuyerCompanyBySearch = createAsyncThunk(
    'buyer-search/get',
    async (value : any, { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user-buyer`, {
                params: {
                    isOwner: true,
                    buyer_name: value.buyer_name
                },
                headers
            })
            if(response.data.errors === null) {
                let message = response.data.message
                return {data : response.data.data, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);

export const loginAsBuyerCompany = createAsyncThunk(
    'login-as-buyer/get',
    async (value : any , { rejectWithValue }) => { 
        try {
            const response : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/user-buyer/${value}/admin`, {headers})
            if(response.data.errors === null) {
                let message = response.data.message
                let data = {
                    access_token : userCredentials.access_token, 
                    id_token : userCredentials.id_token, 
                    expires_in : userCredentials.expires_in,
                    email : response.data.data.user_data.email,
                    fullname : response.data.data.user_data.fullname, 
                    role : response.data.data.user_data.role.data, 
                    features : response.data.data.user_data.features, 
                    modules : response.data.data.user_data.modules, 
                    modules_path : getAllModulesPath(response.data.data.user_data.isFirstTime, "VERIFIED", response.data.data.user_data.role.data),
                    modules_menu : getAllModulesMenu(response.data.data.user_data.isFirstTime, "VERIFIED", response.data.data.user_data.role.data),
                    avatar : "",
                    auth_id : response.data.data.user_data.auth_id,
                    company_detail : response.data.data.user_data.company_detail,
                    first_time : false,
                    company_status : "VERIFIED",
                    login: true,
                    buyer_id : response.data.data.user_data.buyer_id,
                    company_code : response.data.data.user_data.company_code,
                    buyer_name : response.data.data.user_data.buyer_name,
                }
                const saveToLocalStorage = crypto.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CRYPTO_SECRET}`).toString();
                localStorage.setItem('_?credentialss', saveToLocalStorage)
                return {data : true, message : message}
            } else {
                return rejectWithValue(response.data.message)
            }
          } catch (err : any) {
            return rejectWithValue(err) 
        }
    }
);
