import { useState, useEffect } from 'react';
import { Box, Divider, Paper, Stack, Pagination, Chip, Skeleton  } from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAllNotifications, readNotification } from './reducers/notificationReducers';
import moment from 'moment';
import { userCredentials } from '../../../../utilities/config';

const NotificationPage = () => {

    const dispatch = useDispatch()
    const store_notifications = useSelector((state : RootState) => state.notification)

    const [page, setPage] = useState(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const onClickReadNotif = (row:any) => {
        if(row.category === "Orders") {
            if(row.read_by && row.read_by[userCredentials.auth_id]) {
            } else {
              dispatch(readNotification(row._id))
            }
            window.location.href = "/dashboard/purchase-orders" 
        } else if (row.category === "Invoice") {
            if(row.read_by && row.read_by[userCredentials.auth_id]) {
            } else {
              dispatch(readNotification(row._id))
            }
            window.location.href = "/dashboard/finance/invoice"
        } else if (row.category === "Report") {
            if(row.link_url !== "") {
                window.location.href = `${row.link_url}`
            }
            setTimeout(() => {
                if(row.read_by && row.read_by[userCredentials.auth_id]) {
                } else {
                  dispatch(readNotification(row._id))
                }
            }, 500);
        } else {
            if(row.read_by && row.read_by[userCredentials.auth_id]) {
            } else {
              dispatch(readNotification(row._id))
            }
        }
    }

    useEffect(() => {
        dispatch(getAllNotifications(page))
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if(store_notifications.read){
            dispatch(getAllNotifications(page))
        }
        // eslint-disable-next-line
    }, [store_notifications.read]);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Notifications"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>All Notifications</h3>
                </Box>
                {/* <Box>
                    <Button variant="contained" size="small">Read All Notifications</Button>
                </Box> */}
            </Stack>

            { store_notifications.loading ?
            <Stack pt={2}> 
                <Paper>
                    <Stack flexDirection="column" >
                    { [1,2,3,4,5].map((ele : any, i :any) => (
                        <Box key={i} sx={{marginBottom: '2px'}}>
                            <Box p={2}>
                                <Box><Skeleton/></Box>
                                <Box><Skeleton/></Box>
                                <Stack pt={1} direction="row" >
                                    <Box mr={1}> <Skeleton width={100}/></Box>
                                    <Box> <Skeleton width={200}/></Box>
                                </Stack>
                            </Box>
                            <Box>
                                <Divider sx={{ border: '1px solid #ccc'}} />
                            </Box>
                        </Box>
                    )) }
                    </Stack>
                    <Stack flexDirection="row" justifyContent="center" mt={2} pb={2}>
                        <Box>
                            <Skeleton width={400}/>
                        </Box>
                    </Stack>
                </Paper>
            </Stack>
            :
            <Stack pt={2}>
                { store_notifications.data.length === 0 ? 
                    <Box>
                        You dont have any new notification yet.
                    </Box>
                    :
                    <Paper>
                        <Stack flexDirection="column" sx={{  fontSize: '13px' }}>
                        { store_notifications.data.map((ele : any, i :any) => (
                            <Box key={i} sx={{marginBottom: '2px'}}>
                                <Box p={2} sx={{ backgroundColor: ele.read_by && ele.read_by[userCredentials.auth_id] ? '#fff' : '#c8e6c9', cursor: 'pointer' }} onClick={() => onClickReadNotif(ele)}> 
                                    <Box>{ele.message}</Box>
                                    <Stack pt={1} direction="row" alignItems="center">
                                        <Box pr={1}><Chip label="Order" variant='outlined' size="small" /></Box>
                                        <Box>
                                            <Box fontWeight={600} fontSize={13} >{moment(ele.createdAt).fromNow()}</Box>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Divider sx={{ border: '1px solid #ccc'}} />
                                </Box>
                            </Box>
                        )) }
                        </Stack>
                        <Stack flexDirection="row" justifyContent="center" mt={2} pb={2}>
                            <Box>
                                <Pagination count={store_notifications.totalPage} color="primary" page={page} onChange={handleChange} />
                            </Box>
                        </Stack>
                    </Paper> }
            </Stack>  }
        </Box>
    )
}

export default NotificationPage
