import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { disableUserTeams, getAllUsersTeams, postUserTeams, updateUserTeams } from './reducers/teamsReducers';

const initialState: any = {
  data: [], 
  loading : false,
  update : false, 
  loading_update : false,
  create : false,
  name_update : "",
  is_owner: false,
  loading_create: false,
  status : false,
  loading_status: false
};

export const stepRegisterVendor = createSlice({
  name: 'teams',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all users
      .addCase(getAllUsersTeams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersTeams.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getAllUsersTeams.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // update users
      .addCase(updateUserTeams.pending, (state) => {
        state.loading_update = true;
        state.update = false;
      })
      .addCase(updateUserTeams.fulfilled, (state, action:any) => {
        state.loading_update = false;
        state.name_update = action.payload.name
        state.is_owner = action.payload.is_owner
        state.update = action.payload.data;
      })
      .addCase(updateUserTeams.rejected, (state, action : any) => {
        state.loading_update = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // createa users
      .addCase(postUserTeams.pending, (state) => {
        state.loading_create = true;
        state.create = false;
      })
      .addCase(postUserTeams.fulfilled, (state, action:any) => {
        state.loading_create = false;
        state.create = action.payload.data;
      })
      .addCase(postUserTeams.rejected, (state, action : any) => {
        state.loading_create = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // disable/enable users
      .addCase(disableUserTeams.pending, (state) => {
        state.loading_status = true;
        state.status = false;
        state.update = false
      })
      .addCase(disableUserTeams.fulfilled, (state, action:any) => {
        state.loading_status = false;
        state.status = action.payload.data;
        state.update = true
      })
      .addCase(disableUserTeams.rejected, (state, action : any) => {
        state.loading_status = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});


export default stepRegisterVendor.reducer;
