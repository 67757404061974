import { Stack, Box, Grid, Button, TextField, Paper } from '@mui/material'
import { useState } from 'react'
import BreadCrumbs from '../../../components/BreadCrumbs'
// import {userCredentials} from './../../../utilities/config'

function SettingPage() {

    const [initialSave, setInitialSave] = useState(false);
    const [dataSetting, setDataSetting] = useState({
        discount: "",
        payment_term : "",
        down_payment : ""
    });


    const onChangeValue = (e : any) => {
        setInitialSave(true)
        setDataSetting({...dataSetting, 
            [e.target.name] : e.target.value
        })
    }

    const onClickSave = (e : any) => {
        e.preventDefault()
        // let send_data = {
        //     discount: dataSetting.discount,
        //     payment_term : {
        //         name : `${dataSetting.payment_term} Days`,
        //         value : dataSetting.payment_term
        //     },
        //     down_payment : dataSetting.down_payment
        // }
        // console.log(send_data, 'data setting')
    }
    // console.log(userCredentials, 'userrr')

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Setting Page"
            />
           <Box sx={{pt:2}}>
                <h3>Setting Platform</h3>
           </Box>

           <Stack mt={3}>
           <Paper elevation={3}>
               <Box p={2}>
               <form onSubmit={onClickSave}>
                <Box pl={2}>
                    <Box>
                        <Grid container >
                            <Grid item xl={2} lg={2} xs={6}>
                                <Box pt={2}><h4>Default Discount</h4></Box>
                            </Grid>
                            <Grid item xl={4} lg={4} xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                    label='Type Discount in %'
                                    type="number"
                                    onChange={onChangeValue}
                                    name="discount"
                                    required
                                />
                                {/* <Box pt={2}><h4>10%</h4></Box> */}
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xl={2} lg={2} xs={6}>
                                <Box pt={2}><h4>Default Payment Terms</h4></Box>
                            </Grid>
                            <Grid item xl={4} lg={4} xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                    label='Type Payment Term in days'
                                    type="number"
                                    onChange={onChangeValue}
                                    name="payment_term"
                                    required
                                />
                                {/* <Box pt={2}><h4>-</h4></Box> */}
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xl={2} lg={2} xs={6}>
                                <Box pt={2}><h4>Default Down Payment</h4></Box>
                            </Grid>
                            <Grid item xl={4} lg={4} xs={6}>
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                    label='Type Down Payment in %'
                                    type="number"
                                    onChange={onChangeValue}
                                    name="down_payment"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <Button 
                            variant="contained"
                            disabled={initialSave ? false : true}
                            type="submit"
                        >
                            Save Change
                        </Button>
                    </Box> 
                </Box>
                </form>
               </Box>
           </Paper>
           </Stack>
        </Box>
    )
}

export default SettingPage
