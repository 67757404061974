import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import InvoicesPage from './InvoicesPage';
import InvoiceSummaryPage from './InvoiceSummaryPage';
import PaymentCheckout from './payment/components/PaymentCheckout';
import PaymentConfirmed from './payment/components/PaymentConfirmed';
import WaitingPayment from './payment/components/WaitingPayment';

function MainInvoicePage() {
    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'payment') {
            return <PaymentCheckout/>
        } else if (active_page === 'waiting-payment') {
            return <WaitingPayment/>
        } else if (active_page === 'payment-confirmed') {
            return <PaymentConfirmed/> 
        } else if (active_page === 'summary') {
            return <InvoiceSummaryPage/>
        } else {
            return <InvoicesPage/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
        </Stack>
    )
}

export default MainInvoicePage