import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import algoliasearch from "algoliasearch";
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { Backdrop, Box, Breadcrumbs, CircularProgress, Stack, Typography } from '@mui/material';
import qs from 'qs';
import {
    InstantSearch,
    ClearRefinements,
    Configure,
    Stats,
    Highlight,
    connectSearchBox,
    RefinementList,
    Panel,
    InfiniteHits 
} from "react-instantsearch-dom";
import { 
  Button, Grid, 
} from "@mui/material";
import axios from 'axios'
import swal from 'sweetalert'
import { filter_images_pp, userCredentials } from "../../../../utilities/config";
import { useHistory } from "react-router-dom";
import AddBoxIcon from '@mui/icons-material/AddBox';
import Swal from "sweetalert2";
import { Autocomplete } from './Autocomplete';
import { RootState } from '../../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyAddress } from '../../profile/reducers/profilesReducers';
import Select from 'react-select'
import { filterBreadcrumbs, filterCategories, setRefectComparation } from '../productsSlice';


const searchClient = algoliasearch(
  `${process.env.REACT_APP_API_ALGOLIA_KEY}`,
  `${process.env.REACT_APP_API_ALGOLIA_SECRET}`
);

function createURL(searchState:any) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location } :any, searchState : any) {
  if (Object.keys(searchState).length === 0) {
    return '';
  }

  return `${location.pathname}${createURL(searchState)}`;
}

function urlToSearchState({ search } : any) {
  return qs.parse(search.slice(1));
}

const VirtualSearchBox = connectSearchBox(() => null);

const AlgoliaSearchNew : React.FC<any> = ({
  addToCart, 
  vendor,
  vendor_name
}) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const store_profile = useSelector((state : RootState) => state.profile)
  const store_products = useSelector((state : RootState) => state.detail_product)

  // console.log(store_products, 'store product')

  const [optionsAddress, setOptionsAddress] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(); 
  const [loadedAddress, setLoadedAddress] = useState(false);

  useEffect(() => {
    if(store_profile.address.length !== 0) {
      let address = []
      for(let element of store_profile.address) {
          address.push({
              label : element.village,
              name : element.city,
              value : element
          })
      }
      setOptionsAddress(address)
      let address_local : any = localStorage.getItem('delivery_area')
      if(address_local === null) {
        localStorage.setItem('delivery_area', JSON.stringify(address.filter((item:any) => item.value.is_default === true)[0]))
        setSelectedAddress(address.filter((item:any) => item.value.is_default === true)[0])
      } else {
        let address = JSON.parse(address_local)
        setSelectedAddress(address)
      }
      setTimeout(() => {
        setLoadedAddress(true)
      }, 1000);
    }
     // eslint-disable-next-line
  }, [store_profile.address]);

  const handleChangeAddress = (value: any) : void => {
    setSelectedAddress(value)
    localStorage.setItem('delivery_area', JSON.stringify(value))
  }

  useEffect(() => {
    dispatch(getCompanyAddress())
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState({
    id : null,
    loading : false,
    type : ""
  });

  const addToCartItem =  ( product : any, type : string ) => {
    setLoading({...loading, id: product._id, loading: true, type : type})
    setTimeout( async () => {
      try {
        const vendor_detail : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/vendor/detail?vendor_id=${product.vendor_id}`, {
          headers : {
            'Authorization' : `Bearer ${userCredentials.access_token}`
          }
        })
        if(vendor_detail.data.errors === null) {
            let vendor = vendor_detail.data.data
            try {
              const special_price : any = await axios.get(`${process.env.REACT_APP_API_SERVER}/mybuyer/product`, {
                params : {
                  vendor_id : product.vendor_id,
                  buyer_id : userCredentials.buyer_id,
                  product_id : product._id,
                },
                headers : {
                  'Authorization' : `Bearer ${userCredentials.access_token}`
                }
              })
              if(special_price.data.errors === null) {
                let quantity = product.minimum_order_quantity < 1 ? 1 : product.minimum_order_quantity
                let discount_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].discount))
                let down_payment_setting = vendor.setting.length === 0 ? 0 : (parseInt(vendor.setting[0].setting[0].down_payment))
                let discount_preferred = (parseInt(special_price.data.data.discount_percentage))
                let discount_product = ( product.discount * 100)
                let sum_discount =  discount_product + discount_preferred
                let tax_percentage = product.pajak.value
                let discount = product.discount === 0 && discount_preferred === 0 ? (discount_setting / 100) : (sum_discount / 100)
                let sum_price_discount = discount * product.retail_price
                let discount_price = product.retail_price - sum_price_discount
                let sub_total = discount_price * quantity
                let single_tax_price = (tax_percentage * discount_price)
                let tax_price = (tax_percentage * discount_price) * quantity
                let final_price = sub_total + tax_price

                let product_item = {
                  productId : product._id,
                  vendorId: product.vendor_id,
                  name: product.name,
                  vendor : {
                    phone: vendor.phone,
                    email: vendor.email,
                    address: `${vendor.street}, ${vendor.village}, ${vendor.subdistrict}, ${vendor.city}, ${vendor.province}, ${vendor.country}, ${vendor.postcode}`,
                    name: product.vendor_name,
                    _id: product.vendor_id
                  },
                  tax : {
                    title : product.pajak.title,
                    value : product.pajak.value,
                  },
                  vendor_name: product.vendor_name,
                  vendor_phone : vendor.phone,
                  sku: product.SKU,
                  slug: product.slug_product,
                  stock: product.stock,
                  brand: product.brand,
                  images_product: product.images_product[0],
                  storage: product.storage,
                  dimension: product.dimension,
                  sub_products: product.sub_products,
                  categories: product.category_id,
                  reported_times: product.reported_times,
                  minimum_order_quantity: product.minimum_order_quantity,
                  retail_price: product.retail_price, 
                  discount: discount,
                  discount_product: discount_product,
                  is_special_discount : special_price.data.data.discount_percentage === "0" ? false : true,
                  special_discount : discount_preferred,
                  discount_price: discount_price,
                  quantity : quantity,
                  sub_total : sub_total,
                  single_tax_price: Math.floor(single_tax_price),
                  tax_price : tax_price,
                  tax_percentage: tax_percentage,
                  sum_price_discount: sum_price_discount,
                  final_price : final_price,
                  payment_term: product.payment_term,
                  warehouse_detail: product.warehouse_detail,
                  category_tree : product.category_tree,
                  down_payment_setting: down_payment_setting,
                  note : ""
                }
                  addToCart(product_item, type)
                  setLoading({...loading, id: product._id, loading: false, type: ""})
              } else {
                swal('Error', `${special_price.data.message}`, 'error')
              }
            } catch (error) {
              swal('Error', `${error}`, 'error')
            }
        } else {
            swal('Error', `${vendor_detail.data.message}`, 'error') 
        }
      } catch (error) {
          swal('Error', `${error}`, 'error')
      }
    }, 1000);
  }

  const onClickDetail = (slug : string) => {
    history.push({
      pathname: "/dashboard/products",
      search: `?page=detail-product`, 
      state : {
        slug : slug
      }
    })
  }

  const [dataComparation, setDataComparation] = useState<any>([]);

  useEffect(() => {
    let data_comparation = localStorage.getItem('data_comparation')
    let array_comparation : any = data_comparation === null ? [] : JSON.parse(data_comparation)
    setDataComparation(array_comparation)
  }, [store_products.comparation]);

  const onClickComparation = (hit : any) => {
    let array_comparation = [...dataComparation]
    if(array_comparation.length === 5) {
      Swal.fire(
        'Error!',
        'Maksimum product comparation is 5',
        'error'
      )
    } else {
      if(array_comparation.find((element : any) => element._id === hit._id)) {
        Swal.fire(
          'Error!',
          'Product already in comparation',
          'error'
        )
      } else {
        array_comparation.push(hit)
        localStorage.setItem('data_comparation', JSON.stringify(array_comparation))
        Swal.fire(
          'Success!',
          'Product success added in comparation',
          'success'
        )
        // setRefetch(!refetch)
        dispatch(setRefectComparation(!store_products.comparation))
      }
    }
  }

  const Hit = ({ hit } : any) => (
    <div className="content-search">
        <div 
          className="img-product" 
          onClick={() => onClickDetail(hit.slug_product)}
        >
          <img src={hit.images_product[0]} alt={hit.name} />
        </div>
        <div className="hit-name">
            <h5><Highlight attribute="name" hit={hit} /></h5>
        </div>
        <Stack flexDirection="column" pb={1}>
            { hit.brand === "" ? 
            <Box pb={1}><Highlight attribute="vendor_name" hit={hit} /></Box>
            :
            <Box>Brand : <Highlight attribute="brand" hit={hit} style={{ fontWeight: 500 }} /></Box> }
        </Stack>
        <Box sx={{minHeight: 60}} className={ hit.discount <= 0 ? "hit-price" : "hit-price-coret"}>
            <h3>Rp.{parseInt(hit.discount_price).toLocaleString()}</h3> 
            { hit.discount <= 0 ? null :  
              <Stack flexDirection="row">
                <Box> 
                  <div className="badge-discount">
                    {Math.floor(hit.discount * 100)}%
                  </div>
                </Box>
                <Box >
                  <h4>Rp.  {hit.retail_price === null ? "0" : parseInt(hit.retail_price).toLocaleString()} </h4>
                </Box>
              </Stack>
              }
        </Box>
        <div className="button-card">
            <Button 
                variant="contained" 
                size="small" 
                color="primary"
                fullWidth
                sx={{ mb : 1}}
                onClick={() => addToCartItem(hit, 'pr')}
                disabled={loading.loading && loading.type === "pr" ? true : false}
            >
              { loading.loading && loading.id === hit._id && loading.type === "pr" ? "Loading" : "Add to PR"}
            </Button>
            <Button 
                variant="contained" 
                size="small" 
                color="info"
                fullWidth
                disabled={loading.loading && loading.type === "template" ? true : false}
                onClick={() => addToCartItem(hit, 'template')}
            >
              { loading.loading && loading.id === hit._id && loading.type === "template" ? "Loading" : "Add to Template"}
            </Button>
            <Box pt={1}>
              <Stack 
                flexDirection="row" alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => onClickComparation(hit)}
              >
                <Box pr={1}><AddBoxIcon/></Box>
                <Box fontSize={14} sx={{paddingBottom: '4px'}}>Add to Comparison</Box>
              </Stack>
            </Box>
        </div>
    </div>
  );


  const [searchState, setSearchState] = useState<any>(() =>
    urlToSearchState(window.location)
  );
  const timerRef = useRef<any>(null);

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      window.history.pushState(
        searchState,
        searchStateToUrl({ location: window.location }, searchState)
      );
    }, 400);
  }, [searchState]);

  const onSubmit = useCallback(({ state }) => {
    setSearchState((searchState:any) => ({
      ...searchState,
      query: state.query,
    }));
  }, []);
  const onReset = useCallback(() => {
    setSearchState((searchState :any) => ({
      ...searchState,
      query: '',
    }));
  }, []);


  const plugins = useMemo(() => {
    const recentSearchesPlugin : any = createLocalStorageRecentSearchesPlugin({
      key: 'search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect(params) {
            setSearchState((searchState : any) => ({
              ...searchState,
              query: params.item.label,
            }));
          },
        };
      },
    });

    return [
      recentSearchesPlugin,
      createQuerySuggestionsPlugin({
        searchClient,
        indexName: 'products_query_suggestions',
        getSearchParams() {
          return recentSearchesPlugin.data.getAlgoliaSearchParams({
            hitsPerPage: 5,
          });
        },
        transformSource({ source }) {
          return {
            ...source,
            onSelect(params) {
              setSearchState((searchState:any) => ({
                ...searchState,
                query: params.item.query,
              }));
            },
          };
        },
      }),
    ];
  }, []);


  const onClickBreadCrumbs = (data:any, idx:any) => {
    let newArray = [...store_products.filter_breadcrumbs]
    let remove = newArray.splice(idx+1)
    console.log(remove)
    dispatch(filterCategories(data.filter)) 
    dispatch(filterBreadcrumbs(newArray)) 
  }

  const filterVendor = `warehouse_detail.coverage: '${ loadedAddress && selectedAddress.name }' AND vendor_name: '${ vendor_name }'`
  const filterDefault =`warehouse_detail.coverage: '${ loadedAddress && selectedAddress.name }'`

  if(!loadedAddress) {
    return ( 
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
    <div className="ais-InstantSearch">
        <InstantSearch
          searchClient={searchClient}
          indexName="products"
          searchState={searchState}
          onSearchStateChange={setSearchState}
          createURL={createURL}
        >
          <Configure 
            hitsPerPage={9} 
            filters={vendor ? filterVendor : filterDefault}
            // filters={filterDefault}
            facetFilters={[store_products.filter_categories]}
          />          
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              { store_products.filter_breadcrumbs.length ? 
                <Typography 
                  color="text.primary" 
                  sx={{ cursor: 'pointer', color: '#0096db' }}
                  onClick={() => { 
                    dispatch(filterCategories([])) 
                    dispatch(filterBreadcrumbs([])) 
                  }}
                >
                  Home
                </Typography> 
              : null }
              { store_products.filter_breadcrumbs.map((data : any, idx: any) => (
                <Box key={idx}>
                { data.link ? 
                <Typography 
                  color="text.primary" 
                  key={idx}
                  sx={{ cursor: 'pointer', color: '#0096db' }}
                  onClick={() => onClickBreadCrumbs(data, idx)}
                >
                  {data.name}
                </Typography> :
                <Typography 
                  color="text.primary" 
                  key={idx}
                >
                  {data.name}
                </Typography> }
                </Box>
              ))}
            </Breadcrumbs>
            </Grid>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Box mt={2}>
                <Panel header="Delivery Area">
                  <Select
                      placeholder="Select Area"
                      value={selectedAddress}
                      isSearchable={true}
                      options={optionsAddress}
                      onChange={handleChangeAddress}
                      id="select-style-delivery"
                  />
                </Panel>
              </Box>
              <Box mt={2}>
                <Panel header="Brand">
                  <RefinementList
                    attribute="brand"
                    searchable={true}
                    limit={5}
                    showMoreLimit={30}
                    showMore={true}
                    translations={{
                      placeholder: 'Search for brand..',
                    }}
                  />
                </Panel>
              </Box>
              { vendor ? null : 
              <Box mt={2}>
                <Panel header="Vendors">
                  <RefinementList
                    attribute="vendor_name"
                    searchable={true}
                    limit={5}
                    showMoreLimit={50}
                    showMore={true}
                    translations={{
                      placeholder: 'Search for vendor..',
                    }}
                  />
                </Panel>
              </Box> }
              <Configure hitsPerPage={9} />

            </Grid>
            <Grid item lg={10} md={10} sm={12} xs={12}>
                <Box mt={2}>
                  <VirtualSearchBox />
                  <Autocomplete
                    placeholder="Search"
                    detachedMediaQuery="none"
                    initialState={{
                      query: searchState.query,
                    }}
                    openOnFocus={true}
                    onSubmit={onSubmit}
                    onReset={onReset}
                    plugins={plugins}
                  />
                </Box>
                <Stack flexDirection="row" justifyContent="space-between" pt={2}>
                  <Stats/>
                  <ClearRefinements />
                </Stack>
              <InfiniteHits hitComponent={Hit} />
            </Grid>
          </Grid>
        </InstantSearch>
      </div>
  );
  }
}

export default AlgoliaSearchNew;
