/* istanbul ignore file */
import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { 
  Route, 
  Redirect,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
// import NavDashboard from '../components/NavDashboard'
import { userCredentials } from './config';
import NavBarProduct from '../components/NavBarProduct';

interface Props {
  component : React.ElementType;
  path?: string | string[];
  exact?: boolean;
}

const  DashboardLayout : React.FC<Props> = ({ component : Component, ...rest }) => {
  const location = useLocation()

  const auth = useSelector((state : RootState) => state.login.login)

  const checkModule = () => {
    let access = false
    if(userCredentials.modules_path.find((ele:any) => ele === rest.path)) {
      access = true
    } else {
      access = false
    }
    return access
  }

  // console.log(...rest, 'restt')
  const isAuthenticated = () => {
    if(auth && checkModule()) {
        return auth
    } else {
        return false
    }
  }

  const styleProductPage = () => {
    if(location.pathname === "/dashboard") {
        return { display: 'flex', backgroundColor: '#f5f7fa', height: '100vh' }
    }
    return { display: 'flex'}
}


  return (

    <Route {...rest} 
        render={props => {
            if(isAuthenticated()){
                return (
                  <Box sx={styleProductPage}>
                    <NavBarProduct/> 
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                      <Toolbar />
                      <Component {...props} />
                    </Box>
                  </Box>
                )
            } else {
                return (
                    <Redirect 
                        to={{
                            pathname: "*",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        }}
    />
    
  );
}

export default DashboardLayout;