import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { getCompanyCategoryBuyer, getCompanyTypeBuyer, postAddDepartment, postAddUserManagement, postCompanyDetail, postLegalDocument } from './reducers/stepRegisterReducers';


const initialState: any = {
  company_detail: false, 
  loading_comp_detail : false,
  error_comp_detail : null,
  legal_document: false, 
  loading_legal_document : false,
  error_legal_document : null,
  user: false, 
  loading_user : false,
  error_user : null,
  department: false,
  loading_department : false,
  company_category : [],
  loaded_company_category : false,
  loading_company_category : false,
  company_type : [],
  loaded_company_type : false,
  loading_company_type : false
  

};

export const stepRegisterVendor = createSlice({
  name: 'step-register',
  initialState, 
  reducers: {
    defaulCompanyDetail: (state) => {
      state.company_detail = false;
    },
  },
  extraReducers: (builder) => {
    builder

      // get company category
      .addCase(getCompanyCategoryBuyer.pending, (state) => {
        state.loading_company_category = true;
        state.loaded_company_category = false;
      })
      .addCase(getCompanyCategoryBuyer.fulfilled, (state, action:any) => {
        state.loading_company_category = false;
        state.company_category = action.payload
        state.loaded_company_category = true;
      })
      .addCase(getCompanyCategoryBuyer.rejected, (state, action : any) => {
        state.loading_company_category = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get company type
      .addCase(getCompanyTypeBuyer.pending, (state) => {
        state.loading_company_type = true;
        state.loaded_company_type = false;
      })
      .addCase(getCompanyTypeBuyer.fulfilled, (state, action:any) => {
        state.loading_company_type = false;
        state.company_type = action.payload
        state.loaded_company_type = true;
      })
      .addCase(getCompanyTypeBuyer.rejected, (state, action : any) => {
        state.loading_company_type = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // put company detail
      .addCase(postCompanyDetail.pending, (state) => {
        state.loading_comp_detail = true;
        state.company_detail = false
      })
      .addCase(postCompanyDetail.fulfilled, (state, action:any) => {
        state.loading_comp_detail = false;
        // console.log(action.payload, 'palyoad')
        state.company_detail = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postCompanyDetail.rejected, (state, action : any) => {
        state.loading_comp_detail = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // put legal document
      .addCase(postLegalDocument.pending, (state) => {
        state.loading_legal_document = true;
      })
      .addCase(postLegalDocument.fulfilled, (state, action:any) => {
        state.loading_legal_document = false;
        state.legal_document = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')

      })
      .addCase(postLegalDocument.rejected, (state, action : any) => {
        state.loading_legal_document = false;
        state.error_legal_document = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // put user management
      .addCase(postAddUserManagement.pending, (state) => {
        state.loading_user = true;
      })
      .addCase(postAddUserManagement.fulfilled, (state, action:any) => {
        state.loading_user = false;
        state.user = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')

      })
      .addCase(postAddUserManagement.rejected, (state, action : any) => {
        state.loading_user = false;
        state.error_user = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // put department
      .addCase(postAddDepartment.pending, (state) => {
        state.loading_department = true;
      })
      .addCase(postAddDepartment.fulfilled, (state, action:any) => {
        state.loading_department = false;
        state.department = true;
        swal("Success", `${action.payload.message}`, 'success')

      })
      .addCase(postAddDepartment.rejected, (state, action : any) => {
        state.loading_department = false;
        state.error_user = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});

export const { defaulCompanyDetail } = stepRegisterVendor.actions

export default stepRegisterVendor.reducer;
