/* istanbul ignore file */
import crypto from 'crypto-js'
import moment from 'moment'

const checkDataFromLocalStorage = localStorage.getItem('_?credentialss') ? localStorage.getItem('_?credentialss') : null
const decriptValue  = checkDataFromLocalStorage !== null ? crypto.AES.decrypt(checkDataFromLocalStorage, `${process.env.REACT_APP_CRYPTO_SECRET}`) : null

export const userCredentials = decriptValue !== null ? JSON.parse(decriptValue.toString(crypto.enc.Utf8)) : null
export const globalHeaders =  { 'Authorization' : `Bearer ${userCredentials === null ? "" : userCredentials.access_token}` }


export const checkLoginSessionTime = () => {
    return !moment(userCredentials.login_start_date).isAfter(moment().subtract(10, 'hours'));
}

export const filter_images_pp = `AND NOT images_product : 'https://mpindo.sgp1.digitaloceanspaces.com/636cb538141026613e75882b/7ffbad75-8f2c-47fd-88ca-9d0a2ce986df.jpg' AND NOT images_product : 'https://mpindo.sgp1.digitaloceanspaces.com/63919619141026613e75a2c6/98767b6b-cc96-44f3-af03-2c1cb356bd32.jpg' AND NOT images_product : 'https://mpindo.sgp1.cdn.digitaloceanspaces.com/vendor/13779c43-fcdc-46c0-a227-58f9d7f652c0PasarPemesanan-LogoWhiteBlue.png'  AND NOT images_product : 'https://mpindo.sgp1.digitaloceanspaces.com/63a1641f141026613e75aa90/1bc32f11-0de5-405d-bc98-603358d6a790.jpg' AND NOT images_product: 'https://mpindo.sgp1.digitaloceanspaces.com/63074925d3e41010f18aeb76/f91640e4-c290-44e4-9f90-10d741baf1e4.jpg' AND NOT images_product: 'https://mpindo.sgp1.cdn.digitaloceanspaces.com/vendor/b4787dbe-f117-4047-b04a-e773fb26e90dPasarPemesanan-LogoWhiteBlue.png' AND NOT images_product: 'https://mpindo.sgp1.cdn.digitaloceanspaces.com/vendor/81b6fbfe-fcda-4c20-903b-7834895b7990PasarPemesanan-LogoWhiteBlue.png'`


// const array_vendor = [
//     "PT Classic Fine Foods Indonesia",
//     "Dimatique",
//     "UD. HORTI BALI ORGANIK",
//     "Sayur Bening", 
//     "CV BALI SUSTAINABLE SEAFOOD", 
//     "Sari Galih", 
//     "UD Dhananjaya", 
//     "Novio Fresh Bali", 
//     "PT Pelita Maju Perkasa (PMP)", 
//     "PT. Sukanda Djaya",
//     "PT Wahana Boga Nusantara", 
//     "PT Bahana Gourmet Indonesia",
//     "Dama Semesta", 
//     "PT Dewata Sukses Perkasa", 
//     "UD Bintang Sarana Interfood", 
//     "PT Pratama Agung Niaga", 
//     "Darma Pangan Seafood", 
//     "PT Panca Niaga Bali", 
//     "UD. Global Seafood", 
//     "PT Bali Kulina Utama", 
//     "PT Masuya Graha Trikencana",
//     "PT Dusindo Unggas Anugerah", 
//     "Sapi Kembang Kuning", 
//     "UD Hasna Jaya Mandiri", 
//     "Epicurean Asia", 
//     "PT Adima Dwitunggal Jaya", 
//     "PT Inti Sari Buana", 
//     "PT. Bali Permata Jaya Sukses",
//     "Best Wines", 
//     "PT Indah Permai", 
//     "PT Mulia Raya Agrijaya", 
//     "PT Prambanan Kencana", 
//     "PT Sinar Aktif Nusantara", 
//     "Bintang Ubud",
//     "CV Bali Blessindo", 
//     "King Resto", 
//     "PT Alamboga Internusa", 
//     "PT Bali Graha Mandiri", 
//     "PT Cahaya Anugerah Pertiwi", 
//     "PT HATTEN BALI", 
//     "PT Karunia Megah Boga Perkasa", 
//     "PT Satria Pangan Sejati", 
//     "Bali Pick Fresh", 
//     "Boca Espresso", 
//     "CV Dwi Jaya", 
//     "PT Bali Kulina Utama", 
//     "PT Dusindo Unggas Anugerah", 
//     "Ina Mitra Utama", 
//     "PT BALI SATYA RAYA", 
//     "PT Indowines",
//     "PT Soejasch Bali", 
//     "PT. Indoprima Utama Bali", 
//     "PT. Pulau Rempah Indonesia", 
//     "AS Food", 
//     "Aroma", 
//     "CV Ausindo Dewata", 
//     "CV Dwi Boga Utama", 
//     "CV. Prima Indonesia", 
//     "CV. Pusaka Bali Persada", 
//     "CV. Rajawali Anugrah Sentosa", 
//     "Delta Dewata", 
//     "Katarina Blu", 
//     "PT Bahana Genta Viktory", 
//     "PT East Indo Fair Trading", 
//     "PT Giri Amertha", 
//     "PT Mandala Kriya Semesta", 
//     "PT Winetobe Bali", 
//     "PT. Arya Bima Cahaya", 
//     "Pepito Market"
// ]



