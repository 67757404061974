import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { 
    Paper,
} from '@mui/material';
import CardOrder from './components/CardOrder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getPurchaseOrdersData } from './reducers/goodReceiptNoteReducers';


const GoodReceiptNote = () => {
    const dispatch = useDispatch()
    const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)
    
    // const [loading, setLoading] = useState(true);
    const [dataPackageActive, setDataPackageActive] = useState<any>([]);


    function getPurchaseRequestActive() {
      let data_active = store_purchaseorders.data.filter((key : any) => key.lastStatus === "Active")
      let array = []
      for(let element of data_active){
        array.push({...element, value : 0 })
      }
      setDataPackageActive(array)
    }

    useEffect(() => {
        dispatch(getPurchaseOrdersData()) 
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_purchaseorders.data) {
          getPurchaseRequestActive()
        }
        // eslint-disable-next-line
    }, [store_purchaseorders.data]);

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Good Receipt Note Page"
            />
           <Box sx={{pt:2}}>
                <h3>Good Receipt Note</h3>
           </Box>

           <Box sx={{ width: '100%', mt: 3 }}>
            <Box>
              <Paper style={{ backgroundColor: '#ccc' }}>
              <Box p={3}>
                { dataPackageActive.length === 0 ? "You dont have any active orders yet!" : 
                  <CardOrder data={dataPackageActive} setData={setDataPackageActive} />
                }
              </Box>
              </Paper>
            </Box> 
            </Box> 
        </Box>
    )
}

export default GoodReceiptNote;
