import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import swal from 'sweetalert'
import { downloadReports, getChartsReports, getSumChartsReports } from './reducers/chartsReducers';

const initialState: any = {
  data: [], 
  charts : [],
  charts_purchase : [],
  charts_orders : [],
  loading_charts: false,
  loading : false,
  download_report :false,
  loading_download_report : false
};

export const chartsReportSlice = createSlice({
  name: 'charts-report',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get data
      .addCase(getChartsReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChartsReports.fulfilled, (state, action:any) => {
        state.loading = false;
        let data_purchase = []
        let label_purchase = []
        let data_orders = []

        for(let k of action.payload.data.purchase_value_and_order) {
          data_purchase.push(k.total)
          data_orders.push(k.count)
          label_purchase.push(moment(k.createdAt).format('DD-MMMM'))
        }
        const purchase = {
          labels : label_purchase,
          data : data_purchase
        }

        const orders = {
          labels : label_purchase,
          data : data_orders
        }
        state.data = action.payload.data;
        state.charts_purchase = purchase;
        state.charts_orders = orders;
      })
      .addCase(getChartsReports.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // get sum charts
      .addCase(getSumChartsReports.pending, (state) => {
        state.loading_charts = true;
      })
      .addCase(getSumChartsReports.fulfilled, (state, action:any) => {
        state.loading_charts = false;
        state.charts = action.payload.data;
      })
      .addCase(getSumChartsReports.rejected, (state, action : any) => {
        state.loading_charts = false;
        swal("Error", `${action.payload.message}`, 'error')
      })

      // download report
      .addCase(downloadReports.pending, (state) => {
        state.loading_download_report = true;
      })
      .addCase(downloadReports.fulfilled, (state, action:any) => {
        state.loading_download_report = false;
        state.download_report = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(downloadReports.rejected, (state, action : any) => {
        state.loading_download_report = false;
        swal("Error", `${action.payload.message}`, 'error')
      })
      
  },
});


export default chartsReportSlice.reducer;
