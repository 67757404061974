import { Box, Stack } from '@mui/material'
import BreadCrumbs from '../../../components/BreadCrumbs'
import SearchAddNew from './components/SearchAddNew'
import TableVendorList from './components/TableVendorList'
// import {userCredentials} from './../../../utilities/config'

function VendorLists() {

    // console.log(userCredentials, 'userrr')

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Prefered Vendor Lists"
            />

            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>My Vendors</h3>
                </Box>
            </Stack>

            <SearchAddNew />

           <Box sx={{pt:2}}>
                <TableVendorList/>
           </Box>

           
        </Box>
    )
}

export default VendorLists
