import { useState, useEffect } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system'
import { TableColumn } from 'react-data-table-component';
import BreadCrumbs from '../../../components/BreadCrumbs'
import DataTableBase from '../../../components/TableData'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { getAllDepartment, postAddDepartment, removeDepartment, updateDepartment } from './reducers/departmentReducers';
import ButtonLoading from '../../../components/ButtonLoading';
import Swal from 'sweetalert2';
import Select from 'react-select'
import { getAllUsersTeams } from '../teams/reducers/teamsReducers';
    
const Department = () => {

    const dispatch = useDispatch()
    const store_department = useSelector((state : RootState) => state.department)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1); 

    const [open, setOpen] = useState(false);
     // state for Province
     const [optionsUsers, setOptionsUsers] = useState<any>([]);
     const [selectUsers, setSelectUsers] = useState<any>(false);

    const [valueDepartment, setValueDepartment] = useState<any>({
        name : "",
        id: ""
    });

    const onChangeValue = (e : any) => {
        const { name, value } = e.target 
        setValueDepartment({...valueDepartment, [name] : value })
    }

    const handleChangeUser = (value: any) : void => {
        setSelectUsers(value)
    }


    const [update, setUpdate] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUpdate(false)
        setValueDepartment({...valueDepartment, 
            name : "",
            id : ""
        })
    };

    useEffect(() => {
        dispatch(getAllDepartment())
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);


    const onClickSubmit = (e: any) => {
        e.preventDefault()
        let users = []
        for(let k of selectUsers) {
            users.push({
                userId : k.value,
                label : k.label
            })
        }
        if(!update) {
            let body = {
                buyer_id : userCredentials.buyer_id,
                name : valueDepartment.name,
                users : users
            }
            dispatch(postAddDepartment(body))
        } else {
            let body = {
                _id : valueDepartment.id,
                name : valueDepartment.name,
                users : users
            }
            dispatch(updateDepartment(body))
        }
    }

    useEffect(() => {
        if(state_teams.data.length > 0) {
            let users : any= []
            for(let i of state_teams.data) {
                users.push({
                    label : i.fullname,
                    value : i.auth_id
                })
            }
            setOptionsUsers(users)
        }
    }, [state_teams.data]);

    useEffect(() => {
        if(store_department.department_post) {
            handleClose()
            setTimeout(() => {
                setSelectUsers([])
                dispatch(getAllDepartment())
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_department.department_post]);

    useEffect(() => {
        if(store_department.department_update) {
            handleClose()
            setTimeout(() => {
                setSelectUsers([])
                dispatch(getAllDepartment())
            }, 1000);
        }
        // eslint-disable-next-line
    }, [store_department.department_update]);

    /* istanbul ignore next */
    const onClickUpdate = (row : any) => {
        let users : any = []
        for(let i of row.users) {
            users.push({
                label : i.label,
                value : i.userId
            })
        }
        setValueDepartment({...valueDepartment, 
            name : row.name,
            id : row._id
        })
        setSelectUsers(users)
        setUpdate(true)
        setTimeout(() => {
            handleClickOpen()
        }, 100);
    }


    const onClickRemove = (row: any) => {
        Swal.fire({
          title : "Confirmation",
          text: `Are you sure want to remove ${row.name}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'primary',
          confirmButtonText: 'Yes, Remove It',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return dispatch(removeDepartment(row._id))
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              'Removed!',
              'Department has been removed.',
              'success'
            )
            setTimeout(() => {
                dispatch(getAllDepartment())
            }, 1000);
          }
        })
    }

    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>
                { page > 1 ? 
                    <>
                    {index + 1 + (limit * (page-1))}
                    </>
                    :
                    <> 
                    {index + 1}
                    </>
                }
                </p>
            )
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'ACTION',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        onClick={() => onClickUpdate(row)}
                    >
                        Edit
                    </Button>
                    <Button 
                        variant="outlined" color="error" size="small"
                        onClick={() => onClickRemove(row)}
                    >
                        Delete
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs
                isPage={false}
                current="Department Page"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>Department Lists</h3>
                </Box>
                <Box>
                    <Button 
                        variant="contained" color="primary" size="small"
                        onClick={() => handleClickOpen()}
                    >
                        Add New Department
                    </Button>
                </Box>
            </Stack>

            {/* Dialog add new user */}
            <Dialog  
                open={open} 
                fullWidth={true}
                maxWidth="md"
            >
                <form onSubmit={onClickSubmit} >
                    <DialogTitle>{ !update ? "Add New Department" : "Update Department" }</DialogTitle>
                    <DialogContent style={{minHeight: 200}}>
                        <Stack p={3}>
                            <Grid container >
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={1}><h4>Department Name</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField
                                        onChange={onChangeValue}
                                        value={valueDepartment.name}
                                        margin="dense"
                                        fullWidth
                                        id="name"
                                        label="Department Name"
                                        type="text"
                                        name="name"
                                        size="small"
                                        required
                                    />
                                </Grid> 
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={1}><h4>Asign Users</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <Select
                                        placeholder="Select Users"
                                        value={selectUsers}
                                        isSearchable={true}
                                        options={optionsUsers && optionsUsers}
                                        onChange={handleChangeUser}
                                        isMulti
                                        id="select-style-countryy"
                                    />
                                </Grid> 
                            </Grid>
                        </Stack>
                        
                    </DialogContent>
                    <DialogActions sx={{p:3}}>
                        <Box sx={{width: '100%'}}>
                            <Button onClick={handleClose} color="error"  size="small"  variant='outlined'>Cancel</Button>
                        </Box>
                        <Box mr={1} />
                        <Box>
                            { !update ?
                            <ButtonLoading 
                                type="submit"
                                name="Submit"
                                loading={store_department.loading_department_post}
                            /> :
                            <ButtonLoading 
                                type="submit"
                                name="Update"
                                loading={store_department.loading_department_update}
                            /> }
                        </Box>
                    </DialogActions>
                </form>
            </Dialog>

            <Box sx={{pt:3}}>
                <DataTableBase 
                    columns={columns}
                    data={store_department.data}
                    progressPending={store_department?.loading}
                    pagination
                    onChangePage={(value) => setPage(value)}
                    onChangeRowsPerPage={(value) => setLimit(value)}
                />
            </Box>

        </Box>
    )
}

export default Department
