import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import BreadCrumbs from '../../../components/BreadCrumbs'
import { 
    Tabs,
    Tab,
    Paper,
    Badge,
} from '@mui/material';
import CardOrder from './components/CardOrder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { 
  getPurchaseOrdersBySearchWaitingDP, 
  getPurchaseOrdersData, 
  getPurchaseOrdersDataNew, 
  getPurchaseOrdersDataStatus, 
  getPurchaseOrdersWaitingDP 
} from './reducers/purchaseOrdersReducers';
import SearchData from './components/SearchData';
import { useHistory, useLocation } from 'react-router-dom';
import EmptyData from '../../../components/EmptyData';
import LoaderBackdrop from '../../../components/LoaderBackdrop';
import { find } from 'lodash';
import SearchDataDP from './components/SearchDataDP';
import CardOrderDP from './components/CardOrderDP';

interface TabPanelProps {
  children?: React.ReactNode; 
  index: number;
  value: number;
}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const array_tabs = [
  { label: "Ready to Process", value: "New" },
  { label: "Pick & Pack", value: "Pick" },
  { label: "Ready to Ship", value: "Ready to Ship" },
  { label: "Shipped", value: "Shipped" },
  { label: "Delivered", value: "Delivered" },
  { label: "Paid", value: "Paid" },
  { label: "Canceled", value: "Cancel" },
]

const PurchaseOrders = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location : any = useLocation()

    const { 
      data, 
      loading,
      orders_new,
      loading_orders_new,
      data_status,
      data_dp_all,
      loading_data_dp_all,
      data_dp, 
      loading_data_dp,
      count_dp
    } = useSelector((state : RootState) => state.purchase_orders)
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    let query = useQuery();
    let active_page = query.get('status')

    const [stateLocation, setStateLocation] = useState<any>([]);

    const [value, setValue] = React.useState(0);
    const [activeStatus, setActiveStatus] = useState("New");
    const [activeTab, setActiveTab] = useState<any>("Waiting DP");

    const [dataSearch, setDataSearch] = useState<any>({ 
      searchTopic : ['packages.lastStatus'], 
      searchValue: [],
      rangeTopic : "",
      rangeFrom: "",
      rangeTo: "",
      limit: "20",
      skip: "0",
      search : false,
    });

    const [waitingDPSearch, setWaitingDPSearch] = useState<any>({ 
      searchTopic : [], 
      searchValue: [],
      rangeTopic : "",
      rangeFrom: "",
      rangeTo: "",
      limit: "20",
      skip: "0",
      search : false
    });

    function getDataListStatus(data:any, status :string) {
      let count = 0
      let order_status = find(data, { "_id": status })
      if(order_status !== undefined) {
        count = order_status.count
      } 
      return count
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setStateLocation([])
      history.push({
        pathname: "/dashboard/purchase-orders"
      })
      setValue(newValue);
    };

    useEffect(() => {
      if(location.state !== null && location.state !== undefined) {
        setStateLocation(location.state.payload)
      }
    }, [location]);

    useEffect(() => {
      if(dataSearch.searchValue.length) {
        dispatch(getPurchaseOrdersDataNew(dataSearch))
      } else {
        dispatch(getPurchaseOrdersDataStatus())
      }

      // eslint-disable-next-line
    }, [dataSearch.searchValue]); 

    useEffect(() => {
      if(waitingDPSearch.run) {
        dispatch(getPurchaseOrdersBySearchWaitingDP(waitingDPSearch))
      }
      dispatch(getPurchaseOrdersWaitingDP())
      // eslint-disable-next-line
    }, [waitingDPSearch]); 

    useEffect(() => {
      dispatch(getPurchaseOrdersData())
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if(location.state === null && location.state === undefined ) {
        // dispatch(getPurchaseOrdersData()) 
        setStateLocation(location.state.payload)
      }
      // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
      if(active_page === "Waiting DP" ) {
        setValue(0)
        setActiveTab(active_page)
      } else if(active_page === "New" ) {
        setValue(1)
        setActiveTab(active_page)
      } else if(active_page === "Pick" ) {
        setValue(2)
        setActiveTab(active_page)
      } else if(active_page === "Ready to Ship" ) {
        setValue(3)
        setActiveTab(active_page)
      } else if(active_page === "Shipped" ) {
        setValue(4)
        setActiveTab(active_page)
      } else if(active_page === "Delivered" ) {
        setValue(5)
        setActiveTab(active_page)
      } else if(active_page === "Paid" ) {
        setValue(6)
        setActiveTab(active_page)
      } else if(active_page === "Cancel" ) {
        setValue(7)
        setActiveTab(active_page)
      } else {
        setValue(1)
        
      }
    }, [active_page]);


    useEffect(() => {
      if(value === 0) {
        setActiveStatus("Waiting DP")
        if(stateLocation.length !== 0) {
          setWaitingDPSearch({...waitingDPSearch, 
            searchTopic: ['code_po', 'lastStatus'],
            searchValue: [stateLocation.orderCode, 'Waiting Down Payment'],
            run : true
          })
        } else {
          setWaitingDPSearch({...waitingDPSearch, 
            searchTopic: ['lastStatus'],
            searchValue: ['Waiting Down Payment'],
            run : true
          })
        }
      }
      if(value === 1) {
        setActiveStatus("New")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'New']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['New']
          })
        }
      }
      if(value === 2) {
        setActiveStatus("Pick")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Pick']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Pick']
          })
        }
      }
      if(value === 3) {
        setActiveStatus("Ready to Ship")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Ready to Ship']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Ready to Ship']
          })
        }
      }
      if(value === 4) {
        setActiveStatus("Shipped")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Shipped']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Shipped']
          })
        }
      }
      if(value === 5) {
        setActiveStatus("Delivered")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Delivered']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Delivered']
          })
        }
      }
      if(value === 6) {
        setActiveStatus("Paid")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Paid']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Paid']
          })
        }
      }
      if(value === 7) {
        setActiveStatus("Cancel")
        if(stateLocation.length !== 0) {
          setDataSearch({...dataSearch, 
            searchTopic: ['code_po', 'packages.lastStatus'],
            searchValue: [stateLocation.orderCode, 'Cancel']
          })
        } else {
          setDataSearch({...dataSearch, 
            searchTopic: ['packages.lastStatus'],
            searchValue: ['Cancel']
          })
        }
      }
      // eslint-disable-next-line
    }, [value, stateLocation]); 

    function getStyle (val : any) {
      return val === value ? `${process.env.REACT_APP_THEMED_COLOR}` : '#fff'
    }

    function BadgeLabel ({name, total, index} : any) {
        return (
          <Badge 
            color={total === 0 ? "primary" : "error"}
            badgeContent={total === 0 ? '' : total}  
          >
            <Box  
              fontSize={12} 
              fontWeight="bold" 
              sx={{ 
                mr: 1, 
                backgroundColor: index === value ? '#fff' : '',
                padding: '5px 10px',
                borderRadius: '5px',
                fontFamily: 'Roboto', 
                color: getStyle(index) 
              }}
            >{name}</Box>
          </Badge>
        )
    }   

    const styleTabs = { 
        borderBottom: 1, 
        borderColor: 'divider', 
        backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`
    }    
    
    return (
      <div id="scrollableDiv" style={{ height: '85vh', overflow: "auto", }} className="overhiddenflow" >
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={false}
                current="Purchase Orders Page"
            />
           <Box sx={{pt:2}}>
                <h3>Purchase Orders</h3>
           </Box>

           <Box sx={{ width: '100%', mt: 3 }}>
                <Box sx={styleTabs}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" color="error">
                        <Tab 
                          label={
                            <BadgeLabel 
                              name="Waiting Down Payment" 
                              total={count_dp} 
                              index={0} 
                            /> 
                          } 
                          {...a11yProps(0)} 
                        />
                        { array_tabs.map((val,idx) => (
                        <Tab 
                          key={idx}
                          label={
                            <BadgeLabel 
                              name={val.label}
                              total={getDataListStatus(data_status && data_status, val.value)} 
                              index={idx+1} 
                            /> 
                          } 
                          {...a11yProps(1)} 
                        />
                        ))}
                    </Tabs>
                </Box>
                <Paper >
                  <TabPanel value={value} index={0} >
                    <SearchDataDP 
                      data={data_dp && data_dp} 
                      progressPending={loading_data_dp}
                      activeTab={activeTab}
                      status="Waiting Down Payment"
                      waitingDPSearch={waitingDPSearch} 
                      setWaitingDPSearch={setWaitingDPSearch}
                    />
                    { loading_data_dp_all ? <LoaderBackdrop loading={loading_data_dp_all} /> :
                    <>
                      { data_dp_all && 
                        <Box>
                          { !loading_data_dp_all && data_dp_all.length === 0 ? 
                            <EmptyData 
                              message={`Data order with status Waiting Down Payment not found!`} 
                            />
                            :   
                            <Box>
                              <CardOrderDP 
                                data={data_dp_all} 
                                array_tabs={array_tabs}
                                dataSearch={waitingDPSearch}
                                setDataSearch={setWaitingDPSearch}
                                status={activeTab}
                                setActiveStatus={setActiveStatus}
                                activeStatus={activeStatus}
                                stateLocation={stateLocation}
                              />
                            </Box>
                          }
                        </Box>
                      }
                      </>
                    }
                  </TabPanel>
                  { array_tabs.map((val,idx) => (
                    <TabPanel value={value} index={idx+1} key={idx}>
                      <SearchData 
                        data={data} 
                        progressPending={loading}
                        dataSearch={dataSearch}
                        setDataSearch={setDataSearch}
                        status={val.value}
                        activeTab={activeTab}
                        setActiveStatus={setActiveStatus}
                        activeStatus={activeStatus}
                      />
                      { loading_orders_new ? <LoaderBackdrop loading={loading_orders_new} /> :
                        <>
                        { orders_new && 
                          <Box>
                            { orders_new.length === 0 ?  
                              <EmptyData 
                                message={`Data order with status ${val.label} not found!`} 
                              />
                              : 
                              <Box>
                                <CardOrder 
                                  data={orders_new} 
                                  array_tabs={array_tabs}
                                  dataSearch={dataSearch}
                                  status={activeTab}
                                  setActiveStatus={setActiveStatus}
                                  activeStatus={activeStatus}
                                  stateLocation={stateLocation}
                                />
                              </Box>
                            }
                          </Box> 
                        }
                        </>
                      }
                    </TabPanel>
                  ))}
                </Paper>
           </Box>
        </Box>
      </div>
    )
}

export default PurchaseOrders;
