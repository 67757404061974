import { Stack, Box, Button, Paper, TextField, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { userCredentials } from '../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import swal from 'sweetalert';
import { changeDefaultCompanyAddress, getCompanyAddress, postCompanyAddress, removeCompanyAddress, updateCompanyAddress } from '../reducers/profilesReducers';
import AddressForm from '../../../../components/AddressForm';
import PhoneInput from 'react-phone-number-input'

const CompanyAddress = () => {

    const dispatch = useDispatch()
    const store_profile = useSelector((state : RootState) => state.profile)

    const [index, setIndex] = useState<any>("");
    const [open, setOpen] = useState({
        open : false,
        name : ""
    });

    const [valueAddress, setValueAddress] = useState<any>({
        country : "Indonesia",
        country_id : "1",
        error_country : false,
        province : "",
        province_id : "",
        error_province : false,
        city : "",
        city_id : "",
        error_city : false,
        subdistrict : "",
        subdistrict_id : "",
        error_subdistrict : false,
        village : "",
        village_id : "",
        error_village : false,
        zipcode : "",
        zipcode_id : "",
        error_zipcode : false,
        street : "",
        name : "",
        phone : "",
        id: null,
        is_default : false
    });

    const onChangeValue = (e:any) => {
        setValueAddress({...valueAddress, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen({...open, open: false, name : "" })
        setValueAddress({...valueAddress, 
            country : "Indonesia",
            error_country : false,
            province : "",
            error_province : false,
            city : "",
            error_city : false,
            subdistrict : "",
            error_subdistrict : false,
            village : "",
            error_village : false,
            zipcode : "",
            zipcode_id : "",
            error_zipcode : false,
            street : "",
            name : "",
            phone : "",
            id: null,
            is_default : false
        })
    }

    const handleOpen = (name : any) => {
        setOpen({...open, open: true, name : name })

    }

    function checkValidation() {
        let validate = false
        if(valueAddress.province === "") {
            setValueAddress({...valueAddress, error_province : true})
            validate = false
        } else if (valueAddress.city === "") {
            setValueAddress({...valueAddress, error_city : true})
            validate = false
        } else if (valueAddress.subdistrict === "") {
            setValueAddress({...valueAddress, error_subdistrict : true})
            validate = false
        } else if (valueAddress.village === "") {
            setValueAddress({...valueAddress, error_village : true})
            validate = false
        } else {
            validate = true
        }
        return validate
    }

    const onClickSubmit = (e : any) => {
        e.preventDefault()
        if(checkValidation()) {
            if(open.name === "Update") {
                let body_send_update = {
                    "_id" : valueAddress.id,
                    "buyer_id": userCredentials.buyer_id,
                    "name": valueAddress.name,
                    "phone":valueAddress.phone,
                    "is_default": valueAddress.is_default,
                    "country": valueAddress.country,
                    "country_id": valueAddress.country_id,
                    "province": valueAddress.province,
                    "province_id": valueAddress.province_id,
                    "city": valueAddress.city,
                    "city_id": valueAddress.city_id,
                    "subdistrict": valueAddress.subdistrict,
                    "subdistrict_id": valueAddress.subdistrict_id,
                    "village": valueAddress.village,
                    "village_id": valueAddress.village_id,
                    "postcode": valueAddress.zipcode,
                    "postcode_id": valueAddress.zipcode_id,
                    "street": valueAddress.street,
                }
                // console.log(body_send_update, 'body send')
                dispatch(updateCompanyAddress(body_send_update))
            } else {
                let body_send_post = {
                    "buyer_id": userCredentials.buyer_id,
                    "name": valueAddress.name,
                    "phone":valueAddress.phone,
                    "is_default": false,
                    "country": valueAddress.country,
                    "country_id": valueAddress.country_id,
                    "province": valueAddress.province,
                    "province_id": valueAddress.province_id,
                    "city": valueAddress.city,
                    "city_id": valueAddress.city_id,
                    "subdistrict": valueAddress.subdistrict,
                    "subdistrict_id": valueAddress.subdistrict_id,
                    "village": valueAddress.village,
                    "village_id": valueAddress.village_id,
                    "postcode": valueAddress.zipcode,
                    "postcode_id": valueAddress.zipcode_id,
                    "street": valueAddress.street,
                }
                // console.log(body_send_post, 'body send')
    
                dispatch(postCompanyAddress(body_send_post))
            }
        }
    }

    useEffect(() => {
        if(store_profile.address_post) {
            handleClose()
            swal('Success', 'Success Add Address', 'success')
            dispatch(getCompanyAddress())
        }
        // eslint-disable-next-line
    }, [store_profile.address_post])

    useEffect(() => {
        if(store_profile.address_update) {
            handleClose()
            swal('Success', 'Success Update Address', 'success')
            dispatch(getCompanyAddress())
        }
        // eslint-disable-next-line
    }, [store_profile.address_update])

    useEffect(() => {
        if(store_profile.address_default) {
            setIndex("")
            swal('Success', 'Success Change to Default Address', 'success')
            dispatch(getCompanyAddress())
        }
        // eslint-disable-next-line
    }, [store_profile.address_default])

    useEffect(() => {
        if(store_profile.address_remove) {
            setIndex("")
            swal('Success', 'Success Remove Address', 'success')
            dispatch(getCompanyAddress())
        }
        // eslint-disable-next-line
    }, [store_profile.address_remove])

    useEffect(() => {
        dispatch(getCompanyAddress())
        // eslint-disable-next-line
    }, []);

    return (
        <Stack mt={1}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Box fontWeight="700" fontSize={22}>List Address </Box>
                <Box>
                    <Button
                        variant='contained'
                        size="small"
                        color="primary"
                        onClick={() => handleOpen('Create New')}
                    >
                        Add New Address
                    </Button>
                </Box>
            </Stack>

            { store_profile.loading_address ? 
            <Box pt={3}>Loading...</Box>
            :
            <Stack>
                { store_profile.address.length === 0 ? 
                <Stack mt={2}>
                    <Paper elevation={3} sx={{ border: '#ddd 2px solid' }}>
                        <Box p={3}>
                            <Box pt={1} fontWeight="700">
                                You dont have any address. Please create at least one address!
                            </Box>
                        </Box>
                    </Paper>
                </Stack> 
                :  
                <Stack>
                {store_profile.address.map((data:any, i: any) => (
                <Stack mt={2} key={i}>
                    <Paper elevation={3} sx={{ border: !data.is_default ? '#fff 2px solid' : `${process.env.REACT_APP_THEMED_COLOR} 2px solid` }}>
                        <Box p={3}>
                            <Box fontWeight="600">{data.name}</Box>
                            <Box fontWeight="400">{data.phone}</Box>
                            <Box pt={1}>
                               {data.street} <br/>
                               {data.village}, {data.subdistrict}, {data.city} <br/>
                               {data.province}, {data.postcode}
                            </Box>
                            <Stack pt={2} flexDirection="row">
                                { data.is_default ? 
                                <Box mr={2}>
                                    <Box
                                        sx={{
                                            backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`,
                                            color: '#fff',
                                            padding: '3px 10px',
                                            borderRadius: 5
                                        }}
                                    >
                                        Default Address
                                    </Box>
                                </Box>
                                :
                                <Box mr={2}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            setIndex(i)
                                            dispatch(changeDefaultCompanyAddress(data._id))
                                        }}
                                    >
                                        { store_profile.loading_address_default && index === i ?
                                         "Loading..." : 
                                         "Change to Default" }
                                    </Button>
                                </Box>
                                }
                                <Box mr={2}>
                                    <Button
                                        size="small"
                                        color="info"
                                        onClick={() => {
                                            setValueAddress({...valueAddress, 
                                                country : data.country,
                                                country_id : data.country_id,
                                                province : data.province,
                                                province_id : data.province_id,
                                                city : data.city,
                                                city_id : data.city_id,
                                                subdistrict : data.subdistrict,
                                                subdistrict_id : data.subdistrict_id,
                                                village : data.village,
                                                village_id : data.village_id,
                                                street : data.street,
                                                name : data.name,
                                                zipcode : data.postcode,
                                                zipcode_d : data.postcode_id,
                                                phone : data.phone,
                                                id : data._id,
                                                is_default : data.is_default
                                            })
                                            handleOpen('Update')
                                        }}
                                    >
                                        Change Address
                                    </Button>
                                </Box>
                                <Box mr={2}>
                                    <Button
                                        size="small"
                                        color="error"
                                        disabled={data.is_default ? true : false}
                                        onClick={() => {
                                            setIndex(i)
                                            dispatch(removeCompanyAddress(data._id))
                                        }}
                                    >
                                        { store_profile.loading_address_remove && index === i ?
                                         "Loading..." : 
                                         "Remove" }
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                </Stack>
                ))}
            </Stack> }
            </Stack> }

            <Dialog open={open.open} maxWidth="lg" fullWidth={true}  >
                <form onSubmit={onClickSubmit}>
                    <DialogTitle>{open.name} Address</DialogTitle>
                    <DialogContent>
                        <Paper sx={{ border: '1px solid #ccc' }}>
                        <Stack p={3}>
                            <Grid container >
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={1}><h4>Name / House / Office</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <TextField 
                                        label="Name / House / Office"
                                        onChange={onChangeValue}
                                        value={valueAddress.name}
                                        name="name"
                                        type="text"
                                        margin='dense'
                                        size='small'
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={3} lg={4} xs={12}>
                                    <Box pt={1}><h4>Phone Number</h4></Box>
                                </Grid>
                                <Grid item xl={9} lg={8} xs={12}>
                                    <Box>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={valueAddress.phone}
                                            onChange={(e) => setValueAddress({...valueAddress, phone : e})}
                                            defaultCountry="ID"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <AddressForm 
                                setValueAddress={setValueAddress}
                                valueAddres={valueAddress}
                            />
                        </Stack>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancel</Button>
                        {open.name === "Update" ? 
                        <Button variant="contained" type="submit">
                            { store_profile.loading_address_update ? "Loading" : "Save Address" } 
                        </Button>
                        :
                        <Button variant="contained" type="submit">
                        { store_profile.loading_address_post ? "Loading" : "Create Address" } 
                        </Button> }
                    </DialogActions>
                </form>
            </Dialog>
        </Stack>
    )
}

export default CompanyAddress
