import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import {
  ICartPurchaseRequests
} from '../purchaseRequestTypes'

import { 
    getLocalDBCarts,
    addToLocalDBCarts, 
    addQuantityProduct,
    removeQuantityProduct,
    removeItemProduct,
    removeVendorItems,
    resetCart,
    savePurchaseRequest,
    submitPurchaseRequest,
    updateItemPurchaseRequest,
    postSubmitPurchaseRequest,
    onChangeQuantityProduct
} from './reducers/createPurchaseRequestReducers'


const initialState: ICartPurchaseRequests = {
  carts: [], 
  loading : true,
  error : null,
  loading_add : false,
  error_add : null,
  success_add : false,
  success_reset : false,
  message_snackbar : null,
  success_save: false,
  loading_save: false,
  error_save: null,
  submit : false,
  loading_submit : false,
  error_submit : null,
  update_item : false,
  loading_update_item : false,
  auto_saved : 0,
  date_saved : null
};

export const createPurchaseRequest = createSlice({
  name: 'carts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get local db
      .addCase(getLocalDBCarts.pending, (state) => {
        state.loading = true;
        state.success_add = false
        state.success_save = false
        state.success_reset = false
      })
      .addCase(getLocalDBCarts.fulfilled, (state, action:any) => {
        state.loading = false;
        state.carts = action.payload;
      })
      .addCase(getLocalDBCarts.rejected, (state, action : any) => {
        state.loading = false;
        state.error = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // add item to local db
      .addCase(addToLocalDBCarts.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(addToLocalDBCarts.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        state.message_snackbar = action.payload.message
        state.auto_saved = state.auto_saved + 1
      })
      .addCase(addToLocalDBCarts.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // increase quantity item from local db
      .addCase(addQuantityProduct.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(addQuantityProduct.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        state.message_snackbar = action.payload.message
        state.auto_saved = state.auto_saved + 1
      })
      .addCase(addQuantityProduct.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      //  change quantity item from local db
      .addCase(onChangeQuantityProduct.pending, (state) => {
        state.loading_add = true;
        state.success_add = false
        state.success_save = false
        state.success_reset = false
      })
      .addCase(onChangeQuantityProduct.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        state.message_snackbar = action.payload.message
        state.auto_saved = state.auto_saved + 1
      })
      .addCase(onChangeQuantityProduct.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })

      // decrease quantity item from local db
      .addCase(removeQuantityProduct.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(removeQuantityProduct.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        state.message_snackbar = action.payload.message
        state.auto_saved = state.auto_saved + 1
      })
      .addCase(removeQuantityProduct.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // remove item from local db
      .addCase(removeItemProduct.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(removeItemProduct.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        state.auto_saved = state.auto_saved + 1
        // state.message_snackbar = action.payload.message
      })
      .addCase(removeItemProduct.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // remove all item by vendor from local db
      .addCase(removeVendorItems.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(removeVendorItems.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_add = action.payload.success;
        // state.message_snackbar = action.payload.message
      })
      .addCase(removeVendorItems.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // remove all item from local db
      .addCase(resetCart.pending, (state) => {
        state.loading_add = true;
      })
      .addCase(resetCart.fulfilled, (state, action:any) => {
        state.loading_add = false;
        state.success_reset = action.payload.success;
        state.message_snackbar = action.payload.message;
        window.location.reload()
      })
      .addCase(resetCart.rejected, (state, action : any) => {
        state.loading_add = false;
        state.error_add = action.payload; 
        swal("Error", `${action.payload}`, 'error')
      })
      // save purchase request  
      .addCase(savePurchaseRequest.pending, (state) => {
        state.loading_save = true;
        state.success_save = false
      })
      .addCase(savePurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_save = false;
        state.success_save = action.payload.success;
        if(action.payload.save !== 'auto') {
          swal("Success", `${action.payload.message}`, 'success')
        }
      })
      .addCase(savePurchaseRequest.rejected, (state, action : any) => {
        state.loading_save = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // submit purchase request from create pr
      .addCase(submitPurchaseRequest.pending, (state) => {
        state.loading_submit = true;
      })
      .addCase(submitPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_submit = false;
        state.submit = action.payload.submit;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(submitPurchaseRequest.rejected, (state, action : any) => {
        state.loading_submit = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // submit purchase request from list
      .addCase(postSubmitPurchaseRequest.pending, (state) => {
        state.loading_submit = true;
      })
      .addCase(postSubmitPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_submit = false;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(postSubmitPurchaseRequest.rejected, (state, action : any) => {
        state.loading_submit = false;
        swal("Error", `${action.payload}`, 'error')
      })
      // update item purchase request 
      .addCase(updateItemPurchaseRequest.pending, (state) => {
        state.loading_update_item = true;
        state.update_item = false
      })
      .addCase(updateItemPurchaseRequest.fulfilled, (state, action:any) => {
        state.loading_update_item = false;
        state.update_item = action.payload.data;
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(updateItemPurchaseRequest.rejected, (state, action : any) => {
        state.loading_update_item = false;
        swal("Error", `${action.payload}`, 'error')
      })
  },
});

export default createPurchaseRequest.reducer;
