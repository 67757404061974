import { createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert'
import { checkStatusPayment, getBankVendor, getDataAllBank, getDataInvoice, getInvoicesBySearch, 
  postCreatePaymentWithOY, postCreatePaymentWithXendit, postPayBankTransfer,
  uploadProofPayment  
} from './reducers/invoiceReducers';

const initialState: any = {
  data: [], 
  loaded : false,
  loading : false,
  create_va : false,
  response_va : {},
  loading_create_va : false,
  bank : [],
  loading_bank : true,
  check : false,
  check_payment : false,
  loading_check_payment : false,
  bank_transfer : true,
  loading_bank_transfer: false,
  proof_payment : false,
  loading_proof_payment : false,
  bank_vendor : [],
  loading_bank_vendor: false,
};

export const getInvoiceSlice = createSlice({
  name: 'invoices',
  initialState, 
  reducers: {},
  extraReducers: (builder) => {
    builder 
      // get invoices
      .addCase(getDataInvoice.pending, (state) => {
        state.loading = true;
        state.data = []
        state.bank_transfer = false;
      })
      .addCase(getDataInvoice.fulfilled, (state, action:any) => {
        state.data = action.payload.data;
        state.loaded = true
        state.loading = false;
      })
      .addCase(getDataInvoice.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get invoices by search
      .addCase(getInvoicesBySearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoicesBySearch.fulfilled, (state, action:any) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getInvoicesBySearch.rejected, (state, action : any) => {
        state.loading = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // get bank
      .addCase(getDataAllBank.pending, (state) => {
        state.loading_bank = true;
      })
      .addCase(getDataAllBank.fulfilled, (state, action:any) => {
        state.loading_bank = false;
        state.bank = action.payload.data;
      })
      .addCase(getDataAllBank.rejected, (state, action : any) => {
        state.loading_bank = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // create virtual account oy
      .addCase(postCreatePaymentWithOY.pending, (state) => {
        state.loading_create_va = true;
      })
      .addCase(postCreatePaymentWithOY.fulfilled, (state, action:any) => {
        state.loading_create_va = false;
        state.create_va = action.payload.data;
        state.response_va = action.payload.response;
        localStorage.setItem('transaction_id', JSON.stringify(action.payload.response))
      })
      .addCase(postCreatePaymentWithOY.rejected, (state, action : any) => {
        state.loading_create_va = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // create virtual account xendit
      .addCase(postCreatePaymentWithXendit.pending, (state) => {
        state.loading_create_va = true;
      })
      .addCase(postCreatePaymentWithXendit.fulfilled, (state, action:any) => {
        state.loading_create_va = false;
        state.create_va = action.payload.data;
        state.response_va = action.payload.response;
        localStorage.setItem('transaction_id', JSON.stringify(action.payload.response))
      })
      .addCase(postCreatePaymentWithXendit.rejected, (state, action : any) => {
        state.loading_create_va = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // pay bank transfer
      .addCase(postPayBankTransfer.pending, (state) => {
        state.loading_bank_transfer= true;
      })
      .addCase(postPayBankTransfer.fulfilled, (state, action:any) => {
        state.loading_bank_transfer = false;
        state.bank_transfer = action.payload.data;
      })
      .addCase(postPayBankTransfer.rejected, (state, action : any) => {
        state.loading_bank_transfer = false;
        swal("Error", `${action.payload}`, 'error')
      })

      // check status payment
      .addCase(checkStatusPayment.pending, (state) => {
        state.loading_check_payment = true;
        state.check = false
      })
      .addCase(checkStatusPayment.fulfilled, (state, action:any) => {
        state.loading_check_payment = false;
        state.check_payment = action.payload.response;
        state.check = true
      })
      .addCase(checkStatusPayment.rejected, (state, action : any) => {
        state.loading_check_payment = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
      // upload proof payment
      .addCase(uploadProofPayment.pending, (state) => {
        state.loading_proof_payment = true;
        state.proof_payment = false
      })
      .addCase(uploadProofPayment.fulfilled, (state, action:any) => {
        state.loading_proof_payment = false;
        state.proof_payment = true
        swal("Success", `${action.payload.message}`, 'success')
      })
      .addCase(uploadProofPayment.rejected, (state, action : any) => {
        state.loading_proof_payment = false;
        swal("Error", `${action.payload}`, 'error')
      })

       // get bank vendor
       .addCase(getBankVendor.pending, (state) => {
        state.loading_bank_vendor = true;
        state.bank_vendor = []
      })
      .addCase(getBankVendor.fulfilled, (state, action:any) => {
        state.loading_bank_vendor = false;
        state.bank_vendor = action.payload.data;
      })
      .addCase(getBankVendor.rejected, (state, action : any) => {
        state.loading_bank_vendor = false;
        swal("Error", `${action.payload}`, 'error')
      })
      
  },
});

export default getInvoiceSlice.reducer;
