import { Box, Stack, Skeleton } from '@mui/material';

const BuyerByOrder : React.FC<any> = ({ data }) => {


    const stylesHeader : any = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        height: '40px',
        pt: 2,
        backgroundColor: `${process.env.REACT_APP_THEMED_COLOR}`
    }

    const stylesColumnLeft : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        padding: '5px 0'

    }

    const stylesColumn : any  = {
        borderBottom: '1px solid #000', 
        borderLeft: '1px solid #000', 
        borderRight: '1px solid #000', 
        textAlign: 'center',
        width : '100%',
        padding: '5px 0'

    }

    return (
        <Box pt={2}>
            { data.loading ?
                <Box>
                    <Skeleton height={70} variant="rectangular" />
                </Box>
                :
                <Box>
                    <Box sx={stylesHeader} >Seller by sales Value</Box>
                    { data.data.seller ? 
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Box sx={{width: '100%'}}>
                            <Box sx={stylesColumnLeft}>Seller</Box>
                            { data.data.seller.map((data : any,i :any) => (
                                <Box sx={stylesColumnLeft} key={i} fontSize={12}>{data.vendorName}</Box>
                            )) }
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <Box sx={stylesColumn}>Total</Box>
                            { data.data.seller.map((data : any,i :any) => (
                                <Box sx={stylesColumn} key={i} fontSize={12}>Rp. {data.total.toLocaleString()}</Box>
                            )) }
                        </Box>
                    </Stack> : null }
                </Box>
            }
        </Box>
    )
}

export default BuyerByOrder
