import React, { CSSProperties, useEffect, useState } from 'react';
import { 
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    Box, 
    Stack,
    Button,
    Autocomplete,
    TextField,
    MenuItem,
    Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'; 
// import { RootState } from '../../../../app/store';
import { postApprovalItem, postRejectItem, postSendEmail,getAllUsersTeams } from '../reducers/purchaseOrdersReducers';
import TableItemGRN from './TableItemGRN';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  pdf,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PrintDocument from '../../../../components/PrintOrders';
import { IData } from '../../globalTypes';
import AWS from 'aws-sdk'
import { RootState } from '../../../../app/store';
import swal from 'sweetalert';

interface setConfig{
  digitalOceanSpaces : string |undefined,
  bucketName : string | undefined
}
const config : setConfig = {
  digitalOceanSpaces: process.env.REACT_APP_S3_API_URL,
  bucketName : process.env.REACT_APP_S3_BUCKET_NAME
}

const styleTableRow = () => {
  return {fontSize: '11px', fontWeight: '600'}
}

const styleTableColumn = () => {
  return {fontSize: '11px', fontWeight: '500'}
}

const autocompleteTextfield : React.CSSProperties = {
    fontSize : '13px',
    color : '#43a047',
    textTransform : 'uppercase',
    fontWeight : '430',
    textAlign : 'center',
    display : 'flex',
    justifyContent : 'center',
    alignContent : 'center',
    alignItems : 'center',
}

const boxAutocomplete = {
  display : 'flex', justifyContent : 'end',width :'100px',
  '&:hover': {
    width : '300px', 
    display : 'flex', 
    justifyContent : 'end'
  },
  '& .MuiOutlinedInput-root' : {
    height:'31px', fontSize:'14px',
    '& .MuiOutlinedInput-input' : {
      textOverflow : 'ellipsis', overflow : 'hidden', width : '20px'
    }
  },

}

const autocompleteStyle = {
  width : '200px', 
  '& .MuiFormControl-root' : { 
    '& .MuiFormLabel-root' : { 
      lineHeight : '13px'} 
    }, 
    "& .MuiOutlinedInput-notchedOutline" :{
      borderColor : "rgba(67, 160, 71, 0.5) !important",
      fontFamily : "Roboto,Helvetica,Arial,sanSerif"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#43a047 !important',
    },
}

const emailValid : CSSProperties = {
  color : 'grey',
  fontSize : '12px',
  textAlign : 'start',
  fontWeight: '500'
}
const emailInValid : CSSProperties = {
  color : 'red',
  fontSize : '12px',
  textAlign : 'start',
  fontWeight: '450'
}

const TableItem : React.FC<any> = ({ data, status, dataOrders, department, vendor_name, vendor_id }) => {  
  const dispatch = useDispatch()
  const history = useHistory()

  const selector = useSelector((state:RootState) => state.purchase_orders)
  const arrEmailVendor = selector.email_value_vendor
  
  const [inputValue, setInputValue] = React.useState<string>('');
  const [emailFocus, setEmailFocus] = React.useState<any>({
    state : false,
    id : null
  })
  const [isRegexEmailPassed,setIsRegexEmailPassed] = React.useState<boolean>(false)
  const [listEmailOption, setListEmailOption] = React.useState<IData[]>([])
  const [inValidEmail, setInvalidEmail] = React.useState<boolean>(false)

  const [dataGRN, setDataGRN] = useState<any>({
    grn : false,
    id : null,
    data : []
  });

  useEffect(()=>{
    if(status === 'Ready to Process'){
      function filterEmail(){
        const filteredEmailVendor = arrEmailVendor.filter((e:any) => e.vendor_id === vendor_id._id)
        setListEmailOption(filteredEmailVendor)
      }
      filterEmail()
    }
    // eslint-disable-next-line
  },[emailFocus])

  useEffect(()=>{
    if(status === 'Ready to Process'){
      function getAllUsers(){
        dispatch(getAllUsersTeams(vendor_id))
      }
      getAllUsers()
    }
    // eslint-disable-next-line
  },[])

  const regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  React.useEffect(()=>{
    if (regexEmail.test(inputValue)){
      setIsRegexEmailPassed(true)
      setInvalidEmail(false)
    }else{
      setIsRegexEmailPassed(false)
      setInvalidEmail(true)
    }
     // eslint-disable-next-line
  },[inputValue])
  

  const onClickCreateGRN = (element : any) => {
    
    let itemsNew = []
    for(let k of element.items) {
      itemsNew.push({...k, 
        received : k.quantity, 
        sub_total_received : k.discount_price * k.quantity,
        sub_total_tax : k.single_tax_price * k.quantity
      })
    }
    let newItems = {
      items: itemsNew,
      lastStatus: element.lastStatus,
      code_package: element.code_package,
      payment_terms: element.payment_terms,
      shipping_cost: element.shipping_cost,
      statuses: element.statuses,
      total: element.total,
      updated: element.updated,
      _id: element._id,
    }
    
    setDataGRN({...dataGRN, grn: true, data : newItems,  id : element._id })
  }

  const onClickActionItem = (status : any, row : any, package_id : any) => {
    let data_send = {
      data : {
        vendorId : row.vendorId,
        packageId : package_id,
        itemsId : row._id
      }
    }
    Swal.fire({
        title: 'Warning!',
        text: `Are you sure want to ${status} this Item?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: status === "Reject" ? '#d33' : 'green',
        cancelButtonColor: status === "Reject" ? 'primary' : '#d33',
        confirmButtonText: 'Yes!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if(status !== "Reject") {
            return dispatch(postApprovalItem(data_send))
          } else {
            return dispatch(postRejectItem(data_send))
          }
        },
        allowOutsideClick: () => !Swal.isLoading() 
      }).then((result) => {
        if (result.isConfirmed) {
            
        }
    })
  }

  const onClickPrint = async (val:any, vendor:any) => {
    const data = {
      items : val.items,
      code_package : val.code_package,
      date : val.date,
      payment_terms : val.payment_terms,
      grand_total : val.total,
      code_po : dataOrders.code_po ? dataOrders.code_po : "-",
      vendor : dataOrders.vendor,
      buyer : dataOrders.buyer,
      department: dataOrders.department
    }
    const doc = <PrintDocument data={data}/>;
    const asPdf : any = pdf(); 
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Purchase Order ${vendor} - ${val.code_package}.pdf`);
    
  }

  const handleSendEmail = async () =>{
    try {
    const folderName = 'emailPO'
    let selectedEmail = inputValue
    let emailPO = dataOrders    

    for(let dataEmail of data){
      const dataForPdf = {
        items : dataEmail.items,
        code_package : dataEmail.code_package,
        date : dataEmail.date,
        payment_terms : dataEmail.payment_terms,
        grand_total : dataEmail.total,
        code_po : dataOrders.code_po ? dataOrders.code_po : "-",
        vendor : dataOrders.vendor,
        buyer : dataOrders.buyer,
        department: dataOrders.department
      }
      
    const doc : JSX.Element = <PrintDocument data={dataForPdf}/>;
    const asPdf : any = pdf(); 
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    
    const spacesEndpoint : AWS.Endpoint = new AWS.Endpoint("sgp1.digitaloceanspaces.com");
    const s3 : AWS.S3.ClientConfiguration | undefined | any = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
      secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET
      });
      const params = {
        Body: blob, 
        Bucket: `${config.bucketName}`, 
        Key: folderName+'/'+vendor_name
     };
     
      s3.putObject(params)
       .on('build', (request : any) => {
         request.httpRequest.headers.Host = `${config.digitalOceanSpaces}`;
         request.httpRequest.headers['Content-Length'] = blob.size;
         request.httpRequest.headers['Content-Type'] = blob.type;
         request.httpRequest.headers['x-amz-acl'] = 'public-read';
      })
      .send((err : string) => {
        if (err) return swal("Error", "Error "+ err, "error")
        else {
          const fileUrl : string = `${config.digitalOceanSpaces}/${folderName}/${vendor_name}`
          
            const sendEmailTemplate = {
              "subject" : "Purchase Order Information",
              "template" : "order-send",
              "email" : selectedEmail !== undefined? selectedEmail : emailPO.vendor.email, 
              "context" : {
                "company" : "",
                "code" : emailPO.code_po,
                "custom" : {
                  "buyer" : emailPO.buyer.name,
                  "vendor" : emailPO.vendor.name,
                  "link" : fileUrl,
                  "NomerMbilesaaBuyerregistration" : emailPO.buyer.phone,
                  "EmaildariuseryangmelakukanpengirimianPOiNI" : emailPO.buyer.email,
                  "Buyercompanyname" : emailPO.buyer.name
                }
              }
            }
          dispatch(postSendEmail(sendEmailTemplate))  
          return swal("Success","Success send Email", "Success")
        }
      });
    }  
    } catch (error) {
      return swal("Error","Error send Email","error")
    }
  }
  
  function sumSubPrice (data:any) {
    const totalPriceVendor = data.items.reduce((total : any, data : any) => {
      return total + data.sub_total
    }, 0)
    return totalPriceVendor;
  }
  
  function backgroundColorStyle (row : any) {
      if((status === "Pick & Pack" && row.statuses.length === 0) || row.lastStatus === "Approve") {
      return "#1bc5bd"
    } else if (status === "Pick & Pack" && row.lastStatus === "Waiting Approval") {
      return "#ffc866"
    } else if((status === "Shipped" && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if((status === "Delivered" && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if((status === "Paid" && row.statuses.length === 0) || row.lastStatus === "Approved") {
      return "#1bc5bd"
    } else if (status === "Cancel") {
      return "#8aa7b2"
    } else {
      return ""
    }
  }

  return (
    <>
    <Box>
      { data.length === 0  ? `This order has no ${status} package!` :
      <Box>
      { data.map((element:any, indexPackage:number) => (
          <div key={indexPackage}>
          { element._id === dataGRN.id ? 
          <TableItemGRN 
            element={dataGRN.data}
            setDataGRN={setDataGRN}
            dataGRN={dataGRN}
            dataOrders={dataOrders}
            department={department}
          />
          : 
          <Box mb={2} key={indexPackage}>
            <Box>
              <Paper square>
                <Stack flexDirection="row" justifyContent="space-between" sx={{ p: 2 }}>
                  <Box>
                    <h5>Department: {department === "generic" ? "N/A" : department}</h5>
                    <h5>Code Package: {element.code_package}</h5>
                    <h5>Payment Terms : {element.payment_terms}</h5>
                  </Box>
                  <Box>
                  <Stack flexDirection="row" >
                    { status === 'Ready to Process' ? 
                    <Box 
                    onClick={()=>setEmailFocus({ state : true, id:indexPackage})}
                    onBlur={()=>setEmailFocus(inputValue === ''? { state:false, id:indexPackage } : { state: true, id:indexPackage})}
                      sx={emailFocus.state && emailFocus.id === indexPackage?{ 
                        width : '300px', display : 'flex', justifyContent : 'end',
                        '& .MuiOutlinedInput-root' : {
                          height:'31px', fontSize:'14px',
                          '& .MuiOutlinedInput-input' : {
                            textOverflow : 'ellipsis', overflow : 'hidden', width : '20px'
                          }
                        },
                      } : boxAutocomplete}
                    >
                      <Autocomplete
                        sx={ autocompleteStyle }
                        openOnFocus
                        autoHighlight
                        ListboxProps = {{ style : {maxHeight : '100px'} }}
                        readOnly={ emailFocus? false : true}
                        size='small'
                        options={ listEmailOption }
                        disablePortal
                        noOptionsText={inValidEmail? <Typography sx={emailInValid}>Email incomplate or invalid</Typography> :<Typography sx={emailValid}>Enter to create email option</Typography>}
                        getOptionLabel={(option:IData) =>( option.email )}
                        onInputChange={(e, newValue) => {
                          setInputValue(newValue);
                        }}
                        renderOption={ (e,option:IData)=>
                            <MenuItem {...e} sx={{ height : '30px'}}>
                              <div style={{ fontSize: '13px', textAlign: 'left', textOverflow : 'ellipsis', overflow: 'hidden' }}>
                                { emailFocus? option.email : null }
                              </div>
                            </MenuItem>  
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Email"
                            sx={{ '& .MuiInputLabel-root' :{
                              ...autocompleteTextfield
                              }
                            }}
                            variant="outlined"
                            size='small'
                            fullWidth
                            onKeyDown={( e : React.KeyboardEvent<HTMLDivElement> ) => {
                              if (
                                e.key === "Enter" 
                                && listEmailOption.findIndex((o : IData) => o.email === inputValue) === -1 
                                && inValidEmail !== true
                              ){
                                setListEmailOption((o : any) => o.concat({ email: inputValue }))
                              }
                            }}
                          />
                        )}
                      />
                      { emailFocus && isRegexEmailPassed?
                        <Button 
                          sx={{ marginLeft : "10px"}}
                          variant='outlined' 
                          color='success' 
                          size='small'
                          onClick={() =>handleSendEmail()}
                        >
                            Send
                        </Button>
                        : null}
                    </Box>
                       : null}
                    <Box pr={1} pl={1}>
                      { element.lastStatus === "Shipped" ? 
                        <Button 
                          variant="contained" color="primary" size="small"
                          onClick={() => onClickCreateGRN(element)}  
                        >
                          Create INBOUND
                        </Button> 
                      : null }
                    </Box>
                    <Box pr={1} pl={1}>
                      { element.lastStatus === "Delivered" ? 
                        <Button 
                          variant="contained" color="primary" size="small"
                          onClick={() => {
                            history.push({
                              pathname: '/dashboard/finance/invoice'
                            })
                          }}  
                        >
                          View Invoices
                        </Button> 
                      : null }
                    </Box>
                    { element.lastStatus !== "Cancel" && dataOrders !== undefined ? 
                      <Box pr={1} pl={1} sx={{ display:'flex' }}>
                          <Box sx={{ margin:'auto', display:'flex', justifyContent: 'center', alignItem:'center', alignContent: 'center' }}>
                            <Button 
                                variant='outlined' size="small" 
                                onClick={() => onClickPrint(element, vendor_name)}
                            >
                                Print Order
                            </Button>
                          </Box>
                      </Box> : null }
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Box>
            <TableContainer sx={{ backgroundColor: '#fff', border: '1px solid #ddd' }}>
              <Table aria-label="simple table"  > 
                  <TableHead >
                    <TableRow >
                        <TableCell width="20px"  sx={styleTableRow()}>No</TableCell>
                        <TableCell width="350px" sx={styleTableRow()}>Name</TableCell>
                        <TableCell width="150px" sx={styleTableRow()}>Retail Price</TableCell>
                        <TableCell width="100px" sx={styleTableRow()}>Disc</TableCell>
                        <TableCell width="200px" sx={styleTableRow()}>Final Price </TableCell>
                        <TableCell width="80px" sx={styleTableRow()}>Ppn</TableCell>
                        <TableCell width="80px" sx={styleTableRow()}>Qty</TableCell>
                        <TableCell width="180px" sx={styleTableRow()}>Sub Total + Ppn</TableCell>
                        { status === "Pick & Pack" ? 
                        <>
                        <TableCell width="150px" sx={styleTableRow()}>Note</TableCell> 
                        <TableCell width="150px" sx={styleTableRow()}>Status</TableCell> 
                        <TableCell width="150px" sx={styleTableRow()}>Action</TableCell> 
                        </> : 
                        <>
                        <TableCell></TableCell> 
                        <TableCell></TableCell> 
                        <TableCell></TableCell> 
                        </> }
                        
                    </TableRow>
                  </TableHead> 
                  { element.items.map((row :any, indexItem : any) => (
                  <TableBody key={indexItem} style={{ backgroundColor: `${backgroundColorStyle(row)}`}}>
                    <TableRow >
                      <TableCell sx={styleTableColumn()}>
                        {indexItem + 1}
                      </TableCell>
                      <TableCell sx={styleTableColumn()}> 
                        <Box 
                          sx={{ cursor: 'pointer', color: 'blue' }}
                          onClick={() => {
                            history.push({
                              pathname: "/dashboard/products",
                              search: `?page=detail-product`, 
                              state : {
                                slug : row.slug
                              }
                            })
                          }}
                        >
                          {row.name}
                        </Box>
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" && row.retail_price !== row.retail_price_original ? 
                          <Box className='span-linethrought'>
                            Rp. {row.retail_price_original.toLocaleString()}
                          </Box> 
                        : null }
                        RP. {row.retail_price.toLocaleString()}
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        {Math.floor(row.discount * 100)}%
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" && row.discount_price !== row.discount_price_original ? 
                          <Box className='span-linethrought'>
                            Rp. {row.discount_price_original.toLocaleString()}
                          </Box> 
                        : null }
                        RP. {row.discount_price.toLocaleString()}
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                          <Box >
                            { row.tax ? row.tax.title : null }
                          </Box> 
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" && row.quantity !== row.quantity_original  ? 
                          <Box className='span-linethrought'>
                            {row.quantity_original}
                          </Box> 
                        : null }
                        {row.quantity}
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" && row.sub_total !== row.sub_total_original ? 
                          <Box className='span-linethrought'>
                            Rp. {row.sub_total_original.toLocaleString()}
                          </Box> 
                        : null }
                        RP. { row.sub_total.toLocaleString()}
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" ? 
                          <Box>
                            {row.note === undefined ? "-" : row.note}
                          </Box> 
                        : "" }
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                        { status === "Pick & Pack" ? 
                          <Box>
                            { row.lastStatus === "Waiting Approval" ? "Waiting Approval" : "OK"}
                          </Box> 
                        : null }
                      </TableCell>
                      <TableCell sx={styleTableColumn()}>
                      { status === "Pick & Pack" && row.lastStatus === "Waiting Approval" ? 
                        <Stack flexDirection="row">
                          <Button 
                            color="success"
                            variant="contained"
                            size="small"
                            sx={{ width: 'fit-content', height: '25px', fontSize: '10px' }}
                            onClick={() => onClickActionItem("Accept", row, element._id)}

                          >Approve</Button>
                          <Button 
                            color="error"
                            variant="contained"
                            sx={{ ml: 1, width: 'fit-content', height: '25px', fontSize: '10px' }}
                            onClick={() => onClickActionItem("Reject",row, element._id )}
                          
                            >Reject</Button>
                        </Stack> : null }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  ))}
              </Table>
            </TableContainer>
            <Box>
              <Paper>
                <Stack flexDirection="row" sx={{ p: 2 }}>
                  <Box fontSize="13px">
                    Sub Total + PPN :
                  </Box>
                  <Box>
                    <Box pl={2}><h5>Rp. { sumSubPrice(element).toLocaleString()}</h5></Box>
                  </Box>
                </Stack>
              </Paper>
            </Box>
        </Box>
        }
        </div>
      )) }
      </Box>
      }
    </Box>
    </>
    )
}

export default TableItem