import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BreadCrumbs from '../../../components/BreadCrumbs';
import TableData from '../../../components/TableData'
import { TableColumn } from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux'
import ModuleItems from './components/ModuleItems';
import swal from 'sweetalert';
import ModuleDetail from './components/ModuleDetail';
import { RootState } from '../../../app/store';
import { fetchRolesCustom, postRolesCustom, updateRolesCustom } from './reducers/customRolesReducers';
import { userCredentials } from '../../../utilities/config';
import Axios from 'axios'; 

// const default_item = [{ 
//     selectedChild : [],
//     selectedParent : [{
//         value : "61d69b1125585e58dcbdae8a",
//         label : "Dashboard"
//     }],
//     selectedFeatures: [],
//     optionFeatures : [],
//     optionChild : [],
//     has_child_module : false,
//     flag: "BUYER"
// }]
    
const CustomRole = () => {

    const dispatch = useDispatch()
    const store_custom_role = useSelector((state : RootState) => state.custom_roles)

    const [roleName, setRoleName] = useState("");
    const [moduleItems, setModuleItems] = useState<any>([]);

    const [moduleDetail, setModuleDetail] = useState<any>([]);
    const [open, setOpen] = useState({
        open : false,
        name : ""
    });
    const [openModule, setOpenModule] = useState(false);
    
    const [IdRole, setIdRole] = useState <any>(null);

    const handleClickOpen = (name : string) => {
        setOpen({...open, open : true, name : name });
    };

    const getFeaturesByid = async () => {
        try {
            const response : any = await Axios.get(`${process.env.REACT_APP_API_SERVER}/feature?flag=BUYER&parent_module_id=61d69b1125585e58dcbdae8a`)
            if(response.data.errors === null) { 
                let selectFeature : any = []

                for(let k of response.data.data) {
                    selectFeature.push({ 
                        value : k._id,
                        label : k.name
                    })
                }

                const default_item = [{ 
                    selectedChild : [],
                    selectedParent : {
                        value : "61d69b1125585e58dcbdae8a",
                        label : "Dashboard"
                    },
                    selectedFeatures: selectFeature,
                    optionFeatures : [],
                    optionChild : [],
                    has_child_module : false,
                    mandatory: true,
                    flag: "BUYER"
                }]
                setModuleItems(default_item)
                
            } else {
                swal("Error", `${response.data.message}`, 'error' )
            }
        } catch (error) {
            swal("Error", `${error}`, 'error' )
            handleClose()
        }
    }

    const handleClose = () => {
        setOpen({...open, open : false, name : "" });
        setRoleName("")
        setIdRole("")
    };

    const handleClickOpenModule = (row:any) => {
        setModuleDetail(row.modules)
        setOpenModule(true);
    };

    const handleCloseModule = () => {
        setOpenModule(false);
    };

    const proceedOptionsFeatures = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsChild = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name })
        }
        return data_options
    }

    const proceedOptionsParent = (data : any) => {
        let data_options = []
        for(let element of data) {
            data_options.push({ value: element._id, label: element.name, childs : element.childs })
        }
        return data_options
    }

    const onUpdateRoles = (row:any) => {
        let modules = [...row.modules]

        let array_modules = []
        for(let element of modules) {
            array_modules.push({
                selectedParent : { value : element.module_detail._id, label: element.module_detail.name  },
                selectedChild : element.module_detail.has_child ? proceedOptionsChild(element.child_navigations) : [],
                selectedFeatures: proceedOptionsFeatures(element.features),
                optionFeatures : proceedOptionsParent(element.all_features_parents),
                optionChild : proceedOptionsParent(element.all_child_navigations_parents),
                has_child_module : element.module_detail.has_child,
                mandatory: true,
            })
        }
        setModuleItems(array_modules)
        setRoleName(row.name)
        setIdRole(row._id)
        handleClickOpen("Edit")
        // console.log(array_modules, 'array_modules')

    }

    // harus update validasi !!
    const onSubmit = (e : any) => {
        e.preventDefault()

        if(moduleItems.length === 0) {
            swal('Error', "Please at least pick one module!", "error")
        } else {
            let module_array : any = [...moduleItems]
            let array_send = []
            for(let element of module_array) {
                let array_childs = []
                let array_features = []
                if(element.selectedChild.length !== 0) {
                    for(let i of element.selectedChild) {
                        array_childs.push(i.value)
                    }
                }
                if(element.selectedFeatures.length !== 0) {
                    for(let i of element.selectedFeatures) {
                        array_features.push(i.value)
                    }
                }
                array_send.push({
                    id_modules: element.selectedParent.value,
                    features: array_features,
                    childs : array_childs,
                    flag: "BUYER",

                })
            }
            if(open.name === "Create") {
                let body_send = {
                    name : roleName,
                    default : false,
                    buyer_id : userCredentials.buyer_id,
                    modules : array_send,
                    flag : "BUYER",
                }
                // console.log(body_send, 'body send post')
                dispatch(postRolesCustom(body_send))
            } else {
                let body_update = {
                    body : {
                        name : roleName,
                        modules : array_send,
                        flag : "BUYER"
                    },
                    _id : IdRole
                }
                // console.log(body_update, 'body update')
                dispatch(updateRolesCustom(body_update))
            }
        }
    }

    useEffect(() => {
        getFeaturesByid()
        // eslint-disable-next-line
    }, []);

    // /* istanbul ignore next */
    const columns: TableColumn<any>[] = [
        {
            name: 'NO',
            width: '70px',
            cell: (row, index) => (
                <p>{index + 1}</p>
            )
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'TYPE',
            selector: row => row.default ? 'Default' : 'Custom',
        },
        {
            name: 'MODULES',
            cell : (row) => (
                <Stack>
                    <Button variant='outlined' size='small' onClick={() => handleClickOpenModule(row)}>View Modules</Button>
                </Stack>
            )
        },  
        {
            name: 'ACTION',
            width: '200px',
            cell: (row) => (
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant="outlined" color="primary" size="small"
                        disabled={row.default}
                        onClick={() => {
                            // setValue("name", row.name);
                            // setIDRow(row._id)
                            onUpdateRoles(row)
                        }}
                    >
                        EDIT
                    </Button>
                    {/* <Button onClick={() => onRemoveCapability(row) } variant="outlined" color="error" size="small">
                        Delete 
                    </Button> */}
                </Stack>
            ),
        },
    ];
    
    useEffect(() => {
        handleClose()
        dispatch(fetchRolesCustom())
        // eslint-disable-next-line
    },  [store_custom_role.create, store_custom_role.update, store_custom_role.remove]);


    return (
        <div>
            <Box pb={2}>
                <BreadCrumbs
                    current="Custom Roles"
                    isPage={false}
                />
            </Box>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <h3>Roles</h3>
                <Button 
                    variant="contained" color="primary" size="small" 
                    onClick={() => {
                        handleClickOpen("Create")
                        getFeaturesByid()
                    }}
                >
                    Create New
                </Button>
            </Stack>
            <Box pt={4}>
                <TableData 
                    columns={columns}   
                    data={store_custom_role.data}
                    progressPending={store_custom_role.loading}
                />
            </Box>

            <Dialog 
                open={open.open} 
                fullWidth={true}
                maxWidth="xl"
            >
                <form onSubmit={onSubmit}>
                    <DialogTitle>{open.name} Custom Role</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="name"
                            label="Role Name"
                            name="name"
                            size='small'
                            onChange={(e) => setRoleName(e.target.value)}
                            value={roleName}
                            margin='dense'
                            required
                            fullWidth
                        />

                        <Box pt={2}>
                            <ModuleItems 
                                moduleItems={moduleItems}
                                setModuleItems={setModuleItems}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="error">Cancel</Button>
                        { open.name === "Edit" ? 
                        <Button type="submit" variant="outlined">{ store_custom_role.loading_update ? "Loading.." : "Save" }</Button> :
                        <Button type="submit" variant="outlined">{ store_custom_role.loading_create ? "Loading.." : "Submit" }</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog 
                open={openModule} 
                fullWidth={true}
                maxWidth="xl"
            >
                <div>
                    <DialogTitle>Detail Modules</DialogTitle>
                    <DialogContent>
                        <Box pt={2}>
                            <ModuleDetail 
                                moduleItems={moduleDetail}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModule} color="error">Close</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )   
}

export default CustomRole
