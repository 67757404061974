import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'; 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Box from '@mui/material/Box';
import logo from '../assets/img/logo.png'
import logop from '../assets/img/logo-p.png'
import Feedback from './Feedback';
import { Button, Card, Menu, Skeleton } from '@mui/material';
import {  Stack } from '@mui/material';
import { checkLoginSessionTime, userCredentials } from '../utilities/config';
import { Offline, Online } from "react-detect-offline";
import { useIdleTimer } from 'react-idle-timer'
import NotificationBell from './Notification'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getCategoryByLevel } from '../features_app/dashboard/productsearch/reducers/productsReducers';
import CloseIcon from '@mui/icons-material/Close';
import { filterBreadcrumbs, filterCategories } from '../features_app/dashboard/productsearch/productsSlice';
import PurchaseRequestOpen from '../features_app/dashboard/productsearch/components/PurchaseRequestOpen';
import ProductComparation from '../features_app/dashboard/productsearch/components/ProductComparation';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import MuiDrawer from '@mui/material/Drawer';
import ChatNotif from '../features_app/dashboard/chat/components/ChatNotif';

const drawerWidth = 205;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerMini = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


interface Props {
    window?: () => Window;
}

const onClickRefresh = () => {
    window.location.reload()
}

const onClickSignOut = () => {
    localStorage.clear()
    window.location.href = "/"
}

const NavBarProduct = (props: Props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector((state : RootState) => state.login )
    const category_product = useSelector((state : RootState) => state.detail_product )

    const [refetch, setRefetch] = useState(false);
    
    const [dropDownOpen, setDropdownOpen] = React.useState({
        name : '',
        open : false
    });
        
    const handleDropDownMenu = (name : string) => {
        if(dropDownOpen.name === name) {
            setDropdownOpen({...dropDownOpen, name : name, open : !dropDownOpen.open });
        } else {
            setDropdownOpen({...dropDownOpen, name : name, open : true });
        }
    };

    const [openDraw, setOpenDraw] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpenDraw(true);
    };
  
    const handleDrawerClose = () => {
      setOpenDraw(false);
    };

    // dropdown menu 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event : any) => {
        setAnchorEl(event.currentTarget);
    };

    /* istanbul ignore next */
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    /* istanbul ignore next */
    const id = 2 ? 'simple-popover' : undefined;

    function checkModule () {
        if(userCredentials.modules_path.find((ele:any) => ele === "/dashboard/products")) {
            return (
                <Box ml={2} sx={{ display: { xs: 'none', sm: 'none', lg : 'block' } }}>
                    { userCredentials.first_time ? null : 
                    <NavLink exact to="/dashboard/products" activeClassName="active-link">
                        <Button color="info" variant='contained' size="small"
                        >Browse Product</Button>
                    </NavLink> }
                </Box>
            )
        } else {
            return null
        }
    }

    useEffect(() => {
        if(checkLoginSessionTime()) {
            onClickSignOut()
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        dispatch(getCategoryByLevel(0))
        // eslint-disable-next-line
    }, []);


    const handleOnIdle = (event : any) => {
        onClickSignOut()
        getRemainingTime()
    }
    

    const { getRemainingTime } = useIdleTimer({
        timeout: 3600000,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const styleProductPage = () => {
        if( (location.pathname === "/dashboard/products" && location.search === "" ) || location.pathname === "/dashboard/detail/vendor") {
            // eslint-disable-next-line
            return { marginTop: 6.5 }
        }
        return { marginTop: 0 }
    }

    const drawerComponent = (
    <div>   
        <Box className="sidebar-navbar" sx={styleProductPage()} onMouseEnter={handleDrawerOpen}>
            { userCredentials.modules_menu.map((nav:any, index:any) => (
                <div key={index}>
                { nav.child_navigations.length === 0 ?
                <>
                <NavLink exact to={nav.module_detail.path} activeClassName="active-link" >
                    <ul>
                        <li className="navbar-list">
                            <div className="icon">
                                <Box
                                    component="img"
                                    src={nav.module_detail.iconImage}
                                    sx={{}}
                                />
                            </div>
                            { openDraw ? <span>{nav.module_detail.name}</span> : null }
                        </li>
                    </ul>
                </NavLink>
                <Divider />
                </>
                : 
                <>
                <div className="sidebar-navbar-dropdown" onMouseLeave={() => handleDropDownMenu(nav.module_detail.name)} onMouseEnter={() => handleDropDownMenu(nav.module_detail.name)}>
                    <div className="menu-dropdown">
                        <Stack flexDirection="row">
                            <div className="icon">
                                <Box
                                    component="img"
                                    src={nav.module_detail.iconImage}
                                />
                            </div>
                            <Box ml={1} mr={1}> { openDraw ? <span>{nav.module_detail.name}</span> : null }</Box>
                            <Box>
                                { dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 
                                <ArrowDropUpIcon/> : <ArrowDropDownIcon/>  }
                            </Box>
                        </Stack>
                    </div>
                    <div className={dropDownOpen.name === nav.module_detail.name && dropDownOpen.open ? 'menu-list-dropdown-active' : 'menu-list-dropdown'}>
                        { nav.child_navigations.map((child:any, indexchild:any) => (
                        <div key={indexchild}>
                        <Divider />
                        <NavLink exact to={child.path} activeClassName="active-link" >
                            <ul>
                                <li className="navbar-list-child">
                                    <div className="icon">
                                        <Box
                                            component="img"
                                            src={child.iconImage}
                                            sx={{ width: '23px', height: '23px' }}
                                        />
                                    </div>
                                    { openDraw ? <span>{child.name}</span> : null }
                                </li>
                            </ul>
                        </NavLink>
                        <Divider />
                        </div>
                        ))}
                    </div>
                </div>
                <Divider />
                </>
                }
                </div>
            ))}
            <Feedback disabled={userCredentials.first_time ? true : false} openDraw={openDraw} />
        </Box>
    </div>
  );

  const [currentLevel, setCurrentLevel] = useState<any>({
      name : "",
      child : []
  });

  const [arrayHierarchyAllLevel, setArrayHierarchyAllLevel] = useState<any>([]);
  const [categoriesBreadcrumbs, setCategoriesBreadcrumbs] = useState<any>([]);

  const [arrayHierarchyLevel0, setArrayHierarchyLevel0] = useState<any>([]);
  const [arrayHierarchyLevel1, setArrayHierarchyLevel1] = useState<any>([]);
  const [arrayHierarchyLevel2, setArrayHierarchyLevel2] = useState<any>([]);
  const [arrayHierarchyLevel3, setArrayHierarchyLevel3] = useState<any>([]);
//   const [arrayHierarchyLevel4, setArrayHierarchyLevel4] = useState<any>([]);
//   const [arrayHierarchyLevel5, setArrayHierarchyLevel5] = useState<any>([]);

  const [hierarchyLevel0, setHierarchyLevel0] = useState("");
  const [hierarchyLevel1, setHierarchyLevel1] = useState("");
  const [hierarchyLevel2, setHierarchyLevel2] = useState("");
  const [hierarchyLevel3, setHierarchyLevel3] = useState("");
  const [hierarchyLevel4,] = useState("");
//   const [hierarchyLevel5, setHierarchyLevel5] = useState("");

  const onClickCategoryLevel = (item : any) => {
    // console.log(item, 'item')
    if(item.level === 0) {
        let hierarchy_lvl0 = [`hierarchicalCategories.lvl0: ${item.name}`]
        setHierarchyLevel0(item.name)
        setArrayHierarchyLevel0(hierarchy_lvl0)
        if(!item.child) {
            setArrayHierarchyAllLevel(hierarchy_lvl0)
            let breadcrumbs = [{
                filter : hierarchyLevel0,
                name : item.name,
                link: false
            }]
            setCategoriesBreadcrumbs(breadcrumbs)
        } else {
            setArrayHierarchyAllLevel([])
        }
    } else if (item.level === 1) {
        let hierarchy_lvl1 = [`hierarchicalCategories.lvl1: ${hierarchyLevel0} > ${item.name}`]
        setHierarchyLevel1(item.name)
        setArrayHierarchyLevel1(hierarchy_lvl1)
        if(!item.child) {
            let copy_array = [...arrayHierarchyLevel0,...hierarchy_lvl1]
            setArrayHierarchyAllLevel(copy_array)
            let breadcrumbs = [
                {
                    filter : arrayHierarchyLevel0,
                    name : hierarchyLevel0,
                    link: true,
                },
                {
                    filter : hierarchy_lvl1,
                    name : item.name,
                    link: false
                }
            ]
            setCategoriesBreadcrumbs(breadcrumbs)
        }
    } else if (item.level === 2) {
        let hierarchy_lvl2 = [`hierarchicalCategories.lvl2: ${hierarchyLevel0} > ${hierarchyLevel1} > ${item.name}`]
        setHierarchyLevel2(item.name)
        setArrayHierarchyLevel2(hierarchy_lvl2)
        if(!item.child) {
            let copy_array = [...arrayHierarchyLevel0,...arrayHierarchyLevel1,...hierarchy_lvl2]
            setArrayHierarchyAllLevel(copy_array)
            let breadcrumbs = [
                {
                    filter : arrayHierarchyLevel0,
                    name : hierarchyLevel0,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel1,
                    name : hierarchyLevel1,
                    link: true
                },
                {
                    filter : hierarchy_lvl2,
                    name : item.name,
                    link: false
                }
            ]
            setCategoriesBreadcrumbs(breadcrumbs)
        }
    }  else if (item.level === 3) {
        let hierarchy_lvl3 = [`hierarchicalCategories.lvl3: ${hierarchyLevel0} > ${hierarchyLevel1} > ${hierarchyLevel2} > ${item.name}`]
        setHierarchyLevel3(item.name)
        setArrayHierarchyLevel3(hierarchy_lvl3)
        if(!item.child) {
            let copy_array = [...arrayHierarchyLevel0,...arrayHierarchyLevel1,...arrayHierarchyLevel2,...hierarchy_lvl3]
            setArrayHierarchyAllLevel(copy_array)
            let breadcrumbs = [
                {
                    filter : arrayHierarchyLevel0,
                    name : hierarchyLevel0,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel1,
                    name : hierarchyLevel1,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel2,
                    name : hierarchyLevel2,
                    link: true
                },
                {
                    filter : hierarchy_lvl3,
                    name : item.name,
                    link: false
                }
            ]
            setCategoriesBreadcrumbs(breadcrumbs)
        }
    } else if (item.level === 4) {
        let hierarchy_lvl4 = [`hierarchicalCategories.lvl4: ${hierarchyLevel0} > ${hierarchyLevel1} > ${hierarchyLevel2} > ${hierarchyLevel3} > ${item.name}`]
        if(!item.child) {
            let copy_array = [...arrayHierarchyLevel0,...arrayHierarchyLevel1,...arrayHierarchyLevel2,...arrayHierarchyLevel3, hierarchyLevel4]
            setArrayHierarchyAllLevel(copy_array)
            let breadcrumbs = [
                {
                    filter : arrayHierarchyLevel0,
                    name : hierarchyLevel0,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel1,
                    name : hierarchyLevel1,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel2,
                    name : hierarchyLevel2,
                    link: true
                },
                {
                    filter : arrayHierarchyLevel3,
                    name : item.name,
                    link: false
                },
                {
                    filter : hierarchy_lvl4,
                    name : item.name,
                    link: false
                }
            ]
            setCategoriesBreadcrumbs(breadcrumbs)
        }
    }
  }


  useEffect(() => {
    if(arrayHierarchyAllLevel.length) {
        handleCloseMenu()
        dispatch(filterCategories(arrayHierarchyAllLevel))
        dispatch(filterBreadcrumbs(categoriesBreadcrumbs))
    }
    // eslint-disable-next-line
  }, [arrayHierarchyAllLevel]);

  const listCategory = () => (
    <Box
      role="presentation"
      sx={{ pl: 1 }}
    >
    { category_product.loading_category ? 
      <List>
        {[1,2,3,4,5,6].map((text, index) => (
          <ListItem button key={text}>
            <Skeleton/>
          </ListItem>
        ))}
      </List>
      :
      <>
      { currentLevel.child.length ? 
      <List>
        <ListItem 
            button 
            onClick={ () => { 
                setArrayHierarchyAllLevel([])
                if(currentLevel.level === 0) {
                    setCurrentLevel({...currentLevel, 
                        name : currentLevel.name_level_0,
                        name_level_0 : currentLevel.name_level_0,
                        child_level_0 : currentLevel.child_level_0,
                        name_level_1 : currentLevel.name_level_1,
                        child_level_1 : currentLevel.child_level_1,
                        name_level_2 : currentLevel.name_level_2,
                        child_level_2 : currentLevel.child_level_2,
                        name_level_3 : currentLevel.name_level_3,
                        child_level_3 : currentLevel.child_level_3,
                        level:currentLevel.level,
                        child : []
                    })
                } else if (currentLevel.level === 1) {
                    setCurrentLevel({...currentLevel, 
                        name : currentLevel.name_level_0,
                        name_level_0 : currentLevel.name_level_0,
                        child_level_0 : currentLevel.child_level_0,
                        name_level_1 : currentLevel.name_level_1,
                        child_level_1 : currentLevel.child_level_1,
                        name_level_2 : currentLevel.name_level_2,
                        child_level_2 : currentLevel.child_level_2,
                        name_level_3 : currentLevel.name_level_3,
                        child_level_3 : currentLevel.child_level_3,
                        level: 0,
                        child : currentLevel.child_level_1
                    })
                } else if (currentLevel.level === 2) {
                    setCurrentLevel({...currentLevel, 
                        name : currentLevel.name_level_1,
                        name_level_0 : currentLevel.name_level_0,
                        child_level_0 : currentLevel.child_level_0,
                        name_level_1 : currentLevel.name_level_1,
                        child_level_1 : currentLevel.child_level_1,
                        name_level_2 : currentLevel.name_level_2,
                        child_level_2 : currentLevel.child_level_2,
                        name_level_3 : currentLevel.name_level_3,
                        child_level_3 : currentLevel.child_level_3,
                        level: 1,
                        child : currentLevel.child_level_2
                    })
                } else if (currentLevel.level === 3) {
                    setCurrentLevel({...currentLevel, 
                        name : currentLevel.name_level_1,
                        name_level_0 : currentLevel.name_level_0,
                        child_level_0 : currentLevel.child_level_0,
                        name_level_1 : currentLevel.name_level_1,
                        child_level_1 : currentLevel.child_level_1,
                        name_level_2 : currentLevel.name_level_2,
                        child_level_2 : currentLevel.child_level_2,
                        name_level_3 : currentLevel.name_level_3,
                        child_level_3 : currentLevel.child_level_3,
                        name_level_4 : currentLevel.name_level_3,
                        child_level_4 : currentLevel.child_level_3,
                        level: 1,
                        child : currentLevel.child_level_2
                    })
                }
            }}
        >
            <ArrowCircleLeftIcon />
            <ListItemText primary={`${currentLevel.name}`} sx={{pl:1}}  />
        </ListItem>
        { currentLevel.child.map((text:any, index:any) => (
            <ListItem 
                button key={index} 
                onClick={() => { 
                    onClickCategoryLevel(text)
                    if(text.child.length) {
                        if(text.level === 1) {
                            setCurrentLevel({...currentLevel, 
                                name : currentLevel.name_level_1,
                                name_level_0 : currentLevel.name_level_0,
                                child_level_0 : currentLevel.child_level_0,
                                name_level_1 : currentLevel.name_level_1,
                                child_level_1 : currentLevel.child_level_1,
                                name_level_2 : text.name,
                                child_level_2 : text.child,
                                name_level_3 : "",
                                child_level_3 : [],
                                level: text.level,
                                child : text.child
                            })
                        } else if (text.level === 2) {
                            setCurrentLevel({...currentLevel, 
                                name : currentLevel.name_level_2,
                                name_level_1 : currentLevel.name_level_1,
                                child_level_1 : currentLevel.child_level_1,
                                name_level_2 : currentLevel.name_level_2,
                                child_level_2 : currentLevel.child_level_2,
                                name_level_3 : text.name,
                                child_level_3 : text.child,
                                name_level_4 : "",
                                child_level_4 : [],
                                level: text.level,
                                child : text.child
                            })
                        }
                    }
                }}
            >
                <ListItemText primary={text.name} sx={{pr:2}} />
                { text.child.length ?  
                <ListItemIcon>
                    <ChevronRightIcon />
                </ListItemIcon> : null }
            </ListItem>
        ))}
      </List>
      :
      <List>
        { category_product.category.map((text:any, index:any) => (
            <ListItem 
                button key={index} 
                onClick={() => { 
                    onClickCategoryLevel(text)
                    if(text.child.length) {
                        setCurrentLevel({...currentLevel, 
                            name : "Main Category",
                            name_level_0 : "Main Category",
                            child_level_0 : category_product.category,
                            name_level_1 : text.name,
                            child_level_1 : text.child,
                            name_level_2 : "",
                            child_level_2 : [],
                            name_level_3 : "",
                            child_level_3 : [],
                            level: text.level,
                            child : text.child
                        })
                    } 
                }}
            >
                <ListItemText primary={text.name} sx={{pr:2}} />
                { text.child.length ?  
                <ListItemIcon>
                    <ChevronRightIcon />
                </ListItemIcon> : null }
            </ListItem>
        ))}
        </List>  
        }
    </>}
    </Box>
  );


    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorElMenu);

    const handleClickMenu = (event: any) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };


    return (
        <div>
            {/* <CssBaseline /> */}
            <AppBar
                position="fixed"
                open={openDraw}
            >
                <Toolbar>
                    <Box 
                        className="logo-mpi-p" 
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <a href="/dashboard">
                            <img 
                                alt="logo mpi dashboard" 
                                src={logop}
                            />
                        </a>
                    </Box>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onMouseEnter={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(openDraw && { display: 'none' }),
                        }}
                    >
                        <DoubleArrowIcon sx={{color:'#fff'}}  />
                    </IconButton>
                    {checkModule()}
                    <Box sx={{ display: { xs: 'none', sm: 'none', lg : 'block' } }}>
                        <Stack 
                            pl={2} pt={1} 
                            flexDirection="row"
                            sx={{ cursor: 'pointer' }}
                            textAlign="center"
                            onClick={onClickRefresh}
                        >
                            <Box sx={{paddingTop: '3px'}}>Reload</Box>
                            <Box>
                                <AutorenewIcon />
                            </Box>
                        </Stack>
                    </Box>
                    <div style={{flexGrow: 1}} />
                    <Box pr={4} sx={{ display: { xs: 'none', sm: 'none', lg : 'block' } }} >
                        <div>
                            <Online>
                                <Box
                                    sx={{
                                        border: '1px solid #fff',
                                        borderRadius: '4px',
                                        padding: '4px 20px',
                                        fontSize: '14px'
                                    }} 
                                
                                >You are Connected.</Box>
                            </Online>
                            <Offline>
                                <Box
                                    sx={{
                                        border: '1px solid red',
                                        backgroundColor: 'red',
                                        borderRadius: '4px',
                                        padding: '4px 20px',
                                        fontSize: '14px'
                                    }} 
                                >Disconnected from server, Please Check your internet!</Box>
                            </Offline>
                        </div>
                    </Box>
                    { !!auth.login && <>
                        {/* <Box pr={1} pl={1}><ChatNotif /></Box> */}
                        <Box pr={1} pl={1}><NotificationBell/></Box>
                    </>}
                    <Box>
                        <div 
                            className="right-navbar"  
                            data-testid="dropdownButton"
                            onClick={handleClick}
                        >
                            <Box pl={1}> {auth?.data?.fullname}</Box>
                            <Box pl={1}> <ArrowDropDownIcon/></Box>
                        </div>
                        {/* Dropdown Menu */}
                        <Popover
                            data-testid="dropdown"
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className="popper-style"
                        >
                        <Box>
                            <div style={{ width: 150 }}>
                                <Card>
                                    <Box p={1}>
                                        <Box pt={1} pb={1} fontSize="14px">
                                            You login as <br/> 
                                            <span style={{ fontWeight: 500 }}>{userCredentials.role.name}</span>
                                        </Box>
                                        <Button 
                                            color="error"
                                            variant="outlined" fullWidth 
                                            size='small'
                                            sx={{mb:1}}
                                            onClick={onClickSignOut}
                                        >
                                            Logout
                                        </Button>
                                    </Box>
                                </Card>
                            </div>
                        </Box>
                        </Popover>
                    </Box>
                </Toolbar>
                
                { (location.pathname === "/dashboard/products" && location.search === "" ) || location.pathname === "/dashboard/detail/vendor"  ? 
                <Stack sx={{ backgroundColor: '#368039', height: 20, pl: 3, pt: 2, pb: 2, }} flexDirection="row" justifyContent="space-between" alignItems="center" > 
                    <Stack id="basic-button" flexDirection="row" alignItems="center" onClick={handleClickMenu}  sx={{ cursor: 'pointer', width: 'fit-content' }}>
                        <Box sx={{ paddingTop: '4px' }}>
                            <MenuIcon/>
                        </Box>
                        <Box pl={1} fontWeight={600} fontSize={15}>
                            ALL CATEGORY
                        </Box>
                    </Stack>
                    <Stack flexDirection="row" alignItems="center" >
                        <Box> <PurchaseRequestOpen/></Box>
                        <Box pb={1}>
                            <ProductComparation  
                                refetch={refetch}
                                setRefetch={setRefetch}
                            />
                        </Box>
                    </Stack>
                </Stack> : "" }

                <Menu
                    id="basic-menu"
                    anchorEl={anchorElMenu}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    sx={{marginTop: 1}}
                >
                    <Stack sx={{ minWidth: '300px' }}>
                        <Stack sx={{px:2, pt: 2}} flexDirection="row" justifyContent="space-between" alignItems="center" >
                            <Box fontWeight={600} >Search by Category</Box>
                            <Box><CloseIcon sx={{cursor: 'pointer' }} onClick={handleCloseMenu} /></Box>
                        </Stack>
                        <Stack>
                            {listCategory()}
                        </Stack>
                    </Stack>
                </Menu>
            </AppBar>

            <DrawerMini
                variant="permanent"
                open={openDraw}
                onMouseLeave={handleDrawerClose}
            >
                <DrawerHeader sx={{ backgroundColor: '#43a047',display:'flex', justifyContent:'left' }}>
                    <Toolbar>
                        <div className="logo-mpi">
                            <a href="/dashboard">
                                <img 
                                    alt="logo mpi dashboard" 
                                    src={logo}
                                />
                            </a>
                        </div>
                    </Toolbar>
                    {/* <IconButton onClick={handleDrawerClose}>
                       <KeyboardDoubleArrowLeftIcon sx={{color:'#fff', fontSize: 30}} />
                    </IconButton> */}
                </DrawerHeader>
                <Divider />
                {drawerComponent}
            </DrawerMini>
        </div>
    )
}

export default NavBarProduct;
