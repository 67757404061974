import { Box } from '@mui/system'
import { Stack } from '@mui/material';
import BreadCrumbs from '../../../../components/BreadCrumbs'
import { useLocation } from 'react-router-dom';
import BoxSearchTemplate from './components/BoxSearchTemplate';

function CreateNewTemplate() {
    const location = useLocation()
    const state_location : any = location.state

    return ( 
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                current={state_location === undefined ? "Create New Template" : `${state_location.name} Template` }
                isPage={true}
                page="Template"
                link="/dashboard/templates"
            />
            <Stack direction="row" justifyContent="space-between" pt={3} >
                <Box>
                    <h3>{state_location === undefined ? "Create New" : state_location.name } Template</h3>
                </Box>
            </Stack>
            <Stack pt={3} >
                <BoxSearchTemplate />
            </Stack>
        </Box>
    )
}

export default CreateNewTemplate;
