import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom';
import BreadCrumbs from '../../../../../components/BreadCrumbs'
import PaymentCheckout from './components/PaymentCheckout';
import PaymentConfirmed from './components/PaymentConfirmed';
import WaitingPayment from './components/WaitingPayment';

function PaymentPage() {

    const location : any = useLocation()

    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'waiting-payment') {
            return <WaitingPayment/>
        } else if (active_page === 'payment-confirmed') {
            return <PaymentConfirmed/> 
        } else {
            return <PaymentCheckout/>
        }
    } 

    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            <BreadCrumbs 
                isPage={true}
                page="Invoice"
                link="/dashboard/finance/invoice"
                current="Payment Checkout"
            />
            {switchPage()}
        </Box>
    )
}

export default PaymentPage
