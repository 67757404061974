import { Stack, Box, Button, Paper } from '@mui/material'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDataInvoice } from '../../reducers/invoiceReducers';


function PaymentConfirmed() {

    const dispatch = useDispatch()
    const location : any = useLocation()
    const state_payment = location.state === undefined ? null : location.state.data

    useEffect(() => {
        if(state_payment === null) {
            window.location.href = '/dashboard/finance/invoice'
        } 
    }, [state_payment]);

    useEffect(() => {
        dispatch(getDataInvoice())
        // eslint-disable-next-line
    }, []);


    return (
        <Box sx={{pt:2, pl:3, pr:3}}>
            { state_payment === null ? null : 
            <Box>
                <Stack pt={3} >
                    <Box>
                        <h3>Payment Confirmed</h3>
                    </Box>
                </Stack>
                <Stack pt={3} >
                    <Paper sx={{ border: '2px solid #ddd', textAlign: 'center' }}>
                        <Stack p={3} flexDirection="row" justifyContent="center">
                            <Paper sx={{ textAlign: 'center', width: '40%', border: '2px solid #28c76f' }}>
                                <Box p={3}>
                                    <Box fontWeight="500" fontSize="30px" color="#28c76f">Yeay! Payment Received</Box>
                                    <Box fontWeight="400" fontSize="15px">Thankyou for the transaction.</Box>
                                    <Stack pt={2}>
                                        <Box fontSize="15px">Total Amount</Box>
                                        <Box fontSize="20px" fontWeight="500">Rp. {state_payment.amount.toLocaleString()}</Box>
                                    </Stack>
                                    <Box pt={3}>
                                        <Button variant="outlined" color="info"
                                         size="small" 
                                            onClick={() => {
                                                window.location.href= "/dashboard/finance/invoice"
                                            }}
                                        >
                                            Back to Invoice
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        </Stack>
                    </Paper>
                </Stack>
            </Box> }
           
        </Box>
    )
}

export default PaymentConfirmed
