import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom';
import DetailProductNew from './DetailProductNew';
import ProductSearchNew from './ProductSearchNew';
import ProductSearchVendor from './ProductSearchVendor';
// import ChatFloating from '../chat/ChatFloating';

function MainProductPage() {

    const location : any = useLocation()
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    
    let query = useQuery();
    let active_page = query.get('page')

    const switchPage = () => {
        if (active_page === 'detail-product') {
            return <DetailProductNew/>
        } else if (active_page === 'vendor-store') {
            return <ProductSearchVendor/>
        } else {
            return <ProductSearchNew/>
        }
    }
 
    return (
        <Stack>
            {switchPage()}
            {/* <ChatFloating/> */}
        </Stack>
    )
}

export default MainProductPage;
