import React, { useState, useEffect } from 'react'
import { 
    Box, 
    Paper, 
    Grid, 
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableItem from './TableItem';
import moment from 'moment'
import UploadProof from './UploadProof';
import { userCredentials } from '../../../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrdersDPLoadMore, postProofDownPayment } from '../reducers/purchaseOrdersReducers';
import { RootState } from '../../../../app/store';
import swal from 'sweetalert'
import { createNewChat, getUsersForChat } from '../../chat/reducers/chatReducers';
import { getAllUsersTeams } from '../../teams/reducers/teamsReducers';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderProgress from '../../../../components/LoaderProgress';


const CardOrderDP : React.FC<any> = ({ data, dataSearch, activeStatus }) => {
    const dispatch = useDispatch()

    const store_purchaseorders = useSelector((state : RootState) => state.purchase_orders)
    const store_chat = useSelector((state : RootState) => state.chat)
    const state_teams = useSelector((state : RootState) => state.users_team)

    const [dataOrder, setDataOrder] = useState<any>([]);
    const [hasMore,setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    
    const [value, setValue] = useState(0);
    const [defaultValue, setDefaultValue] = useState(0);
    const [open, setOpen] = useState(false); 
    const [dataImage, setDataImage] = useState("");
    const [codePO, setCodePO] = useState("");
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState<any>(false);
    const [idxItem, setIdxItem] = useState();
    const [loadingMessage, setLoadingMessage] = useState(false);
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDataImage("")
        setLoading(false)
    };

    const handleChangeAccordion =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        if(isExpanded) {
            setValue(value);
        } else {
            setValue(defaultValue)
        }
    };

    // const handleChange = (event: any, value : any) => {
    //     setValue(value);
    // };


    function getPackageByStatus(data:any, status: string) {
        let data_package = data.filter((key : any) => key.lastStatus === status)
        return data_package
    }

    const onClickSubmit = () => {
        if(dataImage !== "") {
            const body = {
                "id": codePO,
                "fileUrl": dataImage,
                "uploader": userCredentials.fullname
            }
            dispatch(postProofDownPayment(body))
        }
    }


    // useEffect(() => {
    //     if(location.state !== null && location.state !== undefined ) {
    //         setExpanded("0");
    //         setValue(location.state.value);
    //     } 
    //     // eslint-disable-next-line
    // }, [location]);


    

    const onClickCreateChat = async (row : any, i : any) => {
        setLoadingMessage(true)
        setIdxItem(i)

        const user_vendor : any = await dispatch(getUsersForChat(row.vendor._id))

        if(user_vendor.type === "chat-user/get/fulfilled") {
            let data_response = user_vendor.payload.data.data
            let user_names = []
            for(let k of data_response) {
                user_names.push(`${k.fullname} - ${k.company_code}`)
            }
            for(let l of state_teams.data) {
                user_names.push(`${l.fullname} - ${l.company_code}`)
            }
            let body = {
                username:  `${userCredentials.fullname} - ${userCredentials.company_code}`,
                secret : userCredentials.buyer_id, 
                title : `${row.vendor.name} & ${userCredentials.company_detail.legal_name}`,
                usernames : user_names
            }
            dispatch(createNewChat(body))
        } 

        // console.log(body, 'body')
    }

    const fetchMoreData = async () => {
        const body = {
            searchTopic : dataSearch.searchTopic,
            searchValue: dataSearch.searchValue,
            rangeTopic : dataSearch.rangeTopic,
            rangeFrom: dataSearch.rangeFrom,
            rangeTo: dataSearch.rangeTo,
            limit: dataSearch.limit,
            skip: page + 1,
            search : dataSearch.search,
        }
        const newdata : any = await dispatch(getPurchaseOrdersDPLoadMore(body))
        if(newdata.type === "purchase-orders-dp-loadmore/get/fulfilled"){
            setPage(page + 1)
            let array = [...dataOrder, ...newdata.payload.data.data]
            if(newdata.payload.data.data.length === 0){
                setHasMore(false)
            } else {
                setHasMore(true)
                setDataOrder(array)
            }
        } else {
            swal("Error", newdata.payload.message, "error")
        }
    };

    useEffect(() => {
        if(store_chat.chat) {
            setLoadingMessage(false)
            window.location.href = '/dashboard/message'
        }
    }, [store_chat.chat]);

    useEffect(() => {
      if(activeStatus === "New" ) {
        setValue(0)
        setDefaultValue(0)
      } else if(activeStatus === "Pick" ) {
        setValue(1)
        setDefaultValue(1)
      } else if(activeStatus === "Ready to Ship" ) {
        setDefaultValue(2)
        setValue(2)
      } else if(activeStatus === "Shipped" ) {
        setDefaultValue(3)
        setValue(3)
      } else if(activeStatus === "Delivered" ) {
        setDefaultValue(4)
        setValue(4)
      } else if(activeStatus === "Paid" ) {
        setDefaultValue(5)
        setValue(5)
      } else if(activeStatus === "Cancel" ) {
        setDefaultValue(6)
        setValue(6)
      } 
      
    }, [activeStatus]);

    useEffect(() => {
        setDataOrder(data)
        if(data.length < dataSearch.limit) {
            setHasMore(false)
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(getAllUsersTeams())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(store_purchaseorders.proof) {
            handleClose()
            swal('Success', "Success upload image proof, Please waiting vendor to confirmation.", 'success')
            setTimeout(() => {
                window.location.reload()
            }, 2500);
        }
    }, [store_purchaseorders.proof]);

    const scrollToTop = () => {
        // @ts-ignore
        document.getElementById("scrollableDiv").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
    <Stack>
        <div>
            <InfiniteScroll
                dataLength={dataOrder.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<LoaderProgress/>}
                scrollableTarget="scrollableDiv"
                endMessage={
                    <Stack flexDirection="column" justifyContent="center" alignItems="center" mt={5} mb={5}>
                        <Box>
                            <b>No More Order...</b>
                        </Box>
                        <Box pt={1}>
                            <Button onClick={() => scrollToTop()} size="small" variant='contained'>
                                <p>Back to Top</p>
                            </Button>
                        </Box>
                    </Stack>
                }
            >
            { dataOrder?.map((val : any, i : any) => (
                <Box mb={4} mt={4} key={i} >
                    <Paper elevation={2} sx={{ borderLeft: '3px solid #43a047', borderRight: '3px solid #43a047', borderBottom: '3px solid #43a047' }}>
                        <Paper sx={{ borderTop: '3px solid #43a047' }}>
                            <Grid container spacing={2} justifyContent="space-between" sx={{pt:1, pb:1}}>
                                <Grid item>
                                    <Box fontWeight="bold" pl={2} fontSize={14}>Order ID : {val.code_po}</Box>
                                    <Box fontWeight="normal" pl={2} fontSize={13}> Date : {moment(val.date).format('ll')}</Box>
                                </Grid>
                                { val.lastStatus === "Waiting Down Payment" ?
                                <Grid item>
                                   <Box pr={2}>
                                    <Button 
                                        size="small" color="error" variant="outlined"
                                        onClick={() => {
                                            setCodePO(val._id)
                                            handleClickOpen()
                                        }}
                                        disabled={val.proof_of_advance_payment ? true : false}
                                    >
                                        {val.proof_of_advance_payment ? "Waiting Vendor Confirmation" : "Upload Proof DP"}
                                    </Button> 
                                   </Box>
                                </Grid> : null }
                            </Grid>
                           
                        </Paper>
                        <Box p={2}>
                            <Grid container spacing={3} >
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" style={{display:'flex', alignItems: 'center'}}>
                                    <Box pr={1} fontSize={13}>{val.vendor.name} </Box>
                                        {/* { state_teams.loading ? "Loading" : 
                                        <Button 
                                            size="small" color="primary" variant="outlined"
                                            onClick={() => onClickCreateChat(val, i)}
                                            disabled={loadingMessage ? true : false}
                                        >
                                            { loadingMessage && idxItem === i ? "Loading" : "Contact Vendor" }
                                        </Button> } */}
                                    </Box>  
                                    <Box pt={1} fontWeight="normal" fontSize={14}>
                                        <Box style={{width: '80%'}} fontSize={12}>{val.vendor.phone}</Box>
                                        <Box style={{width: '80%'}}>{val.vendor.address}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Department : {val.department === 'generic' ? "N/A" : val.department}
                                    </Box>
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Total Price : Rp. {(val.grand_total).toLocaleString()}
                                    </Box>
                                    <Box fontWeight="bold" pt={1} fontSize={13}> 
                                        Down Payment : Rp. {val.down_payment === null ? 0 : parseInt(val.down_payment).toLocaleString()}
                                    </Box>
                                </Grid>
                            </Grid>
                            
                        </Box>

                        <Box pt={2} >
                            <Paper elevation={3}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}  expanded={expanded === `${i}`} onChange={handleChangeAccordion(`${i}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id={`panel1a-header-${val._id}`}
                                        sx={{ borderTop: '3px solid #43a047', borderBottom: `${expanded === `${i}` ? '3px solid #43a047' : ''}` }}
                                    >
                                        <Stack flexDirection="row" alignItems="center">
                                            <Box fontWeight="bold" fontSize={14} color="#000">
                                                Click here to {expanded === `${i}` ? "close" : "view"} All Packages ({val.packages?.length})
                                            </Box>
                                            <Box pl={1} pr={2}>
                                                <ExpandMoreIcon sx={{ color: '#000', marginTop: '4px' }}/>
                                            </Box>
                                        </Stack> 
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            
                                            <Paper style={{ backgroundColor: '#ddd' }}>
                                                <TableItem 
                                                    data={getPackageByStatus(val.packages, "New")}  
                                                    status="New"
                                                    department={val.department}
                                                /> 
                                            </Paper>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Box>

                    </Paper>
                </Box>
                ))}
            </InfiniteScroll>
        </div>

        {/* DIALOG UPLOAD PRROF */}
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {"Upload Proof Down Payment"}
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <UploadProof 
                        setDataImage={setDataImage}
                        dataImage={dataImage} 
                        loading={loading}
                        setLoading={setLoading}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">Close</Button>
                <Button disabled={loading} onClick={() => onClickSubmit()} variant="contained" color="primary">
                    { store_purchaseorders.loading_proof ? 
                        <div>Loading.. <CircularProgress size={20} color="inherit"/> </div> :
                        "Submit"
                    }
                </Button>
            </DialogActions>
        </Dialog>
    </Stack>
    )
}

export default CardOrderDP
