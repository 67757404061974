import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      main: `${process.env.REACT_APP_THEMED_COLOR}`
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#fb8c00'
    },
    info: {
      contrastText: '#fff',
      main: '#ff6d00'
    },
    warning: {
      contrastText: '#000',
      main: '#fff'
    },
  }
});

export default theme;
